import { CreateRoleUsersStepContent } from "#src/batteries-included-components/Panels/FormPanels/CreateRoleFormPanel/UsersStep";
import { useMultiStepFormContext } from "#src/hooks/useMultiStepForm";
import { Form, useForm } from "@validereinc/common-components";
import React, { useCallback, useEffect } from "react";

export const CreateRoleAssignMembersStep = () => {
  const { currentStep, getStep, updateStep } = useMultiStepFormContext();
  const stepDetails = getStep(2);
  const form = useForm({
    defaultValues: stepDetails?.defaultValues ?? {},
  });

  const memoizedGetFormState = useCallback(
    () => ({
      isSubmitting: form.formState.isSubmitting,
      isValid: form.formState.isValid,
    }),
    [form.formState.isSubmitting, form.formState.isValid]
  );

  useEffect(() => {
    updateStep(2, {
      getValues: () => form.getValues(),
      getFormState: memoizedGetFormState,
    });
  }, [form, memoizedGetFormState]);

  if (currentStep !== 2) {
    return null;
  }

  return (
    <Form
      {...form}
      style={{ height: "100%" }}
    >
      <CreateRoleUsersStepContent />
    </Form>
  );
};
