import { useCallback, useEffect, useState } from "react";

import debounce from "lodash/debounce";
import get from "lodash/get";
import isEqual from "lodash/isEqual";

import { useSearchParams } from "#routers/hooks";
import { SortingType } from "@validereinc/common-components";
import isNil from "lodash/isNil";

const DEBOUNCE_TIME = 300;

const useTableCallbacks = (setInternalTableState) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const onPaginationChange = (values) => {
    const { page, pageSize } = values;

    const newValues = {
      ...searchParams,
      page,
      rowPerPage: pageSize,
    };

    if (setInternalTableState) {
      setInternalTableState(newValues);
    } else {
      setSearchParams(newValues);
    }
  };

  const onSortChange = (newSort: SortingType) => {
    const { sortBy, sortDirection } = newSort;

    const newValues = {
      ...searchParams,
      sort: sortBy,
      sortDirection,
    };

    if (setInternalTableState) {
      setInternalTableState(newValues);
    } else {
      setSearchParams(newValues);
    }
  };

  return [onPaginationChange, onSortChange];
};

// TODO: remove this
type refetchIfImproperPaginationPropsType = {
  data: Partial<{ page_number: number; total_pages: number }>;
  updatePaginationCallback: (...args: any[]) => void;
  searchParams?: Record<string, any>;
};

// TODO: remove this
export const refetchIfImproperPagination = ({
  data,
  updatePaginationCallback,
  searchParams,
}: refetchIfImproperPaginationPropsType) => {
  const paginationDataExists =
    !isNil(data?.page_number) && !isNil(data?.total_pages);

  const paginationDataDataExists =
    !isNil(data?.data?.page_number) && !isNil(data?.data?.total_pages);

  if (
    (paginationDataExists && data?.page_number > data?.total_pages) ||
    (paginationDataDataExists &&
      data?.data?.page_number > data?.data?.total_pages)
  ) {
    if (!searchParams) {
      updatePaginationCallback({ page: 1 });
    } else {
      updatePaginationCallback({
        ...searchParams,
        page: 1,
      });
    }
  }
};

// TODO: improve types
type UseTableStatePropsType = {
  /** fetch request that returns a paginated response */
  onFetchData?: (arg: unknown) => Promise<any>;
  /** fetch request will only run when the isEnabled flag is true. this prop is watched for changes. */
  isEnabled?: boolean;
  /** the { header, direction } object describing the initial sort of the table
   *    if no searchParams for sorting exist. Undefined defaults to the first
   *    column in ascending order */
  initialSort?: SortingType;
  /** the dot notation of where the array of items are found in the fetch */
  itemsKey?: string;
  /** An optional string array to list the query params to track and run the
   * fetch request on changes. If undefined, will run the fetch request on any
   * query param change */
  subscription?: string[] | null;
  /** enables the multi select functionality of the table */
  selectable?: boolean;
  isUsingInternalState?: boolean;
  pageSizeText?: string;
  defaultParams?: object; // to easily allow for changing defaults ie rowPerPage
};

/**
 * Given a fetch request that returns a paginated response, runs the fetch request
 * every time a query parameter changes in subscription
 * @deprecated use useReducer and tableReducer instead along with react query. see the conventions around tables in the developer docs for more info.
 */
const useTableState = ({
  onFetchData,
  isEnabled = true,
  itemsKey = "data.data",
  subscription,
  selectable,
  initialSort,
  isUsingInternalState = false,
  pageSizeText = "rows per page",
  defaultParams = {},
}: UseTableStatePropsType) => {
  // onFetchData currently running?
  const [loading, setLoading] = useState(true);
  // loaded once?
  const [loaded, setLoaded] = useState(false);
  const [data, setData] = useState();
  const [items, setItems] = useState([]);
  const [cachedSearchParams, setCachedSearchParams] =
    useState<Record<string, string>>();
  const [selected, setSelected] = useState([]);

  const [internalTableState, setInternalTableState] = useState(initialSort);
  const [cachedInternalTableState, setCachedInternalTableState] =
    useState(initialSort);

  const [searchParams, setSearchParams] = useSearchParams();
  const [sorting] = useState(
    searchParams?.sort && !isUsingInternalState
      ? {
          sortBy: searchParams?.sort,
          sortDirection: searchParams?.sortDirection,
        }
      : initialSort
  );

  const [onPaginationChange, onSortChange] = useTableCallbacks(
    isUsingInternalState ? setInternalTableState : null
  );

  const onSelectionChange = (newSelected) => {
    setSelected(newSelected);
  };

  const fetchData = useCallback(
    debounce(async (values) => {
      setLoading(true);
      const newData = (await onFetchData(values)) || {};
      /** TODO: Replace this with a useEffect resetting the page on filter change */
      refetchIfImproperPagination({
        data: newData,
        updatePaginationCallback: setSearchParams,
        searchParams,
      });

      if (itemsKey) {
        setItems(get(newData, itemsKey, []));
      } else {
        setItems(newData);
      }

      // Specifically for elixir API calls
      // If data is an array, it is just the items
      if (newData?.data && !Array.isArray(newData.data)) {
        setData(newData?.data);
      } else {
        setData(newData);
      }

      setLoading(false);
      setLoaded(true);
    }, DEBOUNCE_TIME),
    [onFetchData, searchParams]
  );

  useEffect(() => {
    if (!isEnabled) {
      return;
    }

    const fetchDataOnCacheMiss = async () => {
      if (isUsingInternalState) {
        await fetchData({ ...internalTableState, ...newSearchParams });
      } else {
        // TODO: Replace temp fix.
        const searchParamsWithNameReplaced = {
          ...searchParams,
          ...(Array.isArray(searchParams?.name)
            ? { name: searchParams?.name.join() }
            : {}),
        };
        await fetchData({ ...defaultParams, ...searchParamsWithNameReplaced });
      }
    };

    setLoading(true);
    const newSearchParams = { ...searchParams };

    // if usingInternalState, we add subscribed properties to internalState
    // if not, we only listen to subscribed properties
    if (
      subscription?.length ||
      (isUsingInternalState && !subscription === null)
    ) {
      subscription = subscription ?? [];

      Object.keys(searchParams)?.forEach((key) => {
        if (!subscription?.includes(key)) {
          delete newSearchParams[key];
        }
      });
    }

    // only re-fetch data when the cache misses
    if (
      !isEqual(cachedSearchParams, newSearchParams) ||
      !isEqual(cachedInternalTableState, internalTableState)
    ) {
      setCachedSearchParams(newSearchParams);
      setCachedInternalTableState(internalTableState);
      fetchDataOnCacheMiss();
    } else {
      setLoading(false);
    }
  }, [searchParams, subscription, internalTableState, isEnabled]);

  const pagination =
    data?.page_number && data?.page_size && data?.total_entries
      ? {
          page: data?.page_number,
          pageSize: data?.page_size,
          total: data?.total_entries,
          pageSizeText,
        }
      : { page: 1, pageSize: 10, total: 0, pageSizeText };

  return {
    data,
    loading,
    loaded,
    fetchData,
    tableProps: {
      items,
      pagination,
      loading,
      onPaginationChange,
      onSortChange,
      sorting,
      ...(selectable ? { selected, onSelectionChange } : {}),
    },
  };
};

export default useTableState;
