import styles from "#batteries-included-components/Banners/ImportDataActionStatus/ImportDataActionStatus.module.scss";
import { RoutingLink } from "#batteries-included-components/RoutingLink";
import { getPollingOptions } from "#hooks/adapters/adapterUtils";
import {
  useClearReportsCache,
  useDownloadReport,
  useGetOneReport,
} from "#hooks/adapters/useReports";
import { TEMPLATED_REPORT_EXPORT_KEY } from "#hooks/adapters/useTemplatedReports";
import { linkToTemplatedReportList } from "#routes/reports/templated-reports";
import { useStickyState } from "#src/hooks/useStickyState";
import {
  CustomStorageKeyTypes,
  useCustomStorageKey,
} from "#src/hooks/useStorageKey";
import { Banner, IconLoading, useAlert } from "@validereinc/common-components";
import { ReportStatus } from "@validereinc/domain";
import classNames from "classnames/bind";
import React, { useEffect } from "react";

const cx = classNames.bind(styles);

export const TemplatedReportProgressBanner = () => {
  const { addAlert } = useAlert();
  const reportStorageKey = useCustomStorageKey(
    TEMPLATED_REPORT_EXPORT_KEY,
    CustomStorageKeyTypes.REPORT
  );
  const [reportId, setReportId] = useStickyState<string | undefined>(
    undefined,
    reportStorageKey
  );

  const { data, error } = useGetOneReport(
    { id: reportId },
    getPollingOptions([ReportStatus.SUCCESS, ReportStatus.FAILED])
  );

  const report = data?.data;

  const downloadReport = useDownloadReport();
  const { invalidate } = useClearReportsCache();

  useEffect(() => {
    if (report?.status === ReportStatus.SUCCESS) {
      addAlert?.({
        variant: "success",
        message: "Successfully downloaded report",
      });
      downloadReport.mutate({
        id: reportId,
      });
      setReportId(undefined);
      invalidate();
    }

    if (error || report?.status === ReportStatus.FAILED) {
      addAlert?.({
        variant: "error",
        message: "Failed to download report",
      });
      setReportId(undefined);
      invalidate();
    }
  }, [report?.status]);

  return reportId ? (
    <Banner
      className={cx("banner")}
      titleText="Exporting Report"
      actionContent={
        <>
          You can view the exported report in the{" "}
          <RoutingLink
            to={{
              pathname: linkToTemplatedReportList(),
              search: "?tab=history",
            }}
          >
            Export History
          </RoutingLink>
        </>
      }
      color="loading"
      variant="loading"
    />
  ) : null;
};
