import { useEstimationMethodDetailContext } from "#batteries-included-components/Layouts/EstimationMethod/Detail";
import { DefaultInputSourceDialog } from "#batteries-included-components/Tabs/EstimationMethod/ConfigurationTab/DefaultInputSourceDialog";
import { DefaultValueDialog } from "#batteries-included-components/Tabs/EstimationMethod/ConfigurationTab/DefaultValueDialog";
import { getCalculationInputTableCellDisplayValue } from "#batteries-included-components/Tabs/EstimationMethod/helpers";
import { QUERY_STATUS } from "#constants";
import {
  EstimationMethodDetailViewFilterArea,
  EstimationMethodDetailViewFilterType,
} from "#src/batteries-included-components/FilterAreas/EstimationMethodsFilterAreas/EstimationMethodsFilterAreas";
import { RoutingLink } from "#src/batteries-included-components/RoutingLink";
import { useGetEstimationMethod } from "#src/components/hooks/useEstimationMethod";
import { useMeasurementTypes } from "#src/contexts/MeasurementTypeContext";
import { useSessionStickyState } from "#src/hooks/useStickyState";
import { linkToAssetDetailPage } from "#utils/links";
import { DataTablePanel } from "@validereinc/common-components";
import type { CalculationParameterType } from "@validereinc/domain";
import { AssetTypeType, EstimationMethodInputType } from "@validereinc/domain";
import { toStartCaseString } from "@validereinc/utilities";
import React, { useState } from "react";

export type CalculationParameterConfigurationType = CalculationParameterType & {
  has_default: boolean;
  default_value?: Record<string, EstimationMethodInputType>;
  reporting_group_id?: string;
  reporting_group: {
    name: string;
    id: string;
  };
  asset_name?: string;
  asset_type?: AssetTypeType;
  asset_id?: string;
  measurement_type?: string;
};

export const ConfigurationTab = ({
  viewConfigStorageKey,
}: {
  viewConfigStorageKey: string;
}) => {
  const { getUnitName, getTypeName } = useMeasurementTypes();
  const { calculatorVersion, method, defaultRecords } =
    useEstimationMethodDetailContext();

  const [filters] = useSessionStickyState<EstimationMethodDetailViewFilterType>(
    {
      month: {},
    },
    viewConfigStorageKey
  );

  const [methodData, fetchState, methodDataRefetch] = useGetEstimationMethod(
    method?.data?.id,
    method?.data?.entity_type,
    filters?.month?.from
  );

  const [defaultValueTarget, setDefaultValueTarget] =
    useState<CalculationParameterConfigurationType>();
  const [inputSourceTarget, setInputSourceTarget] =
    useState<CalculationParameterConfigurationType>();

  const getItemActions = ({
    item,
  }: {
    item: CalculationParameterConfigurationType;
  }) => [
    {
      label: "Set Source",
      isOverflow: true,
      buttonProps: {
        onClick: () => setInputSourceTarget(item),
      },
    },
    {
      label: "Define Default Value",
      isOverflow: true,
      buttonProps: {
        onClick: () => setDefaultValueTarget(item),
      },
    },
  ];

  const items = (calculatorVersion?.calculation_parameters ?? []).map(
    (item) => {
      const defaultRecord = defaultRecords?.data?.data?.find(
        ({ input_name }) => input_name === item.id
      );
      return {
        ...item,
        has_default: !!defaultRecord,
        default_value: methodData?.default_values[item.id],
        reporting_group_id: defaultRecord?.reporting_group_id,
        reporting_group: defaultRecord?.reporting_group,
        asset_type: defaultRecord?.asset?.asset_type,
        asset_id: defaultRecord?.asset_id,
        asset_name:
          defaultRecord?.asset?.flow?.name ??
          defaultRecord?.asset?.equipment?.name ??
          defaultRecord?.asset?.facility?.name,
        measurement_type: defaultRecord?.measurement_type,
      };
    }
  );

  return (
    <>
      <EstimationMethodDetailViewFilterArea
        viewConfigStorageKey={viewConfigStorageKey}
      />
      <DataTablePanel
        panelProps={{ title: "Configuration" }}
        dataTableProps={{
          headers: [
            {
              key: "display_name",
              label: "Input Field",
            },
            {
              key: "asset_type",
              label: "Asset Type",
              renderComponent: ({ item }) =>
                item.asset_type ? toStartCaseString(item.asset_type) : "-",
            },
            {
              key: "asset_name",
              label: "Asset Name",
              renderComponent: ({ item }) =>
                item.asset_type && item.asset_id ? (
                  <RoutingLink
                    to={linkToAssetDetailPage(item.asset_type, item.asset_id)}
                  >
                    {item.asset_name}
                  </RoutingLink>
                ) : (
                  "-"
                ),
            },
            {
              key: "reporting_group_id",
              label: "Reporting Scenario",
              renderComponent: ({ item }) => item?.reporting_group?.name ?? "-",
            },
            {
              key: "measurement_type",
              label: "Measurement Type",
              renderComponent: ({ item }) =>
                getTypeName(item.measurement_type) ?? "-",
            },
            {
              key: "default_value",
              label: "Default Value",
              alignment: "right",
              renderComponent: ({ item }) =>
                getCalculationInputTableCellDisplayValue(
                  {
                    type: item.type,
                    measurement_value: item.default_value?.value,
                    measurement_unit: item.default_value?.unit
                      ? getUnitName(item.default_value?.unit)
                      : undefined,
                  },
                  "right"
                ),
            },
          ],
          items,
          isLoading: fetchState === QUERY_STATUS.LOADING,
          getItemActions,
        }}
      />
      <DefaultValueDialog
        methodId={methodData?.id}
        entityType={methodData?.entity_type}
        defaultValues={methodData?.default_values ?? {}}
        itemToEdit={defaultValueTarget}
        onClose={() => setDefaultValueTarget(undefined)}
        onSubmit={() => methodDataRefetch?.()}
      />
      <DefaultInputSourceDialog
        methodId={methodData?.id}
        entityType={methodData?.entity_type}
        itemToEdit={inputSourceTarget}
        onClose={() => setInputSourceTarget(undefined)}
        onSubmit={() => defaultRecords?.refetch?.()}
      />
    </>
  );
};
