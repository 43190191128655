import { RoutingLink } from "#src/batteries-included-components/RoutingLink";
import { FileDataDisplay } from "#src/components/DataDisplay/FileDataDisplay";
import { GeocoordinateDataDisplay } from "#src/components/DataDisplay/GeocoordinateDataDisplay";
import {
  linkToDeviceDetail,
  linkToEquipmentDetail,
  linkToFacilities,
} from "#src/Routers/links";
import { FlowDetailRoute } from "#src/routes/organization/flows/[flowId]/detail";
import { NetworkDetailRoute } from "#src/routes/organization/networks/[networkId]/detail";
import {
  ArrayDataDisplay,
  BooleanDataDisplay,
  DateDataDisplay,
  DateRangeDataDisplay,
  IntegerDataDisplay,
  NumberDataDisplay,
  TextDataDisplay,
} from "@validereinc/common-components";
import {
  AssetType,
  DateSchema,
  DateTimeRangeSchema,
  DateTimeSchema,
  GeocoordinateSchema,
  UploadedFileSchema,
  type AttributeDataTypeType,
  type ResourceLookupType,
  type UploadedFileType,
} from "@validereinc/domain";
import isPlainObject from "lodash/isPlainObject";
import React from "react";
import { z } from "zod";

export const displayValueByDataType = (
  value: any,
  dataType: AttributeDataTypeType,
  emptyText = "-"
) => {
  switch (dataType) {
    case "boolean": {
      return (
        <BooleanDataDisplay
          value={!!value}
          emptyText={emptyText}
        />
      );
    }
    case "string":
    case "pick-list":
      return value !== undefined && value !== null && value !== "" ? (
        <TextDataDisplay
          value={value as string}
          clamp={2}
          style={{ width: "100%" }}
        />
      ) : (
        emptyText
      );
    case "multi-pick-list":
      return (
        <ArrayDataDisplay
          value={value ?? []}
          emptyText={emptyText}
        />
      );
    case "file": {
      const { name, ref } = value as UploadedFileType;

      return (
        <FileDataDisplay
          fileName={name}
          fileId={ref}
        />
      );
    }
    case "number":
      return (
        <NumberDataDisplay
          value={value}
          emptyText={emptyText}
        />
      );
    case "integer":
      return (
        <IntegerDataDisplay
          value={value}
          emptyText={emptyText}
        />
      );
    case "geo_point":
      return <GeocoordinateDataDisplay coordinates={value} />;
    case "lookup":
      return (
        getLookupLink(
          value?.id,
          value?.name,
          value?.lookup_entity_type ?? value?.entity_type
        ) ?? emptyText
      );
    case "date":
      return <DateDataDisplay value={value} />;
    case "date-time":
      return (
        <DateDataDisplay
          value={value}
          convertToUTC
          emptyText={emptyText}
        />
      );
    case "date-time-range": {
      if (!DateTimeRangeSchema.optional().nullable().safeParse(value).success)
        return emptyText;

      return (
        <DateRangeDataDisplay
          value={value ? { from: value[0], to: value[1] } : value}
          convertToUTC
          emptyText={emptyText}
        />
      );
    }
    case "number-array": {
      if (!Array.isArray(value)) return emptyText;

      return value.length !== 0 ? (
        <ArrayDataDisplay value={value} />
      ) : (
        emptyText
      );
    }
  }
};

export const displayValueWithUnknownDataType = (
  value: any,
  emptyText = "-"
) => {
  if (z.boolean().safeParse(value).success) {
    return (
      <BooleanDataDisplay
        value={value}
        emptyText={emptyText}
      />
    );
  }

  if (Array.isArray(value)) {
    if (GeocoordinateSchema.safeParse(value).success) {
      return (
        <GeocoordinateDataDisplay
          coordinates={value as [number | null, number | null]}
          emptyText={emptyText}
        />
      );
    }

    if (DateTimeRangeSchema.safeParse(value).success) {
      return (
        <DateRangeDataDisplay
          value={{ from: value[0], to: value[1] }}
          emptyText={emptyText}
        />
      );
    }

    return (
      <ArrayDataDisplay
        value={value}
        emptyText={emptyText}
      />
    );
  }

  if (isPlainObject(value)) {
    if (UploadedFileSchema.safeParse(value).success) {
      return (
        <FileDataDisplay
          fileName={value.name}
          fileId={value.ref}
          emptyText={emptyText}
        />
      );
    }
  }

  if (DateTimeSchema.safeParse(value).success) {
    return (
      <DateDataDisplay
        value={value}
        emptyText={emptyText}
        convertToUTC
      />
    );
  }

  if (DateSchema.safeParse(value).success) {
    return (
      <DateDataDisplay
        value={value}
        emptyText={emptyText}
      />
    );
  }

  return value ?? emptyText;
};

export const getLookupLink = (
  id: string,
  name: string,
  resourceType: ResourceLookupType
) => {
  switch (resourceType) {
    case AssetType.FACILITY:
      return <RoutingLink to={linkToFacilities(id)}>{name}</RoutingLink>;
    case AssetType.EQUIPMENT:
      return <RoutingLink to={linkToEquipmentDetail(id)}>{name}</RoutingLink>;
    case AssetType.FLOW:
      return (
        <RoutingLink
          to={FlowDetailRoute.toLink({
            pathParams: { flowId: id },
          })}
        >
          {name}
        </RoutingLink>
      );
    case AssetType.DEVICE:
      return <RoutingLink to={linkToDeviceDetail(id)}>{name}</RoutingLink>;
    case AssetType.ASSET_GROUP:
      return (
        <RoutingLink
          to={NetworkDetailRoute.toLink({
            pathParams: { networkId: id },
          })}
        >
          {name}
        </RoutingLink>
      );
    default:
      return null;
  }
};
