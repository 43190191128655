import { CalculationsProgressBanner } from "#batteries-included-components/Banners/CalculationsProgressBanner";
import { ConfigurationProgressBanner } from "#batteries-included-components/Banners/ConfigurationProgressBanner";
import { ImportDataActionStatusWithQueue } from "#batteries-included-components/Banners/ImportDataActionStatus";
import { CalculationsTablePanel } from "#src/batteries-included-components";
import { getImportDataActionQueueKey } from "#src/batteries-included-components/Buttons/ImportDataAction/ImportDataAction.helpers";
import { CalculationsViewFilterArea } from "#src/batteries-included-components/FilterAreas/CalculationsFilterAreas";
import { useIsFeatureAvailable } from "#src/contexts/AuthenticatedContext.helpers";
import { useStorageKey } from "#src/hooks/useStorageKey";
import { AssetType } from "@validereinc/domain";
import React from "react";

const importDataActionQueueKey = getImportDataActionQueueKey({
  resource: {
    id: "estimation_method_config",
    description: "",
    label: {
      singular: "Network Calculation",
      plural: "Network Calculations",
    },
  },
});

export const CalculatorsNetworkTab = () => {
  const [isDataIngestionEnabled] = useIsFeatureAvailable({
    featureFlagQuery: "core:data_pipeline",
  });

  const calcsAndRecordsSharedStorageKeys = useStorageKey(
    "calculations-records-shared"
  );
  const networkStorageKeys = useStorageKey("calculations-networks");
  const networkCalculationJobStorageKey = useStorageKey(
    "calculations-emissions-bulk-calculate"
  ).tableConfigStorageKey;
  const networkConfigurationJobStorageKey = useStorageKey(
    "calculations-emissions-bulk-configure"
  ).tableConfigStorageKey;

  const measurementTypeFilter = () => false;

  return (
    <>
      {isDataIngestionEnabled ? (
        <ImportDataActionStatusWithQueue queueKey={importDataActionQueueKey} />
      ) : null}
      <CalculationsProgressBanner
        jobStorageKey={networkCalculationJobStorageKey}
      />
      <ConfigurationProgressBanner
        jobStorageKey={networkConfigurationJobStorageKey}
      />
      <CalculationsViewFilterArea
        viewConfigStorageKey={
          calcsAndRecordsSharedStorageKeys.viewConfigStorageKey
        }
      />
      <CalculationsTablePanel
        {...networkStorageKeys}
        viewConfigStorageKey={
          calcsAndRecordsSharedStorageKeys.viewConfigStorageKey
        }
        assetType={AssetType.ASSET_GROUP}
        defaultMeasurementTypes={[
          "upstream_volume",
          "downstream_volume_adjustable",
          "downstream_volume_adjusted",
          "downstream_volume_non_adjustable",
          "adjustment_factor",
        ]}
        measurementTypeFilter={measurementTypeFilter}
        calculationJobStorageKey={networkCalculationJobStorageKey}
        configurationJobStorageKey={networkConfigurationJobStorageKey}
      />
    </>
  );
};
