import { useBreadcrumbs } from "#src/Routers/breadcrumbsHelper";
import { useNavigate, useParams, useSearchParams } from "#src/Routers/hooks";
import { linkToFormSubmissionDetail } from "#src/Routers/links";
import { FormSubmissionField } from "#src/batteries-included-components/Forms/FormSubmissionForm/FormSubmissionSections/FormSubmissionField";
import { FormSubmissionSection } from "#src/batteries-included-components/Forms/FormSubmissionForm/FormSubmissionSections/FormSubmissionSection";
import { RoutingLink } from "#src/batteries-included-components/RoutingLink";
import AlertMessageWithLink from "#src/components/Common/Alerts/AlertMessageWithLink";
import { useGetOneWorkflowTask } from "#src/components/hooks/adapters/useWorkflowTasks";
import { useAuthenticatedContext } from "#src/contexts/AuthenticatedContext.helpers";
import { FORMS_BREADCRUMB } from "#src/routes/forms";
import { FORMS_CATEGORIES_BREADCRUMB } from "#src/routes/forms/categories";
import { WorkflowDetailsRoutePath } from "#src/routes/workflows/all/[workflowId]";
import { ExceptionUtils } from "#src/utils/exception";
import {
  useMutation,
  useQueries,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import {
  Banner,
  Button,
  Form,
  Page,
  useAlert,
  useForm,
} from "@validereinc/common-components";
import {
  EventsDomain,
  FormCategoryAdapter,
  FormSchemaAdapter,
  FormSubmissionAdapter,
  FormSubmissionType,
  Resources,
} from "@validereinc/domain";
import {
  getErrorCount,
  getSmartDefaultValues,
  useGenerateFieldsToWatch,
  useGenerateLookupQueries,
  useProcessAttributeLookupQueries,
  useProcessCalculatedFields,
} from "@validereinc/domain-controllers/logic/forms";
import { FormSubmissionFormController } from "@validereinc/domain-controllers/view/forms";
import classNames from "classnames/bind";
import { Base64 } from "js-base64";
import React, { useMemo } from "react";
import { CREATE_FORM_SUBMISSION_BREADCRUMB } from ".";
import { FORM_TEMPLATE_DETAILS_BREADCRUMB, linkToFormTemplateDetail } from "..";
import { FORM_CATEGORY_DETAILS_BREADCRUMB } from "../../..";
import { DEFAULT_QUERY_OPTIONS } from "../../../../../../../components/hooks/adapters/adapterUtils";
import styles from "./CreateFormSubmissionPage.module.scss";

const cx = classNames.bind(styles);

export const CreateFormSubmissionPage = () => {
  const navigate = useNavigate();

  const { categoryId, formTemplateId } = useParams<{
    categoryId: string;
    formTemplateId: string;
  }>();

  const { addAlert } = useAlert();

  const {
    v2: {
      userInfo: { user },
    },
  } = useAuthenticatedContext();

  const [
    {
      "event-id": eventId,
      "task-id": workflowTaskId,
      "associated-asset-id": associatedAssetId,
      "associated-asset-type": associatedAssetType,
      "default-values": serializedEncodedDefaultValues,
    },
  ] = useSearchParams();

  const defaultValues = useMemo(() => {
    let value = {} as Record<string, string>;
    if (!serializedEncodedDefaultValues) return value;
    try {
      const decodedDefaultValues = Base64.decode(
        serializedEncodedDefaultValues
      );
      value = JSON.parse(decodedDefaultValues) as Record<string, string>;
    } catch (err) {
      ExceptionUtils.reportException(err, "error", {
        hint: `Default values (URLParam: default-values) couldn't be decoded and parsed: ${serializedEncodedDefaultValues}`,
      });
    }
    return value;
  }, [serializedEncodedDefaultValues]);

  const query = useQuery({
    queryKey: [Resources.FORM_SCHEMA, formTemplateId] as const,
    queryFn: ({ queryKey: [_, formSchemaId] }) =>
      FormSchemaAdapter.getOne({ id: formSchemaId }),
    ...DEFAULT_QUERY_OPTIONS,
    select: (resp) => resp?.data,
  });

  const taskQuery = useGetOneWorkflowTask({ id: workflowTaskId });
  const task = taskQuery.data?.data;

  const workflowName =
    task?.workflow.name ?? task?.workflow.workflow_template.name;
  const formSchema = query.data;

  const now = useMemo(() => new Date().toISOString(), []);

  const calculatedDefaultAnswers = getSmartDefaultValues(formSchema, {
    now,
    currentUserName: user?.name,
    associatedAssetId,
    associatedAssetType,
    defaultValues,
  });

  const form = useForm<Pick<FormSubmissionType, "answers">>({
    defaultValues: calculatedDefaultAnswers,
  });

  const formValues = form.watch();

  const { fieldsToWatch, evaluationOrder } = useGenerateFieldsToWatch({
    formSchema,
    formValues,
  });

  /**
   * When receiving UUID default values for a form submission (from associatedAssetId), its related custom attributes should be fetched
   * and not wait for the user to interact with the form.
   */
  const serializedFormValues = JSON.stringify(formValues);
  const prepopulatedLookupFieldsToQuery = useMemo(() => {
    const formValues = JSON.parse(serializedFormValues);

    if (!associatedAssetId || !associatedAssetType || !formSchema) return [];

    const output: string[] = [];

    const questionIds = Object.entries(formSchema?.config?.questions ?? {})
      .filter(
        ([_, question]) =>
          "lookup_entity_type" in question &&
          question.lookup_entity_type &&
          question.lookup_entity_type === associatedAssetType
      )
      .map((entry) => entry[0]);
    (formSchema?.config?.sections ?? []).forEach((section) => {
      if (!formValues?.answers?.[section.id]?.length) return;

      for (
        let sectionIndex = 0;
        sectionIndex < formValues.answers[section.id].length;
        sectionIndex++
      ) {
        questionIds.forEach((questionId) => {
          if (section.questions.includes(questionId))
            output.push(`answers.${section.id}.${sectionIndex}.${questionId}`);
        });
      }
    });

    return output;
  }, [
    associatedAssetId,
    associatedAssetType,
    formSchema,
    serializedFormValues,
  ]);

  const attributeLookupQueryDetails = useGenerateLookupQueries({
    watchedFields: fieldsToWatch,
    formValues,
    dirtyFields: form.formState.dirtyFields,
    questionPathsToForcePopulate: prepopulatedLookupFieldsToQuery,
  });

  const attributeLookupQueries = useQueries({
    queries: attributeLookupQueryDetails.map((q) => q.queryProps),
  });

  const { fieldsNamesToDisable } = useProcessAttributeLookupQueries({
    queries: attributeLookupQueries,
    queriesDetails: attributeLookupQueryDetails,
    watchedFields: fieldsToWatch,
    form,
    formValues,
    formSchema,
  });

  const watchedFieldsState = useProcessCalculatedFields({
    evaluationOrder,
    watchedFields: fieldsToWatch,
    form,
    formValues,
    formSchema,
  });

  const queryClient = useQueryClient();

  const categoryQuery = useQuery({
    queryKey: ["formCategories", categoryId],
    queryFn: async ({ queryKey }) => {
      const [_, id] = queryKey;

      return await FormCategoryAdapter.getOne({
        id,
      });
    },
  });

  const templateQuery = useQuery({
    queryKey: [Resources.FORM_SCHEMA, formTemplateId],
    queryFn: async ({ queryKey }) => {
      const [_, formSchemaId] = queryKey;

      return await FormSchemaAdapter.getOne({
        id: formSchemaId,
      });
    },
    staleTime: 2 * 60 * 1000,
    select: (resp) => resp?.data,
  });

  const breadcrumbs = useBreadcrumbs(
    [
      FORMS_BREADCRUMB,
      FORMS_CATEGORIES_BREADCRUMB,
      FORM_CATEGORY_DETAILS_BREADCRUMB,
      FORM_TEMPLATE_DETAILS_BREADCRUMB,
      CREATE_FORM_SUBMISSION_BREADCRUMB,
    ],
    {
      2: categoryQuery?.data?.name,
      3: templateQuery?.data?.name,
    }
  );

  const mutation = useMutation({
    mutationFn: async (
      formValues: Parameters<typeof FormSubmissionAdapter.createOne>[0]["data"]
    ) => {
      const body = {
        data: {
          ...formValues,
          workflow_task_id: workflowTaskId,
        },
      };

      const {
        data: { id, ...restResponse },
      } = await FormSubmissionAdapter.createOne(body);

      if (eventId) {
        await EventsDomain.formSubmissions.addToEvent({
          formSubmissionId: id,
          eventId,
        });

        queryClient.invalidateQueries({
          queryKey: ["event", { id: eventId }],
        });
      }

      return { id, ...restResponse };
    },
    onSuccess: ({ id }, formValues) => {
      queryClient.invalidateQueries({ queryKey: [Resources.WORKFLOW] });
      queryClient.invalidateQueries({ queryKey: ["workflows", "tasks"] });
      queryClient.invalidateQueries({ queryKey: ["events"] });
      queryClient.invalidateQueries({
        queryKey: ["forms", "submissions"],
      });
      queryClient.invalidateQueries({
        queryKey: [Resources.FORM_SUBMISSION],
      });

      addAlert?.({
        variant: "success",
        message:
          formValues.status !== "draft" ? (
            <AlertMessageWithLink
              mainText="Successfully created form submission."
              linkText="View Submission Details"
              position="bottom"
              onLinkClick={() =>
                navigate({
                  pathname: linkToFormSubmissionDetail(id),
                })
              }
            />
          ) : (
            "Successfully saved form as draft."
          ),
      });

      form.reset({}, { keepDirty: false });

      if (workflowTaskId || eventId) {
        navigate.goBack();
      } else {
        navigate({
          pathname: linkToFormTemplateDetail(categoryId, formTemplateId),
          query: {
            "submission-type": formValues.status === "draft" ? "draft" : "all",
          },
          replace: true,
        });
      }
    },
    onError: (err, formValues) => {
      addAlert?.({
        variant: "error",
        message:
          formValues.status !== "draft"
            ? "Failed to create form submission"
            : "Failed to save form as draft.",
      });
      console.error(err);
    },
  });

  const onError = (errors: Record<string, unknown>) => {
    console.error("Error submitting form", errors);
  };

  const onCancel = () => {
    navigate.goBack();
  };

  const onSubmit = form.handleSubmit((formValues) => {
    mutation.mutate({
      ...formValues,
      form_schema_id: formTemplateId,
    });
  }, onError);

  const onSaveAsDraft = async () => {
    const formValues = form.getValues();

    await mutation.mutate({
      ...formValues,
      form_schema_id: formTemplateId,
      status: "draft",
    });
  };

  const errorCount = getErrorCount(form);

  return (
    <Page
      isLoading={query?.isLoading}
      category={CREATE_FORM_SUBMISSION_BREADCRUMB.title}
      title={templateQuery.data?.name}
      breadcrumbs={breadcrumbs}
      footer={
        <div className={cx("footerContainer")}>
          <Button
            key="cancel-action"
            onClick={onCancel}
          >
            Cancel
          </Button>

          <div className={cx("footerActionsContainer")}>
            {errorCount ? (
              <p className={cx("errorCount")}>
                {errorCount
                  ? `${errorCount} field${
                      errorCount > 1 ? "s have" : " has"
                    } an error. Please fix the error${
                      errorCount > 1 ? "s" : ""
                    } to submit the form.`
                  : ""}
              </p>
            ) : null}

            <Button
              key="save-as-draft-action"
              onClick={onSaveAsDraft}
              isLoading={
                mutation.isLoading && mutation.variables?.status === "draft"
              }
              disabled={mutation.isLoading}
            >
              Save as Draft
            </Button>

            <Button
              key="submit-action"
              variant="primary"
              onClick={onSubmit}
              isLoading={
                mutation.isLoading && mutation.variables?.status !== "draft"
              }
              disabled={mutation.isLoading}
            >
              Submit
            </Button>
          </div>
        </div>
      }
    >
      {task && !taskQuery.isLoading ? (
        <Banner
          className={cx("banner")}
          titleText="This submission has some associated automation"
          descriptionText={`Submitting this form will attach it to the "${task?.name}" task in workflow "${workflowName}".`}
          actionContent={
            <RoutingLink
              to={WorkflowDetailsRoutePath.toLinkParts({
                pathParams: {
                  workflowId: task?.workflow_id,
                },
              })}
            >
              View Workflow
            </RoutingLink>
          }
        />
      ) : null}

      <Form {...form}>
        <FormSubmissionFormController
          form={form}
          formSchema={formSchema}
          sectionRenderFunction={(all) => <FormSubmissionSection {...all} />}
          questionRenderFunction={(all) => {
            return (
              <FormSubmissionField
                {...all}
                key={all.name}
                isDisabled={fieldsNamesToDisable.has(all.name)}
                isReady={watchedFieldsState[all.dataKey]?.areSourcesReady}
                isBusy={watchedFieldsState[all.dataKey]?.isCalculating}
                isValid={watchedFieldsState[all.dataKey]?.isCalculatable}
                {...(watchedFieldsState[all.dataKey]?.value.calculation ?? {})}
                questionsMap={formSchema!.config.questions}
              />
            );
          }}
          // This prop is needed when adding a repeatable section:
          defaultAnswers={calculatedDefaultAnswers}
        />
      </Form>
    </Page>
  );
};
