import { useSearchParams } from "#routers/hooks";
import { CalculatorsEmissionsTab } from "#routes/calculations/calculators/CalculatorsEmissionsTab";
import { CalculatorsNetworkTab } from "#routes/calculations/calculators/CalculatorsNetworkTab";
import { CalculatorsVolumeTab } from "#routes/calculations/calculators/CalculatorsVolumeTab";
import { useIsFeatureAvailable } from "#src/contexts/AuthenticatedContext.helpers";
import { CalculatorsListRoutePath } from "#src/routes/calculations/calculators";
import { useBreadcrumbsFromRoute } from "#src/utils/route";
import { Page, Tab } from "@validereinc/common-components";
import startCase from "lodash/startCase";
import React from "react";
import { CalculatorsTab } from ".";

export const CalculatorsPage = () => {
  const [breadcrumbs] = useBreadcrumbsFromRoute(CalculatorsListRoutePath);
  const [{ tab }, setSearchParams] = useSearchParams();

  const [isFlowVolumeEnabled, isLoadingFlowVolumeEnabled] =
    useIsFeatureAvailable({
      featureFlagQuery: "core:flows",
      permissionQuery: { $and: ["flows:read", "records:read"] },
    });
  const [isNetworkProrationEnabled, isLoadingNetworkProrationEnabled] =
    useIsFeatureAvailable({
      featureFlagQuery: "core:networks",
      permissionQuery: "networks:read",
    });
  const [isEquipmentEmissionsEnabled, isLoadingEquipmentEmissionsEnabled] =
    useIsFeatureAvailable({
      featureFlagQuery: "core:equipment",
      permissionQuery: { $and: ["equipment:read", "calculator_results:read"] },
    });

  return (
    <Page
      category={CalculatorsListRoutePath.subtitle}
      title={CalculatorsListRoutePath.title}
      isLoading={
        isLoadingFlowVolumeEnabled ||
        isLoadingNetworkProrationEnabled ||
        isLoadingEquipmentEmissionsEnabled
      }
      breadcrumbs={breadcrumbs}
      activeTabKey={tab ?? (isFlowVolumeEnabled ? "volume" : "emissions")}
      onActiveTabKeyChange={(tab) => setSearchParams({ tab })}
    >
      {isFlowVolumeEnabled ? (
        <Tab
          title={startCase(CalculatorsTab.volume)}
          tabKey={CalculatorsTab.volume}
        >
          <CalculatorsVolumeTab />
        </Tab>
      ) : null}
      {isNetworkProrationEnabled ? (
        <Tab
          title={startCase(CalculatorsTab.networks)}
          tabKey={CalculatorsTab.networks}
        >
          <CalculatorsNetworkTab />
        </Tab>
      ) : null}
      {isEquipmentEmissionsEnabled ? (
        <Tab
          title={startCase(CalculatorsTab.emissions)}
          tabKey={CalculatorsTab.emissions}
        >
          <CalculatorsEmissionsTab />
        </Tab>
      ) : null}
    </Page>
  );
};
