import { RoutingLink } from "#batteries-included-components/RoutingLink";
import { FLOW_ATTRIBUTES, useFlowHeaders } from "#hooks/tables/useFlowHeaders";
import { useMeasurementTypes } from "#src/contexts/MeasurementTypeContext";
import useLocalization from "#src/hooks/useLocalization";
import { FlowRecordDetailRoute } from "#src/routes/organization/flows/[flowId]/detail/record/[recordId]";
import { DataTable, HeaderType } from "@validereinc/common-components";
import {
  EstimationMethodConfigurationType,
  FlowType,
  FlowV2Type,
} from "@validereinc/domain";
import { yearMonthName } from "@validereinc/utilities";
import React from "react";

export const useNetworkCalculationFlowHeaders = (): Array<
  HeaderType<FlowType>
> => {
  const { localize } = useLocalization();

  const equipmentTypeHeader: HeaderType<FlowType> = {
    key: "equipment_type_id",
    label: `${localize("Equipment")} Type`,
    isSortable: false,
    renderComponent: ({ item }) =>
      item?.associated_equipment?.type?.name ?? "-",
  };

  const flowHeaders = useFlowHeaders().filter(
    ({ key }) => key !== FLOW_ATTRIBUTES.FACILITY.key
  );

  const equipmentHeaderIndex =
    flowHeaders.findIndex(({ key }) => key === FLOW_ATTRIBUTES.EQUIPMENT.key) +
    1;

  return [
    ...flowHeaders.slice(0, equipmentHeaderIndex),
    equipmentTypeHeader,
    ...flowHeaders.slice(equipmentHeaderIndex),
  ];
};

export const useNetworkCalculationSourceHeaders = <
  ItemType extends FlowType | FlowV2Type = FlowType,
>(
  configuration?: EstimationMethodConfigurationType
) => {
  const { getUnitName } = useMeasurementTypes();

  return [
    {
      key: "volume_record_id",
      label: "Source",
      renderComponent: ({ item }: { item: ItemType }) => {
        const input = configuration?.asset_input_sources?.[item.id]?.volume;

        if (!input) {
          return "-";
        }

        return (
          <RoutingLink
            to={FlowRecordDetailRoute.toLink({
              pathParams: { flowId: item.id, recordId: input.record_id },
            })}
          >
            {`${item.name} - ${yearMonthName(configuration.year_month)}`}
          </RoutingLink>
        );
      },
    },
    {
      key: "volume_source_type",
      label: "Source Type",
      renderComponent: ({ item }: { item: ItemType }) => {
        const input = configuration?.asset_input_sources?.[item.id]?.volume;
        return input ? "Record" : "-";
      },
    },
    {
      key: "volume",
      label: "Volume Input",
      variant: "number",
      alignment: "right",
      renderComponent: ({ item }: { item: ItemType }) => {
        const input = configuration?.asset_inputs?.[item.id]?.volume;

        return (
          <DataTable.DataRow.NumberCell
            value={input?.value}
            unit={input ? getUnitName(input.unit) : ""}
          />
        );
      },
    },
  ];
};
