import { FLOW_ATTRIBUTES, useFlowHeaders } from "#hooks/tables/useFlowHeaders";
import {
  useClientSideSortingAndPagination,
  useTableSortingAndPagination,
} from "#redux/reducers/tableStateReducer";
import { useStorageKey } from "#src/hooks/useStorageKey";
import { DataTablePanel } from "@validereinc/common-components";
import { AssetGroupAssetType } from "@validereinc/domain";
import classNames from "classnames/bind";
import React from "react";
import styles from "./NetworkForm.module.scss";

const cx = classNames.bind(styles);

export const NetworkFlowSelectionTablePanel = ({
  selectedFlows,
  setSelectedFlows,
}: {
  selectedFlows: Record<string, AssetGroupAssetType>;
  setSelectedFlows: (newFlows: Record<string, AssetGroupAssetType>) => void;
}) => {
  const { tableConfigStorageKey } = useStorageKey("network-selected-flows");
  const [tableState, updateTableState] = useTableSortingAndPagination();
  const { items, pagination, sorting } = useClientSideSortingAndPagination(
    selectedFlows ? Object.values(selectedFlows) : [],
    tableState
  );

  const removeItem = (item: AssetGroupAssetType) => {
    const { [item.id]: itemToRemove, ...newSelection } = selectedFlows;
    return itemToRemove ? setSelectedFlows(newSelection) : null;
  };

  const getItemActions = ({ item }: { item: AssetGroupAssetType }) => {
    return [
      {
        label: "Remove",
        buttonProps: {
          onClick: () => removeItem(item),
          icon: "x-circle",
        },
      },
    ];
  };

  const emptyStateProps = {
    title: "No Selected Flows",
    suggestion: "Add selected flows from the Flows table.",
  };

  return (
    <div className={cx("streamSelectionContainer")}>
      <div className={cx("streamTableContainer")}>
        <DataTablePanel
          storageKey={tableConfigStorageKey}
          panelProps={{ title: "Selected Flows " }}
          dataTableProps={{
            headers: useFlowHeaders<AssetGroupAssetType>().filter(
              ({ key }) =>
                ![
                  FLOW_ATTRIBUTES.PRODUCT_TYPE.key,
                  FLOW_ATTRIBUTES.PRODUCT_CATEGORY.key,
                ].includes(key)
            ),
            items,
            sorting,
            pagination,
            onSortChange: updateTableState,
            onPaginationChange: updateTableState,
            isFluid: false,
            getItemId: (item: AssetGroupAssetType) => item.id,
            selected: items.length ? selectedFlows : undefined,
            onSelectionChange: setSelectedFlows,
            getItemActions,
            emptyStateProps,
          }}
        />
      </div>
    </div>
  );
};
