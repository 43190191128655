import { parseCustomReportConfiguration } from "#batteries-included-components/Layouts/CustomReport/CustomReportConfigurationPanel/CustomReportConfigurationPanel.helpers";
import { CustomReportDetailLayout } from "#batteries-included-components/Layouts/CustomReport/CustomReportDetailLayout";
import {
  getCustomReportFilters,
  getCustomReportGroupBy,
} from "#batteries-included-components/Layouts/CustomReport/CustomReportDetailLayout.helpers";
import { CreateCustomReportModal } from "#batteries-included-components/Modals/CustomReports/CreateCustomReportModal";
import { useNavigate, useParams } from "#routers/hooks";
import { CustomReportsDetailRoutePath } from "#routes/reports/custom-reports/[reportVariant]/[reportId]/detail";
import { CustomReportCreateRoutePath } from "#routes/reports/custom-reports/[reportVariant]/create/index";
import {
  CustomStorageKeyTypes,
  useCustomStorageKey,
  useStorageKey,
} from "#src/hooks/useStorageKey";
import { useBreadcrumbsFromRoute } from "#src/utils/route";
import { Button, Page, useFilters } from "@validereinc/common-components";
import { getFilterObject } from "@validereinc/domain";
import { toStartCaseString } from "@validereinc/utilities";
import React, { useEffect, useState } from "react";

export const CreateCustomReportPage = () => {
  const navigate = useNavigate();
  const [breadcrumbs] = useBreadcrumbsFromRoute(CustomReportCreateRoutePath);
  const { reportVariant } =
    useParams<
      Exclude<typeof CustomReportCreateRoutePath.pathParams, undefined>
    >();
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const storageKeys = useStorageKey("custom-report-detail");
  const configStorageKey = useCustomStorageKey(
    "custom-report-detail",
    CustomStorageKeyTypes.CONFIG
  );

  const [filters] = useFilters(storageKeys.filterConfigStorageKey);
  const [configuration, setConfiguration] = useFilters(configStorageKey);

  const { rows } = parseCustomReportConfiguration(configuration);

  useEffect(() => {
    setConfiguration({});
  }, []);

  const filter = getFilterObject(
    getCustomReportFilters(reportVariant, configuration, filters)
  );

  return (
    <>
      <Page
        category={`${toStartCaseString(reportVariant)} Custom Report`}
        title={`Unsaved ${toStartCaseString(reportVariant)} Report`}
        actionRow={[
          <Button
            key="save-custom-report"
            variant="primary"
            onClick={() => setIsCreateModalOpen(true)}
          >
            Save Report
          </Button>,
        ]}
        breadcrumbs={breadcrumbs}
      >
        <CustomReportDetailLayout
          configStorageKey={configStorageKey}
          reportVariant={reportVariant}
          {...storageKeys}
        />
      </Page>
      <CreateCustomReportModal
        variant={reportVariant}
        isOpen={isCreateModalOpen}
        onClose={() => {
          setIsCreateModalOpen(false);
        }}
        onSave={(id) => {
          navigate(
            CustomReportsDetailRoutePath.toLinkParts({
              pathParams: { reportId: id, reportVariant: reportVariant },
            })
          );
        }}
        filter={filter}
        groupBy={getCustomReportGroupBy(reportVariant, rows)}
      />
    </>
  );
};
