import { RoutePath } from "#src/utils/route";
import { DataSubmissionsRoutePath } from "..";

export { DataSetSubmissionsListTab } from "./DataSetSubmissionsListTab";

export const DataSetSubmissionsListRoutePath = DataSubmissionsRoutePath.concat(
  new RoutePath({
    path: "/datasets",
    title: "Dataset Submissions",
    isPresentational: true,
  })
);
