import { useTableSortingAndPagination } from "#redux/reducers/tableStateReducer";
import { linkToDeviceDetail } from "#routers/links";
import { RoutingLink } from "#src/batteries-included-components/RoutingLink";
import { useListDevices } from "#src/components/hooks/adapters/utils/useDevices";
import { linkToFacilityDetail } from "#src/routes/organization/facilities/[facilityId]";
import {
  DataTable,
  DataTablePanel,
  HeaderType,
  SortingType,
  StorageKeys,
  useFilters,
} from "@validereinc/common-components";
import { DeviceType, SortDirection } from "@validereinc/domain";
import { toFlattenedObject, toStartCaseString } from "@validereinc/utilities";
import React from "react";

export const DevicesTablePanel = ({
  filterConfigStorageKey,
  tableConfigStorageKey,
  selectedDevices,
  onSelectDevice,
  onDeselectDevice,
}: {
  selectedDevices: DeviceType[];
  onSelectDevice: (newDevice: DeviceType) => void;
  onDeselectDevice: (id: string) => void;
} & StorageKeys) => {
  const [filters] = useFilters(filterConfigStorageKey);
  const [tableState, updateTableState] = useTableSortingAndPagination(
    {
      sortBy: "created_at",
      sortDirection: SortDirection.DESCENDING,
    },
    filters
  );

  const params = {
    page: tableState.page,
    pageSize: tableState.pageSize,
    sort_by: tableState.sortBy,
    sort_direction: tableState.sortDirection,
    filters: toFlattenedObject(filters),
  };

  const { data, isLoading, isFetching } = useListDevices(params, {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  const headers: Array<HeaderType<DeviceType>> = [
    {
      key: "name",
      label: "Name",
      renderComponent: ({ item }) =>
        item.id && item.name ? (
          <RoutingLink to={linkToDeviceDetail(item.id)}>
            {item.name}
          </RoutingLink>
        ) : (
          "-"
        ),
    },
    {
      key: "type.name",
      label: "Type",
    },
    {
      key: "facility.name",
      label: "Facility",
      renderComponent: ({ item }) =>
        item.facility_id && item.facility.name ? (
          <RoutingLink to={linkToFacilityDetail(item.facility_id)}>
            {item.facility.name}
          </RoutingLink>
        ) : (
          "-"
        ),
    },
    {
      key: "status",
      label: "Status",
      renderComponent: ({ item }) => (
        <DataTable.DataRow.PillCell
          variant={item.status === "active" ? "success" : "default"}
          value={toStartCaseString(item.status)}
        />
      ),
    },
  ];

  const items = data?.data ?? [];
  return (
    <DataTablePanel
      storageKey={tableConfigStorageKey}
      panelProps={{
        title: "Devices",
      }}
      dataTableProps={{
        variant: "simplicity-first",
        items,
        headers,
        isLoading,
        isBusy: isFetching,
        sorting: {
          sortBy: tableState.sortBy,
          sortDirection: tableState.sortDirection,
        },
        pagination: {
          page: tableState.page,
          pageSize: tableState.pageSize,
          total: data?.total_entries,
        },
        onPaginationChange: updateTableState,
        onSortChange: updateTableState,
        getItemActions: ({ item }: { item: DeviceType }) =>
          selectedDevices?.find((selected) => selected.id === item.id)
            ? [
                {
                  label: "Remove",
                  buttonProps: {
                    onClick: () => onDeselectDevice(item?.id),
                    icon: "trash",
                  },
                },
              ]
            : [
                {
                  label: "Add",
                  buttonProps: {
                    onClick: () => onSelectDevice(item),
                    icon: "plus-circle",
                  },
                },
              ],
      }}
    />
  );
};
