import { FILTER_CONFIG } from "#batteries-included-components/Layouts/Emissions/Record/Detail/EmissionsRecordDetail";
import { useCalculatorResultsMeasurementHeaders } from "#hooks/tables/useMeasurementHeaders";
import { useTableSortingAndPagination } from "#redux/reducers/tableStateReducer";
import { useSearchParams } from "#src/Routers/hooks";
import { RoutingLink } from "#src/batteries-included-components/RoutingLink";
import { linkToEquipmentDetail } from "#src/routes/organization/equipment/[equipmentId]";
import { useQuery } from "@tanstack/react-query";
import {
  DataTablePanel,
  HeaderType,
  useAlert,
} from "@validereinc/common-components";
import type {
  CalculatorResultType,
  GetListCalculatorResultsFiltersType,
} from "@validereinc/domain";
import { CalculatorResultsDomain, SortDirection } from "@validereinc/domain";
import React, { useEffect } from "react";

export const EquipmentPanel = ({
  filters: filtersProp,
  timePeriod,
}: EquipmentPanelPropType) => {
  const { addAlert } = useAlert();
  const [searchParams] = useSearchParams();

  const filters = {
    year_month: [timePeriod],
    reporting_group: searchParams[FILTER_CONFIG.reportingScenario.name],
    ...filtersProp,
  };

  const [tableState, updateTableState] = useTableSortingAndPagination(
    {
      sortBy: "equipment.name",
      sortDirection: SortDirection.ASCENDING,
    },
    filters
  );

  const queryParams = {
    page: tableState.page,
    pageSize: tableState.pageSize,
    sort_by: tableState.sortBy,
    sort_direction: tableState.sortDirection,
    filters,
    groupBy: ["equipment.name", "equipment.type.name", "equipment.id"],
  };

  const { data, isLoading, error } = useQuery({
    queryKey: ["calculatorResults", queryParams],
    queryFn: () => CalculatorResultsDomain.getList(queryParams),
  });

  const items = data?.data ?? [];
  const measurementResultHeaders =
    useCalculatorResultsMeasurementHeaders(items);

  useEffect(() => {
    if (error) {
      addAlert({
        variant: "error",
        message: "Unable to fetch emissions records",
      });
    }
  }, [error]);

  const headers: Array<HeaderType<CalculatorResultType>> = [
    {
      label: "Equipment Name",
      key: "equipment.name",
      isSortable: true,
      renderComponent: ({
        item: { "equipment.name": equipmentName, "equipment.id": equipmentId },
      }: {
        item: CalculatorResultType;
      }) =>
        equipmentName && equipmentId ? (
          <RoutingLink to={linkToEquipmentDetail(equipmentId)}>
            {equipmentName}
          </RoutingLink>
        ) : (
          "-"
        ),
    },
    {
      label: "Equipment Type",
      key: "equipment.type.name",
      isSortable: true,
    },
    ...measurementResultHeaders,
  ];

  return (
    <DataTablePanel
      panelProps={{ title: "Emissions by Equipment" }}
      dataTableProps={{
        headers,
        items,
        isLoading,
        sorting: {
          sortBy: tableState.sortBy,
          sortDirection: tableState.sortDirection,
        },
        pagination: {
          page: tableState.page,
          pageSize: tableState.pageSize,
          total: data?.total_entries,
        },
        onSortChange: updateTableState,
        onPaginationChange: updateTableState,
      }}
    />
  );
};

type EquipmentPanelPropType = {
  filters: GetListCalculatorResultsFiltersType;
  timePeriod: string;
  isUsingInternalState?: boolean;
  subscription?: null | string[];
};
