import { ITEMS_PER_PAGE } from "#constants";
import { useCustomAttributeFilters } from "#hooks/FilterPanel/useCustomAttributeFilters";
import {
  useDeleteFlow,
  useExportFlows,
  useListFlows,
} from "#hooks/adapters/useFlows";
import { useFlowHeaders } from "#hooks/tables/useFlowHeaders";
import { ImportDataAction } from "#src/batteries-included-components/Buttons/ImportDataAction";
import EditFlowDialog from "#src/batteries-included-components/Dialogs/EditFlowDialog";
import { FlowsTableFilterArea } from "#src/batteries-included-components/FilterAreas/AssetsFilterAreas";
import { useTableSortingAndPagination } from "#src/components/Redux/reducers/tableStateReducer";
import { useIsFeatureAvailable } from "#src/contexts/AuthenticatedContext.helpers";
import useLocalization from "#src/hooks/useLocalization";
import { useSessionStickyState } from "#src/hooks/useStickyState";
import {
  Button,
  ButtonWithPopover,
  DataTablePanel,
  Dialog,
  StorageKeys,
} from "@validereinc/common-components";
import {
  AssetType,
  FlowDomain,
  FlowFilterType,
  FlowType,
  ResourceDefinitions,
  SortDirection,
} from "@validereinc/domain";
import {
  getYearMonthFromDateRange,
  toFlattenedObject,
} from "@validereinc/utilities";
import React, { useMemo, useState } from "react";

export const FlowsTablePanel = ({
  filterConfigStorageKey,
  viewConfigStorageKey,
  tableConfigStorageKey,
  isWithCustomAttributes,
  isWithTableActions,
  pageSize = ITEMS_PER_PAGE,
}: {
  isWithCustomAttributes?: boolean;
  isWithTableActions?: boolean;
  pageSize?: number;
} & StorageKeys) => {
  const { localize } = useLocalization();
  const [isDataIngestionEnabled] = useIsFeatureAvailable({
    featureFlagQuery: "core:data_pipeline",
  });

  const [viewFilters] = useSessionStickyState<{
    period?: {
      from?: string | Date;
      to?: string | Date;
    };
  }>({}, viewConfigStorageKey);
  const [tableFilters] = useSessionStickyState<FlowFilterType>(
    {},
    filterConfigStorageKey
  );
  const rawFilters = useMemo(
    () => ({ ...tableFilters, ...viewFilters }),
    [tableFilters, viewFilters]
  );
  const [assetIdToEdit, setAssetIdToEdit] = useState<string | undefined>();
  const [flowToDelete, setFlowToDelete] = useState<FlowType>();

  const { customAttributeHeaders } = useCustomAttributeFilters({
    assetType: AssetType.FLOW,
    enabled: isWithCustomAttributes,
  });

  const { period: periodFilter, ...otherFilters } = rawFilters;

  const [tableState, updateTableState] = useTableSortingAndPagination(
    {
      sortBy: "name",
      sortDirection: SortDirection.ASCENDING,
      pageSize,
    },
    otherFilters
  );

  const flowParams: Parameters<typeof FlowDomain.getFlows>[0] = {
    page: tableState.page,
    pageSize: tableState.pageSize,
    sortBy: tableState.sortBy,
    sortDirection: tableState.sortDirection,
    filters: {
      ...toFlattenedObject(otherFilters),
      period: getYearMonthFromDateRange(periodFilter),
    },
  };

  const { data, isLoading, isFetching } = useListFlows(flowParams);
  const exportFlows = useExportFlows(flowParams);

  const items = data?.data ?? [];

  const { mutate: deleteFlow } = useDeleteFlow();
  const deleteFlowOnClick = async () => {
    if (flowToDelete) {
      deleteFlow(flowToDelete.id);
    }
    setFlowToDelete(undefined);
  };

  const rowActionItems = ({ item }: { item: FlowType }) => [
    {
      label: "Edit",
      buttonProps: {
        onClick: () => setAssetIdToEdit(item.id),
        icon: "pencil-simple",
      },
    },
    {
      label: "Delete",
      buttonProps: { onClick: () => setFlowToDelete(item), icon: "trash" },
    },
  ];

  const headers = [
    ...useFlowHeaders(),
    ...(isWithCustomAttributes ? customAttributeHeaders : []),
  ];

  const actions = [
    <ButtonWithPopover
      icon="arrow-square-out"
      label="Export"
      key="export-flows"
      variant="outline"
      onClick={() => {
        exportFlows.mutate();
      }}
      isLoading={exportFlows.isLoading}
    />,
    isDataIngestionEnabled ? (
      <ImportDataAction
        key="import-flow"
        resource={{
          ...ResourceDefinitions.flow,
          label: {
            singular: localize(ResourceDefinitions.flow.label.singular),
            plural: localize("flow_plural"),
          },
        }}
      />
    ) : null,
  ];

  return (
    <>
      <DataTablePanel
        storageKey={tableConfigStorageKey}
        panelProps={{
          actionRow: isWithTableActions ? actions : undefined,
          isFluidY: false,
        }}
        dataTableProps={{
          variant: "simplicity-first",
          headers,
          items,
          isLoading,
          isBusy: isFetching,
          sorting: {
            sortBy: tableState.sortBy,
            sortDirection: tableState.sortDirection,
          },
          pagination: {
            page: tableState.page,
            pageSize: tableState.pageSize,
            total: data?.total_entries,
          },
          onSortChange: updateTableState,
          onPaginationChange: updateTableState,
          getItemActions: rowActionItems,
        }}
        filterComponent={
          <FlowsTableFilterArea
            filterConfigStorageKey={filterConfigStorageKey}
            hasCustomAttributeFilters={isWithCustomAttributes}
            hasSubAssetFilters
          />
        }
      />
      <Dialog
        isOpen={!!flowToDelete}
        onClose={() => setFlowToDelete(undefined)}
        title={`Delete "${flowToDelete?.name}"`}
        actionRow={[
          <Button
            key="delete-dialog-action"
            onClick={deleteFlowOnClick}
            variant="error"
          >
            Delete
          </Button>,
        ]}
      >
        <div>
          Are you sure you want to delete this flow? This action cannot be
          undone.
        </div>
      </Dialog>
      <EditFlowDialog
        defaultPeriod={getYearMonthFromDateRange(periodFilter) ?? ""}
        onClose={() => setAssetIdToEdit(undefined)}
        flowId={assetIdToEdit}
      />
    </>
  );
};
