import { ITEMS_PER_PAGE } from "#constants";
import { useCustomAttributeFilters } from "#hooks/FilterPanel/useCustomAttributeFilters";
import { useListEquipment } from "#hooks/adapters/useEquipment";
import { useTableSortingAndPagination } from "#redux/reducers/tableStateReducer";
import { linkToEquipmentDetail, linkToFacilities } from "#routers/links";
import { EquipmentTableFilterArea } from "#src/batteries-included-components/FilterAreas/AssetsFilterAreas";
import { RoutingLink } from "#src/batteries-included-components/RoutingLink";
import useLocalization from "#src/hooks/useLocalization";
import {
  DataTable,
  DataTablePanel,
  HeaderType,
  StorageKeys,
  useFilters,
} from "@validereinc/common-components";
import { AssetType, EquipmentType, SortDirection } from "@validereinc/domain";
import { toFlattenedObject, toStartCaseString } from "@validereinc/utilities";
import React from "react";

export const EquipmentTablePanel = ({
  selected,
  onSelectionChange,
  isWithCustomAttributes = false,
  pageSize = ITEMS_PER_PAGE,
  filterConfigStorageKey,
  tableConfigStorageKey,
}: {
  selected?: Record<string, EquipmentType>;
  onSelectionChange?: (newSelection: Record<string, EquipmentType>) => void;
  isWithCustomAttributes?: boolean;
  pageSize?: number;
} & StorageKeys) => {
  const { localize, isLoading: isMappingLoading } = useLocalization();
  const [filters] = useFilters(filterConfigStorageKey);

  const { customAttributeHeaders, isLoading: isCustomAttributesLoading } =
    useCustomAttributeFilters({
      assetType: AssetType.EQUIPMENT,
    });

  const headers: Array<HeaderType<EquipmentType>> = [
    {
      label: `${localize("Equipment")} Name`,
      key: "name",
      isSortable: true,
      renderComponent: ({ item }) =>
        item?.id && item?.name ? (
          <RoutingLink to={linkToEquipmentDetail(item.id)}>
            {item.name}
          </RoutingLink>
        ) : (
          "-"
        ),
    },
    {
      label: `${localize("Equipment")} Type`,
      key: "type_id",
      isSortable: true,
      renderComponent: ({ item }) => item?.type?.name ?? "-",
    },
    {
      label: `${localize("Facility")} Name`,
      key: "facility_name",
      isSortable: true,
      renderComponent: ({ item }) =>
        item?.facility_id && item?.facility?.name ? (
          <RoutingLink to={linkToFacilities(item.facility_id)}>
            {item.facility.name}
          </RoutingLink>
        ) : (
          "-"
        ),
    },
    {
      key: "status",
      label: "Status",
      renderComponent: ({ item }) => (
        <DataTable.DataRow.PillCell
          variant={item.status === "active" ? "success" : "default"}
          value={toStartCaseString(item.status)}
        />
      ),
    },
    {
      label: "Effective Date",
      key: "effective_date",
      renderComponent: ({ item }) => (
        <DataTable.DataRow.DateCell value={item.effective_date} />
      ),
      isSortable: true,
    },
    ...(isWithCustomAttributes ? customAttributeHeaders : []),
  ];

  const [tableState, updateTableState] = useTableSortingAndPagination(
    {
      sortBy: "created_at",
      sortDirection: SortDirection.DESCENDING,
      pageSize,
    },
    filters
  );

  const {
    data,
    isLoading: isEquipmentLoading,
    isFetching: isEquipmentFetching,
  } = useListEquipment({
    page: tableState.page,
    pageSize: tableState.pageSize,
    sortBy: tableState.sortBy,
    sortDirection: tableState.sortDirection,
    filters: toFlattenedObject(filters),
  });

  const items = data?.data ?? [];

  const loading =
    isMappingLoading || isEquipmentLoading || isCustomAttributesLoading;

  const getItemActions = ({ item }: { item: EquipmentType }) =>
    selected?.[item.id]
      ? [
          {
            label: "Remove",
            buttonProps: {
              onClick: () => {
                const { [item.id]: _, ...newSelection } = selected;
                onSelectionChange?.(newSelection);
              },
              icon: "trash",
            },
          },
        ]
      : [
          {
            label: "Add",
            buttonProps: {
              onClick: () =>
                onSelectionChange?.({
                  ...selected,
                  [item.id]: item,
                }),
              icon: "plus-circle",
            },
          },
        ];

  return (
    <DataTablePanel
      storageKey={tableConfigStorageKey}
      panelProps={{ title: localize("Equipment"), isFluidY: false }}
      dataTableProps={{
        variant: "simplicity-first",
        sorting: {
          sortBy: tableState.sortBy,
          sortDirection: tableState.sortDirection,
        },
        isLoading: loading,
        isBusy: isEquipmentFetching,
        headers,
        items,
        getItemActions,
        pagination: {
          page: tableState.page,
          pageSize: tableState.pageSize,
          total: data?.total_entries,
        },
        onPaginationChange: updateTableState,
        onSortChange: updateTableState,
        selected,
        onSelectionChange,
      }}
      filterComponent={
        <EquipmentTableFilterArea
          filterConfigStorageKey={filterConfigStorageKey}
          hasCustomAttributeFilters={isWithCustomAttributes}
        />
      }
    />
  );
};
