import { useSearchParams } from "#routers/hooks";
import useLocalization from "#src/hooks/useLocalization";
import { ButtonToggleGroup } from "@validereinc/common-components";
import { AssetType, type AssetTypeType } from "@validereinc/domain";
import React from "react";

export const NetworkFilterPanel = () => {
  const { localize } = useLocalization();
  const [searchParams, setSearchParams] = useSearchParams<{
    entity: AssetTypeType;
  }>();

  return (
    <div style={{ marginBottom: 16 }}>
      <ButtonToggleGroup
        value={searchParams.entity}
        items={[
          { dataKey: AssetType.FLOW, label: localize("flow_plural") },
          {
            dataKey: AssetType.FACILITY,
            label: localize("facility_plural"),
          },
          {
            dataKey: AssetType.EQUIPMENT,
            label: localize("equipment_plural"),
          },
          { dataKey: AssetType.DEVICE, label: "Devices" },
        ]}
        onChange={(val) => setSearchParams({ entity: val })}
      />
    </div>
  );
};
