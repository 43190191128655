import {
  DropdownInput,
  RadioInput,
  TextInput,
  useFormContext,
} from "@validereinc/common-components";
import { AttributeDataType } from "@validereinc/domain";
import React from "react";

const DEFAULT_ANSWER_LABEL = "Default Answer";
const DEFAULT_ANSWER_NAME = "default_answer";

export const QuestionDefaultAnswer = () => {
  const form = useFormContext();

  const dataType = form.watch("data_type");
  const pickListValues = form.watch("pick_list_values");

  return (
    <>
      {dataType === AttributeDataType.STRING ? (
        <TextInput
          label={DEFAULT_ANSWER_LABEL}
          name={DEFAULT_ANSWER_NAME}
          isRequired
          isFluid
        />
      ) : null}

      {dataType === AttributeDataType.BOOLEAN ? (
        <RadioInput
          label={DEFAULT_ANSWER_LABEL}
          name={DEFAULT_ANSWER_NAME}
          options={[
            { label: "Yes", value: true },
            { label: "No", value: false },
          ]}
          valueKey="value"
          labelKey="label"
          isRequired
          isFluid
        />
      ) : null}

      {[
        AttributeDataType.PICK_LIST,
        AttributeDataType.MULTI_PICK_LIST,
      ].includes(dataType) ? (
        <DropdownInput
          label={DEFAULT_ANSWER_LABEL}
          name={DEFAULT_ANSWER_NAME}
          options={pickListValues}
          valueKey="value"
          labelKey="value"
          isMulti={dataType === AttributeDataType.MULTI_PICK_LIST}
          isRequired
          isFluid
        />
      ) : null}
    </>
  );
};
