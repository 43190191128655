import { FILTER_CONFIG } from "#batteries-included-components/Layouts/Emissions/Record/Detail/EmissionsRecordDetail";
import { useCalculatorResultsMeasurementHeaders } from "#hooks/tables/useMeasurementHeaders";
import { RoutingLink } from "#src/batteries-included-components/RoutingLink";
import { useTableSortingAndPagination } from "#src/components/Redux/reducers/tableStateReducer";
import { useSearchParams } from "#src/Routers/hooks";
import { linkToEquipmentDetail } from "#src/routes/organization/equipment/[equipmentId]";
import { linkToEstimationMethodDetail } from "#src/routes/organization/equipment/[equipmentId]/estimation-method/[methodId]/detail";
import { linkToEstimationMethodCalculationResult } from "#src/routes/organization/equipment/[equipmentId]/estimation-method/[methodId]/result/[resultId]/detail";
import { useQuery } from "@tanstack/react-query";
import {
  DataTablePanel,
  HeaderType,
  useAlert,
} from "@validereinc/common-components";
import type { GetListCalculatorResultsFiltersType } from "@validereinc/domain";
import {
  CalculatorResultsDomain,
  CalculatorResultType,
  SortDirection,
} from "@validereinc/domain";
import { DateFormats, monthFormatter } from "@validereinc/utilities";
import parse from "date-fns/parse";
import React, { useEffect } from "react";

export const EstimationMethodPanel = ({
  filters: filtersProp,
  timePeriod,
  showEquipment = false,
}: EstimationMethodPanelPropType) => {
  const { addAlert } = useAlert();
  const [searchParams] = useSearchParams();

  const filters = {
    year_month: [timePeriod],
    reporting_group: {
      $exact: searchParams[FILTER_CONFIG.reportingScenario.name],
    },
    ...filtersProp,
  };

  const [tableState, updateTableState] = useTableSortingAndPagination(
    {
      sortBy: "name",
      sortDirection: SortDirection.ASCENDING,
    },
    filters
  );

  const queryParams = {
    page: tableState.page,
    pageSize: tableState.pageSize,
    sort_by: tableState.sortBy,
    sort_direction: tableState.sortDirection,
    filters,
    groupBy: [
      "year_month",
      "estimation_method_id",
      "equipment.id",
      "equipment.name",
    ],
  };

  const { data, isLoading, error } = useQuery({
    queryKey: ["calculatorResults", queryParams],
    queryFn: () => CalculatorResultsDomain.getList(queryParams),
  });

  const items = data?.data ?? [];
  const measurementResultHeaders =
    useCalculatorResultsMeasurementHeaders(items);

  useEffect(() => {
    if (error) {
      addAlert({
        variant: "error",
        message: "Unable to fetch emissions records",
      });
    }
  }, [error]);

  const headers: Array<HeaderType<CalculatorResultType>> = [
    {
      label: "Time Period",
      key: "year_month",
      isSortable: true,
      renderComponent: ({
        item: {
          year_month: yearMonth,
          estimation_method_id: methodId,
          "equipment.id": equipmentId,
        },
      }: {
        item: CalculatorResultType;
      }) =>
        yearMonth && methodId && equipmentId ? (
          <RoutingLink
            to={linkToEstimationMethodCalculationResult(
              methodId,
              equipmentId,
              yearMonth
            )}
          >
            {yearMonth
              ? monthFormatter(
                  parse(yearMonth, DateFormats.YEAR_MONTH, new Date())
                )
              : null}
          </RoutingLink>
        ) : (
          "-"
        ),
    },
    {
      label: "Estimation Method",
      key: "estimation_method_name",
      isSortable: true,
      renderComponent: ({
        item: {
          estimation_method_id: methodId,
          estimation_method_name: methodName,
          "equipment.id": equipmentId,
        },
      }: {
        item: CalculatorResultType;
      }) =>
        methodId && equipmentId ? (
          <RoutingLink to={linkToEstimationMethodDetail(methodId, equipmentId)}>
            {methodName}
          </RoutingLink>
        ) : (
          "-"
        ),
    },
    ...measurementResultHeaders,
  ];

  if (showEquipment) {
    headers.splice(1, 0, {
      label: "Equipment Name",
      key: "equipment.name",
      isSortable: true,
      renderComponent: ({
        item: { "equipment.name": equipmentName, "equipment.id": equipmentId },
      }: {
        item: CalculatorResultType;
      }) =>
        equipmentName && equipmentId ? (
          <RoutingLink to={linkToEquipmentDetail(equipmentId)}>
            {equipmentName}
          </RoutingLink>
        ) : (
          "-"
        ),
    });
  }

  return (
    <DataTablePanel
      panelProps={{ title: "Emissions by Estimation Method" }}
      dataTableProps={{
        headers,
        items,
        isLoading,
        sorting: {
          sortBy: tableState.sortBy,
          sortDirection: tableState.sortDirection,
        },
        pagination: {
          page: tableState.page,
          pageSize: tableState.pageSize,
          total: data?.total_entries,
        },
        onSortChange: updateTableState,
        onPaginationChange: updateTableState,
      }}
    />
  );
};

type EstimationMethodPanelPropType = {
  filters: GetListCalculatorResultsFiltersType;
  timePeriod: string;
  isUsingInternalState?: boolean;
  subscription?: null | string[];
  showEquipment?: boolean;
};
