import {
  UserPermissionType,
  UserRoleAssignmentType,
  UserType,
  UserWithCompaniesAndCurrentCompanyIdType,
  UserWithCompanyMembershipType,
  UserWithCurrentCompanyIdType,
} from "../schemas";
import {
  CreateOneRequestType,
  CreateOneResponseType,
  FilterObjectType,
  GetListRequestType,
  GetListResponseType,
  GetOneRequestType,
  GetOneResponseType,
  ResourceServiceType,
  UpdateOneRequestType,
  UpdateOneResponseType,
  fetchAndCollate,
  getFilterObject,
} from "../util";
import { restAPI } from "./api";

export const UsersAdapter: UsersAdapterResourceServiceType = {
  /**
   * Search all users
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/users/search_users
   */
  getList: async ({ page, pageSize, sortBy, sortDirection, filters }) =>
    fetchAndCollate(
      ({ page, pageSize }) =>
        restAPI.nodeAPI.POST({
          endpoint: "/users/search",
          body: {
            page,
            page_size: pageSize,
            sort_by: sortBy,
            sort_direction: sortDirection,
            filter: getFilterObject(filters),
          },
        }),
      page,
      pageSize
    ),
  /**
   * Get a user's details
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/users/get_user
   */
  getOne: async ({ id }) =>
    restAPI.nodeAPI
      .GET<UserWithCurrentCompanyIdType>({
        endpoint: `/users/${id}`,
      })
      .then((resp) => ({
        data: resp,
      })),
  getMany: () =>
    Promise.reject({
      success: false,
      error: "Method not implemented",
    }),
  myself: {
    getOne: async () =>
      restAPI.nodeAPI
        .GET<UserWithCompaniesAndCurrentCompanyIdType>({
          endpoint: `/users/myself`,
        })
        .then((resp) => ({
          data: resp,
        })),
  },
  /**
   * Create a user
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/users/create_user
   */
  createOne: ({ data }) =>
    restAPI.nodeAPI
      .POST<UserType>({
        endpoint: `/users`,
        body: data,
      })
      .then((resp) => ({
        data: resp,
      })),
  createMany: () =>
    Promise.reject({
      success: false,
      error: "Method not implemented",
    }),
  /**
   * Update a user's details
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/users/update_user
   */
  updateOne: async ({ id, data }) => {
    const updatedUser = await restAPI.nodeAPI.PUT<UserType>({
      endpoint: `/users/${id}`,
      body: {
        ...(data.name && { name: data.name }),
        ...(data.status && { status: data.status }),
        ...(data.phone && { phone: data.phone }),
        ...(data.timezone && { timezone: data.timezone }),
        ...(data.quicksight && { quicksight: data.quicksight }),
      },
    });

    return {
      data: updatedUser,
    };
  },
  updateMany: () =>
    Promise.reject({
      success: false,
      error: "Method not implemented",
    }),
  /**
   * Delete a single user
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/users/delete_user
   */
  deleteOne: ({ id }) =>
    restAPI.nodeAPI.DELETE({
      endpoint: `/users/${id}`,
    }),
  deleteMany: () =>
    Promise.reject({
      success: false,
      error: "Method not implemented",
    }),
  roles: {
    /**
     * Get a list of roles the user belongs to
     * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/users/list_user_roles
     */
    getList: ({ userId }) =>
      restAPI.nodeAPI.GET<UserRoleAssignmentType[]>({
        endpoint: `/users/${userId}/roles`,
      }),
  },
  permissions: {
    /**
     * Get all the configured permissions for a user
     * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/users/get_user_permission
     */
    getMany: ({ userId }) =>
      restAPI.nodeAPI.GET<UserPermissionType[]>({
        endpoint: `/users/${userId}/permissions`,
      }),
    /**
     * Given a list of permissions, get a permission + boolean record back
     * indicating all the permissions of the ones provided that the user has
     * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/users/get_user_permission_decision
     */
    getList: ({ userId }) =>
      restAPI.nodeAPI.POST<Record<string, boolean>>({
        endpoint: `/users/${userId}/permissions/decision`,
      }),
  },
};

export interface UsersAdapterResourceServiceType
  extends ResourceServiceType<UserType> {
  getList: (
    props: GetListRequestType<FilterObjectType<Partial<UserType>>>
  ) => Promise<GetListResponseType<UserWithCompanyMembershipType>>;
  getOne: (
    params: GetOneRequestType
  ) => Promise<GetOneResponseType<UserWithCurrentCompanyIdType>>;
  myself: {
    getOne: () => Promise<
      GetOneResponseType<UserWithCompaniesAndCurrentCompanyIdType>
    >;
  };
  createOne: (
    props: CreateOneRequestType<
      Pick<UserType, "name" | "quicksight" | "phone" | "status" | "email">
    >
  ) => Promise<CreateOneResponseType<UserType>>;
  updateOne: (
    props: UpdateOneRequestType<
      Pick<UserType, "name" | "status" | "phone" | "timezone" | "quicksight">
    >
  ) => Promise<UpdateOneResponseType<UserType>>;
  roles: {
    getList: ({
      userId,
    }: {
      userId: string;
    }) => Promise<UserRoleAssignmentType[]>;
  };
  permissions: {
    getMany: ({ userId }: { userId: string }) => Promise<UserPermissionType[]>;
    getList: ({
      userId,
    }: {
      userId: string;
      permissions: string[];
    }) => Promise<Record<string, boolean>>;
  };
}
