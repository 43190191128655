import styles from "#batteries-included-components/Banners/ImportDataActionStatus/ImportDataActionStatus.module.scss";
import { useGetOneJob } from "#hooks/adapters/useJobs";
import { useStickyState } from "#src/hooks/useStickyState";
import { useClearRecordsCache } from "#src/queries/recordQueries";
import { Banner, IconLoading, useAlert } from "@validereinc/common-components";
import { JobStatus } from "@validereinc/domain";
import classNames from "classnames/bind";
import React, { useEffect } from "react";
import { useTimeoutHandler } from "./Banners.helpers";

const cx = classNames.bind(styles);

export const ApplyAutomationProgressBanner = ({
  jobStorageKey,
}: {
  jobStorageKey: string;
}) => {
  const { invalidate } = useClearRecordsCache();
  const { addAlert } = useAlert();
  const [jobId, setJobId] = useStickyState<string>("", jobStorageKey);
  const job = useGetOneJob(jobId).data?.data;

  const resetJob = () => {
    invalidate();
    setJobId("");
  };

  useTimeoutHandler(job, "Failed to apply automation due to timeout", resetJob);

  useEffect(() => {
    if ([JobStatus.FINISHED, JobStatus.PARTIAL].includes(job?.status)) {
      const {
        success: successCount,
        failed: failureCount,
        skipped: skippedCount,
      } = job?.item_status_counts ?? {};

      if (job?.status === JobStatus.PARTIAL) {
        addAlert?.({
          variant: "error",
          message: `Processed ${job?.processed_items}/${job?.total_items} row(s)`,
        });
      }

      if (successCount) {
        addAlert?.({
          variant: "success",
          message: `Successfully applied automation to ${successCount} row(s)`,
        });
      }

      if (failureCount) {
        addAlert?.({
          variant: "error",
          message: `Failed to apply automation to ${failureCount} row(s)`,
        });
      }

      if (skippedCount) {
        addAlert({
          variant: "normal",
          message: `Skipped applying automation to ${skippedCount} row(s) as they've already been applied.`,
        });
      }

      resetJob();
      return;
    }

    if (job?.status === JobStatus.FAILED) {
      addAlert?.({
        variant: "error",
        message: "Failed to apply automation",
      });
      resetJob();
      return;
    }
  }, [job?.status]);

  return jobId ? (
    <Banner
      className={cx("banner")}
      titleText="Applying Record Automations"
      actionContent={
        <>{job?.total_items ? `Applying ${job.total_items} row(s)` : ""}</>
      }
      color="loading"
      variant="loading"
    />
  ) : null;
};
