import { useNavigate } from "#src/Routers/hooks";
import DeviceList from "#src/batteries-included-components/Layouts/Devices/List/DeviceList";
import useLocalization from "#src/hooks/useLocalization";
import { DevicesListRoutePath } from "#src/routes/organization/devices";
import { DevicesCreateRoutePath } from "#src/routes/organization/devices/create";
import { useBreadcrumbsFromRoute } from "#src/utils/route";
import { Button, Page } from "@validereinc/common-components";
import React from "react";

export const DeviceListPage = () => {
  const navigate = useNavigate();
  const { localize } = useLocalization();
  const { isLoading: isMappingLoading } = useLocalization();
  const [breadcrumbs] = useBreadcrumbsFromRoute(DevicesListRoutePath);

  const handleCreateDevice = () => {
    navigate(DevicesCreateRoutePath.toLinkParts());
  };

  const actionRow = (
    <Button
      variant="primary"
      onClick={handleCreateDevice}
    >
      Create Device
    </Button>
  );

  return (
    <Page
      category={DevicesListRoutePath.subtitle}
      title={localize("device_plural")}
      breadcrumbs={breadcrumbs}
      actionRow={actionRow}
      isLoading={isMappingLoading}
    >
      <DeviceList />
    </Page>
  );
};

DeviceListPage.displayName = "DeviceListPage";
