import { linkToFacilities } from "#routers/links";
import { RoutingLink } from "#src/batteries-included-components/RoutingLink";
import { useListCustomAttributes } from "#src/components/hooks/adapters/useCustomAttributes";
import useLocalization from "#src/hooks/useLocalization";
import { displayValueByDataType } from "#src/utils/display";
import { KeyValuePanel } from "@validereinc/common-components";
import { AssetType, DeviceType } from "@validereinc/domain";
import startCase from "lodash/startCase";
import * as PropTypes from "prop-types";
import React from "react";

const OverviewPanel = ({
  deviceDetail,
  isLoading,
  isDisplayMap,
}: {
  deviceDetail?: DeviceType;
  isLoading?: boolean;
  isDisplayMap?: boolean;
}) => {
  const { localize, isLoading: isMappingLoading } = useLocalization();
  const customAttributeQuery = useListCustomAttributes({
    filters: { entity_type: AssetType.DEVICE },
  });
  const customAttributes = customAttributeQuery.data?.data ?? [];

  const data = [
    {
      title: localize("facility"),
      value: (
        <RoutingLink to={linkToFacilities(deviceDetail?.facility_id)}>
          {deviceDetail?.facility?.name ?? "-"}
        </RoutingLink>
      ),
    },
    ...Object.entries(deviceDetail?.attributes ?? {}).map(([key, value]) => ({
      key: key,
      title: startCase(key),
      value:
        key !== "location"
          ? startCase(value as string)
          : (value as [number, number]).join(", "),
    })),
    {
      title: "Measurement Types",
      value:
        deviceDetail?.measurement_types
          ?.map((type) => startCase(type))
          ?.join(", ") ?? "-",
    },
    ...customAttributes
      .filter(
        ({ entity_subtype }) =>
          !entity_subtype || deviceDetail?.type_id == entity_subtype
      )
      .map(({ display_name, field_name, data_type }) => ({
        key: field_name,
        title: display_name,
        value: displayValueByDataType(
          deviceDetail?.custom_attributes?.[field_name],
          data_type
        ),
      })),
  ];
  return (
    <KeyValuePanel
      panelMaxColumnCount={isDisplayMap ? 2 : 3}
      panelKeyValueListProps={{ maxRowCount: 6, variant: "shaded" }}
      panelProps={{ isFluidY: false, loaded: !isMappingLoading && !isLoading }}
      data={data}
    />
  );
};

OverviewPanel.propTypes = {
  deviceDetail: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isDisplayMap: PropTypes.bool.isRequired,
};

export default OverviewPanel;
