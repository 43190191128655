import {
  Flex,
  KeyValueTile,
  useFormContext,
} from "@validereinc/common-components";
import React from "react";

const { Container, Item } = Flex;

export const CreateFormTemplateMetricTiles = () => {
  const form = useFormContext();

  const sections = form.watch("config.sections");
  const questions = form.watch("config.questions");

  const questionCount = Object.keys(questions).length;

  return (
    <div style={{ marginBottom: 24 }}>
      <Container>
        <Item
          shrink={2}
          flex={0}
        >
          <KeyValueTile
            title="Sections"
            value={String(sections?.length ?? 0)}
          />
        </Item>

        <Item
          shrink={1}
          flex={0}
        >
          <KeyValueTile
            title="Questions"
            value={String(questionCount)}
          />
        </Item>
      </Container>
    </div>
  );
};
