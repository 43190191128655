import { dateFormatter } from "@validereinc/utilities";

type UseCreateUpdateMetaSegmentsProps = {
  created_at?: string;
  updated_at?: string;
};

export function useCreateUpdateMetaSegments(
  props: UseCreateUpdateMetaSegmentsProps
) {
  return [
    !props.updated_at
      ? undefined
      : `Last edited ${dateFormatter(new Date(props.updated_at ?? ""))}`,
    !props.created_at
      ? undefined
      : `Created ${dateFormatter(new Date(props.created_at ?? ""))}`,
  ].filter((s) => s !== undefined);
}
