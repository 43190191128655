import {
  EmptyState,
  Icon,
  KeyValueTileGrid,
  Panel,
  useFormContext,
} from "@validereinc/common-components";
import { UserType } from "@validereinc/domain";
import React from "react";

export const SelectedAssetsPanel = ({
  selectedUsers = [],
}: {
  selectedUsers: UserType[];
}) => {
  const { setValue } = useFormContext();

  const onDeselectUser = ({ id }: { id: string }) => {
    setValue(
      "selected_users",
      selectedUsers.filter(({ id: userId }) => {
        return userId !== id;
      })
    );
  };

  return (
    <Panel
      title="Selected Users"
      isFluidY={false}
    >
      {selectedUsers?.length ? (
        <KeyValueTileGrid
          data={selectedUsers?.map(({ id, name }) => ({
            title: "Name",
            value: name,
            action: {
              onClick: () => onDeselectUser({ id }),
              variant: "outline",
              icon: "minus-circle",
            },
          }))}
        />
      ) : (
        <EmptyState
          title="No user(s) selected"
          icon={<Icon variant="stack" />}
          suggestion="Add user(s) from the table to assign to the role."
        />
      )}
    </Panel>
  );
};
