import type { ResourceDefinitionType } from "@validereinc/domain";
import kebabCase from "lodash/kebabCase";

export const getImportDataActionQueueKey = ({
  resource,
  resourceId,
}: {
  resource: ResourceDefinitionType;
  resourceId?: string;
}) =>
  `queue-import-data-action-${resource.id}-${kebabCase(resource.label.singular)}${
    resourceId ? `-${resourceId}` : ""
  }`;
