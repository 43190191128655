import React, { useMemo } from "react";

import {
  DataTable,
  HeaderType,
  MultiDropdownInputWithSearch,
} from "@validereinc/common-components";

import Input from "#common/Input/Input";
import { linkToDataMappingDetail } from "#routers/links";
import { RoutingLink } from "#src/batteries-included-components/RoutingLink";
import { GetAllObjectValue } from "#utils/objectFormatter";

export default ({ dataMappingList }) => {
  /**
   * name - The unique identifier used to register it in the internal filters state.
   *        Results in least amount of boilerplate if using backend property name
   * component - The element that's cloned and given value and onChange props to
   *             connect it to the internal filters state
   */
  const filters = useMemo(() => {
    return [
      {
        name: "mapping_name",
        component: (
          <Input
            key="nameInput"
            icon="search"
            placeholder="Name"
          />
        ),
      },
      {
        name: "source_format",
        component: (
          <MultiDropdownInputWithSearch
            key="sourceFormatFilter"
            label="Source Format"
            options={GetAllObjectValue(dataMappingList, "source_format")}
            isClearable
          />
        ),
      },
      {
        name: "mapping_entity",
        component: (
          <MultiDropdownInputWithSearch
            key="mappingEntityFilter"
            label="Category"
            options={GetAllObjectValue(dataMappingList, "mapping_entity")}
            isClearable
          />
        ),
      },
    ];
  }, [dataMappingList]);

  /**
   * The key should be unique, and should be the dot notation to find the string
   * representation of each item to use the default renderers.
   */
  const headers: Array<HeaderType<any>> = useMemo(
    () => [
      {
        label: "Data Mapping",
        key: "mapping_name",
        isFixed: true,
        isSortable: true,
        renderComponent: ({ item }) => (
          <RoutingLink to={linkToDataMappingDetail(item?.data_mapping_id)}>
            {item.mapping_name}
          </RoutingLink>
        ),
      },
      {
        label: "Source Format",
        key: "source_format",
        isSortable: true,
      },
      {
        label: "Data Uploads",
        key: "ingestion_count",
        isSortable: true,
      },
      {
        label: "Entity Category",
        key: "mapping_entity",
        isSortable: true,
      },
      {
        label: "Created",
        key: "date_created",
        isSortable: true,
        renderComponent: ({ item }) => (
          <DataTable.DataRow.DateCell value={item.date_created} />
        ),
      },
    ],
    []
  );

  return { filters, headers };
};
