import defaultMapProps, { defaultZoom } from "./MapboxConfig";
import React, { useEffect } from "react";
import Mapbox, { NavigationControl, useMap } from "react-map-gl";
import { AutoSizer } from "react-virtualized";

import * as PropTypes from "prop-types";

// Map tiles don't resize properly when the parent height changes.
// So manual resize is necesary.
const MapResizer = ({ height }) => {
  const { current: map } = useMap();

  useEffect(() => {
    map.resize();
  }, [height]);

  return null;
};

const Map = ({
  initialViewState,
  children,
  overrideHeight,
  subtractFromHeightOnResize,
  ...restMapboxProps
}) => {
  return (
    <AutoSizer disableWidth>
      {({ height }) => (
        <div
          style={{
            height: overrideHeight || height - subtractFromHeightOnResize,
          }}
        >
          <Mapbox
            {...defaultMapProps}
            {...restMapboxProps}
            attributionControl={false}
            initialViewState={{ zoom: defaultZoom, ...initialViewState }}
          >
            <NavigationControl showCompass={false} />
            <MapResizer height={height} />
            {children}
          </Mapbox>
        </div>
      )}
    </AutoSizer>
  );
};

Map.defaultProps = {
  overrideHeight: null,
  subtractFromHeightOnResize: 0,
};

Map.propTypes = {
  initialViewState: PropTypes.object.isRequired,
  overrideHeight: PropTypes.number, // overrides auto MapResizer allowing setting of the height directly
  subtractFromHeightOnResize: PropTypes.number, // helps MapResizer when the parent height changes and Map is used in a panel
  children: PropTypes.element,
};

export default Map;
