import { useNavigate, useSearchParams } from "#routers/hooks";
import {
  FlowsTablePanel,
  RecordsListTablePanel,
} from "#src/batteries-included-components";
import { ApplyAutomationProgressBanner } from "#src/batteries-included-components/Banners/ApplyAutomationProgressBanner";
import { ImportDataActionStatusWithQueue } from "#src/batteries-included-components/Banners/ImportDataActionStatus";
import { FlowsViewFilterArea } from "#src/batteries-included-components/FilterAreas/AssetsFilterAreas";
import {
  FlowRecordsTableFilterArea,
  FlowRecordsViewFilterArea,
} from "#src/batteries-included-components/FilterAreas/RecordsFilterAreas";
import { useIsFeatureAvailable } from "#src/contexts/AuthenticatedContext.helpers";
import useLocalization from "#src/hooks/useLocalization";
import {
  CustomStorageKeyTypes,
  useCustomStorageKey,
  useStorageKey,
} from "#src/hooks/useStorageKey";
import { useBreadcrumbsFromRoute } from "#utils/route";
import { Button, Page, Tab } from "@validereinc/common-components";
import { AssetType, ResourceDefinitions } from "@validereinc/domain";
import React from "react";
import { FlowListRoute } from "./";
import { FlowCreateRoute } from "./create";
import { getImportDataActionQueueKey } from "#src/batteries-included-components/Buttons/ImportDataAction/ImportDataAction.helpers";

const DEFAULT_TAB_KEY = "list";

export const FlowsListPage = () => {
  const [isDataIngestionEnabled] = useIsFeatureAvailable({
    featureFlagQuery: "core:data_pipeline",
  });
  const navigate = useNavigate();
  const { localize, isLoading } = useLocalization();
  const [searchParams, setSearchParams] = useSearchParams();
  const calcsAndRecordsSharedStorageKeys = useStorageKey(
    "calculations-records-shared"
  );
  const assetsSharedStorageKeys = useStorageKey("assets-shared");
  const listStorageKeys = useStorageKey("flows");
  const recordStorageKeys = useStorageKey("flows-records");
  const recordsApplyAutomationStorageKey = useCustomStorageKey(
    "flows-records-apply-automation",
    CustomStorageKeyTypes.JOB
  );

  const onClickAddNewFlow = () => {
    navigate(FlowCreateRoute.toLinkParts());
  };

  const actionRow = (
    <Button
      variant="primary"
      onClick={onClickAddNewFlow}
    >
      {`Create ${localize("Flow")}`}
    </Button>
  );

  const [breadcrumbs] = useBreadcrumbsFromRoute(FlowListRoute);
  const flowsImportDataActionQueueKey = getImportDataActionQueueKey({
    resource: {
      ...ResourceDefinitions.flow,
      label: {
        singular: localize(ResourceDefinitions.flow.label.singular),
        plural: localize("flow_plural"),
      },
    },
  });
  const recordsImportDataActionQueueKey = getImportDataActionQueueKey({
    resource: {
      ...ResourceDefinitions.record,
      label: {
        singular: `${localize(ResourceDefinitions.flow.label.singular)} ${ResourceDefinitions.record.label.singular}`,
        plural: `${localize(ResourceDefinitions.flow.label.singular)} ${ResourceDefinitions.record.label.plural}`,
      },
    },
  });

  return (
    <Page
      breadcrumbs={breadcrumbs}
      category={FlowListRoute.subtitle}
      title={`${localize(FlowListRoute.title)}`}
      actionRow={actionRow}
      activeTabKey={searchParams?.tab ?? DEFAULT_TAB_KEY}
      onActiveTabKeyChange={(tabKey: string) =>
        setSearchParams({ tab: tabKey })
      }
      isLoading={isLoading}
    >
      <Tab
        title="List"
        tabKey="list"
      >
        {isDataIngestionEnabled ? (
          <ImportDataActionStatusWithQueue
            queueKey={flowsImportDataActionQueueKey}
          />
        ) : null}
        <FlowsViewFilterArea
          viewConfigStorageKey={assetsSharedStorageKeys.viewConfigStorageKey}
        />
        <FlowsTablePanel
          {...listStorageKeys}
          viewConfigStorageKey={assetsSharedStorageKeys.viewConfigStorageKey}
          isWithCustomAttributes
          isWithTableActions
        />
      </Tab>
      <Tab
        title="Records"
        tabKey="records"
      >
        {isDataIngestionEnabled ? (
          <ImportDataActionStatusWithQueue
            queueKey={recordsImportDataActionQueueKey}
          />
        ) : null}
        <ApplyAutomationProgressBanner
          jobStorageKey={recordsApplyAutomationStorageKey}
        />
        <FlowRecordsViewFilterArea
          viewConfigStorageKey={
            calcsAndRecordsSharedStorageKeys.viewConfigStorageKey
          }
        />
        <RecordsListTablePanel
          type={AssetType.FLOW}
          {...recordStorageKeys}
          viewConfigStorageKey={
            calcsAndRecordsSharedStorageKeys.viewConfigStorageKey
          }
          applyAutomationStorageKey={recordsApplyAutomationStorageKey}
          filterComponentSlot={({ recordValueTypes }) => (
            <FlowRecordsTableFilterArea
              filterConfigStorageKey={recordStorageKeys.filterConfigStorageKey}
              selectableProperties={recordValueTypes}
            />
          )}
        />
      </Tab>
    </Page>
  );
};
