import { useNavigate } from "#src/Routers/hooks";
import { WorkflowsCategoriesFilterPanel } from "#src/batteries-included-components/Panels/FilterPanels/WorkflowCategoriesFilterPanel";
import { useTableSortingAndPagination } from "#src/components/Redux/reducers/tableStateReducer";
import { useStorageKey } from "#src/hooks/useStorageKey";
import { WorkflowCategoriesRoutePath } from "#src/routes/workflows/categories";
import { WorkflowCategoryDetailsRoutePath } from "#src/routes/workflows/categories/[workflowCategoryId]";
import { useBreadcrumbsFromRoute } from "#src/utils/route";
import { useQuery } from "@tanstack/react-query";
import {
  CardGrid,
  Column,
  EmptyState,
  Page,
  Panel,
  StorageKeys,
  useFilters,
} from "@validereinc/common-components";
import { WorkflowCategoriesAdapter } from "@validereinc/domain";
import React from "react";

const WorkflowCategoriesListPageContent = ({
  filterConfigStorageKey,
}: Pick<StorageKeys, "filterConfigStorageKey">) => {
  const navigate = useNavigate();
  const [filters] = useFilters(filterConfigStorageKey);
  const [tableState, updateTableState] = useTableSortingAndPagination(
    {
      header: "name",
      direction: "asc",
    },
    filters
  );
  const workflowCategoriesQuery = useQuery({
    queryKey: ["workflows", "categories", { ...tableState, filters }],
    queryFn: () => {
      return WorkflowCategoriesAdapter.getList({
        page: tableState.page,
        pageSize: tableState.pageSize,
        sortBy: tableState.header,
        sortDirection: tableState.direction,
        filters,
      });
    },
  });

  const headers = [
    {
      key: "name",
      sortable: true,
      variant: "link",
    },
    { label: "Last Updated", key: "updated_at", variant: "date" },
  ];

  const onCardClick = (workflowCategoryId: string) => {
    navigate(
      WorkflowCategoryDetailsRoutePath.toLinkParts({
        pathParams: {
          workflowCategoryId,
        },
      })
    );
  };

  return (
    <Panel title={"Categories"}>
      <Column>
        <CardGrid
          items={workflowCategoriesQuery.data?.data ?? []}
          pagination={{
            page: tableState.page,
            pageSize: tableState.pageSize,
            total: workflowCategoriesQuery.data?.total_entries,
          }}
          onPaginationChange={updateTableState}
          headers={headers}
          isFluid={false}
          listCardType="schema"
          isLoading={workflowCategoriesQuery.isLoading}
          hasCta={false}
          onPrimaryCardClick={onCardClick}
          cardConfig={{
            hasDescriptionLabel: false,
            hasReducedHeight: true,
            hideGreyBar: false,
          }}
        />
        {!workflowCategoriesQuery.isLoading &&
        workflowCategoriesQuery.data?.total_entries === 0 ? (
          <EmptyState
            title="There are no categories to display"
            suggestion="Please try adjusting your filters"
          />
        ) : null}
      </Column>
    </Panel>
  );
};

export const WorkflowCategoriesListPage = () => {
  const [breadcrumbs] = useBreadcrumbsFromRoute(WorkflowCategoriesRoutePath);
  const { filterConfigStorageKey } = useStorageKey("workflows-categories");

  return (
    <Page
      title={WorkflowCategoriesRoutePath.title}
      category={WorkflowCategoriesRoutePath.subtitle}
      breadcrumbs={breadcrumbs}
    >
      <WorkflowsCategoriesFilterPanel
        filterConfigStorageKey={filterConfigStorageKey}
      />
      <WorkflowCategoriesListPageContent
        filterConfigStorageKey={filterConfigStorageKey}
      />
    </Page>
  );
};
