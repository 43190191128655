import CustomAttributeField from "#common/CustomAttributeField";
import { displayValueByDataType } from "#src/utils/display";
import { HeaderType } from "@validereinc/common-components";
import { AssetTypeType } from "@validereinc/domain";
import React, { useMemo } from "react";
import { useListCustomAttributes } from "../adapters/useCustomAttributes";

/** @deprecated will be removed in the near future */
export const useCustomAttributeFilters = <ItemType,>({
  assetType,
  section = "Filters",
  prefix = "custom_attributes",
  enabled = true,
  sortable = false,
}: {
  assetType: AssetTypeType;
  section?: string;
  prefix?: string;
  enabled?: boolean;
  sortable?: boolean;
}) => {
  const { data, isLoading } = useListCustomAttributes(
    { filters: { entity_type: assetType } },
    { enabled }
  );
  const customAttributes = data?.data ?? [];
  const customAttributeHeaders: Array<HeaderType<ItemType>> = useMemo(() => {
    return customAttributes.map(
      ({ display_name, field_name, data_type, entity_subtype }) => ({
        label: display_name,
        key: `${prefix}.${field_name}`,
        isSelected: !entity_subtype ? true : false,
        isSortable: sortable,
        field_name,
        renderComponent: ({ value }) =>
          displayValueByDataType(value, data_type),
      })
    );
  }, [customAttributes]);

  const customAttributeFilters = useMemo(() => {
    return customAttributes.map((attribute) => {
      const name = prefix
        ? `${prefix}.${attribute.field_name}`
        : attribute.field_name;

      return {
        section,
        component: (
          <CustomAttributeField
            key={name}
            name={name}
            attribute={{ ...attribute, is_required: false }}
            isDisabled={false}
            isOptionalTextShown={false}
            isFilterForm
          />
        ),
      };
    });
  }, [customAttributes, prefix]);

  return {
    customAttributes,
    customAttributeHeaders,
    customAttributeFilters,
    isLoading: enabled && isLoading,
  };
};

export const useCustomAttributeFiltersV2 = <ItemType,>({
  assetType,
  prefix = "custom_attributes",
  enabled = true,
  sortable = false,
}: {
  assetType: AssetTypeType;
  prefix?: string;
  enabled?: boolean;
  sortable?: boolean;
}) => {
  const { data, isLoading } = useListCustomAttributes(
    { filters: { entity_type: assetType } },
    { enabled }
  );
  const customAttributes = data?.data ?? [];

  const customAttributeHeaders: Array<HeaderType<ItemType>> = useMemo(() => {
    return customAttributes.map(
      ({ display_name, field_name, data_type, entity_subtype }) => ({
        label: display_name,
        key: `${prefix}.${field_name}`,
        isSelected: !entity_subtype ? true : false,
        isSortable: sortable,
        renderComponent: ({ value }) =>
          displayValueByDataType(value, data_type),
      })
    );
  }, [customAttributes]);

  const customAttributeFilters = useMemo(() => {
    return customAttributes.map((attribute) => {
      const name = prefix
        ? `${prefix}.${attribute.field_name}`
        : attribute.field_name;

      return (
        <CustomAttributeField
          key={name}
          name={name}
          attribute={{ ...attribute, is_required: false }}
          isDisabled={false}
          isOptionalTextShown={false}
          isFilterForm
        />
      );
    });
  }, [customAttributes, prefix]);

  return {
    customAttributes,
    customAttributeHeaders,
    customAttributeFilters,
    isLoading: enabled && isLoading,
  };
};
