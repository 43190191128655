import { linkToDeviceDetail } from "#routers/links";
import { RoutingLink } from "#src/batteries-included-components/RoutingLink";
import { useStorageKey } from "#src/hooks/useStorageKey";
import useTableState from "#src/hooks/useTableState";
import {
  DataTable,
  DataTablePanel,
  HeaderType,
  SortingType,
} from "@validereinc/common-components";
import type { MeasurementSeriesType } from "@validereinc/domain";
import {
  AssetType,
  MeasurementSeriesAdapter,
  SortDirection,
} from "@validereinc/domain";
import React, { useCallback } from "react";

const sorting: SortingType = {
  sortBy: "device_name",
  sortDirection: SortDirection.ASCENDING,
};

export const FlowDevicesPanel = ({
  flowId,
  isLoading: isFlowLoading,
}: {
  flowId?: string;
  isLoading: boolean;
}) => {
  const { tableConfigStorageKey } = useStorageKey("flows-devices");

  const onFetchData = useCallback(async (searchParams) => {
    const { rowPerPage, sort, sortDirection, page } = searchParams;

    const response = await MeasurementSeriesAdapter.getList({
      page_size: rowPerPage || 10,
      page: page || 1,
      sort_by: sort || sorting.sortBy,
      sort_direction: sortDirection || sorting.sortDirection,
      filters: {
        subjectId: flowId,
        subjectType: AssetType.FLOW,
      },
    });

    return { data: response };
  }, []);

  const { tableProps } = useTableState({
    onFetchData,
    initialSort: sorting,
  });

  const headers: Array<HeaderType<MeasurementSeriesType>> = [
    {
      label: "Name",
      key: "device_name",
      isSortable: true,
      renderComponent: ({ item }) =>
        item.device_id ? (
          <RoutingLink to={linkToDeviceDetail(item.device_id)}>
            {item.device_name}
          </RoutingLink>
        ) : (
          "-"
        ),
    },
    {
      label: "Type",
      key: "type_id",
      isSortable: true,
      renderComponent: ({ item: { device } }) => device?.type?.name ?? "-",
    },
    {
      label: "Status",
      key: "status",
      isSortable: true,
      renderComponent: ({ item: { device } }) => (
        <DataTable.DataRow.PillCell
          variant={device?.status === "active" ? "success" : "default"}
          value={device?.status || "-"}
        />
      ),
    },
  ];

  return (
    <DataTablePanel
      storageKey={tableConfigStorageKey}
      panelProps={{
        title: "Devices",
        isFluidY: false,
        loaded: !isFlowLoading,
      }}
      dataTableProps={{
        ...tableProps,
        variant: "simplicity-first",
        headers,
      }}
    />
  );
};
