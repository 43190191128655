import { useTableSortingAndPagination } from "#redux/reducers/tableStateReducer";
import { linkToFormSubmissionDetail } from "#routers/links";
import { FormSubmissionsTableFilterArea } from "#src/batteries-included-components/FilterAreas/FormsFilterAreas";
import { useAddFormSubmissionContext } from "#src/batteries-included-components/Layouts/Form/Submission/Add/AddFormSubmission/AddFormSubmissionContext";
import { FormSubmissionsFilterType } from "#src/batteries-included-components/Panels/FilterPanels/FormSubmissionsFilterPanel/FormSubmissionsFilterPanel";
import { RoutingLink } from "#src/batteries-included-components/RoutingLink";
import { getStatusType } from "#src/components/Common/Table/rendererHelper";
import { useListFormSubmissions } from "#src/components/hooks/adapters/useFormSubmissions";
import { useMeasurementTypes } from "#src/contexts/MeasurementTypeContext";
import { useSessionStickyState } from "#src/hooks/useStickyState";
import { linkToFormTemplateDetail } from "#src/routes/forms/categories/[categoryId]/templates/[formTemplateId]";
import { useQuery } from "@tanstack/react-query";
import {
  DataTable,
  DataTablePanel,
  HeaderType,
} from "@validereinc/common-components";
import {
  FormSchemaAdapter,
  FormSubmissionAdapter,
  FormSubmissionStatus,
  FormSubmissionType,
  Resources,
  SortDirection,
} from "@validereinc/domain";
import { toFlattenedObject, toStartCaseString } from "@validereinc/utilities";
import React, { useMemo } from "react";
import { getHeadersFromSchema } from "../FormSubmissionsTablePanel.helpers";

export const AddFormSubmissionsTablePanel = ({
  shouldHideDrafts = true,
}: {
  // submissions of status "draft" are hidden by default
  shouldHideDrafts?: boolean;
}) => {
  const {
    selectedFormSubmissions,
    selectFormSubmission,
    deselectFormSubmission,
    filterConfigStorageKey,
    tableConfigStorageKey,
    viewConfigStorageKey,
    filtersDefaultValues,
  } = useAddFormSubmissionContext();
  const [viewFilters] = useSessionStickyState<FormSubmissionsFilterType>(
    {},
    viewConfigStorageKey
  );
  const [tableFilters] = useSessionStickyState<FormSubmissionsFilterType>(
    {},
    filterConfigStorageKey
  );
  const filters = useMemo(
    () => ({
      ...tableFilters,
      ...viewFilters,
    }),
    [tableFilters, viewFilters]
  );
  const [tableState, updateTableState] = useTableSortingAndPagination(
    {
      sortBy: "created_at",
      sortDirection: SortDirection.DESCENDING,
    },
    filters
  );

  const { created_at, form_schema_id, ...restFilters } = filters;
  // name is constructed by the FE and can't be used by the BE to search
  delete restFilters.name;
  const params: Parameters<typeof FormSubmissionAdapter.getList>[0] = {
    page: tableState.page,
    pageSize: tableState.pageSize,
    sortBy: tableState.sortBy,
    sortDirection: tableState.sortDirection,
    filters: {
      created_at,
      ...toFlattenedObject({
        ...restFilters,
        "form_schema.id": form_schema_id,
        "form_schema.status": "active",
      }),
      ...(shouldHideDrafts && !restFilters.status?.length
        ? {
            status: Object.values(FormSubmissionStatus).filter(
              (s) => s !== FormSubmissionStatus.DRAFT
            ),
          }
        : {}),
    },
    meta: { answers: true },
  };
  const { data, isLoading } = useListFormSubmissions(params);

  const schemaQuery = useQuery({
    queryKey: [Resources.FORM_SCHEMA, form_schema_id],
    queryFn: () => {
      if (!form_schema_id) return;

      return FormSchemaAdapter.getOne({
        id: form_schema_id,
      });
    },
    enabled: Boolean(form_schema_id),
    select: (resp) => resp?.data,
    staleTime: 2 * 60 * 1000,
  });

  const { measurementUnits } = useMeasurementTypes();

  const dynamicSubmissionsHeaders = useMemo(
    () => getHeadersFromSchema(measurementUnits, schemaQuery.data),
    [schemaQuery.data]
  );

  const submissionsHeaders: Array<HeaderType<FormSubmissionType>> = [
    {
      label: "Submission Name",
      key: "id",
      isSortable: true,
      renderComponent: ({ item }) => (
        <RoutingLink to={linkToFormSubmissionDetail(item.id)}>
          {`${item?.form_schema?.name} - ${item?.id?.slice(0, 7)}`}
        </RoutingLink>
      ),
    },
    {
      label: "Status",
      key: "status",
      isSortable: true,
      renderComponent: ({ item }: { item: FormSubmissionType }) => (
        <DataTable.DataRow.PillCell
          variant={getStatusType(item.status, "form").type}
          value={toStartCaseString(item.status)}
        />
      ),
    },
    {
      label: "Template",
      key: "template",
      isSortable: true,
      renderComponent: ({ item }) => (
        <RoutingLink
          to={linkToFormTemplateDetail(
            item.form_schema?.form_category_id,
            item.form_schema_id
          )}
        >
          {item.form_schema?.name}
        </RoutingLink>
      ),
    },
    {
      label: "Submission Date",
      key: "created_at",
      isSortable: true,
      renderComponent: ({ item }) => (
        <DataTable.DataRow.DateCell
          value={item.created_at}
          withTime
        />
      ),
    },
    ...dynamicSubmissionsHeaders,
  ];

  const items = useMemo(
    (): Array<FormSubmissionType & { name: string }> =>
      data?.data.reduce(
        (
          itemsWithNames: Array<FormSubmissionType & { name: string }>,
          item: FormSubmissionType
        ) => {
          const name = `${item?.form_schema?.name} - ${item?.id?.slice(0, 7)}`;
          const newItem = { ...item, name };
          if (
            newItem.name
              .toLowerCase()
              .includes(filters.name?.toLowerCase() ?? "")
          ) {
            itemsWithNames.push(newItem);
          }

          return itemsWithNames;
        },
        []
      ),
    [data, filters.name]
  );

  return (
    <DataTablePanel
      storageKey={tableConfigStorageKey}
      panelProps={{
        title: "Form Submissions",
      }}
      filterComponent={
        <FormSubmissionsTableFilterArea
          defaultValues={filtersDefaultValues}
          filterConfigStorageKey={filterConfigStorageKey}
          shouldShowFormSubmissionSearchInput
          shouldShowSavedFilters={false}
        />
      }
      dataTableProps={{
        items,
        headers: submissionsHeaders,
        isLoading,
        sorting: {
          sortBy: tableState.sortBy,
          sortDirection: tableState.sortDirection,
        },
        pagination: {
          page: tableState.page,
          pageSize: tableState.pageSize,
          total: data?.total_entries,
        },
        onPaginationChange: updateTableState,
        onSortChange: updateTableState,
        getItemActions: ({ item }: { item: FormSubmissionType }) =>
          selectedFormSubmissions?.find((selected) => selected.id === item.id)
            ? [
                {
                  label: "Remove",
                  buttonProps: {
                    onClick: () => deselectFormSubmission(item?.id),
                    icon: "minus-circle",
                  },
                },
              ]
            : [
                {
                  label: "Add",
                  buttonProps: {
                    onClick: () => selectFormSubmission(item),
                    icon: "plus-circle",
                  },
                },
              ],
      }}
    />
  );
};
