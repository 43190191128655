import { Icon, type IconProps } from "@validereinc/common-components";
import classNames from "classnames/bind";
import React from "react";
import styles from "./Marker.module.scss";

const cx = classNames.bind(styles);

const iconSizeMap = {
  large: 30,
  small: 15,
};

// IMPROVE: this component and associated styles need cleanup
const MarkerIcon = ({
  iconType,
  size,
  colour,
}: {
  iconType: IconProps["variant"];
  size: "small" | "large";
  colour: "darkBlue" | "red";
}) => {
  const iconSize = iconSizeMap[size];
  const iconWeight = size === "small" ? "regular" : "light";

  return (
    <div
      className={cx(colour, "marker", iconType ? "icon" : "noIcon", {
        [size]: !iconType,
      })}
    >
      <Icon
        variant={iconType}
        size={iconSize}
        weight={iconWeight}
      />
    </div>
  );
};

export default MarkerIcon;
