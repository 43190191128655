import { useMutation } from "@tanstack/react-query";
import {
  Dialog,
  Form,
  FormButton,
  useAlert,
  useForm,
} from "@validereinc/common-components";
import { EstimationMethodDomain } from "@validereinc/domain";
import flatten from "flat";
import merge from "lodash/merge";
import React, { useEffect } from "react";
import { getCalculationParameterInput } from "../helpers";

import { CalculationParameterConfigurationType } from "#batteries-included-components/Tabs/EstimationMethod/ConfigurationTab/ConfigurationTab";
import { DateRange } from "#src/batteries-included-components/Forms/DateRange";
import { useMeasurementTypes } from "#src/contexts/MeasurementTypeContext";
import { isNumeric } from "#src/utils/unitConverter";
import type { AssetTypeType } from "@validereinc/domain";
import { yearMonthFormatter } from "@validereinc/utilities";
import { startOfMonth } from "date-fns";

export const DefaultValueDialog = ({
  methodId,
  entityType,
  itemToEdit,
  defaultValues,
  onSubmit,
  onClose,
}: {
  methodId?: string;
  entityType?: AssetTypeType;
  itemToEdit?: CalculationParameterConfigurationType;
  defaultValues: Record<string, any>;
  onSubmit?: () => void;
  onClose?: () => void;
}) => {
  const { addAlert } = useAlert();
  const { getUnitName } = useMeasurementTypes();

  const form = useForm({
    defaultValues: itemToEdit
      ? { [itemToEdit?.id]: itemToEdit?.default_value?.value }
      : {},
  });

  useEffect(() => {
    form.reset();
  }, [itemToEdit]);

  const updateEstimationMethod = useMutation({
    mutationFn: async (
      inputs: Record<string, string> & {
        dateRange: {
          from?: Date;
          to?: Date;
        };
      }
    ) => {
      try {
        if (!itemToEdit || !methodId || !entityType) {
          throw new Error("Estimation method identifiers unavailable");
        }

        const value = flatten(inputs, { safe: true })[itemToEdit.id];
        const isValueSet = value && value !== "";

        await EstimationMethodDomain.update({
          id: methodId,
          // This is a workaround so we don't delete peer nested default_values
          data: {
            default_values: merge(defaultValues, {
              [itemToEdit.id]: isValueSet
                ? {
                    // TODO: Sometimes we get invalid measurement types from analytics
                    // measurement_quantity: itemToEdit.measurement_quantity,
                    // measurement_type: itemToEdit.measurement_type,
                    unit: itemToEdit.measurement_unit,
                    value: isNumeric(value) ? +value : value,
                  }
                : null,
            }),
            start_date:
              inputs.dateRange?.from && inputs.hasDateRangeStart
                ? yearMonthFormatter(startOfMonth(inputs.dateRange.from))
                : "min",
            end_date:
              inputs.dateRange?.to && inputs.hasDateRangeEnd
                ? yearMonthFormatter(startOfMonth(inputs.dateRange?.to))
                : "max",
          },
          meta: { entityType },
        });
        onClose?.();
        onSubmit?.();
        addAlert({
          variant: "success",
          message: `Updated default value for ${itemToEdit?.display_name}`,
        });
      } catch (err) {
        addAlert({
          variant: "error",
          message: "Unable to update default value",
        });
      }
    },
  });

  return (
    <Form
      {...form}
      onSubmit={form.handleSubmit(updateEstimationMethod.mutate)}
    >
      <Dialog
        isOpen={!!itemToEdit}
        onClose={onClose}
        title="Define Default Value"
        actionRow={[
          <FormButton
            key="save-default-values"
            variant="primary"
            onClick={form.handleSubmit(updateEstimationMethod.mutate)}
          >
            Save
          </FormButton>,
        ]}
      >
        {itemToEdit
          ? getCalculationParameterInput({
              ...itemToEdit,
              required: false,
              measurement_unit: getUnitName(itemToEdit.measurement_unit),
            })
          : null}
        <DateRange />
      </Dialog>
    </Form>
  );
};
