import { FeatureFlagType, PermissionType } from "@validereinc/domain";

// --- V2 Auth Helpers: reflects Node (CarbonHub) authorization API ---
export const AuthQueryOperator = {
  AND: "$and",
  OR: "$or",
  NOT: "$not",
} as const;

export type AuthQueryOperatorType =
  (typeof AuthQueryOperator)[keyof typeof AuthQueryOperator];

/**
 * The query format in which you provide feature flags or permissions to check if they result in an allow or deny authorization
 */
export type AuthQueryType =
  | string
  | {
      [AuthQueryOperator.AND]?: AuthQueryType[];
      [AuthQueryOperator.OR]?: AuthQueryType[];
      [AuthQueryOperator.NOT]?: string;
    };

/**
 * Generate a function that comes loaded with the provided authorization
 * configuration (list of permissions or feature flags) that can be then run
 * against a query to check if access should be granted or denied.
 *
 * @param authEntityList a list of permissions or feature flags configured in
 * the back-end based on the authenticated user
 * @returns a function with the provided authorization data of the authenticated
 * user, closured-in. When run with a query, it then returns true if access
 * should be granted based on query, false if it should be denied.
 */
export const createAuthorizationEvaluator =
  (authEntityList: PermissionType[] | FeatureFlagType[] | undefined = []) =>
  (query: AuthQueryType): boolean => {
    const hasUnlimitedAccess = authEntityList.some(
      (authEntityItem) => authEntityItem.name === "*"
    );

    // "*" is a special auth entity that grants access always for any query
    if (hasUnlimitedAccess) {
      return true;
    }

    // the core check that happens in this recursive function is the logical
    // block below. does the most granular query match an entry in the
    // config list? if so, it's an "allow" otherwise we "deny".
    if (typeof query === "string") {
      // only the "name" property matters i.e. does what you queried for exist in the config or not?
      return authEntityList.some(
        (authEntityItem) => authEntityItem.name === query
      );
    }

    if (typeof query === "object" && query !== null) {
      const operatorFunctions = {
        $and: (conditions: AuthQueryType[]) =>
          conditions.every((condition) =>
            createAuthorizationEvaluator(authEntityList)(condition)
          ),
        $or: (conditions: AuthQueryType[]) =>
          conditions.some((condition) =>
            createAuthorizationEvaluator(authEntityList)(condition)
          ),
        $not: (condition: string) =>
          !createAuthorizationEvaluator(authEntityList)(condition),
      } satisfies Record<
        AuthQueryOperatorType,
        | ((condition: AuthQueryType[]) => boolean)
        | ((condition: string) => boolean)
      >;

      // when operators are provided, only the first one is considered.
      // Additional operators must be nested within the first one. This rule
      // applies at every nested level as well exactly the same as the first level.
      const firstOperator = Object.keys(query)[0] as AuthQueryOperatorType;
      const conditionsUnderFirstOperator =
        firstOperator === "$and" ||
        firstOperator === "$or" ||
        firstOperator === "$not"
          ? query[firstOperator]
          : null;

      if (
        firstOperator === "$and" &&
        Array.isArray(conditionsUnderFirstOperator)
      ) {
        return operatorFunctions[firstOperator](conditionsUnderFirstOperator);
      }

      if (
        firstOperator === "$or" &&
        Array.isArray(conditionsUnderFirstOperator)
      ) {
        return operatorFunctions[firstOperator](conditionsUnderFirstOperator);
      }

      if (
        firstOperator === "$not" &&
        typeof conditionsUnderFirstOperator === "string"
      ) {
        return operatorFunctions[firstOperator](conditionsUnderFirstOperator);
      }
    }

    // if the query is invalid, by principle of least-privelege, deny access
    return false;
  };
