import CalibrationService from "#services/CalibrationService";
import {
  Button,
  Dialog,
  useAlert,
  useForm,
} from "@validereinc/common-components";
import React, { useState } from "react";
import EffectiveDurationForm from "./EffectiveDurationForm";
import "./EffectiveDurationModal.scss";

export const EffectiveDurationModal = (props: {
  show: boolean;
  onHide: () => void;
  instrumentDetail?: Record<string, any> | null;
  refetchCalibrationData: () => void;
  addAlertMessage: () => void;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { addAlert } = useAlert();

  const formMethods = useForm({
    defaultValues: {
      effectiveDuration: props.instrumentDetail?.standard_check_days_duration,
    },
  });

  const handleSubmit = (formState) => {
    setIsLoading(true);

    CalibrationService.updateStandardCheckDuration(
      props.instrumentDetail?.id,
      formState.effectiveDuration
    )
      .then(() => {
        props.refetchCalibrationData();
        addAlert({
          variant: "success",
          message: "Effective Duration updated successfully.",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Dialog
      className="effectiveDurationModal"
      isOpen={props.show}
      onClose={props.onHide}
      title={`Edit Effective Duration: ${props.instrumentDetail?.name ?? ""}`}
      actionRow={[
        <Button
          key="submit"
          variant="primary"
          type="submit"
          className="pull-right"
          isLoading={isLoading}
          onClick={formMethods.handleSubmit(handleSubmit)}
        >
          Update Effective Duration
        </Button>,
      ]}
    >
      <>
        <div className="effectiveDurationModal__hint">
          The updated Standard Check Effective Duration will apply to{" "}
          <b>all future checks</b>.
        </div>

        <EffectiveDurationForm
          onSubmit={handleSubmit}
          formMethods={formMethods}
          isLoading={isLoading}
          onHide={props.onHide}
        />
      </>
    </Dialog>
  );
};

export default EffectiveDurationModal;
