import { useMeasurementTypes } from "#src/contexts/MeasurementTypeContext";
import {
  Button,
  colours,
  DropdownInput,
  DropdownMenu,
  DropdownMenuOption,
  EmptyState,
  Icon,
  Switch,
  TextInput,
  Tooltip,
  useFieldArray,
  useFormContext,
} from "@validereinc/common-components";
import {
  EquationComponentField,
  EquationComponentFieldType,
  EquationComponentType,
  FormSchemaNormalQuestionLookupType,
  FormSchemaQuestionDisplayType,
  MeasurementTypeType,
} from "@validereinc/domain";
import classNames from "classnames/bind";
import React, { useEffect, useState } from "react";
import styles from "./MeasurementFields.module.scss";

const cx = classNames.bind(styles);

const MEASUREMENT_SOURCE_TOGGLE_TOOLTIP_CONTENT =
  "Defining a measurement source allows you to reference a previous look-up question as the source of the measurement.";
const MEASUREMENT_SOURCE_INPUT_TOOLTIP_CONTENT =
  "Select a previous look-up question as the source of the measurement.";
const MEASUREMENT_SUBJECT_TOGGLE_TOOLTIP_CONTENT =
  "Defining a measurement subject allows you to reference a previous look-up question as the subject of the measurement.";
const MEASUREMENT_SUBJECT_INPUT_TOOLTIP_CONTENT =
  "Select a previous look-up question as the subject of the measurement.";
const MEASUREMENT_DATE_TIME_INPUT_TOOLTIP_CONTENT =
  "Select a previous date-time question as the date-time of the measurement.";

export const MeasurementFields = ({
  isCalculatedMeasurement,
  validSourceQuestions,
  validSubjectQuestions,
  validDateTimeQuestions,
  validEquationQuestionComponentQuestions,
  hasMeasurementSource,
  hasMeasurementSubject,
  hasMeasurementDateTime,
  onChangeHasMeasurementSource,
  onChangeHasMeasurementSubject,
  onChangeHasMeasurementDateTime,
}: {
  isCalculatedMeasurement: boolean;
  validSourceQuestions?: FormSchemaNormalQuestionLookupType[];
  validSubjectQuestions?: FormSchemaNormalQuestionLookupType[];
  validDateTimeQuestions?: FormSchemaQuestionDisplayType[];
  validEquationQuestionComponentQuestions: Record<
    string,
    FormSchemaQuestionDisplayType
  >;
  hasMeasurementSource: boolean;
  hasMeasurementSubject: boolean;
  hasMeasurementDateTime: boolean;
  onChangeHasMeasurementSource: (newHasMeasurementSource: boolean) => void;
  onChangeHasMeasurementSubject: (newHasMeasurementSubject: boolean) => void;
  onChangeHasMeasurementDateTime: (newHasMeasurementDateTime: boolean) => void;
}) => {
  const { measurementTypes, measurementUnits, getUnitsByQuantity } =
    useMeasurementTypes();
  const form = useFormContext();
  const measurement_type = form.watch("measurement_type");
  const [measurementUnitOptions, setMeasurementUnitOptions] =
    useState(measurementUnits);
  const values = form.watch();

  const equationComponents: EquationComponentType[] =
    values.equation_components;

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: "equation_components",
  });

  const onClickHandler = (
    equationComponentFieldType: EquationComponentFieldType
  ) => {
    append({
      value: "",
      fieldType: equationComponentFieldType,
    });
  };

  const getDropdownInputProps = (field) => {
    switch (field.fieldType) {
      case EquationComponentField.OPERATOR:
        return {
          placeholder: "Select Operator...",
          options: operatorsOptions,
          valueKey: "value",
        };
      case EquationComponentField.QUESTION:
        return {
          placeholder: "Select Question...",
          options: questionsOptions,
          valueKey: "question_id",
        };
    }
  };

  useEffect(() => {
    const type = measurementTypes.find(
      ({ id }: MeasurementTypeType) => id === measurement_type
    );
    if (measurement_type && type?.quantity) {
      setMeasurementUnitOptions(getUnitsByQuantity(type.quantity));
    } else {
      setMeasurementUnitOptions(measurementUnits);
    }
  }, [measurement_type]);

  const equationComponentsOptions: DropdownMenuOption[] = [
    {
      label: "Constant",
      onClick: () => onClickHandler(EquationComponentField.CONSTANT),
      slotLeft: ({ iconProps }) => (
        <Icon
          variant="hash"
          {...iconProps}
        />
      ),
    },
    {
      label: "Operator",
      onClick: () => onClickHandler(EquationComponentField.OPERATOR),
      slotLeft: ({ iconProps }) => (
        <Icon
          variant="sigma-icon"
          {...iconProps}
        />
      ),
    },
    {
      label: "Question",
      onClick: () => onClickHandler(EquationComponentField.QUESTION),
      slotLeft: ({ iconProps }) => (
        <Icon
          variant="seal-question"
          {...iconProps}
        />
      ),
    },
  ];

  const operatorsOptions = [
    { label: "+", value: "+" },
    { label: "-", value: "-" },
    { label: "*", value: "*" },
    { label: "/", value: "/" },
    { label: "^", value: "^" },
    { label: "(", value: "(" },
    { label: ")", value: ")" },
  ];

  const questionsOptions = Object.values(
    validEquationQuestionComponentQuestions
  );

  return (
    <>
      <DropdownInput
        label="Measurement Type"
        name="measurement_type"
        options={measurementTypes}
        labelKey="name"
        valueKey="id"
        isFluid
        isRequired
      />

      <DropdownInput
        label="Unit of Measurement"
        name="measurement_unit"
        options={measurementUnitOptions}
        labelKey="name.singular"
        valueKey="id"
        isFluid
        isRequired
      />

      {isCalculatedMeasurement && (
        <div>
          <p className={cx("question-heading")}>Equation</p>
          {equationComponents.length ? (
            <>
              {fields.map((field, index: number) => (
                <div
                  key={field.id}
                  className={cx("equation-component-container")}
                >
                  {field.fieldType === EquationComponentField.CONSTANT ? (
                    <TextInput
                      name={`equation_components.${index}.value`}
                      placeholder="Enter Constant..."
                      type="number"
                      isRequired
                      isFluid
                      isInline
                    />
                  ) : (
                    <DropdownInput
                      className={cx(`equation-component-dropdown`)}
                      name={`equation_components.${index}.value`}
                      labelKey="label"
                      {...getDropdownInputProps(field)}
                      isSearchable={false}
                      isFluid
                      isRequired
                    />
                  )}
                  <Button
                    icon="trash"
                    onClick={() => remove(index)}
                    variant="error-outline"
                  />
                </div>
              ))}
            </>
          ) : (
            <EmptyState
              title="There is no equation configured"
              suggestion="Add questions and operators to define the equation."
              icon={<Icon variant="files" />}
              className={cx("equation-empty-state")}
            />
          )}

          <div className={cx("add-equation-component-dropdown-container")}>
            <DropdownMenu
              contentProps={{
                className: cx("add-equation-component-dropdown"),
              }}
              options={equationComponentsOptions}
            >
              <Button
                icon="triangle-down"
                iconPosition="right"
                iconProps={{ size: "12px" }}
                slotLeft={<Icon variant="plus-circle" />}
              >
                Add
              </Button>
            </DropdownMenu>
          </div>

          {equationComponents.length > 0 && equationComponents[0].value && (
            <div className={cx("equation-preview-container")}>
              <p className={cx("heading")}>Equation Preview</p>
              <p className={cx("equation")}>
                {equationComponents
                  .map((equationComponent) =>
                    equationComponent.fieldType ===
                    EquationComponentField.QUESTION
                      ? validEquationQuestionComponentQuestions[
                          equationComponent.value
                        ]?.prompt
                      : equationComponent.value
                  )
                  .join(" ")}
              </p>
            </div>
          )}
        </div>
      )}

      {validSourceQuestions && validSourceQuestions.length > 0 && (
        <>
          <div className={cx("switch-container")}>
            <Switch
              id="hasMeasurementSource"
              name="hasMeasurementSource"
              label="Define measurement source"
              value={hasMeasurementSource}
              onChange={onChangeHasMeasurementSource}
            />
            <Tooltip
              key="measurement-source-toggle"
              content={MEASUREMENT_SOURCE_TOGGLE_TOOLTIP_CONTENT}
              contentClassName={cx("tooltip")}
            >
              <Icon
                variant="info"
                weight="bold"
                size={16}
                colour={colours.colours.primary[700]}
              />
            </Tooltip>
          </div>
          {hasMeasurementSource && (
            <div className={cx("measurement-config-dropdown-container")}>
              <DropdownInput
                isRequired={hasMeasurementSource}
                label="Source Lookup Question"
                name="source_lookup_question_id"
                description={MEASUREMENT_SOURCE_INPUT_TOOLTIP_CONTENT}
                options={validSourceQuestions}
                labelKey="label"
                valueKey="question_id"
                isFluid
              />
            </div>
          )}
        </>
      )}

      {validSubjectQuestions && validSubjectQuestions.length > 0 && (
        <>
          <div className={cx("switch-container")}>
            <Switch
              id="hasMeasurementSubject"
              name="hasMeasurementSubject"
              label="Define measurement subject"
              value={hasMeasurementSubject}
              onChange={onChangeHasMeasurementSubject}
            />
            <Tooltip
              key="measurement-subject-toggle"
              content={MEASUREMENT_SUBJECT_TOGGLE_TOOLTIP_CONTENT}
              contentClassName={cx("tooltip")}
            >
              <Icon
                variant="info"
                weight="bold"
                size={16}
                colour={colours.colours.primary[700]}
              />
            </Tooltip>
          </div>
          {hasMeasurementSubject && (
            <div className={cx("measurement-config-dropdown-container")}>
              <DropdownInput
                isRequired={hasMeasurementSubject}
                label="Subject Lookup Question"
                name="subject_lookup_question_id"
                description={MEASUREMENT_SUBJECT_INPUT_TOOLTIP_CONTENT}
                options={validSubjectQuestions}
                labelKey="label"
                valueKey="question_id"
                isFluid
              />
            </div>
          )}
        </>
      )}

      {validDateTimeQuestions && validDateTimeQuestions.length > 0 && (
        <>
          <div className={cx("switch-container")}>
            <Switch
              id="hasMeasurementDateTime"
              name="hasMeasurementDateTime"
              label="Include measurement date-time"
              value={hasMeasurementDateTime}
              onChange={onChangeHasMeasurementDateTime}
            />
          </div>
          {hasMeasurementDateTime && (
            <div className={cx("measurement-config-dropdown-container")}>
              <DropdownInput
                isRequired={hasMeasurementDateTime}
                label="Date-Time Reference Question"
                name="measurement_time_question_id"
                description={MEASUREMENT_DATE_TIME_INPUT_TOOLTIP_CONTENT}
                options={validDateTimeQuestions}
                labelKey="label"
                valueKey="question_id"
                isFluid
              />
            </div>
          )}
        </>
      )}
    </>
  );
};
