import { EmissionCustomReportListTab } from "#batteries-included-components/Tabs/CustomReports/EmissionCustomReportListTab";
import { VolumeCustomReportListTab } from "#batteries-included-components/Tabs/CustomReports/VolumeCustomReportListTab";
import { useSearchParams } from "#routers/hooks";
import { CustomReportsListRoutePath } from "#routes/reports/custom-reports/index";
import { useIsFeatureAvailable } from "#src/contexts/AuthenticatedContext.helpers";
import { useBreadcrumbsFromRoute } from "#src/utils/route";
import { Page, Tab } from "@validereinc/common-components";
import React from "react";

export const CustomReportListPage = () => {
  const [{ tab }, setSearchParams] = useSearchParams();
  const [breadcrumbs] = useBreadcrumbsFromRoute(CustomReportsListRoutePath);
  const [isFlowVolumeEnabled] = useIsFeatureAvailable({
    featureFlagQuery: { $and: ["core:flows", "core:volumetric_reporting"] },
    permissionQuery: {
      $and: ["flows:read", "records:read", "volumetric_reporting:read"],
    },
  });
  const [isEquipmentEmissionsEnabled] = useIsFeatureAvailable({
    featureFlagQuery: "core:equipment",
    permissionQuery: { $and: ["equipment:read", "calculator_results:read"] },
  });

  return (
    <Page
      category={CustomReportsListRoutePath.subtitle}
      title={CustomReportsListRoutePath.title}
      breadcrumbs={breadcrumbs}
      activeTabKey={tab ?? (isFlowVolumeEnabled ? "volume" : "emissions")}
      onActiveTabKeyChange={(tab) => setSearchParams({ tab })}
    >
      {isFlowVolumeEnabled ? (
        <Tab
          title="Volume"
          tabKey="volume"
        >
          <VolumeCustomReportListTab />
        </Tab>
      ) : null}
      {isEquipmentEmissionsEnabled ? (
        <Tab
          title="Emissions"
          tabKey="emissions"
        >
          <EmissionCustomReportListTab />
        </Tab>
      ) : null}
    </Page>
  );
};
