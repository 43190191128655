import { Icon, IconLoading } from "@validereinc/common-components";
import classNames from "classnames/bind";
import React, { ReactNode } from "react";
import styles from "./FileRenderer.module.scss";

const cx = classNames.bind(styles);

export const FileRenderer = ({
  fileName,
  fileUrl,
  isLoading,
  error,
  fallbackRender,
  errorRender,
  imageClassName,
}: {
  /** the file name */
  fileName: string;
  /** the file URL ready for rendering */
  fileUrl: string;
  error?: unknown;
  isLoading?: boolean;
  /** what to render when the file format is unsupported for previewing */
  fallbackRender?: () => ReactNode;
  /** what to render when there is an error fetching the file details */
  errorRender?: () => ReactNode;
  imageClassName?: string;
}) => {
  const isImage = ["png", "jpeg", "jpg", "gif"].includes(
    fileName.split(".").pop() ?? ""
  );
  const isVideo = ["mpeg", "mp4", "mov", "webm"].includes(
    fileName.split(".").pop() ?? ""
  );

  if (isLoading) {
    return <IconLoading speed="fast" />;
  }

  if (error) {
    return errorRender ? <>{errorRender()}</> : null;
  }

  if (!isImage && !isVideo) {
    return fallbackRender ? <>{fallbackRender()}</> : null;
  }

  return isVideo ? (
    <video
      controls
      muted
      className={imageClassName}
    >
      <source src={fileUrl} />
      {fallbackRender ? <>{fallbackRender()}</> : null}
    </video>
  ) : (
    <img
      className={imageClassName}
      src={fileUrl}
      alt={fileName}
    />
  );
};
