import { FormSubmissionsViewFilterArea } from "#src/batteries-included-components/FilterAreas/FormsFilterAreas";
import { useAddFormSubmissionContext } from "#src/batteries-included-components/Layouts/Form/Submission/Add/AddFormSubmission/AddFormSubmissionContext";
import {
  SelectedFormSubmissionsPanel,
  SelectedFormSubmissionsPanelProps,
} from "#src/batteries-included-components/Panels/SelectedEntitiesPanels/SelectedFormSubmissionsPanel";
import { AddFormSubmissionsTablePanel } from "#src/batteries-included-components/Panels/TablePanels/AddFormSubmissionsTablePanel";
import { useGetOneWorkflowTask } from "#src/components/hooks/adapters/useWorkflowTasks";
import classNames from "classnames/bind";
import React from "react";
import { useParams } from "react-router";
import styles from "./AddFormSubmission.module.scss";

const cx = classNames.bind(styles);

export type AddFormSubmissionProps = {
  selectedFormSubmissionsPanelProps: SelectedFormSubmissionsPanelProps;
};

export const AddFormSubmission = ({
  selectedFormSubmissionsPanelProps,
}: AddFormSubmissionProps) => {
  const { viewConfigStorageKey } = useAddFormSubmissionContext();
  const { taskId } = useParams<{
    taskId: string;
  }>();
  const workflowTaskQuery = useGetOneWorkflowTask(
    { id: taskId },
    { enabled: Boolean(taskId) }
  );
  const workflowTaskDetails = workflowTaskQuery.data?.data;

  return (
    <>
      <div style={{ marginBottom: 16 }}>
        <FormSubmissionsViewFilterArea
          viewConfigStorageKey={viewConfigStorageKey}
          defaultFormSchemaId={
            workflowTaskDetails && "config_form_schema" in workflowTaskDetails
              ? workflowTaskDetails.config_form_schema?.id
              : undefined
          }
          shouldShowFormTemplateDropdown
        />
      </div>
      <div className={cx("container")}>
        <AddFormSubmissionsTablePanel />
        <SelectedFormSubmissionsPanel {...selectedFormSubmissionsPanelProps} />
      </div>
    </>
  );
};
