export const SCENARIOS_PAGE_TITLE = "Scenario Management";

export const linkToScenarioList = () => "/app/scenarios";

export const SCENARIOS_BREADCRUMB = { title: "Scenarios" };

export const SCENARIO_LIST_BREADCRUMB = {
  title: SCENARIOS_PAGE_TITLE,
  path: linkToScenarioList,
};

export * from "./ScenarioListPage";
