import {
  getFromStorage,
  useSessionStickyState,
} from "#src/hooks/useStickyState";
import {
  DefaultValues,
  Form,
  useHookForm as useForm,
  UseFormReturn,
  usePrevious,
} from "@validereinc/common-components";
import { NodeAPISavedFilterType, SavedFilterType } from "@validereinc/domain";
import classNames from "classnames/bind";
import debounce from "lodash/debounce";
import React, {
  AriaAttributes,
  createContext,
  CSSProperties,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  convertFilterValuesToStoredFilters,
  countAppliedFilters,
  type FilterComparisonOperatorConfig,
  getDefaultValuesAndStoredFilters,
  reduceStoredFiltersToAppliedFilters,
  resetFilterValues,
} from "./FilterArea.helpers";
import styles from "./FilterArea.module.css";

const cx = classNames.bind(styles);

export type FilterAreaContextProps<TFilters extends Record<string, any>> = {
  /** the react hook form object */
  form: UseFormReturn<TFilters>;
  /** reset all filters wired up to the filter form. This resets the cache
   * (sessionStorage) as well and fires an onChange event. */
  handleResetFilters: () => Promise<void>;
  /** reset all filters wired up to the filter form. This does everything
   * resetting does as well as clear the applied saved filter, if any. */
  handleClearFilters: () => void;
  /** apply filter values of filters wired up to the filter form. The values
   * will be written to cache (sessionStorage). */
  handleSubmitFilters: () => void;
  /** get the filter values from cache (sessionStorage) */
  storedFilters: TFilters;
  /** set the filter values to the cache (sessionStorage) */
  setStoredFilters: (filters: Partial<TFilters>) => void;
  /** override the comparison operator used for specific filters. Object is flat
   * where key is the dot-separated name of the filter input and the value is an
   * operator to use. If operators are not specified for an input, the default
   * logic will be used.
   *
   * NOTE: this mainly affects the format of filters when they get saved. It
   * does not at the moment affect how the filters actually get applied against
   * a query. That entirely depends on the query implementation and the
   * underlying domain method logic.
   * // IMPROVE: we need a comprehensive filter abstraction that can help us do
   * this and more.
   *  */
  filterComparisonOperatorConfig?: FilterComparisonOperatorConfig;
  /** the number of filters that have been applied (i.e. in cache) */
  numOfFiltersApplied: number;
  /** a saved filter that has been applied if any */
  appliedSavedFilter: SavedFilterType<NodeAPISavedFilterType> | null;
  /** change the saved filter to apply */
  setAppliedSavedFilter: React.Dispatch<
    React.SetStateAction<SavedFilterType<NodeAPISavedFilterType> | null>
  >;
  // IMPROVE: saved views will go here
};

const FilterAreaContext = createContext<FilterAreaContextProps<
  Record<string, any>
> | null>(null);

const useFilterAreaContext = <TFilters extends Record<string, any>>() => {
  const ctx = useContext(
    FilterAreaContext as React.Context<FilterAreaContextProps<TFilters>>
  );

  if (!ctx) {
    throw new Error("Must be used within a FilterAreaProvider");
  }

  return ctx;
};

export type FilterAreaRootProps<TFilters extends Record<string, any>> =
  PropsWithChildren<{
    /** the cache key (for sessionStorage) to use for all the filters under this area */
    storageKey: string;
    /** the default values to use for all the filters wired up to the form */
    defaultValues?: DefaultValues<TFilters>;
    /** apply the default values to storage once its ready? */
    applyDefaultValues?: boolean;
    /** should stored filters overwrite default values in getAndApplyDefaultValues()? */
    shouldPrioritizeStoredFiltersWhenApplyingDefaultValues?: boolean;
    /** callback invoked when a change happens to any filter values */
    onChange?: (newFilters: Partial<TFilters>) => void;
    /** callback invoked when filters are cleared */
    onClear?: (newFilters: Record<string, never>) => void;
    /** callback invoked when filters are applied */
    onApply?: (newFilters: TFilters) => void;
    /** comparison operator overrides to use for specified filters */
    comparisonOperatorConfig?: FilterAreaContextProps<TFilters>["filterComparisonOperatorConfig"];
  }>;

/**
 * The base component of the FilterArea. Renders children as-is within a
 * context provider.
 */
const FilterAreaRoot = <TFilters extends Record<string, any>>({
  children,
  storageKey,
  defaultValues,
  applyDefaultValues,
  comparisonOperatorConfig,
  shouldPrioritizeStoredFiltersWhenApplyingDefaultValues = true,
  onChange,
  onClear,
  onApply,
}: FilterAreaRootProps<TFilters>) => {
  const [storedFilters, setStoredFilters] = useSessionStickyState<TFilters>(
    {} as TFilters,
    storageKey
  );
  const [appliedSavedFilter, setAppliedSavedFilter] =
    useState<SavedFilterType<NodeAPISavedFilterType> | null>(null);
  const appliedDefaultValues = useRef<boolean>(false);
  const previousStorageKey = usePrevious(storageKey);

  const getAndApplyDefaultValues = useCallback(
    (storedFilters: TFilters) =>
      getDefaultValuesAndStoredFilters({
        defaultValues,
        storedFilters,
        prioritizeStoredFilters:
          shouldPrioritizeStoredFiltersWhenApplyingDefaultValues,
      }).then((values) => {
        // commit default values as stored filters, but only once
        if (applyDefaultValues && !appliedDefaultValues.current) {
          appliedDefaultValues.current = true;
          setStoredFilters(values);
        }

        return values;
      }),
    [defaultValues, applyDefaultValues, appliedDefaultValues, setStoredFilters]
  );
  const { getValues, reset, ...restForm } = useForm<TFilters>({
    defaultValues: () => getAndApplyDefaultValues(storedFilters),
    resetOptions: {
      keepDefaultValues: true,
      keepDirty: false,
      keepDirtyValues: false,
      keepValues: false,
    },
  });

  const numOfFiltersApplied = useMemo(
    () =>
      countAppliedFilters(reduceStoredFiltersToAppliedFilters(storedFilters)),
    [storedFilters]
  );
  const handleSetStoredFilters = useCallback(
    (newFilters: Partial<TFilters>) => {
      onChange?.(newFilters);
      setStoredFilters((oldFilters) => ({
        ...(oldFilters ?? {}),
        ...((newFilters as TFilters) ?? {}),
      }));
    },
    [setStoredFilters, onChange]
  );
  const handleResetFilters = () => {
    const allValues = getValues();

    return getDefaultValuesAndStoredFilters({
      storedFilters: resetFilterValues(allValues) ?? ({} as TFilters),
      defaultValues,
      prioritizeStoredFilters: false,
    }).then((resetAllValues) => {
      // filter area is reset with default values on purpose
      reset(resetAllValues, { keepDefaultValues: true });
      // on the other hand, applied filters and consumers reflect fully cleared state
      setStoredFilters({} as TFilters);
      onChange?.({} as TFilters);
    });
  };
  const handleClearFilters = () => {
    setAppliedSavedFilter(null);
    handleResetFilters();
    onClear?.({});
  };
  const handleSubmitFilters = () => {
    const values = getValues();

    handleSetStoredFilters(convertFilterValuesToStoredFilters(values));
    onApply?.(values);
  };

  // re-apply default values on storage key change
  useEffect(() => {
    // if the storage key hasn't changed or default values haven't been applied yet
    if (previousStorageKey === storageKey || !appliedDefaultValues.current)
      return;

    appliedDefaultValues.current = false;
    // when the key changes, the sticky state might not have updated in time -
    // so read directly from the storage location and apply it to the new
    // storage location
    getAndApplyDefaultValues(
      getFromStorage({} as TFilters, storageKey, {
        storageSystem: window.sessionStorage,
      })
    ).then((values) => {
      // reset the filter area with all the new values
      reset(values, {
        keepDefaultValues: false,
      });
    });
  }, [
    previousStorageKey,
    storageKey,
    appliedDefaultValues,
    getAndApplyDefaultValues,
    getFromStorage,
    reset,
  ]);

  // NOTE: intentionally written for type assertion
  const FilterAreaContextTyped = FilterAreaContext as React.Context<
    FilterAreaContextProps<TFilters>
  >;

  return (
    <FilterAreaContextTyped.Provider
      value={{
        storedFilters,
        setStoredFilters: handleSetStoredFilters,
        numOfFiltersApplied,
        form: { getValues, reset, ...restForm },
        handleSubmitFilters,
        handleClearFilters,
        handleResetFilters,
        appliedSavedFilter,
        setAppliedSavedFilter,
        filterComparisonOperatorConfig: comparisonOperatorConfig,
      }}
    >
      {children}
    </FilterAreaContextTyped.Provider>
  );
};

export type FilterAreaContainerProps = PropsWithChildren<
  Pick<AriaAttributes, "aria-label"> &
    Partial<Pick<HTMLDivElement, "className">> & {
      style?: CSSProperties;
    } & {
      formProps?: Partial<Pick<HTMLDivElement, "className">> & {
        style?: CSSProperties;
      };
    }
>;

/**
 * The container for the filters of this filter area. Renders an actual element
 * classified as a filter region. Also renders the <Form /> to wrap the fitler
 * inputs. Make sure this container wraps all the filter inputs in your filter area.
 */
const FilterAreaContainer = ({
  children,
  className,
  style,
  formProps = {},
  ...restProps
}: FilterAreaContainerProps) => {
  const { form } = useFilterAreaContext();

  return (
    <div
      role="region"
      aria-label="Filters"
      {...restProps}
      className={cx("container", className)}
      style={style}
    >
      <Form
        {...form}
        className={cx("form-container", formProps?.className)}
        style={formProps.style}
      >
        {children}
      </Form>
    </div>
  );
};

export type FilterAreaContentContextType = {
  /** meant to intercept the onChange handler of your filter input to apply a
   * filter on change. provide the value as-is and the name of the filter
   * input. */
  handleOnChange: (value: unknown, name: string) => void;
};

const FilterAreaContentContext =
  createContext<FilterAreaContentContextType | null>(null);

const useFilterAreaContentContext = () => {
  const ctx = useContext(FilterAreaContentContext);

  if (!ctx) {
    throw new Error("Must be used within a FilterAreaContentProvider");
  }

  return ctx;
};

export type FilterAreaContentProps = {
  children: ((props: FilterAreaContentContextType) => ReactNode) | ReactNode;
};

/**
 * Wrapper for all the filters of this filter area. Renders children as-is.
 * Provides a debounced onChange handler that can be wired up to your filter
 * inputs as you choose, to apply filters immediately on change. Don't use the
 * handler and use the apply and clear functions instead from the filter area
 * context if you want to explicitly apply and clear filters by triggers of your
 * choice.
 *
 * Additionally, use {@link useFilterAreaContentContext()} if you want access to
 * the debounced onChange handler anywhere under this component tree.
 */
const FilterAreaContent = <TFilters extends Record<string, any>>({
  children,
}: FilterAreaContentProps) => {
  const { storedFilters, setStoredFilters } = useFilterAreaContext<TFilters>();

  const handleOnChange = (value: unknown, name: string) => {
    // @ts-expect-error unknown expected
    setStoredFilters({
      [name]: value,
    });
  };

  const debouncedHandleOnChange = useCallback(debounce(handleOnChange, 400), [
    storedFilters,
  ]);

  if (typeof children !== "function") return <>{children}</>;

  return (
    <FilterAreaContentContext.Provider
      value={{ handleOnChange: debouncedHandleOnChange }}
    >
      {children({ handleOnChange: debouncedHandleOnChange })}
    </FilterAreaContentContext.Provider>
  );
};

const Root = FilterAreaRoot;
const Container = FilterAreaContainer;
const Content = FilterAreaContent;
const FilterArea = {
  Root,
  Container,
  Content,
};

export {
  FilterArea,
  FilterAreaContainer,
  FilterAreaContent,
  FilterAreaRoot,
  useFilterAreaContext,
  useFilterAreaContentContext,
};
