import { useGetOneNetwork } from "#hooks/adapters/useNetworks";
import { useParams } from "#routers/hooks";
import { NetworkDetailPageParameters } from "#routes/organization/networks/[networkId]/detail";
import { useListCustomAttributes } from "#src/components/hooks/adapters/useCustomAttributes";
import useLocalization from "#src/hooks/useLocalization";
import { useSessionStickyState } from "#src/hooks/useStickyState";
import {
  KeyValuePanel,
  StorageKeys,
  type KeyValuePanelProps,
} from "@validereinc/common-components";
import { AssetType } from "@validereinc/domain";
import { getYearMonthFromDateRange } from "@validereinc/utilities";
import classNames from "classnames/bind";
import React from "react";
import styles from "./NetworkDetailsTab.module.scss";
import { displayValueByDataType } from "#src/utils/display";

const cx = classNames.bind(styles);

export const NetworkDetailsPanel = ({
  viewConfigStorageKey,
}: Pick<StorageKeys, "viewConfigStorageKey">) => {
  const { localize } = useLocalization();
  const { networkId } = useParams<NetworkDetailPageParameters>();
  const [filters] = useSessionStickyState<{ period: { from?: string } }>(
    {},
    viewConfigStorageKey
  );

  const { data: assetGroup } = useGetOneNetwork(networkId, {
    period: getYearMonthFromDateRange(filters.period),
  });
  const customAttributesQuery = useListCustomAttributes({
    filters: { entity_type: AssetType.ASSET_GROUP },
  });

  const networkCustomAttributes = customAttributesQuery.data?.data ?? [];

  const data: KeyValuePanelProps["data"] = [
    {
      title: localize("facility_plural"),
      value: assetGroup?.assets?.filter(
        ({ asset_type }) => asset_type === AssetType.FACILITY
      )?.length,
    },
    {
      title: localize("equipment_plural"),
      value: assetGroup?.assets?.filter(
        ({ asset_type }) => asset_type === AssetType.EQUIPMENT
      )?.length,
    },
    {
      title: "Devices",
      value: assetGroup?.assets?.filter(
        ({ asset_type }) => asset_type === AssetType.DEVICE
      )?.length,
    },
    {
      title: localize("flow_plural"),
      value: assetGroup?.assets?.filter(
        ({ asset_type }) => asset_type === AssetType.FLOW
      )?.length,
    },
    ...networkCustomAttributes.map(
      ({ display_name, field_name, data_type }) => {
        const value = assetGroup?.custom_attributes?.[field_name];

        return {
          title: display_name,
          value: displayValueByDataType(value, data_type),
        };
      }
    ),
  ];

  return (
    <div className={cx("flexedContainer")}>
      <KeyValuePanel
        panelMaxColumnCount={2}
        panelKeyValueListProps={{ maxRowCount: 6, variant: "shaded" }}
        data={data}
      />
    </div>
  );
};
