import React, { useContext, useMemo, useState } from "react";

import {
  Button,
  DataTablePanel,
  Dialog,
  useAlert,
} from "@validereinc/common-components";
import { dateFormatter } from "@validereinc/utilities";

import { useNavigate } from "#routers/hooks";
import BulkRecordValueStatusChangeButton from "#src/batteries-included-components/Buttons/BulkRecordValueStatusChangeButton/BulkRecordValueStatusChangeButton";
import { RoutingLink } from "#src/batteries-included-components/RoutingLink";
import InlineRecordValueStatusPicker from "#src/components/Common/InlineRecordValueStatusPicker/InlineRecordValueStatusPicker";
import { RecordValueCell } from "#src/components/Common/RecordValueCell/RecordValueCell";
import { useMeasurementTypes } from "#src/contexts/MeasurementTypeContext";
import { RecordContext } from "#src/contexts/RecordContext";
import { useApplyDefaultRecordConfiguration } from "#src/queries/recordQueries";
import {
  linkToRecordValueDetail,
  linkToRecordValueSourceDetail,
} from "#utils/links";
import { getSourceName, getSourcePath } from "#utils/recordUtils";
import { useMutation } from "@tanstack/react-query";
import type {
  RecordValueLookupType,
  RecordValueType,
} from "@validereinc/domain";
import { RecordDomain, isRecordValueLocked } from "@validereinc/domain";
import { AddRecordValueDialog } from "../../Dialogs/AddRecordValueDialog";

export const RecordValueTablePanel = () => {
  const { addAlert } = useAlert();
  const navigate = useNavigate();
  const {
    measurementTypes,
    getTypeName,
    isLoading: isMeasurementTypeLoading,
  } = useMeasurementTypes();
  const { record, users, isLoading, refetch } = useContext(RecordContext) ?? {};

  const [valueToDelete, setValueToDelete] = useState<RecordValueType>();
  const [selectedRecords, setSelectedRecords] = useState<
    Record<string, RecordValueType>
  >({});
  const [isAddValueOpen, setIsAddValueOpen] = useState(false);
  const measurementType = useMemo(
    () =>
      measurementTypes?.find(
        ({ id }) => id === valueToDelete?.measurement_type
      ),
    [measurementTypes, valueToDelete]
  );

  const applyAutomation = useApplyDefaultRecordConfiguration();

  const deleteValue = useMutation({
    mutationFn: async (recordValueLookup: Partial<RecordValueLookupType>) => {
      if (!recordValueLookup.recordId || !recordValueLookup.measurementType) {
        throw new Error("Missing lookup values to delete record");
      }
      return RecordDomain.recordValue.delete(recordValueLookup);
    },
    onSuccess: () => {
      setValueToDelete(undefined);
      refetch?.();
      addAlert({
        variant: "success",
        message: `Deleted record value type '${measurementType?.name}'`,
      });
    },
    onError: (error) => {
      console.error(error);
      addAlert({
        variant: "error",
        message: `Unable to delete record value type '${measurementType?.name}'`,
      });
    },
  });

  const navigateToDetailPage = (valueType: string) =>
    record
      ? linkToRecordValueDetail(
          record.asset_type,
          record.asset_id,
          record.id,
          valueType
        )
      : "";

  const navigateToAddSourcePage = (valueType: string) => {
    if (record) {
      navigate({
        pathname: linkToRecordValueSourceDetail(
          record.asset_type,
          record.asset_id,
          record.id,
          valueType
        ),
      });
    }
  };

  const selectedMeasurementTypes = Object.values(selectedRecords);
  const measurementTypesToChange =
    selectedMeasurementTypes.length > 0
      ? selectedMeasurementTypes.map(
          (recordValue) => recordValue.measurement_type
        )
      : record?.values?.map((recordValue) => recordValue?.measurement_type);

  const actionRow = [
    <BulkRecordValueStatusChangeButton
      recordId={record?.id ?? ""}
      measurementTypes={measurementTypesToChange}
      key="status-picker"
      onSuccess={() => {
        refetch?.();
      }}
    />,
    ...(Object.keys(selectedRecords).length === 0
      ? [
          <Button
            key="apply-automation"
            onClick={() =>
              applyAutomation.mutate({
                filters: { id: record?.id },
                overwriteAllValues: false,
              })
            }
          >
            Apply Record Automation
          </Button>,
        ]
      : []),
    <Button
      key="add-record-value"
      onClick={() => setIsAddValueOpen(true)}
    >
      Add Record Value Type
    </Button>,
  ];

  const items = record?.values ?? [];

  return (
    <>
      <DataTablePanel
        panelProps={{ title: "Record Values", actionRow }}
        dataTableProps={{
          isLoading: isMeasurementTypeLoading || isLoading,
          headers: [
            {
              key: "measurement_type",
              label: "Record Value Type",
              renderComponent: ({ item }: { item: RecordValueType }) => (
                <RoutingLink to={navigateToDetailPage(item.measurement_type)}>
                  {getTypeName(item?.measurement_type)}
                </RoutingLink>
              ),
            },
            {
              key: "value",
              label: "Value",
              alignment: "right",
              renderComponent: ({ item }: { item: RecordValueType }) => (
                <RecordValueCell value={item} />
              ),
            },
            {
              key: "status",
              label: "Record Status",
              renderComponent: ({ item }: { item: RecordValueType }) =>
                item?.record_id ? (
                  <InlineRecordValueStatusPicker
                    recordId={item.record_id}
                    values={[item]}
                    onSuccess={() => {
                      refetch?.();
                    }}
                  />
                ) : null,
            },
            {
              key: "source",
              label: "Source",
              renderComponent: ({ item }: { item: RecordValueType }) => {
                const configurationType =
                  item.configuration?.configuration_type;
                const sources = item?.configuration?.sources ?? [];
                return sources.length ? (
                  sources.length === 1 ? (
                    <RoutingLink
                      to={getSourcePath(configurationType, sources[0])}
                    >
                      {getSourceName(configurationType, sources[0])}
                    </RoutingLink>
                  ) : (
                    "Many"
                  )
                ) : (
                  "Manual Entry"
                );
              },
            },
            {
              key: "updated_by",
              label: "Updated By",
              renderComponent: ({ item }: { item: RecordValueType }) =>
                users?.find(({ id }) => id === item?.updated_by)?.name,
            },
            {
              key: "updated_at",
              label: "Updated At",
              renderComponent: ({ item }: { item: RecordValueType }) =>
                item?.updated_at && dateFormatter(new Date(item?.updated_at)),
            },
          ],
          getItemActions: ({ item }: { item: RecordValueType }) =>
            !isRecordValueLocked(item)
              ? [
                  {
                    label: "Edit",
                    buttonProps: {
                      onClick: () =>
                        navigateToAddSourcePage(item?.measurement_type),
                      icon: "pencil-simple",
                    },
                  },
                  {
                    label: "Delete",
                    buttonProps: {
                      onClick: () => setValueToDelete(item),
                      icon: "trash",
                    },
                  },
                ]
              : [],
          items,
          onSelectionChange: setSelectedRecords,
          selected: selectedRecords,
          getItemId: (item) => item?.id ?? "",
        }}
      />
      <AddRecordValueDialog
        isOpen={isAddValueOpen}
        onClose={() => setIsAddValueOpen(false)}
        onSubmit={refetch}
      />
      <Dialog
        isOpen={!!valueToDelete}
        onClose={() => setValueToDelete(undefined)}
        title={`Delete "${measurementType?.name}" value?`}
        actionRow={[
          <Button
            key="delete-dialog-action"
            onClick={() =>
              deleteValue.mutate({
                recordId: record?.id,
                measurementType: valueToDelete?.measurement_type,
              })
            }
            variant="error"
          >
            Delete
          </Button>,
        ]}
      >
        <div>
          Are you sure you want to delete this record value type? This action
          cannot be undone.
        </div>
      </Dialog>
    </>
  );
};
