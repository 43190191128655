import { useMeasurementTypes } from "#src/contexts/MeasurementTypeContext";
import { DataTable, Icon, Link } from "@validereinc/common-components";
import { isRecordValueLocked, type RecordValueType } from "@validereinc/domain";
import { getFormattedNumberWithUnit } from "@validereinc/utilities";
import classNames from "classnames/bind";
import React from "react";
import styles from "./RecordValueCell.module.scss";

const cx = classNames.bind(styles);

export type RecordValueCellProps = {
  value?: RecordValueType;
  alignment?: "left" | "right";
  onClick?: () => void;
};

export const RecordValueCell = ({
  value,
  onClick,
  alignment = "right",
}: RecordValueCellProps) => {
  const { getUnitName, getPrecisionByType } = useMeasurementTypes();

  const isValueDefined =
    // @ts-expect-error this is an intentional check
    // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
    value !== undefined && value?.value !== null && value?.value !== "";
  const precision = getPrecisionByType(value?.measurement_type ?? "");
  const unit = getUnitName(
    value?.measurement_unit ?? "",
    value?.value ?? undefined
  );
  const formattedValue = isValueDefined
    ? getFormattedNumberWithUnit(
        {
          value: value?.value,
          unit: getUnitName(value?.measurement_unit, value?.value),
        },
        null,
        {
          maxFractionDigits: getPrecisionByType(value?.measurement_type),
          minFractionDigits: 0,
          errorFallback: "-",
        }
      )
    : "";

  return (
    <div className={cx("value-container", alignment)}>
      {isValueDefined && isRecordValueLocked(value) && (
        <span className={cx("lock-icon")}>
          <Icon
            variant="lock-simple"
            size={16}
          />
        </span>
      )}
      {isValueDefined && typeof onClick === "function" ? (
        <Link
          onClick={onClick}
          label={formattedValue}
        />
      ) : (
        <DataTable.DataRow.NumberCell
          value={value?.value}
          {...(isValueDefined ? { unit } : {})}
          {...(precision ? { maxFractionDigits: precision } : {})}
          minFractionDigits={0}
        />
      )}
    </div>
  );
};
