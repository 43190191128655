import { useNavigate } from "#routers/hooks";
import { linkToEditScenario } from "#routes/scenarios/[scenarioId]/edit";
import { useTableSortingAndPagination } from "#src/components/Redux/reducers/tableStateReducer";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  Button,
  DataTablePanel,
  Dialog,
  useAlert,
  type HeaderType,
} from "@validereinc/common-components";
import type { ReportingGroupType } from "@validereinc/domain";
import { ReportingGroupDomain } from "@validereinc/domain";
import React, { useState } from "react";

export const ReportingGroupTablePanel = () => {
  const navigate = useNavigate();
  const { addAlert } = useAlert();
  const queryClient = useQueryClient();
  const [itemToDelete, setItemToDelete] = useState<ReportingGroupType>();
  const nameOfDeletedItem = itemToDelete
    ? `"${itemToDelete.name}"`
    : "scenario";

  const [tableState, setTableState] = useTableSortingAndPagination({
    sortBy: "priority",
    sortDirection: "desc",
  });

  const reportingGroupsParams: Parameters<
    typeof ReportingGroupDomain.getList
  >[0] = {
    page: tableState.page,
    pageSize: tableState.pageSize,
    sortBy: tableState.sortBy,
    sortDirection: tableState.sortDirection,
  };

  const reportingGroups = useQuery({
    queryKey: ["reportingGroups", reportingGroupsParams],
    queryFn: () => ReportingGroupDomain.getList(reportingGroupsParams),
  });

  const deleteReportingGroup = useMutation({
    mutationFn: (id: string) => ReportingGroupDomain.deleteOne({ id }),
    onSuccess: async () => {
      addAlert({
        variant: "success",
        message: `Successfully deleted ${nameOfDeletedItem}.`,
      });
      await queryClient.invalidateQueries({ queryKey: ["reportingGroups"] });
    },
    onSettled: () => {
      setItemToDelete(undefined);
    },
  });

  const headers: Array<HeaderType<ReportingGroupType>> = [
    {
      key: "name",
      label: "Name",
      isSortable: true,
    },
    {
      key: "description",
      label: "Description",
    },
  ];

  const getItemActions = ({ item }: { item: ReportingGroupType }) => [
    {
      label: "Edit",
      buttonProps: {
        onClick: () => navigate({ pathname: linkToEditScenario(item.id) }),
        icon: "pencil-simple",
      },
    },
    {
      label: "Delete",
      buttonProps: {
        onClick: () => setItemToDelete(item),
        icon: "trash",
      },
    },
  ];

  return (
    <>
      <DataTablePanel
        panelProps={{ title: "Scenarios" }}
        dataTableProps={{
          items: reportingGroups.data?.data ?? [],
          pagination: {
            page: tableState.page,
            pageSize: tableState.pageSize,
            total: reportingGroups.data?.total_entries,
          },
          sorting: {
            sortBy: tableState.sortBy,
            sortDirection: tableState.sortDirection,
          },
          onSortChange: setTableState,
          onPaginationChange: setTableState,
          variant: "simplicity-first",
          isLoading: reportingGroups.isLoading,
          isBusy: reportingGroups.isFetching,
          headers,
          getItemActions,
          showRowNumbers: true,
        }}
      />
      <Dialog
        isOpen={!!itemToDelete}
        onClose={() => setItemToDelete(undefined)}
        title={`Delete ${nameOfDeletedItem}`}
        actionRow={[
          <Button
            key="delete-dialog-action"
            onClick={() => deleteReportingGroup.mutate(itemToDelete.id)}
            variant="error"
          >
            Delete
          </Button>,
        ]}
      >
        Are you sure you want to delete this reporting group? This action cannot
        be undone.
      </Dialog>
    </>
  );
};
