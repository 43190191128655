import { useGetOneTransactionDetail } from "#hooks/adapters/useTransactions";
import { extractItemsFromTransactionDetails } from "#routes/data-tools/submissions/datasets/[transactionId]/detail/DataSetSubmissionDetail.helpers";
import { KeyValueList, Panel } from "@validereinc/common-components";
import groupBy from "lodash/groupBy";
import React from "react";

export const DataSetSubmissionErrorPanel = () => {
  const { data, isLoading } = useGetOneTransactionDetail();
  const items = extractItemsFromTransactionDetails(data);
  const errors = groupBy(
    items.filter(({ error }) => error),
    ({ error }) => error
  );

  return (
    <Panel title="Error Type Instances">
      <KeyValueList
        isLoading={isLoading}
        variant="shaded"
        data={Object.keys(errors).map((error) => ({
          title: error,
          value: errors[error]?.length,
        }))}
      />
    </Panel>
  );
};
