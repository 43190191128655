import React, { useMemo } from "react";

import uniqBy from "lodash/uniqBy";

import {
  DataTable,
  HeaderType,
  MultiDropdownInputWithSearch,
  Tooltip,
} from "@validereinc/common-components";

import { GetAllObjectValue } from "#utils/objectFormatter";

import SummaryInformation from "#common/SummaryInformation/SummaryInformation";

import config from "#src/config";
import { getTimeStringFromDate } from "#utils/timeFormatter";

import Input from "../Common/Input/Input";

import { INSTRUMENT_TYPE_LABEL } from "./InstrumentConstant";

export default ({ instruments, loading }) => {
  const filters = useMemo(() => {
    if (instruments) {
      const typeOptions = GetAllObjectValue(instruments, "instrument_type").map(
        (type) => {
          return {
            label: INSTRUMENT_TYPE_LABEL[type] ?? type,
            value: type,
          };
        }
      );

      return [
        {
          name: "name",
          component: (
            <Input
              icon="search"
              placeholder="Name"
            />
          ),
        },
        {
          name: "instrument_type",
          component: (
            <MultiDropdownInputWithSearch
              label={"Type"}
              labelKey={"label"}
              options={uniqBy(typeOptions, "value")}
              isClearable
            />
          ),
        },
        {
          name: "location",
          component: (
            <MultiDropdownInputWithSearch
              label={"Site"}
              options={GetAllObjectValue(instruments, "location")}
              isClearable
            />
          ),
        },
        {
          name: "current_serial_number",
          component: (
            <MultiDropdownInputWithSearch
              label={"Serial Number"}
              options={GetAllObjectValue(instruments, "serial_numbers")}
              isClearable
            />
          ),
        },
        {
          name: "type",
          component: (
            <MultiDropdownInputWithSearch
              label={"Instrument Model"}
              options={GetAllObjectValue(instruments, "type")}
              isClearable
            />
          ),
        },
      ];
    }
  }, [instruments]);

  const quickFilters = useMemo(() => {
    return {
      loading,
      items: [
        { label: "All" },
        {
          label: "Archived",
          variant: "warning",
          count: instruments?.reduce(
            (total, { status }) => (status === "archived" ? total + 1 : total),
            0
          ),
          value: "archived",
        },
        {
          label: "Active",
          variant: "success",
          count: instruments?.reduce(
            (total, { status }) => (status === "active" ? total + 1 : total),
            0
          ),
          value: "active",
        },
      ],
    };
  }, [instruments, loading]);

  const headers: Array<HeaderType<any>> = useMemo(
    () => [
      {
        label: "Name",
        key: "name",
        isFixed: true,
        isSortable: true,
      },
      { label: "Site", key: "location", isSortable: true },
      { label: "Status", key: "status", isSortable: true },
      {
        label: "Type",
        key: "instrument_type",
        renderComponent: ({ item }) =>
          INSTRUMENT_TYPE_LABEL[item.instrument_type] ?? "-",
        isSortable: true,
      },
      {
        label: "Serial Number",
        key: "current_serial_number",
        isSortable: true,
      },
      {
        label: "Instrument Model",
        key: "type",
        isSortable: true,
      },
      {
        label: "Latest Sample",
        key: "last_test_at",
        isSortable: true,
        renderComponent: ({ item }) => (
          <DataTable.DataRow.DateCell value={item.last_test_at} />
        ),
      },
      {
        label: "Last Calibration Check Date",
        key: "prev_calibration_date",
        renderComponent: ({ item }) => (
          <Tooltip
            title="Calibration Detail"
            content={
              <div className="instrumentTable__tooltip__body">
                {[
                  {
                    title: "Calibration Status",
                    value: item.prev_calibration_status,
                  },
                  {
                    title: "Calibration Date",
                    value: getTimeStringFromDate(
                      item.prev_calibration_date,
                      config.DATETIME_FORMAT
                    ),
                  },
                  {
                    title: "Calibrated By",
                    value: item.prev_calibration_staff,
                  },
                ].map((information) => (
                  <SummaryInformation
                    key={information.title}
                    title={information.title}
                    value={information.value}
                    spaceBetween
                  />
                ))}
              </div>
            }
          >
            <DataTable.DataRow.DateCell value={item.prev_calibration_date} />
          </Tooltip>
        ),
        sortable: true,
      },
      {
        label: "Next Calibration Check Date",
        key: "next_calibration_date",
        renderComponent: ({ item }) => (
          <DataTable.DataRow.DateCell value={item.next_calibration_date} />
        ),
        isSortable: true,
      },
      {
        label: "Last Standard Check Date",
        key: "prev_standard_check_date",
        renderComponent: ({ item }) => (
          <Tooltip
            title="Standard Check Detail"
            content={
              <div className="instrumentTable__tooltip__body">
                {[
                  {
                    title: "Standard Check Status",
                    value: item.prev_standard_check_status,
                  },
                  {
                    title: "Standard Check Date",
                    value: getTimeStringFromDate(
                      item.prev_standard_check_date,
                      config.DATETIME_FORMAT
                    ),
                  },
                  {
                    title: "Standard Check By",
                    value: item.prev_standard_check_staff,
                  },
                ].map((information) => (
                  <SummaryInformation
                    key={information.title}
                    title={information.title}
                    value={information.value}
                    spaceBetween
                  />
                ))}
              </div>
            }
          >
            <DataTable.DataRow.DateCell value={item.prev_standard_check_date} />
          </Tooltip>
        ),
        isSortable: true,
      },
      {
        label: "Standard Check Effective Duration",
        key: "standard_check_days_duration",
        renderComponent: ({ item }) =>
          item.standard_check_days_duration > 1
            ? `${item.standard_check_days_duration} days`
            : `${item.standard_check_days_duration} day`,
        isSortable: true,
      },
      {
        label: "Next Standard Check Date",
        key: "next_standard_check_date",
        renderComponent: ({ item }) => (
          <DataTable.DataRow.DateCell value={item.next_standard_check_date} />
        ),
        isSortable: true,
      },
    ],
    []
  );

  return { filters, quickFilters, headers };
};
