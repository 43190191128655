import { useBreadcrumbs } from "#src/Routers/breadcrumbsHelper";
import { useNavigate, useParams } from "#src/Routers/hooks";
import { AccessDeniedLayout } from "#src/batteries-included-components/Layouts/Authorization/AccessDenied";
import {
  getPermissionDisplayLabel,
  useAuthenticatedContext,
  useIsFeatureAvailable,
  useIsOpsHubExperience,
} from "#src/contexts/AuthenticatedContext.helpers";
import { SETTINGS_BREADCRUMB } from "#src/routes/settings";
import { USERS_LIST_BREADCRUMB } from "#src/routes/settings/users";
import {
  UserDetailContext,
  UserDetailProvider,
} from "#src/routes/settings/users/detail/UserDetailContext";
import {
  useMutation,
  useQueries,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import {
  Banner,
  Button,
  DropdownInput,
  Form,
  Page,
  useAlert,
  useForm,
} from "@validereinc/common-components";
import { RolesAdapter, UsersAdapter } from "@validereinc/domain";
import classNames from "classnames/bind";
import React, { useContext, useMemo } from "react";
import { EDIT_USER_ROLE_BREADCRUMB } from ".";
import { USER_DETAILS_ROLE_BREADCRUMB } from "..";
import { USER_DETAILS_BREADCRUMB, linkToUserDetailPage } from "../..";
import styles from "./EditUserRolePage.module.scss";

const cx = classNames.bind(styles);

export const EditUserRolePageContent = () => {
  const navigate = useNavigate();
  const { addAlert } = useAlert();
  const { userDetails: user } = useContext(UserDetailContext) ?? {};
  const userId = user?.data?.id ?? "";
  const usersRolesQuery = useQuery(
    ["users", user?.data?.id, "roles"],
    () => UsersAdapter.roles.getList({ userId }),
    {
      enabled: Boolean(user?.data?.id),
      staleTime: 3 * 60 * 1000,
    }
  );

  const form = useForm({
    defaultValues: {
      roles: usersRolesQuery.data?.map((userRole) => userRole.group_id) ?? [],
    },
  });
  const selectedRoles = form.watch("roles");
  const queryClient = useQueryClient();

  const breadcrumbs = useBreadcrumbs(
    [
      SETTINGS_BREADCRUMB,
      USERS_LIST_BREADCRUMB,
      USER_DETAILS_BREADCRUMB,
      USER_DETAILS_ROLE_BREADCRUMB,
      EDIT_USER_ROLE_BREADCRUMB,
    ],
    {
      2: user?.data?.name,
    },
    { userId }
  );

  const associateRolesMutation = useMutation({
    mutationFn: async ({
      userId,
      selectedRoles,
    }: {
      userId: string;
      selectedRoles: string[];
    }) => {
      const currentlyAssignedRoles = usersRolesQuery.data?.map(
        (userRole) => userRole.group_id
      );
      const rolesToAdd =
        selectedRoles.filter((r) => !currentlyAssignedRoles?.includes(r)) ?? [];
      const rolesToRemove =
        currentlyAssignedRoles?.filter((r) => !selectedRoles.includes(r)) ?? [];

      return Promise.all(
        rolesToAdd
          .map((role) => RolesAdapter.members.update({ roleId: role, userId }))
          .concat(
            rolesToRemove.map((role) =>
              RolesAdapter.members.delete({ roleId: role, userId })
            )
          )
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["users"],
      });
      queryClient.invalidateQueries({
        queryKey: ["roles", user?.data?.id, "users"],
      });
      addAlert?.({
        variant: "success",
        message: `Successfully assigned roles to ${user?.data?.name ?? "user"}`,
      });
    },
    onError: () => {
      addAlert?.({
        variant: "error",
        message: `Failed to assign roles to ${user?.data?.name ?? "user"}`,
      });
    },
  });
  const rolesPermissionsQueries = useQueries({
    queries:
      selectedRoles?.map((roleId) => ({
        queryKey: ["roles", roleId, "permissions"],
        queryFn: () => RolesAdapter.permissions.getMany({ id: roleId }),
        staleTime: 3 * 60 * 1000,
      })) ?? [],
  });
  const rolesToAssociatedPermissionsList = useMemo(() => {
    if (rolesPermissionsQueries.some((q) => q.isLoading)) {
      return [];
    }

    return Array.from(
      rolesPermissionsQueries
        .reduce<Set<string>>((set, query) => {
          if (!query.data?.length) {
            return set;
          }

          query.data.forEach((perm) =>
            set.add(getPermissionDisplayLabel(perm.name))
          );

          return set;
        }, new Set())
        .values()
    );
  }, [rolesPermissionsQueries]);

  const goBack = () =>
    navigate({
      pathname: linkToUserDetailPage(user?.data?.id),
      query: { tab: "roles" },
    });
  const onSubmit = form.handleSubmit(async (values: any) => {
    if (!user?.data?.id) {
      return;
    }

    const { roles: selectedRoles } = values;

    await associateRolesMutation.mutateAsync({
      userId,
      selectedRoles,
    });

    goBack();
  });

  const footer = (
    <div className={cx("footer")}>
      <Button
        onClick={goBack}
        disabled={form?.formState.isSubmitting}
      >
        Cancel
      </Button>
      <Button
        variant="primary"
        onClick={onSubmit}
        disabled={form?.formState.isSubmitting}
        type="button"
      >
        Assign Roles
      </Button>
    </div>
  );

  return (
    <Form
      {...form}
      style={{ height: "100%" }}
    >
      <Page
        title="Assign Roles"
        category={`User | ${user?.data?.name}`}
        breadcrumbs={breadcrumbs}
        isLoading={user?.isLoading}
        footer={footer}
      >
        <DropdownInput
          key="roles"
          name="roles"
          onFetchData={async (payload) => {
            let { data } = await RolesAdapter.getList({
              ...payload,
              filters: {
                name: payload.searchTerm,
              },
            });

            // REVIEW: not a fan of the fact that we need to do this. A refactor of DropdownInput is needed.
            if (Array.isArray(payload.value)) {
              data = data.filter((d) => payload.value.includes(d.id));
            }

            return data.map((d) => ({
              name: `${d.name} (${d.status})`,
              id: d.id,
            }));
          }}
          labelKey="name"
          valueKey="id"
          label="Roles"
          placeholder="Select Roles"
          isMulti
          isInline
          isOptionalTextShown={false}
        />
        {rolesToAssociatedPermissionsList.length ? (
          <Banner
            className={cx("associatedPermissionsBanner")}
            variant="info"
            titleText="The following permissions will be granted to this user based on the assigned roles:"
            descriptionText={rolesToAssociatedPermissionsList.join(", ")}
          />
        ) : (
          <Banner
            className={cx("associatedPermissionsBanner")}
            variant="warning"
            titleText="This user will not have access to anything in the application"
            descriptionText="If this is not intentional, assign a role to get started."
          />
        )}
      </Page>
    </Form>
  );
};

export const EditUserRolePage = () => {
  const { userId } = useParams<{ userId: string }>();
  const {
    claims: {
      user: { id: currentUserId },
    },
  } = useAuthenticatedContext();
  const [isPageAvailable] = useIsFeatureAvailable({
    featureFlagQuery: "core:roles_permissions",
    permissionQuery: "roles:write",
  });
  const [isOpsExperienceEnabled] = useIsOpsHubExperience();

  const isThisUserCurrentUser = currentUserId === userId;

  if (!isThisUserCurrentUser && (!isPageAvailable || isOpsExperienceEnabled)) {
    return <AccessDeniedLayout />;
  }

  return (
    <UserDetailProvider userId={userId}>
      <EditUserRolePageContent />
    </UserDetailProvider>
  );
};
