import { useSearchParams } from "#routers/hooks";
import { ApplyAutomationProgressBanner } from "#src/batteries-included-components/Banners/ApplyAutomationProgressBanner";
import { ImportDataActionStatusWithQueue } from "#src/batteries-included-components/Banners/ImportDataActionStatus/ImportDataActionStatus";
import { getImportDataActionQueueKey } from "#src/batteries-included-components/Buttons/ImportDataAction/ImportDataAction.helpers";
import {
  EquipmentRecordsTableFilterArea,
  EquipmentRecordsViewFilterArea,
  FacilityRecordsTableFilterArea,
  FacilityRecordsViewFilterArea,
  FlowRecordsTableFilterArea,
  FlowRecordsViewFilterArea,
} from "#src/batteries-included-components/FilterAreas/RecordsFilterAreas";
import { RecordsListTablePanel } from "#src/batteries-included-components/index";
import { useIsFeatureAvailable } from "#src/contexts/AuthenticatedContext.helpers";
import useLocalization from "#src/hooks/useLocalization";
import {
  CustomStorageKeyTypes,
  useCustomStorageKey,
  useStorageKey,
} from "#src/hooks/useStorageKey";
import { RECORDS_PAGE_BREADCRUMB } from "#src/routes/records/index";
import {
  RECORDS_BREADCRUMB,
  RECORDS_TITLE,
} from "#src/routes/records/records/index";
import { Page, Tab } from "@validereinc/common-components";
import { AssetType, ResourceDefinitions } from "@validereinc/domain";
import React from "react";

export const RecordsListPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { localize } = useLocalization();
  const calcsAndRecordsSharedStorageKeys = useStorageKey(
    "calculations-records-shared"
  );
  const equipmentRecordsStorageKeys = useStorageKey("equipment-records");
  const equipmentRecordsApplyAutomationStorageKey = useCustomStorageKey(
    "equipment-records-apply-automation",
    CustomStorageKeyTypes.JOB
  );
  const flowRecordStorageKeys = useStorageKey("flows-records");
  const flowRecordsApplyAutomationStorageKey = useCustomStorageKey(
    "flows-records-apply-automation",
    CustomStorageKeyTypes.JOB
  );
  const facilityRecordStorageKeys = useStorageKey("facilities-records");
  const facilityRecordsApplyAutomationStorageKey = useCustomStorageKey(
    "facilities-records-apply-automation",
    CustomStorageKeyTypes.JOB
  );
  const [isFlowEnabled] = useIsFeatureAvailable({
    featureFlagQuery: "core:flows",
    permissionQuery: "flows:read",
  });
  const [isEquipmentEnabled] = useIsFeatureAvailable({
    featureFlagQuery: "core:equipment",
    permissionQuery: "equipment:read",
  });
  const [isFacilityEnabled] = useIsFeatureAvailable({
    featureFlagQuery: "core:facilities",
    permissionQuery: "facilities:read",
  });

  const DEFAULT_TAB_KEY = isEquipmentEnabled
    ? "equipment"
    : isFlowEnabled
      ? "flows"
      : "facilities";
  const equipmentRecordsImportDataQueueKey = getImportDataActionQueueKey({
    resource: {
      ...ResourceDefinitions.record,
      label: {
        singular: `${localize(ResourceDefinitions.equipment.label.singular)} Record`,
        plural: `${localize(ResourceDefinitions.equipment.label.singular)} Records`,
      },
    },
  });
  const flowRecordsImportDataQueueKey = getImportDataActionQueueKey({
    resource: {
      ...ResourceDefinitions.record,
      label: {
        singular: `${localize(ResourceDefinitions.flow.label.singular)} Record`,
        plural: `${localize(ResourceDefinitions.flow.label.singular)} Records`,
      },
    },
  });
  const facilityRecordsImportDataQueueKey = getImportDataActionQueueKey({
    resource: {
      ...ResourceDefinitions.record,
      label: {
        singular: `${localize(ResourceDefinitions.facility.label.singular)} Record`,
        plural: `${localize(ResourceDefinitions.facility.label.singular)} Records`,
      },
    },
  });

  return (
    <Page
      title={RECORDS_TITLE}
      breadcrumbs={[RECORDS_PAGE_BREADCRUMB, RECORDS_BREADCRUMB]}
      activeTabKey={searchParams?.tab ?? DEFAULT_TAB_KEY}
      onActiveTabKeyChange={(tabKey: string) => {
        setSearchParams({ tab: tabKey });
      }}
    >
      {isEquipmentEnabled ? (
        <Tab
          title={`${localize("Equipment")}`}
          tabKey="equipment"
        >
          <ImportDataActionStatusWithQueue
            queueKey={equipmentRecordsImportDataQueueKey}
          />
          <ApplyAutomationProgressBanner
            jobStorageKey={equipmentRecordsApplyAutomationStorageKey}
          />
          <EquipmentRecordsViewFilterArea
            viewConfigStorageKey={
              calcsAndRecordsSharedStorageKeys.viewConfigStorageKey
            }
          />
          <RecordsListTablePanel
            type={AssetType.EQUIPMENT}
            {...equipmentRecordsStorageKeys}
            viewConfigStorageKey={
              calcsAndRecordsSharedStorageKeys.viewConfigStorageKey
            }
            applyAutomationStorageKey={
              equipmentRecordsApplyAutomationStorageKey
            }
            filterComponentSlot={({ recordValueTypes }) => (
              <EquipmentRecordsTableFilterArea
                filterConfigStorageKey={
                  equipmentRecordsStorageKeys.filterConfigStorageKey
                }
                selectableProperties={recordValueTypes}
              />
            )}
          />
        </Tab>
      ) : null}
      {isFlowEnabled ? (
        <Tab
          title={`${localize("flow_plural")}`}
          tabKey="flows"
        >
          <ImportDataActionStatusWithQueue
            queueKey={flowRecordsImportDataQueueKey}
          />
          <ApplyAutomationProgressBanner
            jobStorageKey={flowRecordsApplyAutomationStorageKey}
          />
          <FlowRecordsViewFilterArea
            viewConfigStorageKey={
              calcsAndRecordsSharedStorageKeys.viewConfigStorageKey
            }
          />
          <RecordsListTablePanel
            type={AssetType.FLOW}
            {...flowRecordStorageKeys}
            viewConfigStorageKey={
              calcsAndRecordsSharedStorageKeys.viewConfigStorageKey
            }
            applyAutomationStorageKey={flowRecordsApplyAutomationStorageKey}
            filterComponentSlot={({ recordValueTypes }) => (
              <FlowRecordsTableFilterArea
                filterConfigStorageKey={
                  flowRecordStorageKeys.filterConfigStorageKey
                }
                selectableProperties={recordValueTypes}
              />
            )}
          />
        </Tab>
      ) : null}
      {isFacilityEnabled ? (
        <Tab
          title={`${localize("facility_plural")}`}
          tabKey="facilities"
        >
          <ImportDataActionStatusWithQueue
            queueKey={facilityRecordsImportDataQueueKey}
          />
          <ApplyAutomationProgressBanner
            jobStorageKey={facilityRecordsApplyAutomationStorageKey}
          />
          <FacilityRecordsViewFilterArea
            viewConfigStorageKey={
              calcsAndRecordsSharedStorageKeys.viewConfigStorageKey
            }
          />
          <RecordsListTablePanel
            type={AssetType.FACILITY}
            {...facilityRecordStorageKeys}
            viewConfigStorageKey={
              calcsAndRecordsSharedStorageKeys.viewConfigStorageKey
            }
            applyAutomationStorageKey={facilityRecordsApplyAutomationStorageKey}
            filterComponentSlot={({ recordValueTypes }) => (
              <FacilityRecordsTableFilterArea
                filterConfigStorageKey={
                  facilityRecordStorageKeys.filterConfigStorageKey
                }
                selectableProperties={recordValueTypes}
              />
            )}
          />
        </Tab>
      ) : null}
    </Page>
  );
};
