import {
  ButtonToggleGroup,
  FilterPanel,
  StorageKeys,
  TextInput,
  useFilters,
} from "@validereinc/common-components";
import { SavedFilterStatus, SavedFilterStatusType } from "@validereinc/domain";
import React from "react";

export const CustomReportListFilterPanel = ({
  filterConfigStorageKey,
}: Pick<StorageKeys, "filterConfigStorageKey">) => {
  const [filters] = useFilters<{ status: SavedFilterStatusType }>(
    filterConfigStorageKey
  );

  return (
    <FilterPanel
      storageKey={filterConfigStorageKey}
      defaultValue={{
        status: SavedFilterStatus.ACTIVE,
      }}
      filters={[
        {
          component: (
            <ButtonToggleGroup
              name="status"
              key="status"
              activeKey={filters.status ?? SavedFilterStatus.DRAFT}
              items={[
                { label: "Company", dataKey: SavedFilterStatus.ACTIVE },
                { label: "My Reports", dataKey: SavedFilterStatus.DRAFT },
              ]}
            />
          ),
        },
        {
          component: (
            <TextInput
              name="name"
              key="name"
              placeholder="Search Reports..."
              type="search"
              isInline
            />
          ),
        },
      ]}
    />
  );
};
