import { useParams } from "#src/Routers/hooks";
import { RoutingLink } from "#src/batteries-included-components/RoutingLink";
import { useTableSortingAndPagination } from "#src/components/Redux/reducers/tableStateReducer";
import { EventCategoryContext } from "#src/contexts/EventCategoryContext";
import { EventTemplateContext } from "#src/contexts/EventTemplateContext";
import useLocalization from "#src/hooks/useLocalization";
import { linkToEventDetails } from "#src/routes/events/event-categories/[eventCategoryId]/templates/[templateId]/events/[eventId]";
import { linkToFacilityDetail } from "#src/routes/organization/facilities/[facilityId]";
import { useQueries, useQuery } from "@tanstack/react-query";
import {
  DataTable,
  DataTablePanel,
  HeaderType,
  PillProps,
  StorageKeys,
  useFilters,
} from "@validereinc/common-components";
import {
  EventDetailsType,
  EventGetListFilterType,
  EventStatus,
  EventsDomain,
  FacilityDomain,
  GetListRequestType,
  SortDirection,
} from "@validereinc/domain";
import { toStartCaseString } from "@validereinc/utilities";
import React, { useContext } from "react";

export const statusPillMapping: Record<
  keyof typeof EventStatus,
  PillProps["variant"]
> = {
  open: "warning",
  overdue: "error",
  dismissed: "default",
  resolved: "success",
};

export const EventsTablePanel = ({
  filterConfigStorageKey,
  tableConfigStorageKey,
}: StorageKeys) => {
  const { eventCategory } = useContext(EventCategoryContext) || {};
  const { eventTemplate } = useContext(EventTemplateContext) || {};
  const { localize } = useLocalization();
  const { eventCategoryId, eventTemplateId } = useParams<{
    eventCategoryId: string;
    eventTemplateId: string;
  }>();
  const [filters] = useFilters<EventGetListFilterType>(filterConfigStorageKey);
  const [tableState, setTableState] = useTableSortingAndPagination(
    {
      sortBy: "created_at",
      sortDirection: SortDirection.DESCENDING,
    },
    filters
  );

  const reqParams: GetListRequestType<EventGetListFilterType> = {
    page: tableState.page,
    pageSize: tableState.pageSize,
    sortBy: tableState.sortBy,
    sortDirection: tableState.sortDirection,
    filters: {
      ...filters,
      ...(filters.associated_facility
        ? { "associated_facility.id": filters.associated_facility?.id }
        : {}),
      ...(eventCategory || eventCategoryId
        ? { event_category_id: eventCategory?.id || eventCategoryId }
        : {}),
      ...(eventTemplate || eventTemplateId
        ? { event_schema_id: eventTemplate?.id || eventTemplateId }
        : {}),
    },
  };

  const { data: events, isLoading } = useQuery(["events", reqParams], () =>
    EventsDomain.getList(reqParams)
  );

  // REVIEW: this sort of asset relationship is slated to be removed. Remove
  // when BE support is dropped and/or BE migrations have completed.
  const eventsFacilitiesLegacyQueries = useQueries({
    queries:
      events?.data.map((event) => ({
        queryKey: ["facilities", { filter: { "event.id": event.id } }],
        queryFn: () =>
          FacilityDomain.getList({
            page: 1,
            pageSize: 1,
            filters: {
              "event.id": event.id,
            },
          }),
        enabled: !event.associated_facility_id,
      })) ?? [],
  });

  const headers: Array<HeaderType<EventDetailsType>> = [
    {
      key: "name",
      label: "Event Name",
      isSortable: true,
      renderComponent: ({ item }) => {
        const categoryId =
          item?.event_schema?.event_category_id ?? eventCategory?.id;
        const schemaId = item?.event_schema_id ?? eventTemplate?.id;
        const { name, id } = item;
        return categoryId && schemaId && name && id ? (
          <RoutingLink to={linkToEventDetails(categoryId, schemaId, id)}>
            {name}
          </RoutingLink>
        ) : (
          "-"
        );
      },
    },
    {
      key: "status",
      label: "Status",
      renderComponent: ({ item }) => (
        <DataTable.DataRow.PillCell
          variant={statusPillMapping[item.status]}
          value={toStartCaseString(item.status)}
        />
      ),
    },
    { key: "description", label: "Event Description" },
    {
      key: "associated_facility_id",
      label: `Associated ${localize("Facility")}`,
      renderComponent: ({ item, idx }) => {
        const facilityId =
          item.associated_facility_id ??
          eventsFacilitiesLegacyQueries[idx]?.data?.data[0]?.id;
        const facilityName =
          item.associated_facility?.name ??
          eventsFacilitiesLegacyQueries[idx]?.data?.data[0]?.name;
        return facilityId && facilityName ? (
          <RoutingLink to={linkToFacilityDetail(facilityId)}>
            {facilityName}
          </RoutingLink>
        ) : (
          "-"
        );
      },
    },
    {
      key: "start",
      label: "Event Start",
      isSortable: true,
      renderComponent: ({ item }) => (
        <DataTable.DataRow.DateCell
          value={item.start}
          withTime
        />
      ),
    },
    {
      key: "end",
      label: "Event End",
      isSortable: true,
      renderComponent: ({ item }) => (
        <DataTable.DataRow.DateCell
          value={item.end}
          withTime
        />
      ),
    },
    {
      key: "duration",
      label: "Duration",
      isSortable: true,
    },
    {
      key: "due_date",
      label: "Due Date",
      isSortable: true,
      renderComponent: ({ item }) => (
        <DataTable.DataRow.DateCell
          value={item.due_date}
          withTime
        />
      ),
    },
    {
      key: "created_at",
      label: "Date Created",
      isSortable: true,
      renderComponent: ({ item }) => (
        <DataTable.DataRow.DateCell
          value={item.created_at}
          withTime
        />
      ),
    },
  ];

  return (
    <DataTablePanel
      storageKey={tableConfigStorageKey}
      panelProps={{
        title: "Events",
      }}
      dataTableProps={{
        items: events?.data ?? [],
        headers,
        isLoading,
        pagination: {
          page: tableState.page,
          pageSize: tableState.pageSize,
          total: events?.total_entries,
          pageSizeText: tableState.pageSizeText,
        },
        sorting: {
          sortBy: tableState.sortBy,
          sortDirection: tableState.sortDirection,
        },
        onPaginationChange: setTableState,
        onSortChange: setTableState,
      }}
    />
  );
};
