import {
  useListCompanyUnitConfig,
  useUpdateCompanyUnitConfig,
} from "#hooks/adapters/useCompanyUnitConfig";
import { useTableSortingAndPagination } from "#redux/reducers/tableStateReducer";
import {
  useListUserUnitConfig,
  useUpdateUserUnitConfig,
} from "#src/components/hooks/adapters/useUserUnitConfig";
import { useMeasurementTypes } from "#src/contexts/MeasurementTypeContext";
import {
  Button,
  DataTablePanel,
  Dialog,
  DropdownInput,
  Form,
  HeaderType,
  TextInput,
  useForm,
} from "@validereinc/common-components";
import type { UnitConfigurationPreferenceType } from "@validereinc/domain";
import {
  SortDirection,
  UnitConfigurationPreference,
  UnitConfigurationSchema,
  UnitConfigurationType,
  UserUnitConfigurationAdapter,
} from "@validereinc/domain";
import React, { useMemo, useState } from "react";
import { AddUnitConfigDialog } from "../../Dialogs/AddUnitConfigDialog";
import { DeleteConfigDialog } from "../../Dialogs/DeleteUnitConfigDialog";

export const UnitConfigTablePanel = ({
  unitConfigurationPreference,
}: {
  unitConfigurationPreference: UnitConfigurationPreferenceType;
}) => {
  const [tableState, updateTableState] = useTableSortingAndPagination({
    sortBy: "measurement_type",
    sortDirection: SortDirection.DESCENDING,
  });
  const [itemToUpdate, setItemToUpdate] =
    useState<UnitConfigurationType | null>();
  const [itemToDelete, setItemToDelete] =
    useState<UnitConfigurationType | null>();
  const [isAddUnitConfigOpen, setIsAddUnitConfigOpen] = useState(false);
  const { measurementTypes, getUnitsByQuantity, getTypeName, getUnitName } =
    useMeasurementTypes();
  const measurementTypeParams: Parameters<
    typeof UserUnitConfigurationAdapter.getList
  >[0] = {
    page: tableState.page,
    pageSize: tableState.pageSize,
    sortBy: tableState.sortBy,
    sortDirection: tableState.sortDirection,
  };

  const userUnitQuery = useListUserUnitConfig(measurementTypeParams, {
    enabled: unitConfigurationPreference === UnitConfigurationPreference.USER,
  });
  const companyUnitQuery = useListCompanyUnitConfig(measurementTypeParams, {
    enabled:
      unitConfigurationPreference === UnitConfigurationPreference.COMPANY,
  });

  const getUnitPreference = (
    preferenceType: UnitConfigurationPreferenceType
  ) => {
    let unitPreferenceQuery;
    switch (preferenceType) {
      case UnitConfigurationPreference.COMPANY:
        unitPreferenceQuery = companyUnitQuery;
        break;
      case UnitConfigurationPreference.USER:
        unitPreferenceQuery = userUnitQuery;
        break;
    }
    return {
      items: unitPreferenceQuery?.data?.data ?? [],
      isLoading: unitPreferenceQuery?.isLoading,
      count: unitPreferenceQuery?.data?.total_entries,
    };
  };

  const measurementTypeId = itemToUpdate?.measurement_type;
  const measurementType = useMemo(
    () => measurementTypes.find(({ id }) => id === measurementTypeId),
    [measurementTypes, measurementTypeId]
  );
  const units = getUnitsByQuantity(measurementType?.quantity);

  const headers: Array<HeaderType<UnitConfigurationType>> = [
    {
      key: "measurement_type",
      label: "Measurement Type",
      isSortable: true,
      renderComponent: ({ item }) => getTypeName(item.measurement_type),
    },
    {
      key: "measurement_unit",
      label: "Unit",
      isSortable: true,
      renderComponent: ({ item }) =>
        getUnitName(item.measurement_unit) ?? item.measurement_unit,
    },
    {
      key: "precision",
      label: "Precision",
    },
  ];

  const getItemActions = ({ item }: { item: UnitConfigurationType }) => [
    {
      label: "Update",
      buttonProps: {
        onClick: () => setItemToUpdate(item),
        icon: "pencil-simple",
      },
    },
    {
      label: "Delete",
      buttonProps: {
        onClick: () => setItemToDelete(item),
        icon: "trash",
      },
    },
  ];

  const form = useForm({
    defaultValues: itemToUpdate,
  });

  const onSuccess = () => {
    setItemToUpdate(null);
  };

  const updateUserUnitConfig = useUpdateUserUnitConfig({ onSuccess });
  const updateCompanyUnitConfig = useUpdateCompanyUnitConfig({ onSuccess });

  const updateUnitConfig =
    unitConfigurationPreference === UnitConfigurationPreference.COMPANY
      ? updateCompanyUnitConfig
      : updateUserUnitConfig;

  const handleSubmit = form.handleSubmit((values) => {
    const { measurement_unit, precision } = values;
    const measurement_type = itemToUpdate?.measurement_type;
    updateUnitConfig.mutate({
      id: measurement_type,
      data: {
        measurement_unit,
        precision: Number(precision),
      },
    });
  });

  const actionRow = [
    <Button
      key="add-unit-config"
      onClick={() => setIsAddUnitConfigOpen(true)}
    >
      Add
    </Button>,
  ];

  return (
    <>
      <DataTablePanel
        panelProps={{ title: "Measurement Type Unit Configuration", actionRow }}
        dataTableProps={{
          items: getUnitPreference(unitConfigurationPreference).items,
          isLoading: getUnitPreference(unitConfigurationPreference).isLoading,
          headers,
          getItemActions,
          onSortChange: updateTableState,
          onPaginationChange: updateTableState,
          sorting: {
            sortBy: tableState.sortBy,
            sortDirection: tableState.sortDirection,
          },
          pagination: {
            page: tableState.page,
            pageSize: tableState.pageSize,
            total: getUnitPreference(unitConfigurationPreference).count,
          },
        }}
      />
      <AddUnitConfigDialog
        isOpen={isAddUnitConfigOpen}
        onClose={() => setIsAddUnitConfigOpen(false)}
        unitConfigurationPreference={unitConfigurationPreference}
      />
      <DeleteConfigDialog
        isOpen={!!itemToDelete}
        onClose={() => setItemToDelete(undefined)}
        unitConfigurationPreference={unitConfigurationPreference}
        itemToDelete={itemToDelete}
      />
      <Dialog
        isOpen={!!itemToUpdate}
        onClose={() => setItemToUpdate(undefined)}
        title={`Update ${itemToUpdate?.measurement_type}`}
        actionRow={[
          <Button
            key="update-measurement-prefernce"
            onClick={handleSubmit}
            variant="primary"
          >
            Update
          </Button>,
        ]}
      >
        <Form {...form}>
          <DropdownInput
            label="Unit Preference"
            name={UnitConfigurationSchema.keyof().Enum.measurement_unit}
            options={units}
            valueKey="id"
            labelKey="name.symbol"
            isRequired
            isClearable={false}
          />
          <TextInput
            name={UnitConfigurationSchema.keyof().Enum.precision}
            label="Decimal Precision"
            isRequired={false}
            type="number"
          />
        </Form>
      </Dialog>
    </>
  );
};
