import { useListTemplatedReports } from "#hooks/adapters/useTemplatedReports";
import { useTableSortingAndPagination } from "#redux/reducers/tableStateReducer";
import { useNavigate } from "#routers/hooks";
import { linkToExportTemplatedReport } from "#routes/reports/templated-reports/[reportName]/export";
import {
  DataTablePanel,
  SortingType,
  StorageKeys,
  Tag,
  useFilters,
} from "@validereinc/common-components";
import { SortDirection, TemplatedReportType } from "@validereinc/domain";
import classNames from "classnames/bind";
import isPlainObject from "lodash/isPlainObject";
import React from "react";
import styles from "./TemplatedReportTablePanel.module.scss";

const sorting: SortingType = {
  sortBy: "display_name",
  sortDirection: SortDirection.ASCENDING,
};

const cx = classNames.bind(styles);

export const TemplatedReportTablePanel = ({
  filterConfigStorageKey,
  tableConfigStorageKey,
}: StorageKeys) => {
  const navigate = useNavigate();
  const [filters] = useFilters(filterConfigStorageKey);
  const [tableState, updateTableState] = useTableSortingAndPagination(
    sorting,
    filters
  );

  const { data, isLoading } = useListTemplatedReports({
    page: tableState.page,
    pageSize: tableState.pageSize,
    sortBy: tableState.sortBy,
    sortDirection: tableState.sortDirection,
    filters,
  });

  const items = data?.data ?? [];

  const headers = [
    {
      label: "Report Name",
      key: "display_name",
      isSortable: true,
    },
    // IMPROVE: show a report version or applicable year here in the future for more context
    {
      label: "Inputs",
      key: "input_schema",
      renderComponent: ({
        value,
      }: {
        value: TemplatedReportType["input_schema"];
      }) => {
        if (!isPlainObject(value)) return null;

        return (
          <ul className={cx("tag-container")}>
            {Object.entries(value).map(([k, v]) => {
              return (
                <Tag
                  as="li"
                  value={v.display_name}
                  key={k}
                />
              );
            })}
          </ul>
        );
      },
    },
  ];

  const getItemActions = ({ item }: { item: TemplatedReportType }) => [
    {
      label: "Export",
      buttonProps: {
        onClick: () => {
          navigate({
            pathname: linkToExportTemplatedReport(item?.name),
          });
        },
        icon: "share",
      },
    },
  ];

  return (
    <DataTablePanel
      storageKey={tableConfigStorageKey}
      panelProps={{ title: "Exportable Reports" }}
      dataTableProps={{
        variant: "simplicity-first",
        isLoading,
        items,
        headers,
        getItemActions,
        onSortChange: updateTableState,
        onPaginationChange: updateTableState,
        pagination: {
          page: tableState.page,
          pageSize: tableState.pageSize,
          total: data?.total_entries,
        },
      }}
    />
  );
};
