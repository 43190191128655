import { NetworksViewFilterArea } from "#src/batteries-included-components/FilterAreas/AssetsFilterAreas";
import { StorageKeys } from "@validereinc/common-components";
import classNames from "classnames/bind";
import React from "react";
import { NetworkDetailsPanel } from "./NetworkDetailsPanel";
import styles from "./NetworkDetailsTab.module.scss";
import { NetworkEntityTablePanel } from "./NetworkEntityTablePanel/NetworkEntityTablePanel";
import { NetworkFilterPanel } from "./NetworkFilterPanel";
import { NetworkMapPanel } from "./NetworkMapPanel";

const cx = classNames.bind(styles);

export const NetworkDetailsTab = ({
  viewConfigStorageKey,
}: Pick<StorageKeys, "viewConfigStorageKey">) => {
  return (
    <>
      <NetworksViewFilterArea viewConfigStorageKey={viewConfigStorageKey} />
      <div className={cx("container")}>
        <div className={cx("detailsContainer")}>
          <NetworkDetailsPanel viewConfigStorageKey={viewConfigStorageKey} />
          <NetworkMapPanel viewConfigStorageKey={viewConfigStorageKey} />
        </div>

        <div className={cx("entityTableContainer")}>
          <NetworkFilterPanel />
          <NetworkEntityTablePanel
            viewConfigStorageKey={viewConfigStorageKey}
          />
        </div>
      </div>
    </>
  );
};
