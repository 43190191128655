import { UsersDropdownInput } from "#src/batteries-included-components/Dropdowns/UsersDropdownInput";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  Button,
  ButtonToggleGroup,
  Dialog,
  Form,
  useAlert,
  useForm,
} from "@validereinc/common-components";
import {
  Resources,
  WorkflowTaskAdapter,
  WorkflowTaskBaseSchema,
  WorkflowTaskType,
} from "@validereinc/domain";
import classNames from "classnames/bind";
import React, { useState } from "react";
import styles from "./WorkflowTaskUpdateAssigneeDialog.module.css";
import { UserGroupsDropdownInput } from "#src/batteries-included-components/Dropdowns/UserGroupsDropdownInput";

export type WorkflowTaskUpdateAssigneeDialogProps = {
  task: WorkflowTaskType | null;
  isOpen?: boolean;
  onClose?: () => void;
  onSubmit?: (formValues: FormValues) => void;
};

type FormValues = {
  assignee_user: string;
  assignee_group: string;
};

const ButtonToggleGroupTabs = {
  user: "user",
  user_groups: "user_groups",
} as const;

type ButtonToggleGroupTabsType =
  (typeof ButtonToggleGroupTabs)[keyof typeof ButtonToggleGroupTabs];

const cx = classNames.bind(styles);

export const WorkflowTaskUpdateAssigneeDialog = ({
  task,
  isOpen,
  onClose,
  onSubmit,
}: WorkflowTaskUpdateAssigneeDialogProps) => {
  const { addAlert } = useAlert();
  const form = useForm({
    defaultValues: {
      [WorkflowTaskBaseSchema.keyof().Enum.assignee_user]:
        task?.assignee_user ?? "",
      [WorkflowTaskBaseSchema.keyof().Enum.assignee_group]:
        task?.assignee_group ?? "",
    },
  });
  const queryClient = useQueryClient();
  const [assigneeUserType, setAssigneeUserType] =
    useState<ButtonToggleGroupTabsType>(() =>
      task?.assignee_group ? "user_groups" : "user"
    );

  const { mutateAsync } = useMutation({
    mutationFn: (
      updatePayload: Partial<
        Pick<WorkflowTaskType, "status" | "assignee_user" | "assignee_group">
      >
    ) => {
      if (!task || !updatePayload) {
        return;
      }

      return WorkflowTaskAdapter.updateOne({
        id: task?.id,
        data: updatePayload,
        previousData: task,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["workflows"],
      });
      queryClient.invalidateQueries({
        queryKey: [Resources.WORKFLOW],
      });
    },
  });

  const handleClose = () => {
    form.reset();
    onClose?.();
  };

  const handleSubmit = async (values: FormValues) => {
    try {
      onSubmit?.(values);
      await mutateAsync({
        ...(assigneeUserType === ButtonToggleGroupTabs.user
          ? { assignee_user: values.assignee_user, assignee_group: null }
          : { assignee_user: null }),
        ...(assigneeUserType === ButtonToggleGroupTabs.user_groups
          ? { assignee_group: values.assignee_group, assignee_user: null }
          : { assignee_group: null }),
      });
    } catch (err) {
      console.error("Couldn't update assignee for workflow task", err);
      addAlert({
        variant: "error",
        message: "Couldn't update assignee. Try again?",
      });
    } finally {
      handleClose();
    }
  };

  return (
    <Dialog
      title={`Re-Assign Task: ${task?.name ? ` ${task.name}` : ""}`}
      isOpen={isOpen}
      onClose={handleClose}
      actionRow={[
        <Button
          key="save"
          variant="primary"
          onClick={form.handleSubmit(handleSubmit)}
        >
          Save
        </Button>,
      ]}
    >
      <Form {...form}>
        <p>Select a single user or a user group to assign this task to</p>
        <ButtonToggleGroup
          className={cx("assignee-type-content-switcher")}
          items={[
            {
              label: "Individual Users",
              dataKey: ButtonToggleGroupTabs.user,
              description:
                "Tasks assigned to an individual user will be assigned to that specific user.",
            },
            {
              label: "User Groups",
              dataKey: ButtonToggleGroupTabs.user_groups,
              description:
                "Tasks assigned to a user group will be available to all users in that group.",
            },
          ]}
          activeKey={assigneeUserType}
          onChange={(key) => {
            setAssigneeUserType(key as ButtonToggleGroupTabsType);
          }}
        />
        <div
          className={cx(
            assigneeUserType !== ButtonToggleGroupTabs.user && "hidden"
          )}
        >
          <UsersDropdownInput
            key={WorkflowTaskBaseSchema.keyof().Enum.assignee_user}
            inputId={ButtonToggleGroupTabs.user}
            name={WorkflowTaskBaseSchema.keyof().Enum.assignee_user}
            placeholder="Select a user"
            isFluid
          />
        </div>
        <div
          className={cx(
            assigneeUserType !== ButtonToggleGroupTabs.user_groups && "hidden"
          )}
        >
          <UserGroupsDropdownInput
            key={WorkflowTaskBaseSchema.keyof().Enum.assignee_group}
            inputId={ButtonToggleGroupTabs.user_groups}
            name={WorkflowTaskBaseSchema.keyof().Enum.assignee_group}
            placeholder="Select a user group"
            isFluid
          />
        </div>
      </Form>
    </Dialog>
  );
};
