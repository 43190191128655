import { RoutingLink } from "#batteries-included-components/RoutingLink";
import { useNavigate, useParams } from "#routers/hooks";
import { NetworkDetailRoute } from "#routes/organization/networks/[networkId]/detail";
import { useNetworkCalculationResult } from "#routes/organization/networks/[networkId]/detail/calculation-result/[period]/details/NetworkCalculationResultPage.helpers";
import { useListCustomAttributes } from "#src/components/hooks/adapters/useCustomAttributes";
import useLocalization from "#src/hooks/useLocalization";
import { displayValueByDataType } from "#src/utils/display";
import { Panel, StatusPanelItem } from "@validereinc/common-components";
import {
  AssetGroupAssetType,
  AssetType,
  AssetTypeType,
  AttributeDataTypeType,
} from "@validereinc/domain";
import React, { type ReactNode } from "react";

export const NetworkCalculationResultDetailPanel = () => {
  const { localize } = useLocalization();
  const navigate = useNavigate();
  const { networkId } = useParams<{ networkId: string }>();

  const customAttributeQuery = useListCustomAttributes({
    filters: { entity_type: AssetType.ASSET_GROUP },
  });
  const customAttributes = customAttributeQuery.data?.data ?? [];

  const { network } = useNetworkCalculationResult();

  const pathname = NetworkDetailRoute.toLink({ pathParams: { networkId } });

  const getAssetTypeCount = (assetType: AssetTypeType) =>
    network?.assets?.filter(
      ({ asset_type }: AssetGroupAssetType) => asset_type === assetType
    )?.length ?? "-";

  const items: Array<{ label: string; value: ReactNode }> = [
    {
      label: "Network",
      value: (
        <RoutingLink
          to={pathname}
          onClick={() => {
            navigate({
              pathname,
            });
          }}
        >
          {network?.name}
        </RoutingLink>
      ),
    },
    {
      label: localize("flow_plural"),
      value: getAssetTypeCount(AssetType.FLOW),
    },

    ...customAttributes.map(
      ({
        field_name,
        display_name,
        data_type,
      }: {
        field_name: string;
        display_name: string;
        data_type: AttributeDataTypeType;
      }) => {
        const value = network?.custom_attributes?.[field_name];

        return {
          label: display_name,
          value: displayValueByDataType(value, data_type),
        };
      }
    ),
  ];

  return (
    <Panel
      title={"Network Details"}
      isFluidY={false}
    >
      {items.map((item) => (
        <StatusPanelItem
          key={item.label}
          item={item}
        />
      ))}
    </Panel>
  );
};
