import { useParams } from "#routers/hooks";
import { FileDataDisplay } from "#src/components/DataDisplay/FileDataDisplay";
import { useMeasurementTypes } from "#src/contexts/MeasurementTypeContext";
import { RecordContext, RecordProvider } from "#src/contexts/RecordContext";
import {
  Icon,
  IconVariants,
  KeyValueTile,
  MetricTile,
  Panel,
} from "@validereinc/common-components";
import {
  getFormattedNumber,
  getIconVariantFromExtension,
} from "@validereinc/utilities";
import classNames from "classnames/bind";
import truncate from "lodash/truncate";
import React, { useContext } from "react";
import styles from "./RecordValueSummaryPanel.module.css";

const cx = classNames.bind(styles);

export const RecordValueSummaryPanelContent = () => {
  const { record, isLoading: isRecordLoading } =
    useContext(RecordContext) || {};
  const { measurementType } = useParams<{ measurementType: string }>();
  const {
    getTypeName,
    getUnitName,
    isLoading: isMeasurementTypesLoading,
  } = useMeasurementTypes();

  const isLoading = isRecordLoading || isMeasurementTypesLoading;

  const recordValue = record?.values.find(
    ({ measurement_type }) => measurement_type === measurementType
  );

  return (
    <>
      <Panel
        title={getTypeName(measurementType)}
        isFluidY={false}
      >
        <div className={cx("items")}>
          <MetricTile
            isLoading={isLoading}
            title={getTypeName(measurementType)}
            value={
              recordValue ? getFormattedNumber(recordValue.value, null) : ""
            }
            unit={getUnitName(
              recordValue?.measurement_unit ?? "",
              recordValue?.value
            )}
          />
          {recordValue?.note && (
            <KeyValueTile
              title="Note"
              value={truncate(recordValue.note, { length: 500 })}
              isLoading={isLoading}
            />
          )}
          {recordValue?.attachment_filename && (
            <KeyValueTile
              title="Attachment"
              isLoading={isLoading}
              value={(props) => (
                <div
                  className={cx("attachment-name-and-icon", props.className)}
                >
                  <Icon
                    variant={
                      getIconVariantFromExtension(
                        recordValue.attachment_filename ?? ""
                      ) as IconVariants
                    }
                    size={40}
                  />
                  <FileDataDisplay
                    fileName={recordValue.attachment_filename ?? "Unknown"}
                    fileId={recordValue?.attachment_ref ?? ""}
                    hideIcon
                  />
                </div>
              )}
            />
          )}
        </div>
      </Panel>
    </>
  );
};

export const RecordValueSummaryPanel = () => {
  return (
    <RecordProvider>
      <RecordValueSummaryPanelContent />
    </RecordProvider>
  );
};
