import { Map, Marker } from "#src/components/Map";
import {
  Button,
  colours,
  DataDisplayWithAdornment,
  Icon,
  LoadingState,
  Popover,
  Skeleton,
  type DataDisplayProps,
} from "@validereinc/common-components";
import {
  GeocoordinateSchema,
  type GeocoordinateType,
} from "@validereinc/domain";
import {
  copyTextToClipboard,
  formatCoordinateDegrees,
} from "@validereinc/utilities";
import classNames from "classnames/bind";
import React, { useState } from "react";
import { GeolocateControl } from "react-map-gl";
import styles from "./GeocoordinateDataDisplay.module.css";
import { useReverseGeolocation } from "./useReverseGeolocation";

const cx = classNames.bind(styles);

export type GeocoordinateDataDisplayProps = Omit<DataDisplayProps, "value"> & {
  coordinates?: GeocoordinateType;
};

export const GeocoordinateDataDisplay = ({
  coordinates,
  alignment,
  isInline,
  isBusy,
  isLoading,
  emptyText = "-",
  showInvalidIndicator = true,
  invalidText = "Unsupported data type",
  className,
  style,
}: GeocoordinateDataDisplayProps) => {
  const [ready, setIsReady] = useState(false);
  const [hasCopied, setHasCopied] = useState(false);
  const [locationDescription, isLocationDescriptionLoading] =
    useReverseGeolocation({
      coordinates: coordinates,
      isEnabled: ready,
    });
  const displayCoordinateDegrees = coordinates
    ? formatCoordinateDegrees(coordinates, 2)
    : emptyText;
  const displayFullCoordinateDegrees = coordinates
    ? formatCoordinateDegrees(coordinates)
    : emptyText;

  const isEmpty = !coordinates;
  const isInvalid = !!GeocoordinateSchema.safeParse(coordinates).error;

  return (
    <DataDisplayWithAdornment
      showAdornment={showInvalidIndicator && !isEmpty && isInvalid}
      alignment={alignment}
      isInline={isInline}
      isBusy={isBusy}
      isLoading={isLoading}
      adornmentSlot={() =>
        !isEmpty && isInvalid ? (
          <Icon
            variant="warning"
            colour={colours.colours.status.warning}
            size={12}
            alt={invalidText}
            aria-roledescription="Warning Icon"
          />
        ) : null
      }
      className={cx("container", className)}
      style={style}
    >
      {!isEmpty ? (
        <Popover
          contentRender={() => (
            <div className={cx("map-container")}>
              <div className={cx("map-description-container")}>
                <Skeleton isLoading={isLocationDescriptionLoading}>
                  <p className={cx("map-description")}>{locationDescription}</p>
                </Skeleton>
              </div>
              <div className={cx("map-render-container")}>
                {isLocationDescriptionLoading ? (
                  <LoadingState
                    label="Map initializing..."
                    size="small"
                  />
                ) : (
                  <Map
                    initialViewState={{
                      latitude: coordinates[0],
                      longitude: coordinates[1],
                    }}
                    style={{ height: "100%" }}
                  >
                    <Marker
                      latitude={coordinates[0]}
                      longitude={coordinates[1]}
                      iconType="map-pin"
                      size="large"
                      label={displayCoordinateDegrees}
                    />
                    <GeolocateControl showAccuracyCircle />
                  </Map>
                )}
              </div>
              <div className={cx("map-coordinate-details")}>
                <p className={cx("map-coordinates")}>
                  {displayFullCoordinateDegrees}
                </p>
                <Button
                  icon={hasCopied ? "check-circle" : "copy-simple"}
                  disabled={hasCopied}
                  variant="outline"
                  size="xx-small"
                  title="Copy to Clipboard"
                  onClick={() =>
                    copyTextToClipboard(displayFullCoordinateDegrees)
                      .then(() => setHasCopied(true))
                      .then(
                        () =>
                          new Promise((res, rej) => {
                            setTimeout(() => res(), 2500);
                          })
                      )
                      .then(() => setHasCopied(false))
                  }
                >
                  {hasCopied ? "Copied" : ""}
                </Button>
              </div>
            </div>
          )}
          rootProps={{
            onOpenChange: (newState) => {
              if (newState) setIsReady(true);
            },
          }}
          contentClassName={cx("content-container")}
          shouldDisplayArrow={false}
        >
          <Button
            icon="map-trifold"
            iconPosition="left"
            variant="text"
            size="xx-small"
            className={cx("preview-trigger")}
            style={{ marginLeft: 4 }}
            onClick={() => setIsReady(true)}
          >
            {displayCoordinateDegrees}
          </Button>
        </Popover>
      ) : (
        emptyText
      )}
    </DataDisplayWithAdornment>
  );
};
