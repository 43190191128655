import {
  CheckboxInput,
  TextAreaInput,
  TextInput,
} from "@validereinc/common-components";
import React from "react";
import { CreateFormTemplateQuestionTable } from "./CreateFormTemplateQuestionTable";
import classNames from "classnames/bind";
import styles from "./CreateFormTemplateSection.module.css";

const cx = classNames.bind(styles);

export const CreateFormTemplateSection = ({
  index,
  sectionName,
}: {
  index: number;
  sectionName: string;
}) => {
  return (
    <>
      <TextInput
        name={`config.sections.${index}.name`}
        label="Section Name"
        isRequired
        className={cx("text-input")}
      />

      <TextAreaInput
        name={`config.sections.${index}.description`}
        label="Description"
        className={cx("text-input")}
      />

      <CheckboxInput
        name={`config.sections.${index}.is_repeatable`}
        label="Is Repeatable"
        isLabelShown={false}
      />

      <CreateFormTemplateQuestionTable
        formFieldSectionQuestionsName={`config.sections.${index}.questions`}
        sectionName={sectionName}
      />
    </>
  );
};
