import {
  useExportEquipment,
  useListEquipment,
} from "#hooks/adapters/useEquipment";
import { useGetOneFacility } from "#hooks/adapters/useFacilities";
import { useTableSortingAndPagination } from "#redux/reducers/tableStateReducer";
import { useNavigate, useParams } from "#routers/hooks";
import { linkToAddEquipment, linkToEquipmentDetail } from "#routers/links";
import { FacilityDetailPageParams } from "#routes/organization/facilities/[facilityId]";
import { ImportDataActionStatusWithQueue } from "#src/batteries-included-components/Banners/ImportDataActionStatus";
import { ImportDataAction } from "#src/batteries-included-components/Buttons/ImportDataAction";
import { getImportDataActionQueueKey } from "#src/batteries-included-components/Buttons/ImportDataAction/ImportDataAction.helpers";
import { EquipmentTableFilterArea } from "#src/batteries-included-components/FilterAreas/AssetsFilterAreas";
import { RoutingLink } from "#src/batteries-included-components/RoutingLink/RoutingLink";
import { useIsFeatureAvailable } from "#src/contexts/AuthenticatedContext.helpers";
import useLocalization from "#src/hooks/useLocalization";
import { useSessionStickyState } from "#src/hooks/useStickyState";
import { useStorageKey } from "#src/hooks/useStorageKey";
import {
  Button,
  ButtonWithPopover,
  DataTable,
  DataTablePanel,
  HeaderType,
  type StorageKeys,
} from "@validereinc/common-components";
import {
  EquipmentType,
  ResourceDefinitions,
  SortDirection,
} from "@validereinc/domain";
import {
  getYearMonthFromDateRange,
  toSnakeCaseString,
  toStartCaseString,
} from "@validereinc/utilities";
import React from "react";

export const FacilityEquipmentPanel = ({
  viewConfigStorageKey,
}: Pick<StorageKeys, "viewConfigStorageKey">) => {
  const [isDataIngestionEnabled] = useIsFeatureAvailable({
    featureFlagQuery: "core:data_pipeline",
  });
  const { tableConfigStorageKey, filterConfigStorageKey } = useStorageKey(
    "facilities-equipment"
  );
  const navigate = useNavigate();
  const { localize } = useLocalization();
  const { facilityId } = useParams<FacilityDetailPageParams>();
  const [viewFilters] = useSessionStickyState({}, viewConfigStorageKey);
  const [tableFilters] = useSessionStickyState({}, filterConfigStorageKey);
  const [tableState, updateTableState] = useTableSortingAndPagination(
    {
      sortBy: "name",
      sortDirection: SortDirection.ASCENDING,
      pageSize: 25,
    },
    { ...tableFilters, facilityId }
  );
  const equipmentApiParams = {
    page: tableState.page,
    pageSize: tableState.pageSize,
    sortBy: tableState.sortBy,
    sortDirection: tableState.sortDirection,
    period: getYearMonthFromDateRange(viewFilters.period),
    filters: {
      ...tableFilters,
      "facility.id": facilityId,
    },
  };
  const { data, isLoading } = useListEquipment(equipmentApiParams);
  const { data: facility } = useGetOneFacility(facilityId, {
    period: getYearMonthFromDateRange(viewFilters.period),
  });
  const { mutate: handleExport, isLoading: isExporting } = useExportEquipment(
    equipmentApiParams,
    facility?.name ? toSnakeCaseString(facility.name) : undefined
  );

  function handleOnAddEquipmentClick() {
    navigate({
      pathname: linkToAddEquipment(facilityId),
    });
  }

  const actionRow = [
    <Button
      key="add-equipment"
      variant="outline"
      onClick={handleOnAddEquipmentClick}
    >
      Add {localize("Equipment")}
    </Button>,
    <ButtonWithPopover
      icon="arrow-square-out"
      label="Export"
      key="export-devices"
      variant="outline"
      onClick={() => handleExport()}
      isLoading={isExporting}
    />,
    isDataIngestionEnabled ? (
      <ImportDataAction
        key="import-equipment"
        resource={{
          ...ResourceDefinitions.equipment,
          label: {
            singular: localize(ResourceDefinitions.equipment.label.singular),
            plural: localize("equipment_plural"),
          },
        }}
      />
    ) : null,
  ];

  const headers: Array<HeaderType<EquipmentType>> = [
    {
      key: "name",
      label: "Name",
      isSortable: true,
      renderComponent: ({ item }) => (
        <RoutingLink to={linkToEquipmentDetail(item?.id)}>
          {item.name}
        </RoutingLink>
      ),
    },
    {
      key: "type.name",
      label: "Type",
      isSortable: false,
      renderComponent: ({ item }) => item?.type?.name ?? "-",
    },
    {
      label: "Status",
      key: "status",
      isSortable: true,
      renderComponent: ({ item }) => (
        <DataTable.DataRow.PillCell
          variant={item.status === "active" ? "success" : "default"}
          value={toStartCaseString(item.status)}
        />
      ),
    },
    {
      label: "Effective Date",
      key: "effective_date",
      isSortable: true,
      renderComponent: ({ item }) => (
        <DataTable.DataRow.DateCell value={item.effective_date} />
      ),
    },
  ];

  const importDataActionQueueKey = getImportDataActionQueueKey({
    resource: {
      ...ResourceDefinitions.equipment,
      label: {
        singular: localize(ResourceDefinitions.equipment.label.singular),
        plural: localize("equipment_plural"),
      },
    },
  });
  const items = data?.data ?? [];

  return (
    <>
      {isDataIngestionEnabled ? (
        <ImportDataActionStatusWithQueue queueKey={importDataActionQueueKey} />
      ) : null}
      <DataTablePanel
        storageKey={tableConfigStorageKey}
        panelProps={{
          title: localize("equipment_plural"),
          actionRow,
        }}
        dataTableProps={{
          variant: "simplicity-first",
          headers,
          items,
          isLoading,
          sorting: {
            sortBy: tableState.sortBy,
            sortDirection: tableState.sortDirection,
          },
          pagination: {
            page: tableState.page,
            pageSize: tableState.pageSize,
            total: data?.total_entries,
          },
          onSortChange: updateTableState,
          onPaginationChange: updateTableState,
        }}
        filterComponent={
          <EquipmentTableFilterArea
            filterConfigStorageKey={filterConfigStorageKey}
            hasCustomAttributeFilters
            hasFacilityFilters={false}
          />
        }
      />
    </>
  );
};
