import { ReportingGroupFormPanel } from "#batteries-included-components/Panels/FormPanels/ReportingGroupFormPanel";
import { useBreadcrumbs } from "#routers/breadcrumbsHelper";
import { useNavigate, useParams } from "#routers/hooks";
import {
  SCENARIOS_BREADCRUMB,
  SCENARIO_LIST_BREADCRUMB,
  linkToScenarioList,
} from "#routes/scenarios";
import { SCENARIO_DETAIL_BREADCRUMB } from "#routes/scenarios/[scenarioId]";
import {
  EDIT_SCENARIO_BREADCRUMB,
  EDIT_SCENARIO_PAGE_TITLE,
} from "#routes/scenarios/[scenarioId]/edit/index";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  Button,
  Form,
  FormButton,
  Page,
  useAlert,
  useForm,
} from "@validereinc/common-components";
import { ReportingGroupDomain } from "@validereinc/domain";
import React from "react";

export const EditScenarioPage = () => {
  const navigate = useNavigate();
  const { scenarioId } = useParams<{ scenarioId: string }>();
  const { addAlert } = useAlert();

  const { data, isLoading } = useQuery({
    queryKey: ["reportingGroup", scenarioId],
    queryFn: () => ReportingGroupDomain.getOne({ id: scenarioId }),
    select: (resp) => resp.data,
  });

  const breadcrumbs = useBreadcrumbs(
    [
      SCENARIOS_BREADCRUMB,
      SCENARIO_LIST_BREADCRUMB,
      SCENARIO_DETAIL_BREADCRUMB,
      EDIT_SCENARIO_BREADCRUMB,
    ],
    { 2: data?.name }
  );
  const form = useForm({ defaultValues: { is_primary: false, ...data } });

  const navigateToScenarioList = () => {
    navigate({ pathname: linkToScenarioList() });
  };

  const updateScenario = useMutation({
    mutationFn: async ({
      name,
      description,
      is_primary,
    }: {
      name: string;
      description: string;
      is_primary: boolean;
    }) => {
      // The backend requires us to set an 32-bit integer priority
      const priority = is_primary
        ? Math.floor(Date.now() / 1000)
        : data?.priority ?? 1;
      try {
        await ReportingGroupDomain.updateOne({
          id: scenarioId,
          data: {
            name,
            description,
            priority,
          },
        });
        addAlert({
          variant: "success",
          message: `Updated Scenario "${name}"`,
        });
        navigateToScenarioList();
      } catch (err) {
        console.error(err);
        addAlert({
          variant: "error",
          message: "Unable to update Scenario",
        });
      }
    },
  });

  const footer = (
    <>
      <Button
        onClick={navigateToScenarioList}
        disabled={isLoading || updateScenario.isLoading}
      >
        Cancel
      </Button>

      <FormButton
        variant="primary"
        type="submit"
        onClick={form.handleSubmit(updateScenario.mutate)}
        disabled={isLoading || updateScenario.isLoading}
      >
        Save Scenario
      </FormButton>
    </>
  );

  return (
    <Form
      {...form}
      onSubmit={form.handleSubmit(updateScenario.mutate)}
      style={{ height: "100%" }}
    >
      <Page
        title={EDIT_SCENARIO_PAGE_TITLE}
        breadcrumbs={breadcrumbs}
        footer={footer}
      >
        <ReportingGroupFormPanel />
      </Page>
    </Form>
  );
};
