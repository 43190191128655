import mbxClient from "@mapbox/mapbox-sdk";
import mbxGeocodingService, {
  GeocodeFeature,
} from "@mapbox/mapbox-sdk/services/geocoding";
import { useQuery } from "@tanstack/react-query";
import type { GeocoordinateType } from "@validereinc/domain";

const baseClient = mbxClient({
  accessToken: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
});
const geocodingClient = mbxGeocodingService(baseClient);

export const useReverseGeolocation = ({
  coordinates,
  isEnabled = true,
}: {
  coordinates?: GeocoordinateType;
  isEnabled: boolean;
}): [string, boolean] => {
  const query = useQuery({
    queryKey: ["geocoordinates", coordinates, "geocode", "reverse"],
    queryFn: () => {
      if (
        !coordinates ||
        !Array.isArray(coordinates) ||
        coordinates[0] === null ||
        coordinates[1] === null ||
        typeof coordinates[0] !== "number" ||
        typeof coordinates[1] !== "number"
      )
        return;

      return geocodingClient
        .reverseGeocode({
          query: coordinates.slice().reverse() as [number, number],
          types: ["neighborhood", "locality", "place", "region", "country"],
        })
        .send()
        .then((resp) => {
          return resp.body;
        });
    },
    enabled:
      isEnabled &&
      coordinates &&
      Array.isArray(coordinates) &&
      coordinates[0] !== null &&
      coordinates[1] !== null &&
      typeof coordinates[0] === "number" &&
      typeof coordinates[1] === "number",
  });

  const getLocationDescription = (matches: GeocodeFeature[]) => {
    if (!matches.length) return "Unknown";

    const match = matches[0];

    return `${(["neighborhood", "place"] satisfies GeocodeFeature["place_type"]).every((t) => !match.place_type.includes(t)) ? "Near or In " : ""}${match.place_name}`;
  };

  return [getLocationDescription(query.data?.features ?? []), query.isLoading];
};
