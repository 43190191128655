import { useNavigate } from "#src/Routers/hooks";
import { RoutingLink } from "#src/batteries-included-components/RoutingLink";
import { useTableSortingAndPagination } from "#src/components/Redux/reducers/tableStateReducer";
import { useAuthenticatedContext } from "#src/contexts/AuthenticatedContext.helpers";
import { linkToRoleDetailPage } from "#src/routes/settings/roles-and-permissions/roles/[roleId]/detail";
import { linkToCreateUserPage } from "#src/routes/settings/users/create";
import { linkToUserDetailPage } from "#src/routes/settings/users/detail";
import { UserGroupDetailsRoutePath } from "#src/routes/settings/users/groups/[groupId]/details";
import { useQuery } from "@tanstack/react-query";
import {
  Button,
  DataTable,
  DataTablePanel,
  HeaderType,
  Pill,
  StorageKeys,
  useFilters,
} from "@validereinc/common-components";
import {
  GetListRequestType,
  SortDirection,
  UserType,
  UsersAdapter,
} from "@validereinc/domain";
import { toStartCaseString } from "@validereinc/utilities";
import React from "react";

export const UserListTablePanel = ({
  filterConfigStorageKey,
  tableConfigStorageKey,
}: StorageKeys) => {
  const {
    claims: {
      user: { id: currentUserId },
    },
  } = useAuthenticatedContext();
  const navigate = useNavigate();
  const [filters] = useFilters(filterConfigStorageKey);
  const [tableState, updateTableState] = useTableSortingAndPagination(
    {
      sortBy: "name",
      sortDirection: SortDirection.ASCENDING,
    },
    filters
  );
  const queryPayload: GetListRequestType<Partial<UserType>> = {
    page: tableState.page,
    pageSize: tableState.pageSize,
    sortBy: tableState.sortBy,
    sortDirection: tableState.sortDirection,
    filters,
  };

  const users = useQuery(
    ["users", queryPayload],
    () => UsersAdapter.getList(queryPayload),
    {
      staleTime: 3 * 60 * 1000,
    }
  );

  const headers: Array<HeaderType<UserType>> = [
    {
      label: "Name",
      key: "name",
      isSortable: true,
      renderComponent: ({ item }) => {
        const userId = item?.id;

        if (!userId) {
          return null;
        }

        return (
          <RoutingLink
            to={linkToUserDetailPage(userId)}
          >{`${item.name}${currentUserId === userId ? " (You)" : ""}`}</RoutingLink>
        );
      },
    },
    {
      label: "Status",
      key: "status",
      isSortable: true,
      renderComponent: ({ item }) => (
        <DataTable.DataRow.PillCell
          variant={item.status === "active" ? "success" : "default"}
          value={toStartCaseString(item.status)}
        />
      ),
    },
    {
      label: "Email",
      key: "email",
      isSortable: true,
      renderComponent: ({ item }) => item.email,
    },
    {
      label: "Roles",
      key: "roles",
      renderComponent: ({ item }) => {
        if (!item.roles?.length) {
          return "-";
        }

        const rolePillsToRender = item.roles.map((role) => (
          <Pill
            key={role.id}
            variant="default"
          >
            <RoutingLink to={linkToRoleDetailPage(role.id)}>
              {role?.name ?? "-"}
            </RoutingLink>
          </Pill>
        ));

        if (item.roles_count > item.roles.length) {
          rolePillsToRender.push(
            <Pill variant="default">{`+${item.roles_count - item.roles.length} more`}</Pill>
          );
        }

        return <>{rolePillsToRender}</>;
      },
    },
    {
      label: "Groups",
      key: "groups",
      renderComponent: ({ item }) => {
        if (!item.user_groups?.length) {
          return "-";
        }

        const groupPillsToRender = item.user_groups.map((user_group) => (
          <Pill
            key={user_group.id}
            variant="default"
          >
            <RoutingLink
              to={UserGroupDetailsRoutePath.toLink({
                pathParams: { groupId: user_group.id },
              })}
            >
              {user_group?.name ?? "-"}
            </RoutingLink>
          </Pill>
        ));

        if (item.user_groups_count > item.user_groups.length) {
          groupPillsToRender.push(
            <Pill variant="default">{`+${item.user_groups_count - item.user_groups.length} more`}</Pill>
          );
        }

        return <>{groupPillsToRender}</>;
      },
    },
    {
      label: "Created At",
      key: "created_at",
      isSortable: true,
      renderComponent: ({ item }) => (
        <DataTable.DataRow.DateCell
          value={item.created_at}
          withTime
        />
      ),
    },
  ];

  const actionRowWhenNoRowsSelected = [
    <Button
      key="add-user"
      variant="primary"
      onClick={() => navigate({ pathname: linkToCreateUserPage() })}
      iconPosition="left"
      icon="plus-circle"
    >
      Create User
    </Button>,
  ];
  return (
    <DataTablePanel
      storageKey={tableConfigStorageKey}
      actionRowWhenNoRowsSelected={actionRowWhenNoRowsSelected}
      dataTableProps={{
        headers,
        items: users.data?.data ?? [],
        isLoading: users.isLoading,
        pagination: {
          page: tableState.page,
          pageSize: tableState.pageSize,
          total: users.data?.total_entries,
        },
        sorting: {
          sortBy: tableState.sortBy,
          sortDirection: tableState.sortDirection,
        },
        onPaginationChange: updateTableState,
        onSortChange: updateTableState,
      }}
      panelProps={{ title: "Users" }}
    />
  );
};
