import useLocalization from "#src/hooks/useLocalization";
import {
  Accordion,
  EmptyState,
  Icon,
  KeyValueTileGrid,
  Panel,
} from "@validereinc/common-components";
import { AssetGroupAssetType } from "@validereinc/domain";
import React from "react";

export const SelectedAssetsPanel = ({
  selectedOrigins,
  selectedDestinations,
  removeAssetFromFlow,
}: {
  selectedOrigins: AssetGroupAssetType[];
  selectedDestinations: AssetGroupAssetType[];
  removeAssetFromFlow: (
    asset: AssetGroupAssetType,
    isUpstream: boolean
  ) => void;
}) => {
  const { localize } = useLocalization();

  const removeOrigin = (item: AssetGroupAssetType) => {
    removeAssetFromFlow(item, true);
  };

  const removeDestination = (item: AssetGroupAssetType) => {
    removeAssetFromFlow(item, false);
  };

  return (
    <Panel
      title="Selected Assets"
      isFluidY={false}
    >
      <Accordion defaultActiveKeys={["origin", "destination"]}>
        <Accordion.AccordionPanel
          dataKey="origin"
          title="Origin"
        >
          {selectedOrigins?.length ? (
            <KeyValueTileGrid
              data={selectedOrigins.map(({ id, name, asset_type }) => ({
                title: `${localize(asset_type)} Name`,
                value: name,
                action: {
                  onClick: () => removeOrigin({ id, asset_type }),
                  variant: "outline",
                  icon: "minus-circle",
                },
              }))}
            />
          ) : (
            <EmptyState
              title="No origin(s) selected"
              icon={<Icon variant="stack" />}
              suggestion="Add asset(s) as origin from the table."
            />
          )}
        </Accordion.AccordionPanel>

        <Accordion.AccordionPanel
          dataKey="destination"
          title="Destination"
        >
          {selectedDestinations?.length ? (
            <KeyValueTileGrid
              data={selectedDestinations.map(({ id, name, asset_type }) => ({
                title: `${localize(asset_type)} Name`,
                value: name,
                action: {
                  onClick: () => removeDestination({ id, asset_type }),
                  variant: "outline",
                  icon: "minus-circle",
                },
              }))}
            />
          ) : (
            <EmptyState
              title="No destination(s) selected"
              icon={<Icon variant="stack" />}
              suggestion="Add asset(s) as destination from the table."
            />
          )}
        </Accordion.AccordionPanel>
      </Accordion>
    </Panel>
  );
};
