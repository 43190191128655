import { RoutingLink } from "#src/batteries-included-components/RoutingLink";
import { useTableSortingAndPagination } from "#src/components/Redux/reducers/tableStateReducer";
import { linkToAssetDetailPage } from "#src/utils/links";
import { useQuery } from "@tanstack/react-query";
import useLocalization from "#src/hooks/useLocalization";
import {
  AssetAdapter,
  AssetSchemaType,
  AssetType,
  AssetTypeType,
} from "@validereinc/domain";
import {
  DataTable,
  HeaderType,
  KeyValuePanel,
  Panel,
} from "@validereinc/common-components";
import React from "react";

const useDisplayString = (assetType: AssetTypeType | undefined) => {
  const { localize } = useLocalization();
  switch (assetType) {
    case AssetType.FACILITY:
      return localize("facility_plural");
    case AssetType.EQUIPMENT:
      return localize("equipment_plural");
    case AssetType.DEVICE:
      return localize("device_plural");
    case AssetType.FLOW:
      return localize("flow_plural");
    case AssetType.ASSET_GROUP:
      return localize("asset_group_plural");
    default:
      return localize("asset_plural");
  }
};

export const WorkflowTemplateAssetTable = ({
  assetType,
  assetsQueryFilters,
}: {
  assetType?: AssetTypeType;
  assetsQueryFilters?: Parameters<typeof AssetAdapter.getList>[0]["filters"];
}) => {
  const [tableState, updateTableState] = useTableSortingAndPagination({
    sortBy: "id",
    sortDirection: "asc",
  });
  const assetsQuery = useQuery({
    queryKey: ["assets", assetsQueryFilters, tableState],
    queryFn: () =>
      AssetAdapter.getList({
        page: tableState.page,
        pageSize: tableState.pageSize,
        sortBy: tableState.sortBy,
        sortDirection: tableState.sortDirection,
        filters: {
          ...assetsQueryFilters,
          isAlreadyFormatted: true,
        },
      }),
    enabled: Boolean(assetsQueryFilters),
  });
  const assetDisplayString = useDisplayString(assetType);

  const headers: Array<HeaderType<AssetSchemaType>> = [
    {
      label: "Name",
      key: "name",
      isSortable: false,
      renderComponent: ({ item }) => (
        <RoutingLink to={linkToAssetDetailPage(item.asset_type, item.id)}>
          {item.name}
        </RoutingLink>
      ),
    },
  ];

  if (!assetsQueryFilters) {
    return null;
  }

  return (
    <Panel
      title={`Applicable ${assetDisplayString}`}
      isFluidY={false}
    >
      <KeyValuePanel
        panelKeyValueListProps={{
          maxRowCount: 1,
          variant: "shaded",
        }}
        panelProps={{
          title: "",
          isFluidY: false,
          style: {
            marginBottom: 8,
            marginTop: 8,
            padding: 0,
            border: "none",
            backgroundColor: "none",
          },
        }}
        panelMaxColumnCount={2}
        panelKeyValueListContainerProps={{
          style: { padding: 0 },
        }}
        data={[
          {
            title: `# of ${assetDisplayString}`,
            value: assetsQuery.data?.total_entries ?? 0,
          },
        ]}
      />
      <DataTable
        headers={headers}
        items={assetsQuery.data?.data ?? []}
        isLoading={assetsQuery.isLoading}
        pagination={{
          pageSize: tableState.pageSize,
          page: tableState.page,
          total: assetsQuery.data?.total_entries,
          onlyShowPageControls: true,
        }}
        onSortChange={updateTableState}
        onPaginationChange={updateTableState}
      />
    </Panel>
  );
};
