import { useTableSortingAndPagination } from "#redux/reducers/tableStateReducer";
import {
  WorkflowsTableFilterArea,
  WorkflowsTableFilterSchemaKeys,
  WorkflowsTableTitleDecorationFilterArea,
  type WorkflowsTableFiltersType,
  type WorkflowsViewFiltersType,
} from "#src/batteries-included-components/FilterAreas/WorkflowsFilterAreas";
import { AssetTypeSelection } from "#src/batteries-included-components/FilterAreas/assetFilters.helpers";
import { WorkflowStatusToPillVariantMap } from "#src/batteries-included-components/Panels/TablePanels/WorkflowsTablePanel/WorkflowsTablePanel.helpers";
import { RoutingLink } from "#src/batteries-included-components/RoutingLink";
import { useListWorkflows } from "#src/components/hooks/adapters/useWorkflows";
import useLocalization from "#src/hooks/useLocalization";
import { useSessionStickyState } from "#src/hooks/useStickyState";
import { linkToFacilityDetail } from "#src/routes/organization/facilities/[facilityId]";
import { WorkflowDetailsRoutePath } from "#src/routes/workflows/all/[workflowId]";
import { WorkflowCategoryDetailsRoutePath } from "#src/routes/workflows/categories/[workflowCategoryId]";
import { WorkflowTemplateDetailsRoutePath } from "#src/routes/workflows/templates/[workflowTemplateId]";
import { linkToAssetDetailPage } from "#src/utils/links";
import {
  DataTable,
  DataTablePanel,
  HeaderType,
  StorageKeys,
} from "@validereinc/common-components";
import {
  AssetType,
  SortDirection,
  WorkflowAdapter,
  WorkflowType,
} from "@validereinc/domain";
import { toFlattenedObject, toStartCaseString } from "@validereinc/utilities";
import React from "react";

export type WorkflowsTablePanelProps = {
  /** certain columns are turned off when this table panel is used within the workflow template details page */
  isWithinWorkflowTemplateDetails?: boolean;
  /** optionally bypass filters set in session storage which are fetched through useFilters() */
  overrideFilters?: {
    templateId?: string;
  };
} & StorageKeys;

const getAssetFilters = ({
  assetType,
  assetName,
  facilityId,
  equipmentId,
  deviceId,
  flowId,
  assetGroupId,
  workflow,
}: Partial<WorkflowsTableFiltersType & WorkflowsViewFiltersType>) => {
  const isValidId = (assetIds: string[] | undefined) => !!assetIds?.length;

  switch (assetType) {
    case AssetTypeSelection.FACILITY:
      return {
        ...(assetName ? { "facility.name": assetName } : {}),
        ...(isValidId(facilityId)
          ? { "facility.id": facilityId }
          : { "workflow_template.asset_type": assetType }),
        ...(workflow?.facility
          ? toFlattenedObject(workflow.facility, {
              prefix: "facility",
            })
          : {}),
      };
    case AssetTypeSelection.EQUIPMENT:
      return {
        ...(assetName ? { "equipment.name": assetName } : {}),
        ...(isValidId(equipmentId)
          ? { "equipment.id": equipmentId }
          : { "workflow_template.asset_type": assetType }),
        ...(workflow?.equipment
          ? toFlattenedObject(workflow.equipment, {
              prefix: "equipment",
            })
          : {}),
      };
    case AssetTypeSelection.DEVICE:
      return {
        ...(assetName ? { "device.name": assetName } : {}),
        ...(isValidId(deviceId)
          ? { "device.id": deviceId }
          : { "workflow_template.asset_type": assetType }),
        ...(workflow?.device
          ? toFlattenedObject(workflow.device, {
              prefix: "device",
            })
          : {}),
      };
    case AssetTypeSelection.FLOW:
      return {
        ...(assetName ? { "flow.name": assetName } : {}),
        ...(isValidId(flowId)
          ? { "flow.id": flowId }
          : { "workflow_template.asset_type": assetType }),
        ...(workflow?.flow
          ? toFlattenedObject(workflow.flow, {
              prefix: "flow",
            })
          : {}),
      };
    case AssetTypeSelection.ASSET_GROUP:
      return {
        ...(assetName ? { "asset_group.name": assetName } : {}),
        ...(isValidId(assetGroupId)
          ? { "asset_group.id": assetGroupId }
          : { "workflow_template.asset_type": assetType }),
        ...(workflow?.asset_group
          ? toFlattenedObject(workflow.asset_group, {
              prefix: "asset_group",
            })
          : {}),
      };
    default:
      return {};
  }
};

export const WorkflowsTablePanel = ({
  isWithinWorkflowTemplateDetails = false,
  overrideFilters,
  tableConfigStorageKey,
  viewConfigStorageKey,
  filterConfigStorageKey,
}: WorkflowsTablePanelProps) => {
  const [tableFilters] = useSessionStickyState<WorkflowsTableFiltersType>(
    {} as WorkflowsTableFiltersType,
    filterConfigStorageKey
  );
  const [viewFilters] = useSessionStickyState<WorkflowsViewFiltersType>(
    {} as WorkflowsViewFiltersType,
    viewConfigStorageKey
  );
  const { localize } = useLocalization();
  const { categoryId, templateId } = viewFilters;
  const {
    [WorkflowsTableFilterSchemaKeys.date_range]: dateRange,
    [WorkflowsTableFilterSchemaKeys.status]: status,
    [WorkflowsTableFilterSchemaKeys.name]: name,
  } = tableFilters;

  const filters = {
    created_at: dateRange,
    ...(status ? { status } : {}),
    "workflow_category.id": categoryId,
    "workflow_template.id": overrideFilters?.templateId ?? templateId,
    ...(name ? { name } : {}),
    ...getAssetFilters({ ...tableFilters, ...viewFilters }),
  };

  const [tableState, updateTableState] = useTableSortingAndPagination(
    {
      sortBy: "created_at",
      sortDirection: SortDirection.DESCENDING,
    },
    filters
  );

  const reqPayload: Parameters<typeof WorkflowAdapter.getList>[0] = {
    page: tableState.page,
    pageSize: tableState.pageSize,
    sortBy: tableState.sortBy,
    sortDirection: tableState.sortDirection,
    filters,
  };

  const { data, isLoading } = useListWorkflows(reqPayload);

  const headers: Array<HeaderType<WorkflowType>> = [
    {
      label: "Name",
      key: "id",
      isSortable: false,
      renderComponent: ({ item }: { item: WorkflowType }) => {
        return (
          <RoutingLink
            to={WorkflowDetailsRoutePath.toLinkParts({
              pathParams: {
                workflowId: item.id,
              },
            })}
          >
            {item.name}
          </RoutingLink>
        );
      },
    },
    {
      label: "Status",
      key: "status",
      isSortable: true,
      renderComponent: ({ item }) => (
        <DataTable.DataRow.PillCell
          variant={WorkflowStatusToPillVariantMap[item.status] || "default"}
          value={toStartCaseString(item.status)}
        />
      ),
    },
    {
      label: "Due Date",
      key: "due_date",
      isSortable: true,
      renderComponent: ({ item }) => (
        <DataTable.DataRow.DateCell
          value={item.due_date}
          convertToUTC={false}
        />
      ),
    },
    {
      label: "Asset",
      isSortable: false,
      key: "asset.name",
      renderComponent: ({ item }: { item: WorkflowType }) =>
        item.asset ? (
          <RoutingLink
            to={linkToAssetDetailPage(item.asset.asset_type, item.asset.id)}
          >
            {item.asset.name}
          </RoutingLink>
        ) : (
          "-"
        ),
    },
    {
      label: "Asset Type",
      isSortable: false,
      key: "asset.type",
      renderComponent: ({ item }: { item: WorkflowType }) =>
        item.asset ? localize(item.asset.asset_type) : "-",
    },
    {
      label: `Associated ${localize("facility")}`,
      isSortable: false,
      key: "facility.name",
      renderComponent: ({ item }: { item: WorkflowType }) =>
        item.asset &&
        item.asset.asset_type !== AssetType.FACILITY &&
        item.facility ? (
          <RoutingLink to={linkToFacilityDetail(item.facility_id)}>
            {item.facility.name}
          </RoutingLink>
        ) : (
          "-"
        ),
    },
    ...(!isWithinWorkflowTemplateDetails
      ? [
          {
            label: "Category",
            key: "workflow_template.workflow_category.name",
            isSortable: false,
            renderComponent: ({ item }: { item: WorkflowType }) => (
              <RoutingLink
                to={WorkflowCategoryDetailsRoutePath.toLinkParts({
                  pathParams: {
                    workflowCategoryId:
                      item.workflow_template.workflow_category.id,
                  },
                })}
              >
                {item.workflow_template.workflow_category.name}
              </RoutingLink>
            ),
          },
          {
            label: "Template",
            key: "workflow_template.name",
            isSortable: false,
            renderComponent: ({ item }: { item: WorkflowType }) => (
              <RoutingLink
                to={WorkflowTemplateDetailsRoutePath.toLinkParts({
                  pathParams: {
                    workflowTemplateId: item.workflow_template_id,
                  },
                })}
              >
                {item.workflow_template.name}
              </RoutingLink>
            ),
          },
        ]
      : []),
    {
      key: "created_at",
      label: "Created At",
      isSortable: true,
      renderComponent: ({ item }) => (
        <DataTable.DataRow.DateCell
          value={item.created_at}
          convertToUTC={false}
        />
      ),
    },
    {
      key: "updated_at",
      label: "Updated At",
      isSortable: true,
      renderComponent: ({ item }) => (
        <DataTable.DataRow.DateCell
          value={item.updated_at}
          convertToUTC={false}
        />
      ),
    },
  ];

  return (
    <DataTablePanel
      storageKey={tableConfigStorageKey}
      panelProps={{
        title: "Workflows",
        isFluidY: false,
        titleDecorator: (
          <WorkflowsTableTitleDecorationFilterArea
            filterConfigStorageKey={filterConfigStorageKey}
          />
        ),
      }}
      filterComponent={
        <WorkflowsTableFilterArea
          viewConfigStorageKey={viewConfigStorageKey}
          filterConfigStorageKey={filterConfigStorageKey}
        />
      }
      dataTableProps={{
        variant: "simplicity-first",
        headers,
        items: data?.data ?? [],
        isLoading,
        sorting: {
          sortBy: tableState.sortBy,
          sortDirection: tableState.sortDirection,
        },
        pagination: {
          page: tableState.page,
          pageSize: tableState.pageSize,
          total: data?.total_entries,
        },
        onSortChange: updateTableState,
        onPaginationChange: updateTableState,
      }}
    />
  );
};
