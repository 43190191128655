import { useParams, useSearchParams } from "#routers/hooks";
import { WorkflowFormSubmissionsTablePanel } from "#src/batteries-included-components/Panels/TablePanels/WorkflowFormSubmissionsTablePanel";
import { WorkflowTasksTablePanel } from "#src/batteries-included-components/Panels/TablePanels/WorkflowTasksTablePanel";
import { useGetOneFormSubmission } from "#src/components/hooks/adapters/useFormSubmissions";
import { useGetOneWorkflow } from "#src/components/hooks/adapters/useWorkflows";
import { useStorageKey } from "#src/hooks/useStorageKey";
import { useBreadcrumbsFromRoute } from "#src/utils/route";
import {
  Button,
  ButtonToggleGroup,
  Page,
} from "@validereinc/common-components";
import classNames from "classnames/bind";
import React, { useEffect, useState } from "react";
import { WorkflowDetailsRoutePath } from ".";
import { WorkflowAnsersKeyValuePanel } from "./WorkflowAnswersKeyValuePanel";
import { WorkflowDetailDeleteDialog } from "./WorkflowDetailDeleteDialog";
import { WorkflowDetailKeyValuePanel } from "./WorkflowDetailKeyValuePanel";
import styles from "./WorkflowDetailPage.module.scss";
import { WorkflowDetailStatusDialog } from "./WorkflowDetailStatusDialog";
import { WorkflowDetailStatusPanel } from "./WorkflowDetailStatusPanel";

const cx = classNames.bind(styles);

export const WorkflowDetailPage = () => {
  const { workflowId } = useParams<{
    workflowId: string;
  }>();
  const tasksStorageKeys = useStorageKey("workflows-detail-tasks");
  const formsStorageKeys = useStorageKey("workflows-detail-forms");

  const [{ context }, setSearchParams] = useSearchParams<{
    context: "actions" | "submissions";
  }>();

  useEffect(() => {
    if (context) return;
    setSearchParams({ context: "actions" });
  }, [context]);

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [isStatusModalOpen, setIsStatusModalOpen] = useState<boolean>(false);

  const workflowQuery = useGetOneWorkflow(
    { id: workflowId },
    { enabled: Boolean(workflowId) }
  );
  const workflow = workflowQuery.data?.data;
  const workflowIsLoading = workflowQuery.isLoading;

  const workflowWithTrigger = { ...workflow };

  const [breadcrumbs] = useBreadcrumbsFromRoute(WorkflowDetailsRoutePath, {
    "/detail": {
      title: `${workflow?.name} - ${workflow?.id}`,
    },
  });

  const formSubmissionId = workflow?.form_trigger?.form_submission_id;
  const { data: formSubmission, isLoading: formSubmissionIsLoading } =
    useGetOneFormSubmission(
      { id: formSubmissionId },
      {
        enabled: !!formSubmissionId,
      }
    );

  const triggeringWorkflowId =
    workflowWithTrigger.workflow_trigger?.triggering_workflow_id ?? "";
  const triggeringWorkflowQuery = useGetOneWorkflow(
    { id: triggeringWorkflowId },
    { enabled: Boolean(triggeringWorkflowId) }
  );
  const triggeringWorkflow = triggeringWorkflowQuery.data?.data;
  const triggeringWorkflowIsLoading = triggeringWorkflowQuery.isLoading;

  if (formSubmission) {
    workflowWithTrigger.form_trigger.form_submission = formSubmission;
  }
  if (workflowWithTrigger.workflow_trigger && triggeringWorkflow) {
    workflowWithTrigger.workflow_trigger.triggering_workflow_name =
      triggeringWorkflow.name;
  }

  const handleDelete = () => {
    setIsDeleteModalOpen(true);
  };

  const handleChangeStatus = () => {
    setIsStatusModalOpen(true);
  };

  const { workflow_template } = workflow ?? {};

  return (
    <>
      <Page
        title={workflow?.name ?? WorkflowDetailsRoutePath.title}
        category={WorkflowDetailsRoutePath.subtitle}
        breadcrumbs={breadcrumbs}
        isLoading={
          workflowIsLoading ||
          (!!formSubmissionId && formSubmissionIsLoading) ||
          (!!triggeringWorkflowId && triggeringWorkflowIsLoading)
        }
        actionRow={[
          <Button
            key="secondary"
            variant="error-outline"
            onClick={handleDelete}
          >
            Delete
          </Button>,
          <Button
            key="primary"
            variant="primary"
            onClick={handleChangeStatus}
          >
            Change Status
          </Button>,
        ]}
      >
        <div className={cx("topContainer")}>
          <div className={cx("firstColumn")}>
            <WorkflowDetailStatusPanel workflowDetails={workflow} />
          </div>
          <div className={cx("secondColumn")}>
            <WorkflowDetailKeyValuePanel
              workflowDetails={workflowWithTrigger}
            />
            {workflowWithTrigger?.asset_trigger ||
            workflowWithTrigger?.workflow_trigger ||
            workflowWithTrigger?.form_trigger ? (
              <WorkflowAnsersKeyValuePanel
                workflowDetails={workflowWithTrigger}
              />
            ) : null}
          </div>
        </div>
        <div className={cx("filterContainer")}>
          <ButtonToggleGroup
            className={cx("contentSwitch")}
            items={[
              { label: "Actions", dataKey: "actions" },
              { label: "Form Submissions", dataKey: "submissions" },
            ]}
            activeKey={context}
            onChange={(newStatus: "actions" | "submissions") =>
              setSearchParams({ context: newStatus })
            }
          />
        </div>
        <div>
          {context === "actions" ? (
            <WorkflowTasksTablePanel
              {...tasksStorageKeys}
              isWithinWorkflowDetail
              isDisplayingActions
              taskFilters={{ workflow_id: workflowId }}
            />
          ) : (
            <WorkflowFormSubmissionsTablePanel
              {...formsStorageKeys}
              workflowId={workflowId}
            />
          )}
        </div>
      </Page>

      {workflow_template ? (
        <WorkflowDetailDeleteDialog
          setIsDeleteModalOpen={setIsDeleteModalOpen}
          isDeleteModalOpen={isDeleteModalOpen}
          workflowId={workflowId}
          workflowCategoryId={workflow_template.workflow_category.id}
          workflowTemplateId={workflow_template.id}
        />
      ) : null}

      {workflow ? (
        <WorkflowDetailStatusDialog
          setIsStatusModalOpen={setIsStatusModalOpen}
          isStatusModalOpen={isStatusModalOpen}
          workflowDetails={workflow}
        />
      ) : null}
    </>
  );
};
