import { RecordsViewFilterArea } from "#src/batteries-included-components/FilterAreas/RecordsFilterAreas";
import { DefaultRecordValueConfigTablePanel } from "#src/batteries-included-components/Panels/TablePanels/DefaultRecordValueConfigTablePanel";
import { RecordsTablePanel } from "#src/batteries-included-components/Panels/TablePanels/RecordsTablePanel";
import { useStorageKey } from "#src/hooks/useStorageKey";
import { ButtonToggleGroup } from "@validereinc/common-components";
import { AssetType } from "@validereinc/domain";
import React, { useState } from "react";

export const FacilityRecordsTabContent = () => {
  const storageKeys = useStorageKey("facilities-detail-records");
  const [mode, setMode] = useState<"records" | "automation">("records");

  return (
    <>
      <div
        style={{
          marginRight: 8,
          display: "flex",
          gap: 8,
          marginBottom: 16,
          alignItems: "center",
        }}
      >
        <ButtonToggleGroup
          activeKey={mode}
          items={[
            { label: "Records", dataKey: "records" },
            { label: "Default Automations", dataKey: "automation" },
          ]}
          onChange={(val) => setMode(val)}
        />
        <RecordsViewFilterArea
          viewConfigStorageKey={storageKeys.viewConfigStorageKey}
          isPeriodDateRange={true}
          isPeriodAvailable={mode === "records"}
        />
      </div>
      {mode === "records" && (
        <RecordsTablePanel
          type={AssetType.FACILITY}
          {...storageKeys}
        />
      )}
      {mode === "automation" && (
        <DefaultRecordValueConfigTablePanel
          type={AssetType.FACILITY}
          {...storageKeys}
        />
      )}
    </>
  );
};
