import {
  TransactionDetailsType,
  TransactionGetListFilterType,
  TransactionSummaryType,
  TransactionType,
} from "../schemas";
import {
  DataPlatformResponse,
  GetListRequestType,
  ResourceServiceType,
  getPaginatedData,
  getSortedData,
} from "../util";
import { restAPI } from "./api";

const timestampHasTimezone = (timestamp: string) => {
  // Assume the only stated timezone will be UTC
  const timezoneDelimiters = ["z", "Z"];
  return timezoneDelimiters.some((timezoneChar) =>
    timestamp.includes(timezoneChar)
  );
};

// https://validere.atlassian.net/browse/CHB-3077
// Conditionally assert timezone is UTC
// TODO: Delete this later
const enrichTransactionTimestamp = (
  transaction: TransactionType
): TransactionType => ({
  ...transaction,
  created_at: timestampHasTimezone(transaction.created_at)
    ? transaction.created_at
    : `${transaction.created_at}Z`,
});

// TODO: Refactor sink/download types to resource types
type TransactionServiceType = Pick<
  ResourceServiceType<TransactionType>,
  "getList"
> &
  Pick<ResourceServiceType<TransactionType[]>, "getOne"> & {
    sink: {
      getOne: (inputs: {
        transactionId: string;
        hasTransformed?: boolean;
      }) => Promise<any>;
    };
    download: {
      getOne: ({
        transactionId,
        hasTransformed,
      }: {
        transactionId: string;
        hasTransformed?: boolean;
      }) => Promise<any>;
    };
    retry: {
      createOne: (params: {
        transactionId: string;
        transactionRetryStage?: "transformation" | "ingestion";
      }) => Promise<DataPlatformResponse<TransactionType, "Transaction">>;
    };
    details: Pick<ResourceServiceType<TransactionDetailsType[]>, "getOne">;
  };

export const TransactionAdapter: TransactionServiceType = {
  /**
   * Get a list of transactions
   * @see {@link https://sources-staging.dataplatform.validere.xyz/docs#/transactions/list_all_transactions_transactions_get}
   */
  getList: async ({
    filters,
    page,
    pageSize,
    sortBy,
    sortDirection,
  }: GetListRequestType<TransactionGetListFilterType>) => {
    const response = await restAPI.dataPlatformSourceAPI.GET<
      DataPlatformResponse<TransactionType, "Transaction">
    >({
      endpoint: "/transactions",
      query: {
        ...filters,
      },
    });

    return getPaginatedData<TransactionType>(
      getSortedData(
        response.data.map(enrichTransactionTimestamp),
        sortBy as keyof TransactionType,
        sortDirection
      ),
      page,
      pageSize
    );
  },
  /**
   * Get a single transaction but its UUID
   * @see {@link https://sources-staging.dataplatform.validere.xyz/docs#/transactions/get_transaction_by_id_transactions__transaction_id__get}
   */
  getOne: ({ id, meta: { history } = {} }) =>
    restAPI.dataPlatformSourceAPI
      .GET<DataPlatformResponse<TransactionType, "Transaction">>({
        endpoint: `/transactions/${id}`,
        query: {
          ...(history ? { history } : {}),
        },
      })
      .then((resp) => ({ data: resp.data.map(enrichTransactionTimestamp) })),
  download: {
    getOne: ({
      transactionId,
      hasTransformed,
    }: {
      transactionId: string;
      hasTransformed?: boolean;
    }) =>
      restAPI.dataPlatformSourceAPI.GET({
        endpoint: `/transactions/${transactionId}/download`,
        query: {
          version: hasTransformed ? "transformed" : "submitted",
        },
        headers: {
          Accept: "*/*",
        },
        responseType: "blob",
      }),
  },
  retry: {
    /**
     * Retry a single transaction
     * @see {@link https://sources-staging.dataplatform.validere.xyz/docs#/transactions/retry_transaction_by_id_transactions__transaction_id__retry_post}
     */
    createOne: ({ transactionId, transactionRetryStage }) =>
      restAPI.dataPlatformSourceAPI.POST({
        endpoint: `/transactions/${transactionId}/retry`,
        query: {
          ...(transactionRetryStage
            ? { transaction_retry_stage: transactionRetryStage }
            : {}),
        },
      }),
  },
  sink: {
    /**
     * Get the sink summary for a transaction
     * @see {@link https://sources-staging.dataplatform.validere.xyz/docs#/transactions/get_summary_by_transaction_by_id_transactions__transaction_id__sink_summary_get}
     */
    getOne: ({ transactionId }: { transactionId: string }) =>
      restAPI.dataPlatformSourceAPI
        .GET<TransactionSummaryType>({
          endpoint: `/transactions/${transactionId}/sink/summary`,
        })
        .then((data) => ({
          transaction_id: transactionId,
          sink: data,
        })),
  },
  details: {
    getOne: async ({ id, meta: { accept } = {} }) => {
      /** Return a blob if a specific return type is requested */
      if (accept) {
        return restAPI.dataPlatformSourceAPI.GET<
          DataPlatformResponse<TransactionDetailsType, "TransactionDetails">
        >({
          endpoint: `/transactions/${id}/details`,
          headers: {
            accept,
          },
          responseType: "blob",
        });
      }

      const { data } = await restAPI.dataPlatformSourceAPI.GET<
        DataPlatformResponse<TransactionDetailsType, "TransactionDetails">
      >({
        endpoint: `/transactions/${id}/details`,
        headers: {
          accept: "application/json",
        },
      });
      return { data };
    },
  },
};
