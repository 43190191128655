import {
  CREATE_FORM_CATEGORY_DIALOG_TITLE,
  CreateFormCategoryDialog,
} from "#src/batteries-included-components/Dialogs/CreateFormCategoryDialog";
import { FormCategoriesPanel } from "#src/batteries-included-components/Panels/CardPanels/FormCategoriesPanel";
import {
  useHasPermission,
  useIsFeatureAvailable,
} from "#src/contexts/AuthenticatedContext.helpers";
import { useStorageKey } from "#src/hooks/useStorageKey";
import { FORMS_BREADCRUMB } from "#src/routes/forms";
import { FORMS_CATEGORIES_BREADCRUMB } from "#src/routes/forms/categories";
import { Button, Page } from "@validereinc/common-components";
import React, { useState } from "react";

export const FormCategoryListPage = () => {
  const [canWriteFormCategories] = useHasPermission("form_categories:write");
  const [isCreatePageAvailable] = useIsFeatureAvailable({
    featureFlagQuery: "forms:schemas_create",
  });
  const [isCreateFormCategoryDialogOpen, setIsCreateFormCategoryDialogOpen] =
    useState(false);
  const storageKeys = useStorageKey("form-categories-page");

  const onOpenCreateFormCategoryDialog = () =>
    setIsCreateFormCategoryDialogOpen(true);

  const onCloseCreateFormCategoryDialog = () =>
    setIsCreateFormCategoryDialogOpen(false);

  return (
    <>
      <Page
        title="Categories"
        breadcrumbs={[FORMS_BREADCRUMB, FORMS_CATEGORIES_BREADCRUMB]}
        actionRow={
          isCreatePageAvailable && canWriteFormCategories
            ? [
                <Button
                  key="create-form-category-action"
                  variant="primary"
                  onClick={onOpenCreateFormCategoryDialog}
                >
                  {CREATE_FORM_CATEGORY_DIALOG_TITLE}
                </Button>,
              ]
            : undefined
        }
      >
        <FormCategoriesPanel {...storageKeys} />
      </Page>

      <CreateFormCategoryDialog
        isOpen={isCreateFormCategoryDialogOpen}
        onClose={onCloseCreateFormCategoryDialog}
      />
    </>
  );
};
