import { AssertIsAfterOrEqualDate } from "#utils/assert";
import { DateInput, Form } from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React from "react";

export const EditCalibrationForm = ({
  onSubmit,
  formMethods,
  isLoading,
  getNextCheckDateFromLast,
}) => (
  <Form
    className="editCalibrationForm"
    onSubmit={onSubmit}
    {...formMethods}
  >
    <DateInput
      disabled={isLoading}
      name="lastChecked"
      label="Last Checked Date"
      onChange={(e) =>
        formMethods.setValue("nextCheck", getNextCheckDateFromLast(e))
      }
      showIcon
      isRequired
    />
    <DateInput
      name="nextCheck"
      label="Next Check Date"
      dayPickerProps={{ disabledDays: { before: new Date() } }}
      validate={{
        laterThanToday: (date) => {
          return (
            (!!date && AssertIsAfterOrEqualDate(date, new Date(), "date")) ||
            "Date must be later than today"
          );
        },
      }}
      disabled={isLoading}
      isRequired
      showIcon
    />
  </Form>
);

EditCalibrationForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  formMethods: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  getNextCheckDateFromLast: PropTypes.func.isRequired,
};

export default EditCalibrationForm;
