import config from "#src/config";
import { AuthenticatedContextActiveAuthVersionType } from "#src/contexts/AuthenticatedContext";
import {
  captureConsoleIntegration,
  debugIntegration,
} from "@sentry/integrations";
import {
  addBreadcrumb,
  captureException,
  flush,
  getCurrentScope,
  init,
  reactRouterV5BrowserTracingIntegration,
  withScope,
} from "@sentry/react";
import {
  BaseError,
  HTTPRequestError,
  HTTPResponseError,
  UserType,
} from "@validereinc/domain";
import {
  ExceptionContextKeys,
  LifecycleEvent,
  LifecycleEventCategory,
  isPrimitiveString,
  isPrimitiveStringArray,
} from "./exception.helpers";

/**
 * Boot the exception handler utilities
 */
const setup = ({
  dsn,
  environment,
  version,
  isDebuggingEnabled,
}: {
  dsn: string;
  environment: string;
  version: string;
  isDebuggingEnabled?: boolean;
}) => {
  init({
    dsn,
    environment,
    release: version,
    integrations: [
      ...(isDebuggingEnabled ? [debugIntegration()] : []),
      reactRouterV5BrowserTracingIntegration({
        history,
        enableInp: true,
      }),
      captureConsoleIntegration({
        levels: ["warn"],
      }),
    ],
    // IMPROVE: we should enable distributed tracing again when our BE APIs support it https://docs.sentry.io/platforms/javascript/guides/react/performance/
    tracesSampleRate: 0,
    // IMPROVE: we should enable distributed tracing again when our BE APIs support it https://docs.sentry.io/platforms/javascript/guides/react/distributed-tracing/
    tracePropagationTargets: [],
    beforeBreadcrumb: (breadcrumb, hint) => {
      switch (breadcrumb.category) {
        case "ui.click": {
          const { target } = hint?.event ?? {};

          breadcrumb.message =
            target instanceof HTMLElement
              ? `tag:${target.tagName}|role:${target.role ?? "unknown"}|text:${target.textContent && target.textContent.length > 25 ? `${target.textContent.substring(0, 25)}` : target.textContent ?? target.innerText ?? "unknown"}|label:${target.ariaLabel ?? (target.title || "unknown")}`
              : breadcrumb.message;
          break;
        }
      }

      return breadcrumb;
    },
  });
};

const reportException = (
  /** the error instance */
  err: unknown,
  /** the severity of the error */
  severity: LifecycleEvent["level"] = "error",
  {
    sourceComponent,
    hint,
  }: {
    /** the component stringified stack trace or the name of the component */
    sourceComponent?: string;
    /** optional developer-friendly contextual details for additional information about the error */
    hint?: string;
  } = {}
) => {
  withScope(function (scope) {
    // Decrease Sentry noise by reporting production logs only:
    if (process.env.NODE_ENV === "development") {
      console.error(err, { sourceComponent, hint });
      scope.setLevel("debug");
    } else {
      scope.setLevel(severity);
    }

    if (sourceComponent) {
      scope.setContext("Framework", {
        sourceComponent,
      });
    }

    if (hint) {
      scope.setContext("Source", {
        hint,
      });
    }

    if (err instanceof BaseError) {
      const isHTTPError =
        err instanceof HTTPResponseError || err instanceof HTTPRequestError;

      // If error is either "Unauthorized (401)", "Forbidden (403)", or "Not Found (404)", report it as a warning:
      if (isHTTPError) {
        if (err.status && [401, 403, 404].includes(err.status)) {
          scope.setLevel("warning");
        }
      }

      scope.setContext("Custom Enrichment", {
        explanation: err.getExplanation(),
        referenceURL: err.referenceURL,
        ...(isHTTPError
          ? {
              endpoint: err.endpoint,
              method: err.method,
              status: err.status,
              ...(err instanceof HTTPResponseError
                ? {
                    responseContentType: err.responseContentType,
                    responseBodyJson: err.responseBodyJson,
                  }
                : {}),
              ...(err instanceof HTTPRequestError
                ? {
                    requestContentType: err.requestContentType,
                    requestBodyJson: err.requestBodyJson,
                    requestQuery: err.requestQuery,
                  }
                : {}),
            }
          : {}),
        cause: err.cause,
      });
      scope.setTags({
        [ExceptionContextKeys.isValidereDefined]: err._isValidereDefined,
        [ExceptionContextKeys.exceptionCode]: err.code,
        ...(isHTTPError
          ? {
              [ExceptionContextKeys.networkRequestEndpoint]: err.endpoint,
            }
          : {}),
      });
    }

    captureException(err);
  });
};

const registerLifecycleEvent = (data: LifecycleEvent) => {
  addBreadcrumb(
    {
      ...data,
      timestamp: new Date().getTime() / 1000,
    },
    data.data
  );
};

const registerAuthenticatedSessionHandshake = (handshakeData: {
  /** the authenticated user's ID */
  id?: string;
  /** is the authenticated user's email verified? */
  isEmailVerified: boolean;
  /** seconds since UNIX epoch */
  sessionStart: number;
}) => {
  if (!handshakeData.id) return;

  const scope = getCurrentScope();

  scope.setUser({
    id: handshakeData.id,
    isEmailVerified: handshakeData.isEmailVerified,
  });
  scope.setTag(ExceptionContextKeys.sessionStart, handshakeData.sessionStart);
};

const registerAuthenticatedSession = (
  authenticatedUserData: Partial<
    Pick<UserType, "id" | "status" | "company_id" | "quicksight">
  > & {
    /** the authentication service(s) in play for the authenticated user */
    activeAuthVersion: AuthenticatedContextActiveAuthVersionType;
    /** the preferred timezone setting on the user's account */
    timezonePreferred?: UserType["timezone"];
  }
) => {
  const scope = getCurrentScope();

  scope.setUser({
    id: authenticatedUserData.id,
    ip_address: "{{auto}}",
    company_id: authenticatedUserData.company_id,
  });
  scope.setTags({
    [ExceptionContextKeys.timezone_preferred]:
      authenticatedUserData.timezonePreferred,
    [ExceptionContextKeys.timezone_actual]:
      Intl.DateTimeFormat().resolvedOptions().timeZone,
    [ExceptionContextKeys.locale_preferred]: config.locale,
    [ExceptionContextKeys.locale_actual]:
      navigator?.language ??
      Intl.DateTimeFormat().resolvedOptions().locale ??
      config.locale,
    [ExceptionContextKeys.company]: authenticatedUserData.company_id,
    [ExceptionContextKeys.hasQuicksightIntegration]:
      authenticatedUserData.quicksight,
    [ExceptionContextKeys.activeAuthVersion]:
      isPrimitiveString(authenticatedUserData.activeAuthVersion) ||
      isPrimitiveStringArray(authenticatedUserData.activeAuthVersion)
        ? isPrimitiveStringArray(authenticatedUserData.activeAuthVersion)
          ? authenticatedUserData.activeAuthVersion.join("-")
          : authenticatedUserData.activeAuthVersion
        : "unknown",
  });
};

/**
 * Get the details of the current registered authenticated session
 * @returns the user session details and any associated metadata that was registered
 */
const getAuthenticatedSession = () => {
  const scope = getCurrentScope();

  return {
    user: scope.getUser(),
    tags: scope.getScopeData().tags,
    contexts: scope.getScopeData().contexts,
  };
};

/**
 * Shutdown the exception handler utilities
 */
const teardown = () => {
  const scope = getCurrentScope();

  return flush(3000).then(() => {
    scope.setUser(null);
    scope.clear();
  });
};

export const ExceptionUtils = {
  setup,
  teardown,
  reportException,
  registerLifecycleEvent,
  registerAuthenticatedSession,
  registerAuthenticatedSessionHandshake,
  getAuthenticatedSession,
  ExceptionContextKeys,
  LifecycleEventCategory,
};
