import { useParams } from "#src/Routers/hooks";
import {
  FormSchemasTableFilterArea,
  FormSchemasTableFilterAreaContent,
} from "#src/batteries-included-components/FilterAreas/FormsFilterAreas";
import { RoutingLink } from "#src/batteries-included-components/RoutingLink";
import { StatusVariants } from "#src/components/Common/SummaryInformation";
import { useTableSortingAndPagination } from "#src/components/Redux/reducers/tableStateReducer";
import { useSessionStickyState } from "#src/hooks/useStickyState";
import { linkToFormCategoryDetails } from "#src/routes/forms/categories/[categoryId]";
import { linkToFormTemplateDetail } from "#src/routes/forms/categories/[categoryId]/templates/[formTemplateId]";
import { useQuery } from "@tanstack/react-query";
import {
  DataTable,
  DataTablePanel,
  HeaderType,
  StorageKeys,
} from "@validereinc/common-components";
import {
  FormSchemaAdapter,
  type FormSchemaType,
  getFilterObject,
  Resources,
  SortDirection,
} from "@validereinc/domain";
import { toStartCaseString } from "@validereinc/utilities";
import React from "react";

export const FormSchemasTablePanel = ({
  tableConfigStorageKey,
  filterConfigStorageKey,
}: StorageKeys) => {
  const [filters] = useSessionStickyState<{
    "form_category.id": string;
    name: string;
  }>({}, filterConfigStorageKey);
  const [tableState, updateTableState] = useTableSortingAndPagination(
    {
      sortBy: "name",
      sortDirection: SortDirection.ASCENDING,
    },
    filters
  );
  const { categoryId } = useParams<{ categoryId: string }>();

  const queryPayload: Parameters<typeof FormSchemaAdapter.getList>[0] = {
    filters: {
      ...getFilterObject(filters),
      "form_category.id": categoryId,
      status: "active",
      isAlreadyFormatted: true,
    },
    page: tableState.page,
    pageSize: tableState.pageSize,
    sortBy: tableState.sortBy,
    sortDirection: tableState.sortDirection,
  };

  const formSchemasQuery = useQuery({
    queryKey: [Resources.FORM_SCHEMA, queryPayload],
    queryFn: () => {
      return FormSchemaAdapter.getList(queryPayload);
    },
  });

  const getStatusPillVariant = (status: FormSchemaType["status"]) => {
    switch (status) {
      case "active":
        return StatusVariants.ACTIVE;
      case "draft":
        return StatusVariants.INFO;
      case "archived":
      default:
        return StatusVariants.INACTIVE;
    }
  };

  const headers: Array<HeaderType<FormSchemaType>> = [
    {
      label: "Name",
      key: "name",
      isSortable: true,
      renderComponent: ({ item }) => {
        return (
          <RoutingLink
            to={linkToFormTemplateDetail(item.form_category_id, item.id)}
          >
            {item.name}
          </RoutingLink>
        );
      },
    },
    {
      label: "Category",
      key: "form_category_id",
      isSortable: true,
      renderComponent: ({ item }) => {
        if (!item.form_category_id) {
          return "-";
        }

        return (
          <RoutingLink to={linkToFormCategoryDetails(item.form_category_id)}>
            {item.form_category?.name ?? "n/a"}
          </RoutingLink>
        );
      },
    },
    {
      label: "Status",
      key: "status",
      isSortable: true,
      renderComponent: ({ item }) => (
        <DataTable.DataRow.PillCell
          variant={getStatusPillVariant(item.status)}
          value={toStartCaseString(item.status)}
        />
      ),
    },
    {
      label: "Description",
      key: "description",
      isSortable: true,
    },
    {
      label: "Last Updated",
      key: "updated_at",
      isSortable: true,
      renderComponent: ({ item }) => (
        <DataTable.DataRow.DateCell
          value={item.updated_at}
          withTime
        />
      ),
    },
  ];

  return (
    <DataTablePanel
      storageKey={tableConfigStorageKey}
      panelProps={{
        title: "Templates",
      }}
      dataTableProps={{
        variant: "simplicity-first",
        headers,
        items: formSchemasQuery.data?.data ?? [],
        isLoading: formSchemasQuery.isLoading,
        sorting: {
          sortBy: tableState.sortBy,
          sortDirection: tableState.sortDirection,
        },
        pagination: {
          page: tableState.page,
          pageSize: tableState.pageSize,
          total: formSchemasQuery.data?.total_entries,
          pageSizeText: "rows per page",
        },
        onSortChange: updateTableState,
        onPaginationChange: updateTableState,
      }}
      filterComponent={
        <FormSchemasTableFilterArea
          filterConfigStorageKey={filterConfigStorageKey}
          filterDrawerContentSlot={<FormSchemasTableFilterAreaContent />}
        />
      }
    />
  );
};
