import { RoutingLink } from "#src/batteries-included-components/RoutingLink";
import {
  useClientSideSortingAndPagination,
  useTableSortingAndPagination,
} from "#src/components/Redux/reducers/tableStateReducer";
import { getPermissionDisplayLabel } from "#src/contexts/AuthenticatedContext.helpers";
import { linkToRoleDetailPage } from "#src/routes/settings/roles-and-permissions/roles/[roleId]/detail";
import { UserDetailContext } from "#src/routes/settings/users/detail/UserDetailContext";
import { useQueries, useQuery } from "@tanstack/react-query";
import {
  DataTable,
  DataTablePanel,
  HeaderType,
  Icon,
  Pill,
} from "@validereinc/common-components";
import type {
  RolePermissionType,
  RoleType,
  UserPermissionType,
} from "@validereinc/domain";
import { RolesAdapter, UsersAdapter } from "@validereinc/domain";
import isBefore from "date-fns/isBefore";
import React, { useContext, useMemo } from "react";

export const UserPermissionsTablePanel = () => {
  const { userDetails: user } = useContext(UserDetailContext) ?? {};

  const permissionsQuery = useQuery({
    queryKey: ["users", user?.data?.id, "permissions"],
    queryFn: ({ queryKey }) => {
      const [_, id] = queryKey;

      if (!id) {
        return;
      }

      return UsersAdapter.permissions.getMany({ userId: id });
    },
    enabled: Boolean(user?.data?.id),
    staleTime: 3 * 60 * 1000,
  });

  const [tableState, setTableState] = useTableSortingAndPagination({
    sortBy: "created_at",
    sortDirection: "desc",
  });
  const { items, pagination, sorting } = useClientSideSortingAndPagination(
    permissionsQuery.data ?? [],
    tableState
  );

  const userRolesQuery = useQuery({
    queryKey: ["users", user?.data?.id, "roles"],
    queryFn: ({ queryKey }) => {
      const [_, id] = queryKey;

      if (!id) {
        return;
      }

      return UsersAdapter.roles.getList({ userId: id });
    },
    enabled: Boolean(user?.data?.id),
    staleTime: 3 * 60 * 1000,
  });

  const rolesPermissionsQueries = useQueries({
    queries:
      userRolesQuery.data?.map(({ group_id }) => ({
        queryKey: ["roles", group_id, "permissions"],
        queryFn: () => RolesAdapter.permissions.getMany({ id: group_id }),
        staleTime: 3 * 60 * 1000,
      })) ?? [],
  });

  const rolesToAssociatedPermissionsMap = useMemo(() => {
    if (rolesPermissionsQueries.some((q) => q.isLoading)) {
      return {};
    }

    return rolesPermissionsQueries.reduce<Record<string, RolePermissionType[]>>(
      (map, query) => {
        if (!query.data?.length || !query.data[0]) {
          return map;
        }

        map[query.data[0].group_id] = query.data;
        return map;
      },
      {}
    );
  }, [rolesPermissionsQueries]);

  const rolesQueries = useQueries({
    queries:
      Object.keys(rolesToAssociatedPermissionsMap).map((roleId) => ({
        queryKey: ["roles", roleId],
        queryFn: () => RolesAdapter.getOne({ id: roleId }),
        enabled: Boolean(roleId),
      })) ?? [],
  });

  const rolesToRoleDetailsMap = useMemo(() => {
    if (rolesQueries.some((q) => q.isLoading)) {
      return {};
    }

    return rolesQueries.reduce<Record<string, RoleType>>((map, query) => {
      if (!query.data?.data) {
        return map;
      }

      map[query.data.data.id] = query.data.data;
      return map;
    }, {});
  }, [rolesQueries]);

  const permissionsToAssociatedMetadataMap = useMemo(() => {
    const coreMap = Object.entries(rolesToAssociatedPermissionsMap).reduce<
      Record<
        string,
        {
          permissionConfigsAcrossRoles: RolePermissionType[];
          roles: RoleType[];
          effectiveDate?: Date;
        }
      >
    >((map, [roleId, permConfigs]) => {
      if (!permConfigs.length) {
        return map;
      }

      permConfigs.forEach((permConfig) => {
        const relatedPermConfigs = permConfigs.filter(
          (config) => config.name === permConfig.name
        );

        if (!map[permConfig.name]) {
          map[permConfig.name] = {
            permissionConfigsAcrossRoles: relatedPermConfigs,
            roles: [],
          };
        }

        if (rolesToRoleDetailsMap[roleId]) {
          map[permConfig.name].roles.push(rolesToRoleDetailsMap[roleId]);
        }

        map[permConfig.name].permissionConfigsAcrossRoles.concat(
          relatedPermConfigs
        );
      });

      return map;
    }, {});

    // now that all permission configs across roles have been gathered, we can
    // get the earliest date as the effective date
    Object.entries(coreMap).forEach(([permName, meta]) => {
      const oldestCreatedAt = meta.permissionConfigsAcrossRoles.reduce<
        Date | undefined
      >((oldestDate, permConfig) => {
        if (!oldestDate) {
          return new Date(permConfig.created_at);
        }

        const nextDate = new Date(permConfig.created_at);

        if (isBefore(nextDate, oldestDate)) {
          return nextDate;
        } else {
          return oldestDate;
        }
      }, undefined);

      coreMap[permName].effectiveDate = oldestCreatedAt;
    });

    return coreMap;
  }, [rolesToAssociatedPermissionsMap, rolesToRoleDetailsMap]);

  const headers: Array<HeaderType<UserPermissionType>> = [
    {
      label: "Name",
      key: "name",
      isSortable: true,
      renderComponent: ({ item }) =>
        item?.name ? getPermissionDisplayLabel(item.name) : "-",
    },
    {
      label: "Source Role(s)",
      key: "roles",
      tooltip:
        "Each permission can be granted through any combination of assigned roles. Permission configurations across those roles are merged.",
      renderComponent: ({ item }: { item: UserPermissionType }) => {
        if (!permissionsToAssociatedMetadataMap[item.name]) {
          return "-";
        }

        return permissionsToAssociatedMetadataMap[item.name].roles.map(
          (role) => (
            <Pill
              key={role.id}
              variant={role.status === "active" ? "default" : "error"}
            >
              <RoutingLink to={linkToRoleDetailPage(role.id)}>
                {role.name}
                {role.status !== "active" ? ` (${role.status})` : ""}
              </RoutingLink>
            </Pill>
          )
        );
      },
    },
    {
      label: "Effective Date",
      key: "created_at",
      tooltip:
        "When the permission was configured the earliest across all assigned roles.",
      renderComponent: ({ item }) => {
        const effectiveDate =
          permissionsToAssociatedMetadataMap[item.name]?.effectiveDate;
        if (!effectiveDate) {
          return "-";
        }
        return (
          <DataTable.DataRow.DateCell
            value={effectiveDate}
            withTime
          />
        );
      },
    },
  ];

  return (
    <>
      <DataTablePanel
        dataTableProps={{
          headers,
          items,
          isLoading: permissionsQuery.isLoading,
          sorting,
          pagination,
          onPaginationChange: setTableState,
          onSortChange: setTableState,
          emptyStateProps: {
            title: "This user has no permissions",
            suggestion: "Assign roles to grant permissions.",
            icon: <Icon variant="lock" />,
          },
        }}
        panelProps={{
          title: "Permissions",
          loaded: !permissionsQuery.isLoading,
        }}
      />
    </>
  );
};
