import { RoutingLink } from "#batteries-included-components/RoutingLink";
import {
  useListFlowProductCategories,
  useListFlowProductTypes,
  useListFlowTypes,
} from "#hooks/adapters/useFlows";
import { linkToFacilities } from "#routers/links";
import { linkToEquipmentDetail } from "#routes/organization/equipment/[equipmentId]";
import { useListCustomAttributes } from "#src/components/hooks/adapters/useCustomAttributes";
import { displayValueByDataType } from "#src/utils/display";
import { KeyValuePanel } from "@validereinc/common-components";
import { AssetType, type FlowType } from "@validereinc/domain";
import React from "react";

export const FlowDetailsPanel = ({
  flow,
  isLoading,
}: {
  flow?: FlowType;
  isLoading: boolean;
}) => {
  const flowTypes = useListFlowTypes().data ?? [];
  const productCategories = useListFlowProductCategories().data ?? [];
  const productTypes = useListFlowProductTypes().data ?? [];
  const customAttributes =
    useListCustomAttributes({ filters: { entity_type: AssetType.FLOW } }).data
      ?.data ?? [];

  return (
    <KeyValuePanel
      panelMaxColumnCount={3}
      panelKeyValueListProps={{ maxRowCount: 6, variant: "shaded" }}
      panelProps={{ isFluidY: false, loaded: !isLoading }}
      data={[
        {
          title: "Product Type",

          value: productTypes?.find(({ id }) => id === flow?.product_type)
            ?.name,
        },
        {
          title: "Flow Type",
          value: flowTypes?.find(({ id }) => id === flow?.type)?.name,
        },
        {
          title: "Product Category",
          value: productCategories?.find(
            ({ id }) => id === flow?.product_category
          )?.name,
        },
        {
          title: "Associated Facility",
          value: flow?.associated_facility_id ? (
            <RoutingLink to={linkToFacilities(flow?.associated_facility_id)}>
              {flow?.associated_facility?.name ?? "-"}
            </RoutingLink>
          ) : (
            "-"
          ),
        },
        {
          title: "Associated Equipment",
          value: flow?.associated_equipment_id ? (
            <RoutingLink
              to={linkToEquipmentDetail(flow?.associated_equipment_id)}
            >
              {flow?.associated_equipment?.name ?? "-"}
            </RoutingLink>
          ) : (
            "-"
          ),
        },
        ...(customAttributes
          ?.filter(
            ({ entity_subtype }) =>
              !entity_subtype || flow?.type == entity_subtype
          )
          .map(({ display_name, field_name, data_type }) => {
            const value = flow?.custom_attributes?.[field_name];

            return {
              title: display_name,
              value: displayValueByDataType(value, data_type),
            };
          }) ?? []),
      ]}
    />
  );
};
