import type { DrawerProps } from "@validereinc/common-components";
import {
  Accordion,
  Button,
  CheckboxInput,
  Drawer,
  DropdownInput,
  Form,
  Info,
  RadioInput,
  Switch,
  TextAreaInput,
  TextInput,
  useAlert,
  useForm,
  useFormContext,
} from "@validereinc/common-components";
import {
  AssetType,
  AttributeDataType,
  EquationComponentField,
  EquationComponentType,
  FormSchemaQuestionType,
  type FormSchemaNormalQuestionLookupType,
  type FormSchemaQuestionDisplayType,
} from "@validereinc/domain";
import classNames from "classnames/bind";
import get from "lodash/get";
import React, { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import styles from "./CreateFormTemplateAddQuestionDrawer.module.scss";
import { LookupQuestionFilters } from "./LookupQuestionFilters";
import { MeasurementFields } from "./MeasurementFields";
import { QuestionConditions } from "./QuestionConditions";
import { QuestionDefaultAnswer } from "./QuestionDefaultAnswer";
import { QuestionFields } from "./QuestionFields";
import { DateTimeSubstitutions } from "@validereinc/domain-controllers/logic/forms";

const cx = classNames.bind(styles);

const IS_REQUIRED_TOOLTIP_CONTENT =
  "The user will not be able to submit the form without inputting a response to this question.";

const { AccordionPanel } = Accordion;

export const CreateFormTemplateAddQuestionDrawer = ({
  isOpen,
  onClose,
  onSave: onSaveCallback,
  handleDeleteQuestion,
  questionToEdit,
  sectionNumber,
  sectionQuestionsLength,
  displayQuestions,
}: Pick<DrawerProps, "isOpen" | "onClose"> & {
  onSave: (newQuestionId: string) => void;
  isDeleteQuestionDialogOpen: boolean;
  handleDeleteQuestion: (questionId: string, questionNumber: number) => void;
  questionToEdit: FormSchemaQuestionDisplayType | null;
  sectionNumber: number;
  sectionQuestionsLength: number;
  displayQuestions: FormSchemaQuestionDisplayType[];
}) => {
  const { addAlert } = useAlert();

  const parentForm = useFormContext();

  const questions = parentForm.watch("config.questions");

  const form = useForm(
    !questionToEdit
      ? {
          defaultValues: {
            prompt: "",
            description: "",
            type: "",
            question_number: -1,
            is_required: true,
            is_long_string: false,
            conditions: null,
            default_answer: null,
            filter: null,
            equation_components: [],
          },
        }
      : {}
  );

  const values = form.watch();

  const [isConditional, setIsConditional] = useState(
    !!questionToEdit?.conditions
  );
  const [hasDefaultAnswer, setHasDefaultAnswer] = useState(
    questionToEdit && Object.hasOwn(questionToEdit, "default_answer")
  );
  const [isFiltered, setIsFiltered] = useState(!!questionToEdit?.filter);
  const [hasMeasurementSource, setHasMeasurementSource] = useState(false);
  const [hasMeasurementSubject, setHasMeasurementSubject] = useState(false);
  const [hasMeasurementDateTime, setHasMeasurementDateTime] = useState(false);

  const equationOperators = ["+", "-", "*", "/", "^", "(", ")"];

  const onSave = form.handleSubmit((newValues) => {
    const currentQuestions = parentForm.getValues("config.questions");
    const isCalculatedMeasurement =
      newValues.type === FormSchemaQuestionType.CALCULATED_MEASUREMENT;
    const type = isCalculatedMeasurement
      ? FormSchemaQuestionType.MEASUREMENT
      : newValues.type;
    const equation =
      isCalculatedMeasurement && newValues.equation_components?.length > 0
        ? newValues.equation_components
            .map(
              (equationComponent: EquationComponentType) =>
                equationComponent.value
            )
            .join("")
        : undefined;

    const { conditions, ...restQuestion } = newValues;
    const reducedConditions = conditions?.reduce(
      (total, { operation, comparator, question }) => {
        return {
          ...total,
          [question]:
            operation === "$exists"
              ? true
              : {
                  ...(total?.[question] ?? {}),
                  [operation]: comparator,
                },
        };
      },
      {}
    );

    if (questionToEdit) {
      currentQuestions[questionToEdit.question_id] = {
        ...restQuestion,
        conditions: reducedConditions,
        type,
        ...(equation && { equation }),
      };

      parentForm.setValue("config.questions", {
        ...currentQuestions,
      });

      addAlert?.({
        variant: "success",
        message: `Successfully edited question ${restQuestion.question_number} in section ${sectionNumber}`,
      });
    } else {
      // BE parses the "-" in UUIDs as subtraction symbols so strip them out
      const newQuestionId = uuid().split("-").join("");
      const questionNumber = sectionQuestionsLength + 1;

      parentForm.setValue("config.questions", {
        ...currentQuestions,
        [newQuestionId]: {
          ...restQuestion,
          question_id: newQuestionId,
          conditions: reducedConditions,
          question_number: questionNumber,
          type,
          ...(equation && { equation }),
        },
      });
      addAlert?.({
        variant: "success",
        message: `Successfully configured question ${questionNumber} in section ${sectionNumber}`,
      });
      onSaveCallback(newQuestionId);
    }

    onCloseCallback();
  });

  const onDelete = () => {
    handleDeleteQuestion(
      questionToEdit?.question_id ?? "",
      questionToEdit?.question_number
    );
    if (!questionToEdit) {
      onCloseCallback();
    }
  };

  const onCloseCallback = () => {
    setIsConditional(false);
    setHasDefaultAnswer(false);
    setIsFiltered(false);
    setHasMeasurementSource(false);
    setHasMeasurementSubject(false);
    setHasMeasurementDateTime(false);
    onClose();
    form.reset();
  };

  const onQuestionTypeChange = () => {
    const oldQuestionType = values.type;
    if (oldQuestionType === FormSchemaQuestionType.CALCULATED_MEASUREMENT) {
      form.unregister("equation_components");
      form.unregister("equation");
    }
    form.unregister("measurement_unit");
    form.unregister("measurement_type");
    form.unregister("data_type");
    form.unregister("default_answer");
  };

  const onChangeIsConditional = (newIsConditional: boolean) => {
    if (newIsConditional && !questionToEdit?.conditions) {
      form.setValue("conditions", [
        { question: "", operation: "", value: "", type: "" },
      ]);
    } else {
      form.unregister("conditions");
    }

    setIsConditional(newIsConditional);
  };

  const onChangeHasDefaultAnswer = (newHasDefaultAnswer: boolean) => {
    const dateQuestionTypes: string[] = [
      AttributeDataType.DATE,
      AttributeDataType.DATE_TIME,
    ];

    if (newHasDefaultAnswer) {
      form.setValue(
        "default_answer",
        dateQuestionTypes.includes(values?.data_type)
          ? DateTimeSubstitutions.$NOW
          : null
      );
    } else {
      form.unregister("default_answer");
    }

    setHasDefaultAnswer(newHasDefaultAnswer);
  };

  const onChangeIsFiltered = (newIsFiltered: boolean) => {
    if (newIsFiltered) {
      form.setValue("filter", [{}]);
    } else {
      form.unregister("filter");
    }

    setIsFiltered(newIsFiltered);
  };

  const onChangeHasMeasurementSource = (newHasMeasurementSource: boolean) => {
    if (newHasMeasurementSource) {
      form.setValue("source_lookup_question_id", "");
    } else {
      form.unregister("source_lookup_question_id");
    }

    setHasMeasurementSource(newHasMeasurementSource);
  };

  const onChangeHasMeasurementSubject = (newHasMeasurementSubject: boolean) => {
    if (newHasMeasurementSubject) {
      form.setValue("subject_lookup_question_id", "");
    } else {
      form.unregister("subject_lookup_question_id");
    }

    setHasMeasurementSubject(newHasMeasurementSubject);
  };

  const onChangeHasMeasurementDateTime = (
    newHasMeasurementDateTime: boolean
  ) => {
    if (newHasMeasurementDateTime) {
      form.setValue("measurement_time_question_id", "");
    } else {
      form.unregister("measurement_time_question_id");
    }

    setHasMeasurementDateTime(newHasMeasurementDateTime);
  };

  const getValidEquationQuestionComponentQuestions = (id: string) => {
    return questionsArray
      ?.filter(
        ({ question_id, type, data_type }) =>
          ((type === FormSchemaQuestionType.QUESTION &&
            [
              AttributeDataType.DATE,
              AttributeDataType.DATE_TIME,
              AttributeDataType.NUMBER,
              AttributeDataType.INTEGER,
            ].includes(data_type)) ||
            type === FormSchemaQuestionType.MEASUREMENT) &&
          question_id !== id
      )
      ?.reduce((map, question) => {
        map[question.question_id] = question;
        return map;
      }, {}) as Record<string, FormSchemaQuestionDisplayType>;
  };

  useEffect(() => {
    setIsConditional(!!questionToEdit?.conditions);
    setHasDefaultAnswer(
      questionToEdit && Object.hasOwn(questionToEdit, "default_answer")
    );
    setIsFiltered(!!questionToEdit?.filter);
    setHasMeasurementSource(!!questionToEdit?.source_lookup_question_id);
    setHasMeasurementSubject(!!questionToEdit?.subject_lookup_question_id);
    setHasMeasurementDateTime(!!questionToEdit?.measurement_time_question_id);

    if (questionToEdit) {
      form.reset({
        ...questionToEdit,
        type: questionToEdit.equation
          ? FormSchemaQuestionType.CALCULATED_MEASUREMENT
          : questionToEdit.type,
        equation_components: questionToEdit.equation
          ? questionToEdit.equation
              // Split the equation based on operators, but include operators in the results
              .split(/(?<=[*+\-/()])|(?=[*+\-/()])/)
              .map((equationComponent: string) => {
                return {
                  id: uuid(),
                  value: equationComponent,
                  fieldType: equationOperators.includes(equationComponent)
                    ? EquationComponentField.OPERATOR
                    : !Number.isNaN(Number(equationComponent))
                      ? EquationComponentField.CONSTANT
                      : EquationComponentField.QUESTION,
                };
              })
          : [],
      });
    }
  }, [questionToEdit]);

  const questionsArray = Object.entries(questions ?? {})
    .map(([questionId, question]) => {
      const questionNumber = displayQuestions.filter((displayQuestion) => {
        return displayQuestion.question_id === questionId;
      })[0]?.question_number;

      return {
        question_id: questionId,
        question_number: questionNumber,
        label: `Section ${sectionNumber}, Question ${questionNumber}: ${question.prompt}`,
        ...question,
      };
    })
    .sort((a, b) => a.label.localeCompare(b.label));

  const validSubjectQuestions: FormSchemaNormalQuestionLookupType[] = [];
  const validSourceQuestions: FormSchemaNormalQuestionLookupType[] = [];
  const validDateTimeQuestions: FormSchemaQuestionDisplayType[] = [];

  questionsArray?.forEach((question) => {
    const validSubjectQuestion =
      question.type === FormSchemaQuestionType.QUESTION &&
      [
        AssetType.DEVICE,
        AssetType.EQUIPMENT,
        AssetType.FLOW,
        AssetType.FACILITY,
      ].includes(question.lookup_entity_type);

    const validSourceQuestion =
      question.type === FormSchemaQuestionType.QUESTION &&
      question.lookup_entity_type === AssetType.DEVICE;

    const validDateTimeQuestion =
      question.type === FormSchemaQuestionType.QUESTION &&
      (question.data_type === AttributeDataType.DATE ||
        question.data_type === AttributeDataType.DATE_TIME);

    if (validSubjectQuestion) {
      validSubjectQuestions.push(question);
    }

    if (validSourceQuestion) {
      validSourceQuestions.push(question);
    }

    if (validDateTimeQuestion) {
      validDateTimeQuestions.push(question);
    }
  });

  return (
    <Drawer
      title="Configure Question"
      isOpen={isOpen}
      onClose={onCloseCallback}
      size="md"
      actionRow={[
        <Button
          key="delete-question-button"
          variant="error-outline"
          onClick={onDelete}
        >
          Delete
        </Button>,
        <Button
          key="save-question-button"
          onClick={onSave}
          variant="primary"
        >
          Save
        </Button>,
      ]}
    >
      <Form {...form}>
        <Accordion defaultActiveKeys={["question-overview"]}>
          <AccordionPanel
            dataKey="question-overview"
            title="Question Overview"
            isError={[
              "prompt",
              "description",
              "type",
              "data_type",
              "measurement_type",
              "measurement_unit",
              "source_lookup_question_id",
              "subject_lookup_question_id",
              "measurement_time_question_id",
            ].some((key) => get(form.formState.errors, key))}
          >
            <>
              <TextInput
                label="Prompt"
                name="prompt"
                isRequired
                validate={{
                  maxLength: (prompt) =>
                    prompt.length < 100 ||
                    "Prompt must be less than 100 characters.",
                }}
              />

              <TextAreaInput
                label="Description"
                name="description"
                validate={{
                  maxLength: (description) =>
                    description.length < 1024 ||
                    "Description must be less than 1024 characters.",
                }}
              />

              <div className={cx("checkbox-container")}>
                <CheckboxInput
                  name="is_required"
                  label="Question is required"
                  isLabelShown={false}
                  className={cx("checkbox")}
                />
                <Info content={IS_REQUIRED_TOOLTIP_CONTENT} />
              </div>

              <DropdownInput
                label="Question Type"
                name="type"
                options={[
                  { label: "Question", value: FormSchemaQuestionType.QUESTION },
                  {
                    label: "Measurement",
                    value: FormSchemaQuestionType.MEASUREMENT,
                  },
                  {
                    label: "Calculated Measurement",
                    value: FormSchemaQuestionType.CALCULATED_MEASUREMENT,
                  },
                ]}
                labelKey="label"
                valueKey="value"
                onChange={onQuestionTypeChange}
                isRequired
              />

              {values.type === FormSchemaQuestionType.QUESTION ? (
                <QuestionFields />
              ) : null}

              {values.type === FormSchemaQuestionType.MEASUREMENT ||
              values.type === FormSchemaQuestionType.CALCULATED_MEASUREMENT ? (
                <MeasurementFields
                  isCalculatedMeasurement={
                    values.type ===
                    FormSchemaQuestionType.CALCULATED_MEASUREMENT
                  }
                  validSourceQuestions={validSourceQuestions}
                  validSubjectQuestions={validSubjectQuestions}
                  validDateTimeQuestions={validDateTimeQuestions}
                  validEquationQuestionComponentQuestions={getValidEquationQuestionComponentQuestions(
                    values?.question_id
                  )}
                  hasMeasurementSource={hasMeasurementSource}
                  hasMeasurementSubject={hasMeasurementSubject}
                  hasMeasurementDateTime={hasMeasurementDateTime}
                  onChangeHasMeasurementSource={onChangeHasMeasurementSource}
                  onChangeHasMeasurementSubject={onChangeHasMeasurementSubject}
                  onChangeHasMeasurementDateTime={
                    onChangeHasMeasurementDateTime
                  }
                />
              ) : null}

              {values?.data_type === "lookup" && values?.lookup_entity_type ? (
                <div className={cx("switch-container")}>
                  <Switch
                    name="isFiltered"
                    label="Filter lookup options"
                    value={isFiltered}
                    onChange={onChangeIsFiltered}
                  />
                </div>
              ) : null}

              {isFiltered ? (
                <Accordion defaultActiveKeys={["question-filters"]}>
                  <AccordionPanel
                    dataKey="question-filters"
                    title="Filter Options"
                    isError={get(form.formState.errors, "filter")}
                    shouldUseAnimation={false}
                  >
                    <LookupQuestionFilters
                      defaultFilters={questionToEdit?.filter}
                      questions={questionsArray.filter((question) => {
                        return question?.question_id !== values?.question_id;
                      })}
                    />
                  </AccordionPanel>
                </Accordion>
              ) : null}

              {values?.data_type === AttributeDataType.STRING && (
                <RadioInput
                  name="is_long_string"
                  labelKey="label"
                  valueKey="value"
                  label="String Length"
                  options={[
                    { label: "Short String", value: false },
                    { label: "Long String", value: true },
                  ]}
                />
              )}
              {questionsArray?.length &&
              !(
                questionsArray?.length === 1 &&
                questionsArray[0].question_id === values.question_id
              ) ? (
                <div className={cx("switch-container")}>
                  <Switch
                    name="isConditional"
                    label="Define question conditions"
                    value={isConditional}
                    onChange={onChangeIsConditional}
                  />
                </div>
              ) : null}

              {isConditional ? (
                <Accordion defaultActiveKeys={["question-conditions"]}>
                  <AccordionPanel
                    dataKey="question-conditions"
                    title="Conditionality"
                    isError={get(form.formState.errors, "conditions")}
                    shouldUseAnimation={false}
                  >
                    <QuestionConditions
                      defaultConditions={questionToEdit?.conditions}
                      questions={questionsArray.filter((question) => {
                        return question?.question_id !== values?.question_id;
                      })}
                      handleRemoveLastCondition={() =>
                        onChangeIsConditional(false)
                      }
                    />
                  </AccordionPanel>
                </Accordion>
              ) : null}

              {[
                AttributeDataType.STRING,
                AttributeDataType.BOOLEAN,
                AttributeDataType.DATE,
                AttributeDataType.DATE_TIME,
                AttributeDataType.PICK_LIST,
                AttributeDataType.MULTI_PICK_LIST,
              ].includes(values?.data_type) ? (
                <div className={cx("switch-container")}>
                  <Switch
                    name="hasDefaultAnswer"
                    label={`${
                      [
                        AttributeDataType.DATE,
                        AttributeDataType.DATE_TIME,
                      ].includes(values?.data_type)
                        ? `Set default answer to submission ${values.data_type === AttributeDataType.DATE_TIME ? "date & time" : "date"}`
                        : "Define default answer"
                    }`}
                    value={hasDefaultAnswer ?? false}
                    onChange={onChangeHasDefaultAnswer}
                  />
                </div>
              ) : null}

              {hasDefaultAnswer ? <QuestionDefaultAnswer /> : null}
            </>
          </AccordionPanel>
        </Accordion>
      </Form>
    </Drawer>
  );
};
