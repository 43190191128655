import { useBreadcrumbs } from "#routers/breadcrumbsHelper";
import { useNavigate, useParams } from "#routers/hooks";
import { AccessDeniedLayout } from "#src/batteries-included-components/Layouts/Authorization/AccessDenied";
import {
  EditUserFormPanel,
  UserUpdateFormType,
} from "#src/batteries-included-components/Panels/DetailsPanel/EditUserFormPanel";
import {
  useAuthenticatedContext,
  useIsFeatureAvailable,
  useIsOpsHubExperience,
} from "#src/contexts/AuthenticatedContext.helpers";
import { USERS_LIST_BREADCRUMB } from "#src/routes/settings/users";
import {
  USER_DETAILS_BREADCRUMB,
  linkToUserDetailPage,
} from "#src/routes/settings/users/detail";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  Button,
  Form,
  FormButton,
  Page,
  useAlert,
  useForm,
} from "@validereinc/common-components";
import { UsersAdapter } from "@validereinc/domain";
import React, { useContext } from "react";
import { EDIT_USER_PROFILE_BREADCRUMB } from ".";
import { SETTINGS_BREADCRUMB } from "../..";
import {
  UserDetailContext,
  UserDetailProvider,
} from "../detail/UserDetailContext";

const EditUserPageContent = () => {
  const navigate = useNavigate();
  const { addAlert } = useAlert();
  const { userDetails } = useContext(UserDetailContext) ?? {};
  const form = useForm({ defaultValues: { ...userDetails?.data } });
  const queryClient = useQueryClient();
  const breadcrumbs = useBreadcrumbs(
    [
      SETTINGS_BREADCRUMB,
      USERS_LIST_BREADCRUMB,
      USER_DETAILS_BREADCRUMB,
      EDIT_USER_PROFILE_BREADCRUMB,
    ],
    {
      2: userDetails?.data?.name,
    },
    { userId: userDetails?.data?.id ?? "" }
  );

  const navigateToUserDetail = () => {
    if (!userDetails?.data?.id) return;

    navigate({ pathname: linkToUserDetailPage(userDetails.data?.id) });
  };

  const updateUser = useMutation({
    mutationFn: ({ name, phone, timezone, quicksight }: UserUpdateFormType) => {
      if (!userDetails?.data?.id) {
        throw new Error("A user must be specified to update");
      }

      return UsersAdapter.updateOne({
        id: userDetails.data.id,
        data: {
          name,
          phone: phone ? phone : null,
          timezone,
          quicksight,
        },
      });
    },
    onSuccess: (updatedUser) => {
      queryClient.invalidateQueries({
        queryKey: ["users"],
      });
      addAlert?.({
        variant: "success",
        message: `Successfully updated account for ${updatedUser.data?.name}`,
      });
      navigateToUserDetail();
    },
    onError: (err, mutationData) => {
      console.error(err);
      addAlert?.({
        variant: "error",
        message: `Failed to update account for ${mutationData.name}`,
      });
    },
  });

  const footer = (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        gap: 16,
        width: "100%",
        justifyContent: "space-between",
      }}
    >
      <Button
        onClick={navigateToUserDetail}
        disabled={userDetails?.isLoading || updateUser.isLoading}
      >
        Cancel
      </Button>

      <FormButton
        variant="primary"
        type="submit"
        onClick={form.handleSubmit(updateUser.mutate)}
        disabled={userDetails?.isLoading || updateUser.isLoading}
      >
        Save
      </FormButton>
    </div>
  );

  return (
    <Form
      {...form}
      onSubmit={form.handleSubmit(updateUser.mutate)}
      style={{ height: "100%" }}
    >
      <Page
        title="Edit User"
        category={`User | ${userDetails?.data?.name}`}
        isLoading={userDetails?.isLoading}
        breadcrumbs={breadcrumbs}
        footer={footer}
      >
        <EditUserFormPanel />
      </Page>
    </Form>
  );
};

export const EditUserPage = () => {
  const { userId } = useParams<{ userId: string }>();
  const {
    claims: {
      user: { id: currentUserId },
    },
  } = useAuthenticatedContext();
  const [isPageAvailable] = useIsFeatureAvailable({
    featureFlagQuery: "core:roles_permissions",
    permissionQuery: "users:write",
  });
  const [isOpsExperienceEnabled] = useIsOpsHubExperience();

  const isThisUserCurrentUser = currentUserId === userId;

  if (!isThisUserCurrentUser && (!isPageAvailable || isOpsExperienceEnabled)) {
    return <AccessDeniedLayout />;
  }

  return (
    <UserDetailProvider userId={userId}>
      <EditUserPageContent />
    </UserDetailProvider>
  );
};
