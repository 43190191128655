import CalibrationService from "#services/CalibrationService";
import {
  Button,
  Dialog,
  useAlert,
  useForm,
} from "@validereinc/common-components";
import moment from "moment";
import React, { useState } from "react";
import EditCalibrationForm from "./EditCalibrationForm";

export const EditCalibrationModal = (props: {
  addAlertMessage: () => void;
  show: boolean;
  instrumentDetail?: Record<string, any>;
  onHide: () => void;
  onCalibrationUpdated: () => void;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { addAlert } = useAlert();

  const formMethods = useForm({
    defaultValues: {
      lastChecked: moment(
        props.instrumentDetail?.prev_calibration_date
      ).toDate(),
      nextCheck: moment(props.instrumentDetail?.next_calibration_date).toDate(),
    },
  });

  const getNextCheckDateFromLast = (date) => {
    const nextDate = new Date(date);
    //If the instrument measures Vapor Pressure, then we suggest a next
    //recalibration date in 6 months time, otherwise 12 months.
    const monthDiff = props.instrumentDetail?.type.includes("vapor") ? 6 : 12;
    return new Date(nextDate.setMonth(nextDate.getMonth() + monthDiff));
  };

  const onSubmit = (formProps) => {
    const { instrumentDetail } = props;
    const lastChecked = moment(formProps.lastChecked).toDate();
    const nextCheck = moment(formProps.nextCheck).toDate();

    setIsLoading(true);

    CalibrationService.updateCalibrationData(
      instrumentDetail?.id,
      lastChecked,
      nextCheck
    )
      .then(() => {
        setIsLoading(false);

        addAlert({
          variant: "success",
          message: "Calibration update successfully.",
        });

        props.onCalibrationUpdated();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const instrumentName = props.instrumentDetail
    ? props.instrumentDetail?.name
    : "";

  return (
    <Dialog
      isOpen={props.show}
      onClose={props.onHide}
      title={`${instrumentName} Calibration`}
      actionRow={[
        <Button
          key="submit"
          variant="primary"
          type="submit"
          isLoading={isLoading}
          onClick={formMethods.handleSubmit(onSubmit)}
        >
          Update Calibration
        </Button>,
      ]}
    >
      <EditCalibrationForm
        onSubmit={onSubmit}
        formMethods={formMethods}
        isLoading={isLoading}
        getNextCheckDateFromLast={getNextCheckDateFromLast}
      />
    </Dialog>
  );
};

export default EditCalibrationModal;
