import { z } from "zod";

export const TransactionSources = {
  API: "api",
  SFTP: "sftp",
  EMAIL: "email",
  IOT: "iot",
  KAFKA: "kafka",
  UPLOAD: "upload",
  UNKNOWN: "unknown",
} as const;

export type TransactionSourceType =
  (typeof TransactionSources)[keyof typeof TransactionSources];

export const TransactionSteps = {
  RECORDER: "transaction_recorder",
  VULNERABILITY_SCANNER: "vulnerability_scanner",
  ARCHIVER: "archiver",
  VALIDATOR: "validator",
  VALIDATOR_EMAIL: "email_validator",
  OPERATOR: "operator",
  SINK: "sink",
} as const;
export type TransactionStepType =
  (typeof TransactionSteps)[keyof typeof TransactionSteps];

export const TransactionStatus = {
  PENDING: "pending",
  FAILED: "failed",
  COMPLETED: "completed",
  COMPLETED_WITH_PARTIAL_SUCCESS: "completed_with_partial_success",
} as const;

export type TransactionStatusType =
  (typeof TransactionStatus)[keyof typeof TransactionStatus];

export const TransactionStepStates = {
  PENDING: "pending",
  SUCCESS: "success",
  SUCCESS_PARTIAL: "partial_success",
  FAILED: "failed",
  FAILED_PARTIAL: "partial_failure",
  RETRIED: "retried",
} as const;

export type TransactionStepStateType =
  (typeof TransactionStepStates)[keyof typeof TransactionStepStates];

export const TransactionDetailItemStatus = {
  CREATED: "created",
  UPDATED: "updated",
  FAILED: "failed",
  UNCHANGED: "unchanged",
};

export type TransactionDetailItemStatusType =
  (typeof TransactionDetailItemStatus)[keyof typeof TransactionDetailItemStatus];

export const TransactionSchema = z
  .object({
    transaction_id: z.string(),
    client_id: z.string(),
    dataset_id: z.string(),
    source: z.enum([
      TransactionSources.API,
      TransactionSources.SFTP,
      TransactionSources.EMAIL,
      TransactionSources.IOT,
      TransactionSources.KAFKA,
      TransactionSources.UPLOAD,
      TransactionSources.UNKNOWN,
    ]),
    step: z.enum([
      TransactionSteps.RECORDER,
      TransactionSteps.VULNERABILITY_SCANNER,
      TransactionSteps.ARCHIVER,
      TransactionSteps.VALIDATOR,
      TransactionSteps.VALIDATOR_EMAIL,
      TransactionSteps.OPERATOR,
      TransactionSteps.SINK,
    ]),
    bucket: z.string(),
    file_key: z.string(),
    original_file_name: z.string(),
    state: z.enum([
      TransactionStepStates.PENDING,
      TransactionStepStates.SUCCESS,
      TransactionStepStates.SUCCESS_PARTIAL,
      TransactionStepStates.FAILED,
      TransactionStepStates.FAILED_PARTIAL,
      TransactionStepStates.RETRIED,
    ]),
    status: z.enum([
      TransactionStatus.PENDING,
      TransactionStatus.FAILED,
      TransactionStatus.COMPLETED,
      TransactionStatus.COMPLETED_WITH_PARTIAL_SUCCESS,
    ]),
    details: z.string().nullable(),
    total_count: z.number(),
    successful_count: z.number(),
    user_id: z.string(),
    created_at: z.string(),
  })
  .describe("Schema for transactions in the data platform");

export const TransactionRowSummarySchema = z.object({
  index: z.string(),
  data: z.string(),
  msg: z.string(),
});

export const TransactionSummarySchema = z.object({
  entity_type: z.string(),
  success_count: z.number(),
  failure_count: z.number(),
  details: TransactionRowSummarySchema,
});

export const ImprovedTransactionSummarySchema = z.object({
  transaction_id: z.string(),
  sink: TransactionSummarySchema,
});

export const TransactionDetailsItemErrorDetailSchema = z.object({
  field_name: z.string(),
  error_message: z.string(),
  validation_type: z.string(),
  validation_value: z.array(z.string()),
});

export const TransactionDetailsItemSchema = z.object({
  index: z.string(),
  status: z.enum([
    TransactionDetailItemStatus.CREATED,
    TransactionDetailItemStatus.FAILED,
    TransactionDetailItemStatus.UPDATED,
    TransactionDetailItemStatus.UNCHANGED,
  ]),
  data: z.string(),
  error: z.string(),
  error_msg: z.string(),
  entity_type: z.string(),
  error_details: z.array(TransactionDetailsItemErrorDetailSchema),
});

export const TransactionDetailsSchema = z.object({
  success_count: z.number().int(),
  failure_count: z.number().int(),
  summary: z.object({}),
  details: z.array(TransactionDetailsItemSchema),
});

export const TransactionGetListFilterSchema = z
  .object({
    /** Optional, otherwise defaults to 3 months before current datetime. Required in isoformat i.e. %Y%m%dT%H%M%S%z */
    submitted_after: z.string(),
    /** Optional, otherwise defaults to current datetime. Required in isoformat i.e. %Y%m%dT%H%M%S%z */
    submitted_before: z.string(),
    only_latest: z.boolean(),
  })
  .merge(
    TransactionSchema.pick({
      dataset_id: true,
      step: true,
      source: true,
      state: true,
      // REVIEW: not supported by BE yet
      status: true,
      bucket: true,
      file_key: true,
    })
  )
  .partial();

export type TransactionType = z.infer<typeof TransactionSchema>;
export type TransactionSummaryType = z.infer<typeof TransactionSummarySchema>;
export type TransactionGetListFilterType = z.infer<
  typeof TransactionGetListFilterSchema
>;

export type TransactionDetailsType = z.infer<typeof TransactionDetailsSchema>;

export type TransactionDetailsItemType = z.infer<
  typeof TransactionDetailsItemSchema
>;

export type TransactionDetailsItemErrorDetailItemType = z.infer<
  typeof TransactionDetailsItemErrorDetailSchema
>;
