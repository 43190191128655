import { getCalculationInputTableCellDisplayValue } from "#batteries-included-components/Tabs/EstimationMethod/helpers";
import { useEstimationMethodDetailContext } from "#src/batteries-included-components/Layouts/EstimationMethod/Detail";
import { useTableSortingAndPagination } from "#src/components/Redux/reducers/tableStateReducer";
import { useMeasurementTypes } from "#src/contexts/MeasurementTypeContext";
import { useStorageKey } from "#src/hooks/useStorageKey";
import { useQuery } from "@tanstack/react-query";
import {
  DataTable,
  DataTablePanel,
  HeaderType,
  Link,
} from "@validereinc/common-components";
import {
  EstimationMethodDomain,
  EstimationMethodRunType,
  Resources,
  SortDirection,
} from "@validereinc/domain";
import { DateFormats, monthFormatter } from "@validereinc/utilities";
import parse from "date-fns/parse";
import parseISO from "date-fns/parseISO";
import React from "react";

const EstimationMethodRecordsTab = ({
  onClickRow,
}: {
  onClickRow: (methodId: string, entityId: string, yearMonth: string) => void;
}) => {
  const { method, entityId, entityType } = useEstimationMethodDetailContext();
  const methodDetail = method?.data;
  const { tableConfigStorageKey } = useStorageKey(
    `${entityType}-estimation-methods-detail-${
      method?.data?.analytics_calculator_id ?? entityId
    }`
  );
  const [tableState, updateTableState] = useTableSortingAndPagination(
    {
      sortBy: "year_month",
      sortDirection: SortDirection.ASCENDING,
    },
    {
      methodId: methodDetail?.id ?? "",
      entityType,
    }
  );
  const estMethodRunListQueryParams: Parameters<
    typeof EstimationMethodDomain.run.getList
  >[0] = {
    filters: {
      methodId: methodDetail?.id ?? "",
      entityType,
    },
    page: tableState.page,
    pageSize: tableState.pageSize,
    sortBy: tableState.sortBy,
    sortDirection: tableState.sortDirection,
  };
  const estMethodRunListQuery = useQuery({
    queryKey: [
      Resources.ESTIMATION_METHOD,
      "runs",
      estMethodRunListQueryParams,
    ],
    queryFn: () =>
      EstimationMethodDomain.run.getList(estMethodRunListQueryParams),
    enabled: Boolean(
      methodDetail?.id && entityId && entityType && tableState.page
    ),
  });
  const { getPrecisionByType, getTypeName, getUnitName } =
    useMeasurementTypes();

  const extractInputHeadersFromRun = (
    runData?: EstimationMethodRunType
  ): Array<HeaderType<EstimationMethodRunType>> => {
    if (!runData?.input?.calculation_parameters?.length) {
      return [];
    }

    return [
      {
        key: "inputs",
        label: "Inputs",
        headers: runData.input.calculation_parameters.map((input) => {
          const unit = getUnitName(input.measurement_unit);

          return {
            label: `${input.display_name}${unit ? ` [${unit}]` : ""}`,
            key: input.id,
            tooltip: input.description,
            alignment: "right",
            isSortable: false,
            renderComponent: ({ item }: { item: EstimationMethodRunType }) => {
              // find the right input result
              const inputResult = item?.input?.calculation_parameters?.find(
                (inputCandidate) => inputCandidate.id === input.id
              );

              return getCalculationInputTableCellDisplayValue(
                inputResult,
                "right"
              );
            },
          };
        }),
      },
    ];
  };
  const extractOutputHeadersFromRun = (
    runData?: EstimationMethodRunType
  ): Array<HeaderType<EstimationMethodRunType>> => {
    if (!runData?.output?.outputs?.length) {
      return [];
    }

    return [
      {
        key: "results",
        label: "Results",
        headers: runData.output.outputs.map((output) => {
          const unit = getUnitName(output.measurement_unit);

          return {
            label: `${getTypeName(output.measurement_type)}${unit ? ` [${unit}]` : ""}`,
            key: output.measurement_type,
            tooltip: output.description,
            alignment: "right",
            isSortable: false,
            renderComponent: ({ item }) => {
              // find the right output result
              const outputResult = item?.output?.outputs?.find(
                (outputCandidate) =>
                  outputCandidate.measurement_type === output.measurement_type
              );

              return (
                <DataTable.DataRow.NumberCell
                  value={outputResult?.measurement_value}
                  precision={getPrecisionByType(output.measurement_type)}
                  maxFractionDigits={3}
                  showSmallNumberAsExponential
                />
              );
            },
          };
        }),
      },
    ];
  };

  const tableHeaders: Array<HeaderType<EstimationMethodRunType>> = [
    {
      label: "Time Period",
      key: "year_month",
      isSortable: true,
      renderComponent: ({
        item: { year_month: yearMonth, calculation_start: calculationStart },
      }) => (
        <Link
          label={
            yearMonth && calculationStart
              ? monthFormatter(
                  parse(
                    yearMonth,
                    DateFormats.YEAR_MONTH,
                    parseISO(calculationStart)
                  )
                )
              : "-"
          }
          onClick={() => onClickRow(methodDetail?.id, entityId, yearMonth)}
        />
      ),
    },
    ...extractInputHeadersFromRun(estMethodRunListQuery.data?.data?.[0]),
    ...extractOutputHeadersFromRun(estMethodRunListQuery.data?.data?.[0]),
  ];

  return (
    <DataTablePanel
      storageKey={tableConfigStorageKey}
      panelProps={{
        title: "Results",
      }}
      dataTableProps={{
        ...tableState,
        items: estMethodRunListQuery.data?.data ?? [],
        isLoading: estMethodRunListQuery.isLoading,
        isBusy: estMethodRunListQuery.isFetching,
        sorting: {
          sortBy: tableState.sortBy,
          sortDirection: tableState.sortDirection,
        },
        pagination: {
          page: tableState.page,
          pageSize: tableState.pageSize,
          total: estMethodRunListQuery.data?.total_entries,
        },
        onSortChange: updateTableState,
        onPaginationChange: updateTableState,
        headers: tableHeaders,
      }}
    />
  );
};

export default EstimationMethodRecordsTab;
