import { useTableSortingAndPagination } from "#redux/reducers/tableStateReducer";
import { useParams } from "#src/Routers/hooks";
import { RoutingLink } from "#src/batteries-included-components/RoutingLink";
import { useListWorkflowTemplates } from "#src/components/hooks/adapters/useWorkflowTemplates";
import { WorkflowCategoryDetailsRoutePath } from "#src/routes/workflows/categories/[workflowCategoryId]";
import { WorkflowTemplateDetailsRoutePath } from "#src/routes/workflows/templates/[workflowTemplateId]";
import {
  isFormTrigger,
  isScheduledTrigger,
} from "#src/routes/workflows/templates/[workflowTemplateId]/WorkflowTemplatePage.helpers";
import { UseQueryOptions, useQueries } from "@tanstack/react-query";
import {
  DataTable,
  DataTablePanel,
  HeaderType,
  StorageKeys,
  useFilters,
} from "@validereinc/common-components";
import {
  SortDirection,
  UserType,
  UsersAdapter,
  WorkflowTemplateType,
} from "@validereinc/domain";
import {
  cronToSimpleDisplayText,
  toStartCaseString,
} from "@validereinc/utilities";
import React, { useMemo } from "react";

export const WorkflowTemplatesTablePanel = ({
  filterConfigStorageKey,
  tableConfigStorageKey,
}: StorageKeys) => {
  const { workflowCategoryId } = useParams<{
    workflowCategoryId: string;
  }>();
  const [filters] = useFilters(filterConfigStorageKey);

  const [tableState, updateTableState] = useTableSortingAndPagination(
    {
      sortBy: "name",
      sortDirection: SortDirection.ASCENDING,
    },
    filters
  );

  const { data, isLoading, isFetching } = useListWorkflowTemplates({
    page: tableState.page,
    pageSize: tableState.pageSize,
    sortBy: tableState.sortBy,
    sortDirection: tableState.sortDirection,
    filters: {
      ...filters,
      ...(workflowCategoryId ? { category_id: workflowCategoryId } : {}),
    },
  });

  const ownerQueries = useQueries<
    Array<
      UseQueryOptions<
        Awaited<ReturnType<typeof UsersAdapter.getOne>> | undefined,
        unknown,
        UserType | undefined
      >
    >
  >({
    queries:
      data?.data.map((workflow) => ({
        queryKey: ["users", workflow.workflow_owner_id],
        queryFn: () => {
          if (!workflow.workflow_owner_id) {
            return;
          }

          return UsersAdapter.getOne({
            id: workflow.workflow_owner_id,
          });
        },
        enabled: Boolean(workflow.workflow_owner_id),
        select: (resp) => resp?.data,
      })) ?? [],
  });
  const ownersMap = useMemo(() => {
    return ownerQueries.reduce<Record<string, UserType>>((map, q) => {
      if (!q.data?.id || map[q.data.id]) {
        return map;
      }

      map[q.data.id] = q.data;
      return map;
    }, {});
  }, [ownerQueries]);

  const headers: Array<HeaderType<WorkflowTemplateType>> = [
    {
      key: "name",
      label: "Name",
      isSortable: true,
      renderComponent: ({ item }) => (
        <RoutingLink
          to={WorkflowTemplateDetailsRoutePath.toLinkParts({
            pathParams: { workflowTemplateId: item.id },
          })}
        >
          {item.name}
        </RoutingLink>
      ),
    },
    {
      key: "status",
      label: "Status",
      isSortable: true,
      renderComponent: ({ item }) => (
        <DataTable.DataRow.PillCell
          variant={item.status === "active" ? "success" : "default"}
          value={toStartCaseString(item.status)}
        />
      ),
    },
    {
      key: "description",
      label: "Description",
      isSortable: true,
    },
    {
      key: "workflow_owner_id",
      label: "Workflow Owner",
      isSortable: true,
      renderComponent: ({ item }) => {
        const value = item.workflow_owner_id;
        return value ? ownersMap[value]?.name ?? "-" : null;
      },
    },
    {
      key: "triggers[0]",
      label: "Trigger",
      renderComponent: ({ value }) => {
        if (isScheduledTrigger(value)) {
          return "Defined Schedule";
        }

        if (isFormTrigger(value)) {
          return "Form Submission";
        }

        return "-";
      },
    },
    {
      key: "triggers",
      label: "Repeats",
      isSortable: false,
      renderComponent: ({ item }) => {
        if (!item.triggers) {
          return null;
        }

        if (!item.triggers.length || !isScheduledTrigger(item.triggers?.[0])) {
          return "-";
        }

        return (
          item.triggers?.[0] &&
          isScheduledTrigger(item.triggers?.[0]) &&
          cronToSimpleDisplayText(item.triggers?.[0].cron)
        );
      },
    },
    {
      key: "workflow_category.name",
      label: "Category",
      renderComponent: ({ item }) => (
        <RoutingLink
          to={WorkflowCategoryDetailsRoutePath.toLinkParts({
            pathParams: {
              workflowCategoryId: item.category_id,
            },
          })}
        >
          {item.workflow_category.name}
        </RoutingLink>
      ),
    },
    {
      key: "created_at",
      label: "Created At",
      isSortable: true,
      renderComponent: ({ item }) => (
        <DataTable.DataRow.DateCell value={item.created_at} />
      ),
    },
    {
      key: "updated_at",
      label: "Updated At",
      isSortable: true,
      renderComponent: ({ item }) => (
        <DataTable.DataRow.DateCell value={item.updated_at} />
      ),
    },
  ];

  const items = data?.data ?? [];

  return (
    <DataTablePanel
      storageKey={tableConfigStorageKey}
      panelProps={{
        title: "Templates",
      }}
      dataTableProps={{
        variant: "simplicity-first",
        headers,
        sorting: {
          sortBy: tableState.sortBy,
          sortDirection: tableState.sortDirection,
        },
        isLoading,
        isBusy: isFetching,
        items,
        pagination: {
          page: tableState.page,
          pageSize: tableState.pageSize,
          total: data?.total_entries,
        },
        onSortChange: updateTableState,
        onPaginationChange: updateTableState,
      }}
    />
  );
};
