import { linkToEventTemplateDetail } from "#routes/events/event-categories/[eventCategoryId]/templates/[templateId]/details";
import { EVENT_TEMPLATES_FILTER_PANEL_STORAGE_KEY } from "#src/batteries-included-components/Panels/FilterPanels/EventTemplatesFilterPanel";
import { EventCategoryContext } from "#src/contexts/EventCategoryContext";
import {
  DataTable,
  HeaderType,
  SortingType,
} from "@validereinc/common-components";
import type { EventSchemaType, SortDirectionType } from "@validereinc/domain";
import { EventSchemaDomain, SortDirection } from "@validereinc/domain";
import startCase from "lodash/startCase";
import React, { useContext, useEffect, useReducer, useState } from "react";
import { RoutingLink } from "../RoutingLink";

type EventTemplateDataType = {
  eventTemplates: EventSchemaType[];
  isLoading: boolean;
  pagination: {
    page: number;
    pageSize: number;
  };
  sorting: { sortBy: string; sortDirection: SortDirectionType };
  total?: number;
};

export const EventCategoryTemplatesTable = () => {
  const { eventCategory } = useContext(EventCategoryContext) || {};

  const [filters, setFilters] = useState(() => {
    try {
      const filters = JSON.parse(
        sessionStorage.getItem(EVENT_TEMPLATES_FILTER_PANEL_STORAGE_KEY) ?? "{}"
      );

      return filters;
    } catch (caught) {
      console.error("Session storage parse failed");

      return {};
    }
  });

  const dataReducer = (
    prevState: EventTemplateDataType,
    newState: Partial<EventTemplateDataType>
  ) => {
    return { ...prevState, ...newState };
  };

  const [data, setData] = useReducer(dataReducer, {
    eventTemplates: [],
    isLoading: false,
    pagination: { page: 1, pageSize: 10 },
    sorting: { sortBy: "name", sortDirection: SortDirection.ASCENDING },
  });

  const onFetchData = async () => {
    setData({ isLoading: true });

    const { data: eventTemplates, total_entries } =
      await EventSchemaDomain.getList({
        ...data.pagination,
        ...data.sorting,
        filters: { ...filters, "event_category.id": eventCategory?.id },
      });

    setData({
      eventTemplates,
      isLoading: false,
      total: total_entries,
    });
  };

  const headers: Array<HeaderType<EventSchemaType>> = [
    {
      key: "name",
      label: "Name",
      isSortable: true,
      renderComponent: ({ item }) => (
        <RoutingLink to={linkToEventTemplateDetail(eventCategory?.id, item.id)}>
          {item.name}
        </RoutingLink>
      ),
    },
    {
      key: "description",
      label: "Description",
      isSortable: true,
    },
    {
      key: "status",
      label: "Status",
      isSortable: true,
      renderComponent: ({ item }) => (
        <DataTable.DataRow.PillCell
          variant={item.status === "active" ? "success" : "default"}
          value={startCase(item.status)}
        />
      ),
    },
    {
      key: "created_at",
      label: "Date Created",
      isSortable: true,
      renderComponent: ({ item }) => (
        <DataTable.DataRow.DateCell
          value={item.created_at}
          withTime
        />
      ),
    },
  ];

  const onPaginationChange = ({
    page,
    pageSize,
  }: {
    page: number;
    pageSize: number;
  }) => {
    setData({
      pagination: {
        ...data.pagination,
        page,
        pageSize,
      },
    });
  };

  const onSortChange = ({ sortBy, sortDirection }: SortingType) => {
    setData({
      sorting: {
        ...data.sorting,
        sortBy,
        sortDirection,
      },
    });
  };

  useEffect(() => {
    const onStorageEvent = (event: StorageEvent) => {
      if (event.key === EVENT_TEMPLATES_FILTER_PANEL_STORAGE_KEY) {
        try {
          const filters = JSON.parse(
            sessionStorage.getItem(EVENT_TEMPLATES_FILTER_PANEL_STORAGE_KEY) ??
              "{}"
          );

          setFilters(filters);
        } catch (caught) {
          console.error("Session storage parse failed");
        }
      }
    };

    window.addEventListener("storage", onStorageEvent);

    return () => {
      window.removeEventListener("storage", onStorageEvent);
    };
  }, [setFilters]);

  useEffect(() => {
    if (eventCategory?.id) {
      onFetchData();
    }
  }, [data.pagination, data.sorting, filters, eventCategory?.id]);

  return (
    <DataTable
      headers={headers}
      items={data.eventTemplates}
      isLoading={data.isLoading}
      pagination={{
        ...data.pagination,
        total: data.total,
      }}
      onPaginationChange={onPaginationChange}
      onSortChange={onSortChange}
    />
  );
};
