import { FormSchemaDropdownInput } from "#src/batteries-included-components/Dropdowns/FormSchemaDropdownInput";
import {
  FilterArea,
  type FilterAreaRootProps,
} from "#src/components/FilterArea";
import { FilterDrawer } from "#src/components/FilterDrawer";
import { SavedFilterTag } from "#src/components/hooks/FilterPanel/useSavedFilters";
import { useFormCategories } from "#src/components/hooks/useForms";
import { FORM_SUBMISSION_STATUS_OPTIONS } from "#src/constants";
import { DEFAULT_DATE_RANGES } from "#src/hooks/useDateRange";
import {
  DateSelectorInput,
  DropdownInput,
  TextInput,
  type StorageKeys,
} from "@validereinc/common-components";
import {
  FormCategoryAdapter,
  FormSubmissionAdapter,
  FormSubmissionStatus,
  Resources,
} from "@validereinc/domain";
import React, { type ReactNode } from "react";

export const FormSubmissionsViewFilterArea = ({
  viewConfigStorageKey,
  defaultFormSchemaId,
  shouldShowFormTemplateDropdown = false,
}: Pick<StorageKeys, "viewConfigStorageKey"> & {
  defaultFormSchemaId?: string;
  shouldShowFormTemplateDropdown?: boolean;
}) => {
  return (
    <FilterArea.Root<{
      form_schema_id: string;
      name: string;
      created_at: { from: Date; to: Date };
    }>
      storageKey={viewConfigStorageKey}
      defaultValues={{
        form_schema_id: defaultFormSchemaId,
        created_at: DEFAULT_DATE_RANGES.lastThreeMonths,
      }}
      applyDefaultValues
      shouldPrioritizeStoredFiltersWhenApplyingDefaultValues={false}
    >
      <FilterArea.Container aria-label="View Filters for Form Submissions">
        <FilterArea.Content>
          {({ handleOnChange }) => (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: 8,
              }}
            >
              <DateSelectorInput
                name="created_at"
                variant="day"
                max={new Date()}
                isRange
                isInline
                label="Created At"
                isLabelShown={false}
                description="Search by Created At"
                onChange={(val) => handleOnChange(val, "created_at")}
              />
              {shouldShowFormTemplateDropdown ? (
                <FormSchemaDropdownInput
                  name="form_schema_id"
                  label="Form Template"
                  isLabelShown={false}
                  isInline
                  fetchFilters={{ status: "active" }}
                  placeholder="Select Template..."
                  onChange={(val) => handleOnChange(val, "form_schema_id")}
                  isOptionalTextShown={false}
                  isMulti={false}
                />
              ) : null}
            </div>
          )}
        </FilterArea.Content>
      </FilterArea.Container>
    </FilterArea.Root>
  );
};

export const FormSubmissionsTableFilterAreaDrawerContent = ({
  hasStatusFilter = true,
  hasCategoryFilter,
  hasTemplateFilter,
}: {
  hasStatusFilter?: boolean;
  hasCategoryFilter?: boolean;
  hasTemplateFilter?: boolean;
}) => {
  return (
    <>
      {hasStatusFilter ? (
        <DropdownInput
          label="Status"
          name="status"
          options={FORM_SUBMISSION_STATUS_OPTIONS.filter(
            (opt) => opt.value !== FormSubmissionStatus.DRAFT
          )}
          placeholder="Select Status..."
          labelKey="label"
          valueKey="value"
          isFluid
          isMulti
          isSearchable
          isOptionalTextShown={false}
        />
      ) : null}
      {hasCategoryFilter ? (
        <DropdownInput
          name="form_category.id"
          label="Form Category"
          placeholder="Select Category..."
          onFetchData={async (payload) => {
            let { data } = await FormCategoryAdapter.getList({
              ...payload,
              filters: {
                name: payload.searchTerm,
              },
            });

            // REVIEW: not a fan of the fact that we need to do this. A refactor of DropdownInput is needed.
            if (Array.isArray(payload.value)) {
              data = data.filter((d) => payload.value.includes(d.id));
            }

            return data;
          }}
          labelKey="name"
          valueKey="id"
          isMulti
          isOptionalTextShown={false}
        />
      ) : null}
      {hasTemplateFilter ? (
        <FormSchemaDropdownInput
          name="form_schema.id"
          label="Form Template"
          fetchFilters={{ status: "active" }}
          placeholder="Select Template..."
          isMulti
          isOptionalTextShown={false}
        />
      ) : null}
    </>
  );
};

export const FormSubmissionsTableFilterArea = <T extends Record<string, any>>({
  filterConfigStorageKey,
  filterDrawerContentSlot,
  filterContentSlot,
  defaultValues,
  shouldShowFormSubmissionSearchInput = false,
  shouldShowSavedFilters = true,
}: Pick<StorageKeys, "filterConfigStorageKey"> & {
  filterDrawerContentSlot?: ReactNode;
  filterContentSlot?: ReactNode;
  defaultValues?: FilterAreaRootProps<T>["defaultValues"];
  shouldShowFormSubmissionSearchInput?: boolean;
  shouldShowSavedFilters?: boolean;
}) => {
  return (
    <FilterArea.Root<T>
      storageKey={filterConfigStorageKey}
      defaultValues={defaultValues}
    >
      <FilterArea.Container aria-label="Filters for Form Submissions">
        <FilterDrawer.Root>
          {shouldShowFormSubmissionSearchInput ? (
            <FilterArea.Content>
              {({ handleOnChange }) => (
                <TextInput
                  name="name"
                  label="Search"
                  isLabelShown={false}
                  placeholder="Search Submissions..."
                  type="search"
                  autocomplete="on"
                  isInline
                  onChange={(val) => handleOnChange(val, "name")}
                />
              )}
            </FilterArea.Content>
          ) : null}
          {filterContentSlot}
          {shouldShowSavedFilters ? (
            <>
              <FilterDrawer.Trigger />
              <FilterDrawer.Content>
                <FilterDrawer.SavedFilters
                  config={{
                    resourceType: Resources.FORM_SUBMISSION,
                    savedFilterResourceAdapter:
                      FormSubmissionAdapter.savedFilters,
                    tag: SavedFilterTag.LIST,
                    filterBlacklist: [],
                  }}
                />
                <FilterDrawer.SavedFiltersAppliedIndicator />
                {filterDrawerContentSlot}
              </FilterDrawer.Content>
            </>
          ) : null}
        </FilterDrawer.Root>
      </FilterArea.Container>
    </FilterArea.Root>
  );
};

export const FormSchemasTableFilterAreaContent = () => {
  const [formCategories] = useFormCategories();

  return (
    <>
      <DropdownInput
        name="form_category_id"
        label="Form Category"
        options={formCategories}
        placeholder="Search Categories..."
        labelKey="name"
        valueKey="id"
        isSearchable
        isOptionalTextShown={false}
      />
    </>
  );
};

export const FormSchemasTableFilterArea = ({
  filterConfigStorageKey,
  filterDrawerContentSlot,
}: Pick<StorageKeys, "filterConfigStorageKey"> & {
  hasCustomAttributeFilters?: boolean;
  filterDrawerContentSlot: ReactNode;
}) => {
  return (
    <FilterArea.Root storageKey={filterConfigStorageKey}>
      <FilterArea.Container aria-label="Filters for Form Templates">
        <FilterDrawer.Root>
          <FilterArea.Content>
            {({ handleOnChange }) => (
              <div style={{ marginRight: 8, marginBottom: 0 }}>
                <TextInput
                  name="name"
                  label="Search"
                  isLabelShown={false}
                  placeholder="Search Templates..."
                  type="search"
                  autocomplete="on"
                  isInline
                  onChange={(val) => handleOnChange(val, "name")}
                />
              </div>
            )}
          </FilterArea.Content>
          {filterDrawerContentSlot ? <FilterDrawer.Trigger /> : null}
          <FilterDrawer.Content>{filterDrawerContentSlot}</FilterDrawer.Content>
        </FilterDrawer.Root>
      </FilterArea.Container>
    </FilterArea.Root>
  );
};

export const FormCategoriesTableFilterArea = ({
  filterConfigStorageKey,
  filterDrawerContentSlot,
}: Pick<StorageKeys, "filterConfigStorageKey"> & {
  hasCustomAttributeFilters?: boolean;
  filterDrawerContentSlot: ReactNode;
}) => {
  return (
    <FilterArea.Root storageKey={filterConfigStorageKey}>
      <FilterArea.Container aria-label="Filters for Form Categories">
        <FilterDrawer.Root>
          <FilterArea.Content>
            {({ handleOnChange }) => (
              <div style={{ marginRight: 8, marginBottom: 0 }}>
                <TextInput
                  name="name"
                  label="Search"
                  isLabelShown={false}
                  placeholder="Search Categories..."
                  type="search"
                  autocomplete="on"
                  isInline
                  onChange={(val) => handleOnChange(val, "name")}
                />
              </div>
            )}
          </FilterArea.Content>
          {filterDrawerContentSlot ? <FilterDrawer.Trigger /> : null}
          <FilterDrawer.Content>{filterDrawerContentSlot}</FilterDrawer.Content>
        </FilterDrawer.Root>
      </FilterArea.Container>
    </FilterArea.Root>
  );
};
