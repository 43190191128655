import { Form, TextInput } from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React from "react";

const EffectiveDurationForm = ({ onSubmit, formMethods, isLoading }) => (
  <Form
    onSubmit={onSubmit}
    {...formMethods}
  >
    <TextInput
      name="effectiveDuration"
      label="Effective Duration"
      type="number"
      unit="day(s)"
      step={1}
      isDisabled={isLoading}
      placeholder="days in effect"
      showIcon={true}
      isRequired={true}
    />
  </Form>
);

EffectiveDurationForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  formMethods: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default EffectiveDurationForm;
