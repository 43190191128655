import { DataSetSubmissionsListRoutePath } from "#src/routes/data-tools/submissions/datasets";
import { RoutePath } from "#src/utils/route";

export * from "./DataSetSubmissionDetailPage";

export const DataSetSubmissionDetailRoutePath =
  DataSetSubmissionsListRoutePath.concat(
    new RoutePath<"transactionId">({
      path: "/:transactionId/detail",
      title: "Dataset Submission",
    })
  );
