import {
  RoleDetailStatusPanel,
  RolePermissionsTablePanel,
} from "#src/batteries-included-components/Panels";
import classNames from "classnames/bind";
import React from "react";
import styles from "./RolePermissionsTab.module.scss";
const cx = classNames.bind(styles);

export const RolePermissionsTab = () => {
  return (
    <div className={cx("container")}>
      <div className={cx("contentStatus")}>
        <RoleDetailStatusPanel />
      </div>

      <div className={cx("contentContainer")}>
        <RolePermissionsTablePanel />
      </div>
    </div>
  );
};
