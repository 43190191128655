import {
  DataSetTransactionDetailItemType,
  extractTransactionItemData,
} from "#routes/data-tools/submissions/datasets/[transactionId]/detail/DataSetSubmissionDetail.helpers";
import {
  Button,
  Drawer,
  Icon,
  KeyValuePanel,
  Title,
} from "@validereinc/common-components";
import { TransactionDetailsItemErrorDetailItemType } from "@validereinc/domain";
import { toStartCaseString } from "@validereinc/utilities";
import classNames from "classnames/bind";
import React from "react";
import styles from "./DataSetSubmissionErrorDrawer.module.scss";

const cx = classNames.bind(styles);

const parseKeyFromErrorDetails = (
  item: TransactionDetailsItemErrorDetailItemType
) => {
  // Sometimes it's an array with single quotes
  try {
    const name = JSON.parse(item.field_name?.replace(/'/g, '"'));
    return name[0];
  } catch (e) {
    // Sometimes it's just a string
    return item.field_name;
  }
};

export const DataSetSubmissionErrorDrawer = ({
  items,
  itemIndex = -1,
  setItemIndex,
}: {
  items: DataSetTransactionDetailItemType[];
  itemIndex?: number;
  setItemIndex: (index?: number) => void;
}) => {
  const item = items[itemIndex];

  /** Fall back to a single panel if the error details aren't provided */
  const errorPanels = item?.error_details?.map((error) => {
    const rowKey = parseKeyFromErrorDetails(error);
    const data = item?.data ? extractTransactionItemData(item.data) : {};

    return (
      <KeyValuePanel
        key={error.field_name}
        panelProps={{
          title: error?.field_name
            ? toStartCaseString(error.field_name)
            : undefined,
          isFluidY: false,
        }}
        panelKeyValueListProps={{ variant: "shaded" }}
        panelMaxColumnCount={1}
        data={[
          {
            title: "Value",
            value: rowKey ? data?.[rowKey] : "-",
          },
          {
            title: "Message",
            value: (
              <div className={cx("errorText")}>
                {error?.error_message ?? "-"}
              </div>
            ),
          },
          {
            title: "Error Type",
            value: (
              <div className={cx("errorText")}>
                {error?.validation_type
                  ? toStartCaseString(error.validation_type)
                  : "-"}
              </div>
            ),
          },
          {
            title: "Supported Value Format",
            value: error?.validation_value ?? "-",
          },
        ]}
      />
    );
  }) ?? (
    <KeyValuePanel
      panelProps={{
        isFluidY: false,
      }}
      panelKeyValueListProps={{ variant: "shaded" }}
      panelMaxColumnCount={1}
      data={[
        {
          title: "Value",
          value: "-",
        },
        {
          title: "Message",
          value: item?.error_msg ? (
            <div className={cx("errorText")}>{item.error_msg}</div>
          ) : (
            "-"
          ),
        },
        {
          title: "Error Type",
          value: item?.error ? (
            <div className={cx("errorText")}>{item.error}</div>
          ) : (
            "-"
          ),
        },
        {
          title: "Supported Value Format",
          value: "-",
        },
      ]}
    />
  );

  return (
    <Drawer
      size="md"
      isOpen={!!item}
      onClose={() => {
        setItemIndex();
      }}
      title="Row Failure Details"
    >
      <div className={cx("titleContainer")}>
        <Icon
          variant="warning-circle"
          colour="#D83B3E"
        />
        <Title type="subheader">Row #{item?.index + 1}</Title>
        {items.length > 1 ? (
          <span className={cx("actionRow")}>
            <Button
              icon="arrow-circle-left"
              disabled={itemIndex === 0}
              onClick={() => setItemIndex(itemIndex - 1)}
            >
              Previous
            </Button>
            <Button
              icon="arrow-circle-right"
              disabled={itemIndex >= items.length - 1}
              onClick={() => setItemIndex(itemIndex + 1)}
            >
              Next
            </Button>
          </span>
        ) : null}
      </div>
      <Title
        className={cx("spacer")}
        type="panelheader"
      >
        Failed Columns
      </Title>
      {errorPanels}
    </Drawer>
  );
};
