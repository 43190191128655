import { useDownloadReport, useListReports } from "#hooks/adapters/useReports";
import { useGenerateTemplatedReport } from "#hooks/adapters/useTemplatedReports";
import { useGetManyUsers } from "#hooks/adapters/useUsers";
import { useTableSortingAndPagination } from "#redux/reducers/tableStateReducer";
import {
  DataTable,
  DataTablePanel,
  HeaderType,
  PillProps,
  SortingType,
  StorageKeys,
  Tag,
  useFilters,
} from "@validereinc/common-components";
import {
  ReportStatus,
  ReportStatusType,
  ReportType,
  ReportWithDownloadType,
  SortDirection,
  UserType,
} from "@validereinc/domain";
import { datetimeFormatter, toStartCaseString } from "@validereinc/utilities";
import classNames from "classnames/bind";
import isValid from "date-fns/isValid";
import isPlainObject from "lodash/isPlainObject";
import startCase from "lodash/startCase";
import React from "react";
import styles from "./ExportHistoryTablePanel.module.scss";

const sorting: SortingType = {
  sortBy: "created_at",
  sortDirection: SortDirection.DESCENDING,
};

const statusToPillMap: Record<ReportStatusType, PillProps["variant"]> = {
  [ReportStatus.EXPORTING]: "default",
  [ReportStatus.FAILED]: "error",
  [ReportStatus.SUCCESS]: "success",
};

const cx = classNames.bind(styles);

export const ExportHistoryTablePanel = ({
  filterConfigStorageKey,
  tableConfigStorageKey,
}: StorageKeys) => {
  const [filters] = useFilters<
    Pick<ReportType, "status" | "templated_report_name">
  >(filterConfigStorageKey);
  const [tableState, updateTableState] = useTableSortingAndPagination(
    sorting,
    filters
  );

  const { data, isLoading } = useListReports({
    page: tableState.page,
    pageSize: tableState.pageSize,
    sortBy: tableState.sortBy,
    sortDirection: tableState.sortDirection,
    filters: {
      ...(filters.status?.length ? { status: { $in: filters.status } } : {}),
      // REVIEW: the endpoint needs the empty string explicitly to filter out
      // all reports except templated reports even when none are selected to
      // filter for. This is intentional here but perhaps the endpoint needs a
      // better design?
      templated_report_name: !filters.templated_report_name?.length
        ? { $like: "" }
        : { $in: filters.templated_report_name },
      isAlreadyFormatted: true,
    },
  });

  const items = data?.data ?? [];

  const usersQuery = useGetManyUsers(
    Array.from(new Set(items.map(({ created_by }) => created_by))).filter(
      (item) => item
    )
  );

  const userMap = usersQuery.reduce(
    (accumulator: Record<string, UserType>, current) => {
      if (current.data?.id) {
        accumulator[current.data.id] = current.data;
      }
      return accumulator;
    },
    {}
  );

  const headers: Array<HeaderType<ReportWithDownloadType>> = [
    {
      label: "Report Name",
      key: "templated_report.display_name",
      isSortable: true,
    },
    // IMPROVE: show a report version or applicable year here in the future for more context and tie-in with the exportable reports tab where the report is actually exported
    {
      label: "Status",
      key: "status",
      isSortable: true,
      renderComponent: ({ item }) => (
        <DataTable.DataRow.PillCell
          variant={statusToPillMap[item.status] ?? "default"}
          value={toStartCaseString(item.status)}
        />
      ),
    },
    {
      label: "Inputs",
      key: "input",
      renderComponent: ({ item }: { item: ReportType }) => {
        if (!isPlainObject(item.input)) return "unknown";

        const associatedInputSchema = item.templated_report?.input_schema;

        return (
          <ol className={cx("tag-container")}>
            {Object.entries(item.input).map(([k, v]) => {
              // IMPROVE: lookup types automatically provide link to the associated resource in the app
              const displayValue =
                typeof v === "string" && v.includes("Z") && isValid(new Date(v))
                  ? datetimeFormatter(new Date(v))
                  : v;
              const displayLabel = associatedInputSchema
                ? associatedInputSchema[k]?.display_name ?? startCase(k)
                : startCase(k);

              return (
                <Tag
                  key={k}
                  as="li"
                  value={displayValue || "-"}
                  label={displayLabel || "Unknown"}
                />
              );
            })}
          </ol>
        );
      },
    },
    {
      label: "Exported At",
      key: "created_at",
      isSortable: true,
      renderComponent: ({ item }) => (
        <DataTable.DataRow.DateCell
          value={item.created_at}
          withTime
        />
      ),
    },
    {
      label: "Exported By",
      key: "created_by",
      isSortable: true,
      renderComponent: ({ item }) => userMap[item.created_by]?.name ?? "-",
    },
  ];

  const downloadReport = useDownloadReport();
  const generateReport = useGenerateTemplatedReport();

  const getItemActions = ({ item }: { item: ReportType }) => [
    /** Only allow templated reports to be re-run */
    ...(item?.templated_report_name
      ? [
          {
            label: "Re-run Export",
            buttonProps: {
              onClick: () => {
                generateReport.mutate({
                  templatedReportName: item.templated_report_name,
                  name: item.name,
                  input: item.input,
                });
              },
              disabled: generateReport.isLoading,
              icon: "arrow-counter-clockwise",
            },
          },
        ]
      : []),
    /** Only allow items with s3 ids to be downloaded */
    ...(item?.s3_object_id
      ? [
          {
            label: "Download Export",
            buttonProps: {
              onClick: () => {
                downloadReport.mutate({
                  id: item.id,
                });
              },
              disabled: downloadReport.isLoading,
              icon: "download",
            },
          },
        ]
      : []),
  ];

  return (
    <DataTablePanel
      storageKey={tableConfigStorageKey}
      panelProps={{ title: "Export History" }}
      dataTableProps={{
        isLoading,
        items,
        headers,
        getItemActions,
        sorting,
        onSortChange: updateTableState,
        onPaginationChange: updateTableState,
        pagination: {
          page: tableState.page,
          pageSize: tableState.pageSize,
          total: data?.total_entries,
        },
      }}
    />
  );
};
