import { useQueueUnique } from "#src/hooks/useQueue";
import { useQuery } from "@tanstack/react-query";
import { Banner, IconLoading } from "@validereinc/common-components";
import {
  Resources,
  TransactionAdapter,
  TransactionType,
} from "@validereinc/domain";
import classNames from "classnames/bind";
import React from "react";
import styles from "./ReImportDataActionStatus.module.css";

const cx = classNames.bind(styles);

export const getQueueUniqueId = (transactionId: string) =>
  `queue-reimport-data-action-${transactionId}`;

/**
 * Represents the status of a queued import data action
 */
export const ReImportDataActionStatus = ({
  transactionId,
  onDismiss,
}: ReImportDataActionStatusProps) => {
  const maxRetries = 3;
  const {
    data: transactionData,
    status: transactionDataStatus,
    failureCount: transactionDataFailureCount,
  } = useQuery(
    [Resources.TRANSACTION, transactionId, { meta: { history: true } }],
    () =>
      TransactionAdapter.getOne({
        id: transactionId,
        meta: { history: true },
      }),
    {
      enabled: Boolean(transactionId),
      refetchInterval: (data) => {
        if (!data) {
          return false;
        }

        if (
          data.data?.[0].status === "completed" ||
          data.data?.[0].status === "failed"
        ) {
          return false;
        }

        return 3000;
      },
      refetchIntervalInBackground: true,
      retry: maxRetries,
      retryDelay: (attempt) => attempt * 1000,
    }
  );

  const mapTransactionStatusToBannerVariant = (
    status?: TransactionType["status"]
  ) => {
    switch (status) {
      case "completed":
      case "completed_with_partial_success":
        return "success";
      case "failed":
        return "error";
      case "pending":
        return "info";
      default:
        return "generic";
    }
  };

  const mapTransactionToStatusDisplayText = (transaction?: TransactionType) => {
    if (!transaction) {
      return "Re-submitting data: in-progress...";
    }

    if (transaction.status !== "pending") {
      return transaction.status === "completed" ||
        transaction.status === "completed_with_partial_success"
        ? "Successfully re-submitted failed rows."
        : "Failed to re-submit data.";
    }

    const getDisplayTextForTransactionStep = (
      step: TransactionType["step"]
    ) => {
      switch (step) {
        case "transaction_recorder":
          return "Re-submitting data, Logging";
        case "vulnerability_scanner":
          return "Re-submitting data, Safety Checks";
        case "archiver":
          return "Re-submitting data, Backup";
        case "validator":
          return "Re-submitting data, Validation";
        case "operator":
          return "Re-submitting data, Processing";
        case "sink":
        default:
          return "Re-submitting data";
      }
    };

    const getDisplayTextForTransactionState = (
      state: TransactionType["state"]
    ) => {
      switch (state) {
        case "pending":
          return "In-Progress...";
        case "failed":
          return "Failed.";
        case "partial_failure":
          return "Partly failed.";
        case "partial_success":
          return "Partly succeeded.";
        case "retried":
          return "Retried.";
        case "success":
          return "Success.";
      }
    };

    return `${getDisplayTextForTransactionStep(
      transaction.step
    )}: ${getDisplayTextForTransactionState(transaction.state).toLowerCase()}`;
  };

  const handleDismiss = () => {
    if (onDismiss) {
      onDismiss(transactionId);
    }
  };

  const thisTransaction =
    transactionData?.data?.[transactionData.data.length - 1];
  const isImporting = thisTransaction && thisTransaction.status === "pending";
  const withinRetries = transactionDataFailureCount < maxRetries;
  // is this component still loading?
  const isLoading =
    withinRetries &&
    (transactionDataStatus === "loading" ||
      thisTransaction?.state === "retried");
  // this component doesn't have everything it needs
  const isError =
    (!withinRetries && transactionDataStatus === "error") ||
    transactionDataStatus === "error";

  if (isLoading || isError || !thisTransaction) {
    return (
      <Banner
        className={cx("banner")}
        variant={isLoading ? "generic" : "warning"}
        titleText={
          isLoading ? "Preparing re-submission..." : "Couldn't re-submit data."
        }
        icon={isLoading ? <IconLoading speed="slow" /> : undefined}
        isDismissable={!isLoading}
        onDismiss={handleDismiss}
      />
    );
  }

  return (
    <Banner
      className={cx("banner")}
      variant={mapTransactionStatusToBannerVariant(thisTransaction?.status)}
      titleText={mapTransactionToStatusDisplayText(thisTransaction)}
      icon={isImporting ? <IconLoading speed="fast" /> : undefined}
      isDismissable
      onDismiss={handleDismiss}
    />
  );
};

/**
 * Renders the ImportDataActionStatus BIC in-sync with a queue in local storage representing queued data import actions
 */
export const ReImportDataActionStatusWithQueue = ({
  transactionId,
}: ReImportDataActionStatusWithQueueProps) => {
  const [queue, setQueue] = useQueueUnique<TransactionType>(
    [],
    getQueueUniqueId(transactionId),
    (tx) => tx.transaction_id
  );

  const handleDismiss = (transactionId: string) => {
    setTimeout(() => {
      setQueue(queue.cutById(transactionId));
    }, 500);
  };

  return (
    <>
      {queue.size()
        ? queue.getAsArray().map((item) => (
            <ReImportDataActionStatus
              key={item.transaction_id}
              transactionId={item.transaction_id}
              onDismiss={handleDismiss}
            />
          ))
        : null}
    </>
  );
};

export type ReImportDataActionStatusProps = {
  transactionId: string;
  onDismiss?: (transactionId: string) => void;
};

export type ReImportDataActionStatusWithQueueProps = {
  transactionId: string;
};
