import { useEquipmentFilters } from "#hooks/filters/useEquipmentFilters";
import { useFacilityFilters } from "#hooks/filters/useFacilityFilters";
import { useFlowFilters } from "#hooks/filters/useFlowFilters";
import { useSearchParams } from "#routers/hooks";
import useLocalization from "#src/hooks/useLocalization";
import {
  ButtonToggleGroupInput,
  FilterPanel,
  StorageKeys,
} from "@validereinc/common-components";
import { AssetType } from "@validereinc/domain";
import React from "react";

export const CreateFlowFilterPanel = ({
  filterConfigStorageKey,
}: Pick<StorageKeys, "filterConfigStorageKey">) => {
  const { localize } = useLocalization();
  const filterOptions = { isWithCustomAttributes: true };
  const [searchParams, setSearchParams] = useSearchParams();

  const flowFilters = useFlowFilters(filterOptions);
  const equipmentFilters = useEquipmentFilters(filterOptions);
  const facilityFilters = useFacilityFilters(filterOptions);

  const { entity_type } = searchParams;

  let filters: Array<{
    section?: string;
    component: React.ReactElement;
  }> = [];

  switch (entity_type) {
    case AssetType.FACILITY:
      filters = facilityFilters;
      break;
    case AssetType.EQUIPMENT:
      filters = equipmentFilters;
      break;
    case AssetType.FLOW:
      filters = flowFilters;
      break;
    default:
  }

  return (
    <FilterPanel
      storageKey={filterConfigStorageKey}
      defaultActiveKeys={["Filters"]}
      defaultValue={{ entity_type }}
      filters={[
        {
          component: (
            <ButtonToggleGroupInput
              name="entity_type"
              key="entity-type-filter"
              items={[
                {
                  dataKey: AssetType.FACILITY,
                  label: localize("facility_plural"),
                },
                {
                  dataKey: AssetType.EQUIPMENT,
                  label: localize("equipment_plural"),
                },
                { dataKey: AssetType.FLOW, label: localize("flow_plural") },
              ]}
              onChange={(value?: string) => {
                setSearchParams({
                  // Don't overwrite search params unrelated to this component
                  ...searchParams,
                  entity_type: value,
                });
              }}
              isInline
            />
          ),
        },
        ...filters,
      ]}
    />
  );
};
