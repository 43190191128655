import { EntityDropdownInput } from "#batteries-included-components/Dropdowns/EntityDropdownInput";
import { EQUIPMENT_STATUS_OPTIONS } from "#constants";
import { useCustomAttributeFiltersV2 } from "#hooks/FilterPanel/useCustomAttributeFilters";
import { useListCalculatorResults } from "#hooks/adapters/useCalculatorResults";
import { useListCalculators } from "#hooks/adapters/useCalculators";
import { useListEquipmentTypes } from "#hooks/adapters/useEquipment";
import { useListFlowTypes } from "#hooks/adapters/useFlows";
import {
  EquipmentDropdownInput,
  FacilityDropdownInput,
  FlowDropdownInput,
} from "#src/batteries-included-components";
import { ReportingGroupFilterSwitcher } from "#src/batteries-included-components/Filters/ReportingGroupFilterSwitcher";
import { FilterArea, useFilterAreaContext } from "#src/components/FilterArea";
import { FilterDrawer } from "#src/components/FilterDrawer";
import { FACILITY_STATUS_OPTIONS } from "#src/constants";
import { DEFAULT_DATE_RANGES } from "#src/hooks/useDateRange";
import useLocalization from "#src/hooks/useLocalization";
import {
  SIMPLE_CALCULATOR_STATUS,
  SIMPLE_CALCULATOR_STATUS_OPTIONS,
} from "#src/utils/calculatorStatus";
import { useQueryClient } from "@tanstack/react-query";
import {
  Accordion,
  DateSelectorInput,
  DropdownInput,
  PillToggleGroup,
  PillToggleVariants,
  Skeleton,
  StorageKeys,
  TextInput,
} from "@validereinc/common-components";
import {
  AssetGroupStatus,
  AssetType,
  AssetTypeType,
  EquipmentDomain,
  EquipmentStatus,
  EstimationMethodStatus,
  FacilityDomain,
  FacilityStatus,
  FlowStatus,
  ReportingGroupDomain,
  Resources,
  type GetListResponseType,
  type ReportingGroupType,
} from "@validereinc/domain";
import { sortReportingGroupsByPriority } from "@validereinc/domain-controllers/logic/reporting-groups";
import { toStartCaseString } from "@validereinc/utilities";
import startCase from "lodash/startCase";
import React, { useMemo } from "react";
import { z } from "zod";

export const CALCULATIONS_FILTER_CONFIG = {
  dateRange: {
    name: "date_range",
    label: "Period",
  },
  reportingScenario: {
    name: "reporting_group_id",
  },
  status: {
    name: "status",
  },
  equipmentFacilityStatus: {
    name: "equipment.facility.status",
  },
  flowFacilityStatus: {
    name: "flow.facility.status",
  },
  flowStatus: {
    name: "flow.status",
  },
  equipmentStatus: {
    name: "equipment.status",
  },
  flowEquipmentStatus: {
    name: "flow.equipment.status",
  },
  calculatorName: {
    name: "analytics_calculator_id",
    placeholder: "Search Calculators",
    label: "Calculator",
  },
  equipment: {
    name: "equipment.id",
  },
  equipmentType: {
    name: "equipment.type.id",
  },
  flow: {
    name: "flow.id",
  },
  flowType: {
    name: "flow.type",
  },
  flowFacility: {
    name: "flow.associated_facility_id",
  },
  flowEquipment: {
    name: "flow.associated_equipment_id",
  },
  equipmentName: {
    name: "equipment.name",
  },
  facility: {
    name: "facility_id",
  },
  networkName: {
    name: "asset_group.name",
  },
  facilityName: {
    name: "equipment.facility.name",
  },
  estimationMethod: {
    name: "id",
  },
  estimationMethodStatus: {
    name: "estimation_method_status",
    label: "Estimation Method Status",
  },
  library: {
    name: "analytics_library_id",
  },
  calculator: {
    name: "analytics_calculator_id",
  },
  networkStatus: {
    name: "asset_group.status",
  },
} as const;

export const CalculationsViewFilterAreaSchema = z.object({
  date_range: z.object({
    from: z.string().datetime(),
    to: z.string().datetime().optional(),
  }),
  reporting_group_id: z.string(),
});

export type CalculationsViewFilterAreaType = z.infer<
  typeof CalculationsViewFilterAreaSchema
>;

export const CalculationsViewFilterArea = ({
  viewConfigStorageKey,
  isPeriodDateRange = true,
}: Pick<StorageKeys, "viewConfigStorageKey"> & {
  isPeriodDateRange: boolean;
}) => {
  const queryClient = useQueryClient();

  return (
    <FilterArea.Root
      storageKey={viewConfigStorageKey}
      defaultValues={async () => {
        const reportingGroupsQueryFromCache = queryClient.getQueryData<
          GetListResponseType<ReportingGroupType>
        >([Resources.REPORTING_GROUP]);
        let defaultReportingGroup = "";

        if (reportingGroupsQueryFromCache?.data) {
          defaultReportingGroup =
            sortReportingGroupsByPriority(reportingGroupsQueryFromCache.data)[0]
              ?.id ?? "";
        } else {
          const reportingGroups = await ReportingGroupDomain.getList({});

          queryClient.setQueryData(
            [Resources.REPORTING_GROUP],
            reportingGroups
          );

          defaultReportingGroup =
            sortReportingGroupsByPriority(reportingGroups.data)[0]?.id ?? "";
        }

        return {
          [CALCULATIONS_FILTER_CONFIG.dateRange.name]:
            DEFAULT_DATE_RANGES.lastWholeMonth,
          [CALCULATIONS_FILTER_CONFIG.reportingScenario.name]:
            defaultReportingGroup,
        };
      }}
      applyDefaultValues
    >
      <FilterArea.Container style={{ marginBottom: 16 }}>
        <FilterArea.Content>
          {({ handleOnChange }) => (
            <div style={{ marginRight: 8, display: "flex", gap: 8 }}>
              <ReportingGroupFilterSwitcher
                name={CALCULATIONS_FILTER_CONFIG.reportingScenario.name}
                onChange={(val) =>
                  handleOnChange(
                    val,
                    CALCULATIONS_FILTER_CONFIG.reportingScenario.name
                  )
                }
              />
              <DateSelectorInput
                name={CALCULATIONS_FILTER_CONFIG.dateRange.name}
                label={CALCULATIONS_FILTER_CONFIG.dateRange.label}
                isLabelShown={false}
                isOptionalTextShown={false}
                variant="month"
                isRange={isPeriodDateRange}
                isInline
                onChange={(val) =>
                  handleOnChange(val, CALCULATIONS_FILTER_CONFIG.dateRange.name)
                }
              />
            </div>
          )}
        </FilterArea.Content>
      </FilterArea.Container>
    </FilterArea.Root>
  );
};

export const CalculationsTableFilterAreaDrawerContent = ({
  assetType,
  showEstimationMethodStatusFilter,
}: {
  assetType: AssetTypeType;
  showEstimationMethodStatusFilter?: boolean;
}) => {
  const { localize } = useLocalization();
  const equipmentTypesQuery = useListEquipmentTypes({});
  const flowTypesQuery = useListFlowTypes();
  const calculatorQuery = useListCalculators();
  const validCalculatorsQuery = useListCalculatorResults({
    filters: {
      entity_type: { $exact: assetType },
    },
    groupBy: ["estimation_method.analytics_calculator_id"],
  });

  const validCalculatorIds =
    validCalculatorsQuery.data?.data.map(
      (item) => item["estimation_method.analytics_calculator_id"]
    ) ?? [];

  const calculatorOptions = useMemo(
    () =>
      calculatorQuery.data?.calculators && validCalculatorIds.length
        ? calculatorQuery.data.calculators
            .map((calculator) => ({
              value: calculator.id,
              label:
                calculator.versions.find(
                  (version) => version.version === calculator.default_version
                )?.title ?? calculator.id,
            }))
            .filter(({ value }) => validCalculatorIds.includes(value))
        : [],
    [calculatorQuery.data, validCalculatorIds]
  );

  const {
    customAttributeFilters: facilityCustomAttributeFilters,
    isLoading: isFacilityCustomAttributesLoading,
  } = useCustomAttributeFiltersV2({
    assetType: AssetType.FACILITY,
    prefix:
      assetType === AssetType.FLOW
        ? "flow.facility.custom_attributes"
        : "equipment.facility.custom_attributes",
  });

  const {
    customAttributeFilters: equipmentCustomAttributeFilters,
    isLoading: isEquipmentCustomAttributesLoading,
  } = useCustomAttributeFiltersV2({
    assetType: AssetType.EQUIPMENT,
    prefix:
      assetType === AssetType.FLOW
        ? "flow.equipment.custom_attributes"
        : "equipment.custom_attributes",
  });

  const isFlowCustomAttributesQueryEnabled = assetType === AssetType.FLOW; // Only show flow attributes for flow calcs
  const {
    customAttributeFilters: flowCustomAttributeFilters,
    isLoading: isFlowCustomAttributesLoading,
  } = useCustomAttributeFiltersV2({
    assetType: AssetType.FLOW,
    prefix: "flow.custom_attributes",
    enabled: isFlowCustomAttributesQueryEnabled,
  });

  const isAssetGroupCustomAttributesQueryEnabled =
    assetType === AssetType.ASSET_GROUP;
  const {
    customAttributeFilters: assetGroupCustomAttributeFilters,
    isLoading: isAssetGroupCustomAttributesLoading,
  } = useCustomAttributeFiltersV2({
    assetType: AssetType.ASSET_GROUP,
    prefix: "asset_group.custom_attributes",
    enabled: isAssetGroupCustomAttributesQueryEnabled,
  });

  const isLoading =
    calculatorQuery.isLoading ||
    isFacilityCustomAttributesLoading ||
    isEquipmentCustomAttributesLoading ||
    isAssetGroupCustomAttributesLoading ||
    (isFlowCustomAttributesQueryEnabled && isFlowCustomAttributesLoading);

  return (
    <Skeleton isLoading={isLoading}>
      {assetType !== AssetType.ASSET_GROUP ? (
        <DropdownInput
          name={CALCULATIONS_FILTER_CONFIG.calculatorName.name}
          label={CALCULATIONS_FILTER_CONFIG.calculatorName.label}
          placeholder="Search Calculator Name..."
          options={calculatorOptions}
          labelKey="label"
          valueKey="value"
          isLoading={calculatorQuery.isLoading}
          description="Select a single calculator to view inputs for each row"
        />
      ) : null}

      {assetType !== AssetType.ASSET_GROUP ? (
        <>
          {showEstimationMethodStatusFilter ? (
            <DropdownInput
              label={CALCULATIONS_FILTER_CONFIG.estimationMethodStatus.label}
              name={CALCULATIONS_FILTER_CONFIG.estimationMethodStatus.name}
              options={[
                {
                  label: startCase(EstimationMethodStatus.ACTIVE),
                  value: EstimationMethodStatus.ACTIVE,
                },
                {
                  label: startCase(EstimationMethodStatus.INACTIVE),
                  value: EstimationMethodStatus.INACTIVE,
                },
              ]}
              labelKey="label"
              valueKey="value"
              isFluid
              isOptionalTextShown={false}
              isSearchable={false}
            />
          ) : null}
          <TextInput
            name={CALCULATIONS_FILTER_CONFIG.estimationMethod.name}
            label="Estimation Method"
            placeholder="Search Estimation Methods..."
          />
        </>
      ) : null}
      <Accordion defaultActiveKeys={[assetType]}>
        {assetType === AssetType.EQUIPMENT || assetType === AssetType.FLOW ? (
          <Accordion.AccordionPanel
            dataKey={AssetType.FACILITY}
            title={localize(`${AssetType.FACILITY}_plural`)}
          >
            {assetType !== AssetType.FLOW ? (
              <TextInput
                name={CALCULATIONS_FILTER_CONFIG.facilityName.name}
                label={`${localize(AssetType.FACILITY)} Name`}
                placeholder={`Search ${localize(`${AssetType.FACILITY}_plural`)}...`}
                isOptionalTextShown={false}
                type="search"
              />
            ) : null}
            {assetType !== AssetType.FLOW ? (
              <EntityDropdownInput
                name={CALCULATIONS_FILTER_CONFIG.facility.name}
                placeholder={`Search ${localize(`${AssetType.FACILITY}_plural`)}...`}
                label={localize("Facility")}
                fetchEntityList={FacilityDomain.getList}
              />
            ) : null}
            <DropdownInput
              label={`${localize("Facility")} Status`}
              name={
                assetType === AssetType.EQUIPMENT
                  ? CALCULATIONS_FILTER_CONFIG.equipmentFacilityStatus.name
                  : CALCULATIONS_FILTER_CONFIG.flowFacilityStatus.name
              }
              key={
                assetType === AssetType.EQUIPMENT
                  ? CALCULATIONS_FILTER_CONFIG.equipmentFacilityStatus.name
                  : CALCULATIONS_FILTER_CONFIG.flowFacilityStatus.name
              }
              options={FACILITY_STATUS_OPTIONS}
              placeholder="Select Status..."
              labelKey="label"
              valueKey="value"
              isMulti
              isFluid
              isSearchable
              isOptionalTextShown={false}
            />
            {...facilityCustomAttributeFilters}
          </Accordion.AccordionPanel>
        ) : null}
        {assetType === AssetType.EQUIPMENT || assetType === AssetType.FLOW ? (
          <Accordion.AccordionPanel
            dataKey={AssetType.EQUIPMENT}
            title={localize(`${AssetType.EQUIPMENT}_plural`)}
          >
            {assetType !== AssetType.FLOW ? (
              <TextInput
                name={CALCULATIONS_FILTER_CONFIG.equipmentName.name}
                label={`${localize(AssetType.EQUIPMENT)} Name`}
                placeholder={`Search ${localize(`${AssetType.EQUIPMENT}_plural`)}...`}
                isOptionalTextShown={false}
                type="search"
              />
            ) : null}
            {assetType !== AssetType.FLOW ? (
              <EntityDropdownInput
                name={CALCULATIONS_FILTER_CONFIG.equipment.name}
                label={localize(AssetType.EQUIPMENT)}
                placeholder={`Search ${localize(`${AssetType.EQUIPMENT}_plural`)}...`}
                fetchEntityList={EquipmentDomain.getEquipment}
              />
            ) : null}
            <DropdownInput
              name={CALCULATIONS_FILTER_CONFIG.equipmentType.name}
              label={`${localize("Equipment")} Type`}
              options={equipmentTypesQuery.data?.data ?? []}
              placeholder="Select Type..."
              isLoading={equipmentTypesQuery.isLoading}
              labelKey="name"
              valueKey="id"
              isMulti
              isFluid
              isSearchable
              isOptionalTextShown={false}
            />
            <DropdownInput
              label={`${localize("Equipment")} Status`}
              name={
                assetType === AssetType.EQUIPMENT
                  ? CALCULATIONS_FILTER_CONFIG.equipmentStatus.name
                  : CALCULATIONS_FILTER_CONFIG.flowEquipmentStatus.name
              }
              key={
                assetType === AssetType.EQUIPMENT
                  ? CALCULATIONS_FILTER_CONFIG.equipmentStatus.name
                  : CALCULATIONS_FILTER_CONFIG.flowEquipmentStatus.name
              }
              options={EQUIPMENT_STATUS_OPTIONS}
              placeholder="Select Status..."
              labelKey="label"
              valueKey="value"
              isMulti
              isFluid
              isSearchable
              isOptionalTextShown={false}
            />
            {...equipmentCustomAttributeFilters}
          </Accordion.AccordionPanel>
        ) : null}
        {assetType === AssetType.ASSET_GROUP ? (
          <Accordion.AccordionPanel
            dataKey={AssetType.ASSET_GROUP}
            title={localize(`${AssetType.ASSET_GROUP}_plural`)}
          >
            <TextInput
              name={CALCULATIONS_FILTER_CONFIG.networkName.name}
              label={localize(AssetType.ASSET_GROUP)}
              placeholder={`Search ${localize(`${AssetType.ASSET_GROUP}_plural`)}...`}
              isOptionalTextShown={false}
              type="search"
            />
            <DropdownInput
              name={CALCULATIONS_FILTER_CONFIG.networkStatus.name}
              label={`${localize(AssetType.ASSET_GROUP)} Status`}
              options={Object.values(AssetGroupStatus).map((id) => ({
                id,
                name: toStartCaseString(id),
              }))}
              placeholder="Select Status..."
              labelKey="name"
              valueKey="id"
              isMulti
              isFluid
              isSearchable
              isOptionalTextShown={false}
            />
            {...assetGroupCustomAttributeFilters}
          </Accordion.AccordionPanel>
        ) : null}
        {assetType === AssetType.FLOW ? (
          <Accordion.AccordionPanel
            dataKey={AssetType.FLOW}
            title={localize(`${AssetType.FLOW}_plural`)}
          >
            <FlowDropdownInput
              name={CALCULATIONS_FILTER_CONFIG.flow.name}
              placeholder={`Search ${localize(`${AssetType.FLOW}_plural`)}...`}
              label={localize(AssetType.FLOW)}
              isMulti
            />
            <DropdownInput
              name={CALCULATIONS_FILTER_CONFIG.flowType.name}
              label={`${localize("Flow")} Type`}
              placeholder="Select Type..."
              options={flowTypesQuery.data ?? []}
              isLoading={flowTypesQuery.isLoading}
              labelKey="name"
              valueKey="id"
              isMulti
              isFluid
              isSearchable
              isOptionalTextShown={false}
            />
            <DropdownInput
              name={CALCULATIONS_FILTER_CONFIG.flowStatus.name}
              label={`${localize(AssetType.FLOW)} Status`}
              placeholder="Select Status..."
              options={Object.values(FlowStatus).map((id) => ({
                id,
                name: toStartCaseString(id),
              }))}
              labelKey="name"
              valueKey="id"
              isMulti
              isFluid
              isSearchable
              isOptionalTextShown={false}
            />
            <FacilityDropdownInput
              name={CALCULATIONS_FILTER_CONFIG.flowFacility.name}
              label={`Associated ${localize(AssetType.FACILITY)}`}
              placeholder={`Search ${localize(`${AssetType.FACILITY}_plural`)}...`}
              isMulti
            />
            <EquipmentDropdownInput
              name={CALCULATIONS_FILTER_CONFIG.flowEquipment.name}
              label={`Associated ${localize(AssetType.EQUIPMENT)}`}
              placeholder={`Search ${localize(`${AssetType.EQUIPMENT}_plural`)}...`}
              isMulti
            />
            {...flowCustomAttributeFilters}
          </Accordion.AccordionPanel>
        ) : null}
      </Accordion>
    </Skeleton>
  );
};

const getStatusDetailPillToggleVariant = (status: string) => {
  switch (status) {
    case SIMPLE_CALCULATOR_STATUS.simple_completed.name:
      return PillToggleVariants.GOOD;
    case SIMPLE_CALCULATOR_STATUS.simple_pending.name:
      return PillToggleVariants.NEUTRAL;
    case SIMPLE_CALCULATOR_STATUS.simple_missing.name:
      return PillToggleVariants.ATTENTION;
    default:
      return undefined;
  }
};

const CalculationsStatusPillToggles = ({
  onChange,
}: {
  onChange: (val: string[]) => void;
}) => {
  const { storedFilters } = useFilterAreaContext<{
    status: string[];
  }>();
  const pillToggles = [
    {
      name: "All",
      label: "All",
      value: "",
      isSelected:
        !storedFilters.status?.length || storedFilters.status?.includes(""),
      shouldSelectAll: true,
    },
    ...SIMPLE_CALCULATOR_STATUS_OPTIONS.map((status) => ({
      name: status.name,
      label: status.name,
      value: status.id,
      variant: getStatusDetailPillToggleVariant(status.name),
      isSelected: storedFilters.status?.includes(status.id) ?? false,
    })),
  ];

  return (
    <PillToggleGroup
      name="status"
      pills={pillToggles}
      onChange={(val) => onChange(val)}
    />
  );
};

export const CalculationsTableTitleDecorationFilterArea = ({
  filterConfigStorageKey,
}: Pick<StorageKeys, "filterConfigStorageKey">) => {
  return (
    <FilterArea.Root storageKey={filterConfigStorageKey}>
      <FilterArea.Container aria-label="Filters for Calculations">
        <FilterArea.Content>
          {({ handleOnChange }) => (
            <div style={{ marginRight: 8, display: "flex", gap: 8 }}>
              <CalculationsStatusPillToggles
                onChange={(val) =>
                  handleOnChange(val, CALCULATIONS_FILTER_CONFIG.status.name)
                }
              />
            </div>
          )}
        </FilterArea.Content>
      </FilterArea.Container>
    </FilterArea.Root>
  );
};

const getDefaultAssetStatus = (
  assetType: AssetTypeType,
  showEstimationMethodStatusFilter?: boolean
) => {
  switch (assetType) {
    case AssetType.ASSET_GROUP:
      return {
        [CALCULATIONS_FILTER_CONFIG.networkStatus.name]: [
          AssetGroupStatus.ACTIVE,
        ],
      };
    case AssetType.EQUIPMENT:
      return {
        [CALCULATIONS_FILTER_CONFIG.equipmentFacilityStatus.name]: [
          FacilityStatus.ACTIVE,
        ],
        [CALCULATIONS_FILTER_CONFIG.equipmentStatus.name]: [
          EquipmentStatus.ACTIVE,
        ],
        ...(showEstimationMethodStatusFilter
          ? {
              [CALCULATIONS_FILTER_CONFIG.estimationMethodStatus.name]:
                EstimationMethodStatus.ACTIVE,
            }
          : {}),
      };
    case AssetType.FLOW:
      return {
        [CALCULATIONS_FILTER_CONFIG.flowStatus.name]: FlowStatus.ACTIVE,
        [CALCULATIONS_FILTER_CONFIG.flowEquipmentStatus.name]: [
          EquipmentStatus.ACTIVE,
        ],
        [CALCULATIONS_FILTER_CONFIG.flowFacilityStatus.name]: [
          FacilityStatus.ACTIVE,
        ],
        ...(showEstimationMethodStatusFilter
          ? {
              [CALCULATIONS_FILTER_CONFIG.estimationMethodStatus.name]:
                EstimationMethodStatus.ACTIVE,
            }
          : {}),
      };
    default:
      return;
  }
};

export const CalculationsTableFilterArea = ({
  filterConfigStorageKey,
  assetType,
  showEstimationMethodStatusFilter,
}: {
  assetType: AssetTypeType;
  showEstimationMethodStatusFilter?: boolean;
} & Pick<StorageKeys, "filterConfigStorageKey">) => {
  const defaultAssetStatus = getDefaultAssetStatus(
    assetType,
    showEstimationMethodStatusFilter
  );

  return (
    <FilterArea.Root
      storageKey={filterConfigStorageKey}
      defaultValues={{
        ...defaultAssetStatus,
      }}
      applyDefaultValues
    >
      <FilterArea.Container aria-label="Filters for Calculations">
        <FilterDrawer.Root>
          <FilterDrawer.Trigger />
          <FilterDrawer.Content>
            <CalculationsTableFilterAreaDrawerContent
              assetType={assetType}
              showEstimationMethodStatusFilter={
                showEstimationMethodStatusFilter
              }
            />
          </FilterDrawer.Content>
        </FilterDrawer.Root>
      </FilterArea.Container>
    </FilterArea.Root>
  );
};
