import { useSearchParams } from "#routers/hooks";
import { useStorageKey } from "#src/hooks/useStorageKey";
import type { SortingType, StorageKeys } from "@validereinc/common-components";
import {
  AssetType,
  SortDirection,
  type AssetTypeType,
} from "@validereinc/domain";
import React from "react";
import { NetworkDevicesPanel } from "./NetworkDevicesPanel";
import { NetworkEquipmentPanel } from "./NetworkEquipmentPanel";
import { NetworkFacilitiesPanel } from "./NetworkFacilitiesPanel";
import { NetworkFlowsPanel } from "./NetworkFlowsPanel";

export const NETWORK_ENTITY_INITIAL_SORT: SortingType = {
  sortBy: "name",
  sortDirection: SortDirection.ASCENDING,
};

export const NetworkEntityTablePanel = ({
  viewConfigStorageKey,
}: Pick<StorageKeys, "viewConfigStorageKey">) => {
  const [searchParams] = useSearchParams<{ entity: AssetTypeType }>();
  const { entity } = searchParams || {};

  const storageKeys = useStorageKey(`networks-${entity}`);

  switch (entity) {
    case AssetType.FLOW:
      return (
        <NetworkFlowsPanel
          {...storageKeys}
          viewConfigStorageKey={viewConfigStorageKey}
        />
      );
    case AssetType.FACILITY:
      return (
        <NetworkFacilitiesPanel
          {...storageKeys}
          viewConfigStorageKey={viewConfigStorageKey}
        />
      );
    case AssetType.EQUIPMENT:
      return (
        <NetworkEquipmentPanel
          {...storageKeys}
          viewConfigStorageKey={viewConfigStorageKey}
        />
      );
    case AssetType.DEVICE:
      return (
        <NetworkDevicesPanel
          {...storageKeys}
          viewConfigStorageKey={viewConfigStorageKey}
        />
      );
    default:
      return null;
  }
};
