import { parseCustomReportConfiguration } from "#batteries-included-components/Layouts/CustomReport/CustomReportConfigurationPanel/CustomReportConfigurationPanel.helpers";
import { CustomReportDetailLayout } from "#batteries-included-components/Layouts/CustomReport/CustomReportDetailLayout";
import {
  getCustomReportFilters,
  getCustomReportGroupBy,
  useSavedCustomReport,
} from "#batteries-included-components/Layouts/CustomReport/CustomReportDetailLayout.helpers";
import { CreateCustomReportModal } from "#batteries-included-components/Modals/CustomReports/CreateCustomReportModal";
import { DeleteCustomReportModal } from "#batteries-included-components/Modals/CustomReports/DeleteCustomReportModal";
import { EditCustomReportModal } from "#batteries-included-components/Modals/CustomReports/EditCustomReportModal";
import { useNavigate, useParams } from "#routers/hooks";
import { CustomReportsListRoutePath } from "#routes/reports/custom-reports";
import { CustomReportsDetailRoutePath } from "#routes/reports/custom-reports/[reportVariant]/[reportId]/detail/index";
import { useAuthenticatedContext } from "#src/contexts/AuthenticatedContext.helpers";
import {
  CustomStorageKeyTypes,
  useCustomStorageKey,
  useStorageKey,
} from "#src/hooks/useStorageKey";
import { useBreadcrumbsFromRoute } from "#src/utils/route";
import {
  Button,
  DropdownMenu,
  Page,
  useFilters,
} from "@validereinc/common-components";
import { SavedFilterStatus, getFilterObject } from "@validereinc/domain";
import { toStartCaseString } from "@validereinc/utilities";
import React, { useState } from "react";

export const CustomReportDetailPage = () => {
  const navigate = useNavigate();
  const { reportVariant, reportId } =
    useParams<
      Exclude<typeof CustomReportsDetailRoutePath.pathParams, undefined>
    >();
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const storageKeys = useStorageKey("custom-report-detail");
  const configStorageKey = useCustomStorageKey(
    "custom-report-detail",
    CustomStorageKeyTypes.CONFIG
  );
  const {
    v2: {
      userInfo: { checkHasPermissions },
    },
  } = useAuthenticatedContext();

  const canWriteCompanyReports = checkHasPermissions(
    "company_custom_reports:write"
  );
  const canDeleteCompanyReports = checkHasPermissions(
    "company_custom_reports:delete"
  );

  const [filters] = useFilters(storageKeys.filterConfigStorageKey);
  const [configuration] = useFilters(configStorageKey);

  const savedFilter = useSavedCustomReport(reportVariant, reportId);
  const [breadcrumbs] = useBreadcrumbsFromRoute(CustomReportsDetailRoutePath, {
    "/details": {
      title: savedFilter?.name,
    },
  });

  const { rows } = parseCustomReportConfiguration(configuration);
  const filter = getFilterObject(
    getCustomReportFilters(reportVariant, configuration, filters)
  );

  return (
    <Page
      category={`${toStartCaseString(reportVariant)} Custom Report`}
      title={savedFilter?.name ?? CustomReportsDetailRoutePath.title}
      breadcrumbs={breadcrumbs}
      actionRow={[
        ...(savedFilter?.status === SavedFilterStatus.DRAFT ||
        canWriteCompanyReports
          ? [
              <DropdownMenu
                key="edit-custom-report"
                options={[
                  {
                    label: "Overwrite",
                    onClick: () => {
                      setIsEditModalOpen(true);
                    },
                  },
                  {
                    label: "Save as New",
                    onClick: () => {
                      setIsCreateModalOpen(true);
                    },
                  },
                ]}
              >
                <Button
                  variant="primary"
                  icon="caret-down"
                  iconPosition="right"
                >
                  Save Changes
                </Button>
              </DropdownMenu>,
            ]
          : []),
        ...(savedFilter?.status === SavedFilterStatus.DRAFT ||
        canDeleteCompanyReports
          ? [
              <Button
                key="delete-custom-report"
                variant="error-outline"
                onClick={() => setIsDeleteModalOpen(true)}
              >
                Delete
              </Button>,
            ]
          : []),
      ]}
    >
      <CustomReportDetailLayout
        configStorageKey={configStorageKey}
        reportVariant={reportVariant}
        {...storageKeys}
      />
      <CreateCustomReportModal
        variant={reportVariant}
        isOpen={isCreateModalOpen}
        onClose={() => {
          setIsCreateModalOpen(false);
        }}
        onSave={(id) => {
          navigate(
            CustomReportsDetailRoutePath.toLinkParts({
              pathParams: { reportId: id, reportVariant: reportVariant },
            })
          );
        }}
        filter={filter}
        groupBy={getCustomReportGroupBy(reportVariant, rows)}
      />
      <DeleteCustomReportModal
        onClose={() => {
          setIsDeleteModalOpen(false);
        }}
        onSuccess={() => {
          navigate(CustomReportsListRoutePath.toLinkParts());
        }}
        variant={reportVariant}
        customReportId={isDeleteModalOpen ? reportId : undefined}
      />
      <EditCustomReportModal
        onClose={() => setIsEditModalOpen(false)}
        customReportId={isEditModalOpen ? reportId : undefined}
        filter={filter}
        groupBy={getCustomReportGroupBy(reportVariant, rows)}
        name={savedFilter?.name}
        status={savedFilter?.status}
        variant={reportVariant}
      />
    </Page>
  );
};
