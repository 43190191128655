import { ReportingGroupFormPanel } from "#batteries-included-components/Panels/FormPanels/ReportingGroupFormPanel";
import { useBreadcrumbs } from "#routers/breadcrumbsHelper";
import { useNavigate } from "#routers/hooks";
import {
  SCENARIOS_BREADCRUMB,
  SCENARIO_LIST_BREADCRUMB,
  linkToScenarioList,
} from "#routes/scenarios";
import {
  CREATE_SCENARIO_BREADCRUMB,
  CREATE_SCENARIO_PAGE_TITLE,
} from "#routes/scenarios/create-scenario";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  Button,
  Form,
  FormButton,
  Page,
  useAlert,
  useForm,
} from "@validereinc/common-components";
import { ReportingGroupDomain } from "@validereinc/domain";
import { toSnakeCaseString } from "@validereinc/utilities";
import React from "react";

export const CreateScenarioPage = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const form = useForm({ defaultValues: { is_primary: false } });
  const { addAlert } = useAlert();

  const navigateToScenarioList = () => {
    navigate({ pathname: linkToScenarioList() });
  };

  const createScenario = useMutation({
    mutationFn: ({
      name,
      description,
      is_primary,
    }: {
      name: string;
      description: string;
      is_primary: boolean;
    }) => {
      // The backend requires us to set an 32-bit integer priority
      const priority = is_primary ? Math.floor(Date.now() / 1000) : 1;

      return ReportingGroupDomain.createOne({
        data: {
          id: toSnakeCaseString(name),
          name,
          description,
          priority,
        },
      });
    },
    onSuccess: async (_, { name }) => {
      addAlert({
        variant: "success",
        message: `Created new Scenario "${name}"`,
      });
      await queryClient.invalidateQueries({ queryKey: ["reportingGroups"] });
      navigateToScenarioList();
    },
    onError: (error) => {
      console.error(error);
      addAlert({
        variant: "error",
        message: "Unable to create Scenario",
      });
    },
  });

  const footer = (
    <>
      <Button
        onClick={navigateToScenarioList}
        disabled={createScenario.isLoading}
      >
        Cancel
      </Button>

      <FormButton
        variant="primary"
        type="submit"
        onClick={form.handleSubmit(createScenario.mutate)}
        disabled={createScenario.isLoading}
      >
        Save Scenario
      </FormButton>
    </>
  );

  return (
    <Form
      {...form}
      onSubmit={form.handleSubmit(createScenario.mutate)}
      style={{ height: "100%" }}
    >
      <Page
        title={CREATE_SCENARIO_PAGE_TITLE}
        breadcrumbs={useBreadcrumbs([
          SCENARIOS_BREADCRUMB,
          SCENARIO_LIST_BREADCRUMB,
          CREATE_SCENARIO_BREADCRUMB,
        ])}
        footer={footer}
      >
        <ReportingGroupFormPanel />
      </Page>
    </Form>
  );
};
