import { useHasPermission } from "#src/contexts/AuthenticatedContext.helpers";
import { useParams } from "#src/Routers/hooks";
import { Button, Dialog, Radio, Tooltip } from "@validereinc/common-components";
import { FormSubmissionType } from "@validereinc/domain";
import classNames from "classnames/bind";
import React, { useState } from "react";
import styles from "./ConfirmEditFormSubmissionDialog.module.css";

const cx = classNames.bind(styles);

const CONFIRM_EDIT_FORM_SUBMISSION_DIALOG_TITLE = "Save Submission Edits";

export const ConfirmEditFormSubmissionDialog = ({
  isOpen,
  onClose,
  handleSaveEdits,
  formValues,
}: {
  isOpen: boolean;
  onClose: () => void;
  handleSaveEdits: (
    formSubmissionId: string,
    formValues: Pick<FormSubmissionType, "answers">,
    shouldTriggerWorkflow: boolean
  ) => void;
  formValues?: Pick<FormSubmissionType, "answers">;
}) => {
  const [canEditFormSubmissions] = useHasPermission("form_submissions:edit");
  const { formTemplateId } = useParams<{
    formTemplateId: string;
  }>();
  const [shouldTriggerWorkflow, setShouldTriggerWorkflow] = useState(false);

  const actionRow = canEditFormSubmissions
    ? [
        <Button
          key="edit-submission-action"
          variant="primary"
          onClick={() => {
            if (formValues) {
              handleSaveEdits(
                formTemplateId,
                formValues,
                shouldTriggerWorkflow
              );
            }
            onClose();
          }}
        >
          Save Edits
        </Button>,
      ]
    : [
        <Tooltip
          key="no-permission-edit-submission"
          content="You do not have permission to modify the form submission"
        >
          <Button
            key="edit-submission-action"
            variant="primary"
            disabled
            icon="lock"
          >
            Save Edits
          </Button>
        </Tooltip>,
      ];

  return (
    <Dialog
      title={CONFIRM_EDIT_FORM_SUBMISSION_DIALOG_TITLE}
      isOpen={isOpen}
      onClose={() => {
        setShouldTriggerWorkflow(false);
        onClose();
      }}
      actionRow={actionRow}
    >
      <>
        Saving edits to the submission will overwrite all data from the previous
        submission.
        <br />
        <br />
        Be sure to address any workflows that may be associated with the
        previous submission. If there are workflows associated to this
        submission, you may decide whether to trigger new workflows.
      </>

      <div className={cx("radioContainer")}>
        <p className={cx("boldText")}>If workflow association exists</p>
        <Radio
          name="shouldTriggerNewWorkflow"
          value={shouldTriggerWorkflow}
          onChange={(newValue: boolean) => {
            setShouldTriggerWorkflow(newValue);
          }}
          options={[
            {
              label: "Update submission without triggering new workflow(s)",
              value: false,
            },
            {
              label: "Update submission and trigger new workflow(s)",
              value: true,
            },
          ]}
          labelKey="label"
          valueKey="value"
          isDisabled={false}
          isLoading={false}
        />
      </div>
    </Dialog>
  );
};
