export type CalculatorStatusRawType =
  | "not_configured"
  | "missing_inputs"
  | "ready_to_run"
  | "requires_re_run"
  | "run";

export type CalculatorStatusSimpleType =
  | "simple_missing"
  | "simple_pending"
  | "simple_completed";

export const SIMPLE_CALCULATOR_STATUS = {
  simple_missing: {
    id: "simple_missing",
    name: "Incomplete",
    variant: "warning",
  },
  simple_pending: {
    id: "simple_pending",
    name: "Ready",
    variant: "default",
  },
  simple_completed: {
    id: "simple_completed",
    name: "Complete",
    variant: "success",
  },
} as const;

export const SIMPLE_CALCULATOR_STATUS_OPTIONS = Object.values(
  SIMPLE_CALCULATOR_STATUS
);

const rawToSimpleStatusMap: Record<
  CalculatorStatusRawType,
  CalculatorStatusSimpleType
> = {
  not_configured: "simple_missing",
  missing_inputs: "simple_missing",
  ready_to_run: "simple_pending",
  requires_re_run: "simple_pending",
  run: "simple_completed",
};

const simpleToRawStatusMap: Record<
  CalculatorStatusSimpleType,
  CalculatorStatusRawType[]
> = {
  simple_missing: ["not_configured", "missing_inputs"],
  simple_pending: ["ready_to_run", "requires_re_run"],
  simple_completed: ["run"],
};

export const rawToSimpleStatus = (rawId: CalculatorStatusRawType) =>
  SIMPLE_CALCULATOR_STATUS[rawToSimpleStatusMap[rawId]];

export const simpleToRawStatuses = (
  simpleStatuses: CalculatorStatusSimpleType[]
) => {
  let simpleToRawStatuses: CalculatorStatusRawType[] = [];

  simpleStatuses?.forEach((simpleStatus) => {
    simpleToRawStatuses = simpleToRawStatuses.concat(
      simpleToRawStatusMap[simpleStatus]
    );
  });

  return simpleToRawStatuses;
};
