import { RoutingLink } from "#src/batteries-included-components/RoutingLink";
import { useTableSortingAndPagination } from "#src/components/Redux/reducers/tableStateReducer";
import { useAuthenticatedContext } from "#src/contexts/AuthenticatedContext.helpers";
import { linkToUserDetailPage } from "#src/routes/settings/users/detail";
import { useQuery } from "@tanstack/react-query";
import {
  DataTable,
  DataTablePanel,
  PillProps,
  StorageKeys,
  useFilters,
  useFormContext,
} from "@validereinc/common-components";
import type { UserType } from "@validereinc/domain";
import { SortDirection, UsersAdapter } from "@validereinc/domain";
import { toStartCaseString } from "@validereinc/utilities";
import classNames from "classnames/bind";
import React from "react";
import styles from "./CreateRoleUsersStep.module.scss";

const cx = classNames.bind(styles);

export const UserSelectionTablePanel = ({
  selectedUsers = [],
  filterConfigStorageKey,
}: {
  selectedUsers: UserType[];
} & StorageKeys) => {
  const {
    claims: {
      user: { id: currentUserId },
    },
  } = useAuthenticatedContext();
  const { setValue } = useFormContext();
  const [filters] = useFilters<{ name: string }>(filterConfigStorageKey);
  const [tableState, updateTableState] = useTableSortingAndPagination(
    {
      sortBy: "name",
      sortDirection: SortDirection.ASCENDING,
    },
    filters
  );
  const queryPayload = {
    page: tableState.page,
    pageSize: tableState.pageSize,
    sortBy: tableState.sortBy,
    sortDirection: tableState.sortDirection,
    filters,
  } satisfies Parameters<typeof UsersAdapter.getList>["0"];
  const usersQuery = useQuery(
    ["users", queryPayload],
    () => UsersAdapter.getList(queryPayload),
    { staleTime: 5 * 60 * 1000 }
  );

  const onAddUser = (item: UserType) => {
    setValue("selected_users", [...selectedUsers, item]);
  };

  return (
    <div className={cx("streamSelectionContainer")}>
      <div className={cx("streamTableContainer")}>
        <DataTablePanel
          panelProps={{
            title: "All Users",
          }}
          dataTableProps={{
            headers: [
              {
                label: "Name",
                key: "name",
                renderComponent: ({ item }: { item?: UserType }) => (
                  <RoutingLink to={linkToUserDetailPage(item?.id)}>
                    {item?.name}
                    {currentUserId === item?.id ? " (You)" : ""}
                  </RoutingLink>
                ),
              },
              { key: "email", label: "Email", isSortable: true },
              {
                key: "status",
                label: "Status",
                isSortable: true,
                renderComponent: ({ item }) => (
                  <DataTable.DataRow.PillCell
                    variant={
                      (
                        {
                          active: "success",
                          disabled: "default",
                        } satisfies Record<
                          UserType["status"],
                          PillProps["variant"]
                        >
                      )[item.status]
                    }
                    value={toStartCaseString(item.status)}
                  />
                ),
              },
              {
                key: "created_at",
                label: "Created At",
                isSortable: true,
                renderComponent: ({ item }) => (
                  <DataTable.DataRow.DateCell
                    value={item.created_at}
                    withTime={true}
                  />
                ),
              },
            ],
            items: usersQuery.data?.data ?? [],
            isLoading: usersQuery.isLoading,
            sorting: {
              sortBy: tableState.sortBy,
              sortDirection: tableState.sortDirection,
            },
            pagination: {
              page: tableState.page,
              pageSize: tableState.pageSize,
              total: usersQuery.data?.total_entries,
              pageSizeText: "users per page",
            },
            onSortChange: updateTableState,
            onPaginationChange: updateTableState,
            getItemActions: ({ item }: { item: UserType }) =>
              selectedUsers?.map(({ id }) => id).includes(item.id)
                ? []
                : [
                    {
                      label: "Add user",
                      buttonProps: {
                        onClick: () => onAddUser(item),
                        icon: "plus-circle",
                      },
                    },
                  ],
          }}
        />
      </div>
    </div>
  );
};
