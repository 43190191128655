import { AssociatedFlowTablePanel } from "#src/batteries-included-components";
import { TemplatedConfigurationRunStatusWithQueue } from "#src/batteries-included-components/Banners/TemplatedConfigurationRunStatus";
import { FacilitiesViewFilterArea } from "#src/batteries-included-components/FilterAreas/AssetsFilterAreas";
import { IsFeatureAvailable } from "#src/batteries-included-components/Layouts/Authorization/IsFeatureAvailable";
import { useIsFeatureAvailable } from "#src/contexts/AuthenticatedContext.helpers";
import useLocalization from "#src/hooks/useLocalization";
import {
  ButtonToggleGroup,
  type StorageKeys,
} from "@validereinc/common-components";
import {
  AssetType,
  FacilityType,
  Resources,
  type AssetTypeType,
} from "@validereinc/domain";
import React, { useState } from "react";
import { DevicesPanel } from "./DevicesPanel";
import FacilityDetailOverviewTab from "./FacilityDetailOverviewTab";
import { FacilityEquipmentPanel } from "./FacilityEquipmentPanel";

// IMPROVE: this component and sub-components need some cleanup
export const FacilityOverviewTab = ({
  facilityDetail,
  viewConfigStorageKey,
}: {
  facilityDetail?: FacilityType;
} & Pick<StorageKeys, "viewConfigStorageKey">) => {
  const { localize } = useLocalization();
  const [isEquipmentFeatureAvailable] = useIsFeatureAvailable({
    featureFlagQuery: "core:equipment",
    permissionQuery: "equipment:read",
  });
  const [isDevicesFeatureAvailable] = useIsFeatureAvailable({
    featureFlagQuery: "core:devices",
    permissionQuery: "devices:read",
  });
  const [isFlowsFeatureAvailable] = useIsFeatureAvailable({
    featureFlagQuery: "core:flows",
    permissionQuery: "flows:read",
  });
  const [selectedAssetTab, setSelectedAssetTab] = useState<AssetTypeType>(
    isEquipmentFeatureAvailable
      ? AssetType.EQUIPMENT
      : isFlowsFeatureAvailable
        ? AssetType?.FLOW
        : AssetType?.DEVICE
  );

  return (
    <>
      {/* If an equipment is created in this page, its status should be visible */}
      <TemplatedConfigurationRunStatusWithQueue
        primaryResourceType={Resources.EQUIPMENT}
      />
      <FacilitiesViewFilterArea viewConfigStorageKey={viewConfigStorageKey} />
      <FacilityDetailOverviewTab facilityDetail={facilityDetail} />
      <div style={{ marginBottom: 16 }}>
        <ButtonToggleGroup
          value={selectedAssetTab}
          items={[
            ...(isEquipmentFeatureAvailable
              ? [
                  {
                    label: localize(`${AssetType.EQUIPMENT}_plural`),
                    dataKey: AssetType.EQUIPMENT,
                  },
                ]
              : []),
            ...(isFlowsFeatureAvailable
              ? [
                  {
                    label: localize(`${AssetType.FLOW}_plural`),
                    dataKey: AssetType.FLOW,
                  },
                ]
              : []),
            ...(isDevicesFeatureAvailable
              ? [
                  {
                    label: localize(`${AssetType.DEVICE}_plural`),
                    dataKey: AssetType.DEVICE,
                  },
                ]
              : []),
          ]}
          onChange={setSelectedAssetTab}
        />
      </div>
      {facilityDetail && selectedAssetTab === AssetType.EQUIPMENT ? (
        <FacilityEquipmentPanel viewConfigStorageKey={viewConfigStorageKey} />
      ) : null}
      {facilityDetail && selectedAssetTab === AssetType.DEVICE ? (
        <IsFeatureAvailable
          featureFlagQuery="core:devices"
          permissionQuery="devices:read"
          fallbackChildren={null}
        >
          <DevicesPanel />
        </IsFeatureAvailable>
      ) : null}
      {facilityDetail && selectedAssetTab === AssetType.FLOW ? (
        <IsFeatureAvailable
          featureFlagQuery="core:flows"
          permissionQuery="flows:read"
          fallbackChildren={null}
        >
          <AssociatedFlowTablePanel
            id={facilityDetail.id}
            type="facility"
          />
        </IsFeatureAvailable>
      ) : null}
    </>
  );
};
