import { useAddFormSubmissionContext } from "#src/batteries-included-components/Layouts/Form/Submission/Add/AddFormSubmission/AddFormSubmissionContext";
import {
  EmptyState,
  EmptyStateProps,
  Icon,
  KeyValueTileGrid,
  Panel,
} from "@validereinc/common-components";
import React from "react";

export type SelectedFormSubmissionsPanelProps = {
  emptyStateProps: Pick<EmptyStateProps, "suggestion">;
};

export const SelectedFormSubmissionsPanel = ({
  emptyStateProps,
}: SelectedFormSubmissionsPanelProps) => {
  const { selectedFormSubmissions, deselectFormSubmission } =
    useAddFormSubmissionContext();

  return (
    <Panel
      title="Selected Form Submissions"
      isFluidY={false}
    >
      {selectedFormSubmissions?.length ? (
        <KeyValueTileGrid
          layout="even"
          data={selectedFormSubmissions.map(({ id, form_schema }) => ({
            title: "Submission Name",
            value: `${form_schema?.name} - ${id?.slice(0, 7)}`,
            action: {
              onClick: () => deselectFormSubmission(id),
              variant: "outline",
              icon: "minus-circle",
            },
          }))}
        />
      ) : (
        <EmptyState
          title="No Form Submissions Selected"
          icon={<Icon variant="stack" />}
          {...emptyStateProps}
        />
      )}
    </Panel>
  );
};
