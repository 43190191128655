import { RoutingLink } from "#src/batteries-included-components/RoutingLink";
import { FileDataDisplay } from "#src/components/DataDisplay/FileDataDisplay";
import { GeocoordinateDataDisplay } from "#src/components/DataDisplay/GeocoordinateDataDisplay";
import { linkToAssetDetailPage } from "#src/utils/links";
import {
  ArrayDataDisplay,
  CalculatedFieldDataDisplay,
  DataTable,
  MathDataDisplay,
  NumberDataDisplay,
  type HeaderType,
} from "@validereinc/common-components";
import {
  FormSchemaType,
  isMeasurementFormQuestion,
  type FormSchemaQuestionType,
  type FormSubmissionAnswersBySectionMapType,
  type FormSubmissionAnswerType,
  type FormSubmissionType,
  type MeasurementUnitType,
} from "@validereinc/domain";
import { FormCalculatedFieldService } from "@validereinc/domain-controllers/logic/forms";
import { CalculatedFieldEquationController } from "@validereinc/domain-controllers/view/forms";
import {
  booleanFormatter,
  datetimeFormatter,
  getFormattedNumber,
} from "@validereinc/utilities";
import isValid from "date-fns/isValid";
import isBoolean from "lodash/isBoolean";
import React from "react";

export const getHeaderVisualConfigBasedOnFormSubmissionQuestion = (
  question: FormSchemaQuestionType
): Pick<HeaderType<FormSubmissionType>, "alignment" | "minWidth"> => {
  switch (question.type) {
    case "question": {
      switch (question.data_type) {
        case "integer":
        case "number":
          return {
            alignment: "right",
          };
        default:
          return {};
      }
    }
    case "measurement":
      return {
        alignment: "right",
      };
  }
};

export const renderFormSubmissionAnswer = (
  answer?: FormSubmissionAnswerType,
  questionId: string,
  {
    measurementUnits,
    schema,
    sectionId,
    sectionIdx,
    allAnswers,
    allQuestions,
  }: {
    measurementUnits: MeasurementUnitType[];
    schema: FormSchemaType;
    sectionId: string;
    sectionIdx: number;
    allAnswers: FormSubmissionAnswersBySectionMapType;
    allQuestions: FormSchemaType["config"]["questions"];
  }
) => {
  const question = schema.config.questions[questionId];

  if (!answer) return "-";

  if (question.equation) {
    const equationVariablesAndValues =
      FormCalculatedFieldService.getEquationVariablesAndValuesFromSubmission(
        question.equation,
        sectionId,
        sectionIdx,
        allAnswers,
        allQuestions
      );
    const displayMeasurementUnitId =
      FormCalculatedFieldService.getMeasurementUnitFromQuestion(
        question,
        false
      );
    const displayMeasurementUnit = measurementUnits.find(
      ({ id }) => displayMeasurementUnitId === id
    );

    return (
      <CalculatedFieldDataDisplay
        value={answer.value}
        displaySlot={(props) => (
          <NumberDataDisplay
            {...props}
            unit={
              displayMeasurementUnit?.name?.symbol ?? displayMeasurementUnitId
            }
          />
        )}
        alignment="right"
        showHint
        isValid
        hintSlot={
          <>
            This was calculated using the equation:{" "}
            <CalculatedFieldEquationController
              equation={question.equation}
              questionsMap={schema.config.questions}
              sourceFieldValuesMap={equationVariablesAndValues}
              getTokens
            >
              {({ tokens }) => (
                <MathDataDisplay.Root>
                  <MathDataDisplay
                    colorScheme="light"
                    style={{ whiteSpace: "nowrap", fontSize: 14 }}
                  >
                    {tokens.map((t, idx) => {
                      if (t.startGroup || t.endGroup) {
                        return <span key={idx}>{t.displayValue}</span>;
                      } else if (t.isVariable) {
                        const displayMeasurementUnit = measurementUnits.find(
                          ({ id }) => t.unit === id
                        );
                        const unit =
                          displayMeasurementUnit?.name?.symbol ?? t.unit;

                        return (
                          <MathDataDisplay.InteractiveGroup
                            key={idx}
                            value={t.value}
                            displayValue={
                              t.value !== undefined &&
                              t.value !== null &&
                              String(t.value) !== ""
                                ? t.value.toString()
                                : null
                            }
                            description={`This is the answer from the question of the same name.${unit ? ` In units: "${unit}"` : ""}`}
                          >
                            &quot;{t.displayValue}&quot;
                          </MathDataDisplay.InteractiveGroup>
                        );
                      } else {
                        return (
                          <span
                            key={idx}
                            dangerouslySetInnerHTML={{
                              __html: t.displayValue,
                            }}
                          ></span>
                        );
                      }
                    })}
                  </MathDataDisplay>
                </MathDataDisplay.Root>
              )}
            </CalculatedFieldEquationController>
          </>
        }
      />
    );
  }

  if (isMeasurementFormQuestion(question)) {
    if (!answer?.subject_type || !answer?.subject_id) return answer.value;

    const displayMeasurementUnit = measurementUnits.find(
      ({ id }) => schema.config.questions[questionId].measurement_unit === id
    );

    const displayValue =
      Number(answer.value) > 10 ** 6
        ? Number(answer.value).toPrecision(6)
        : getFormattedNumber(answer.value, null, {
            errorFallback: "n/a",
            maxFractionDigits: 6,
            showSmallNumberAsExponential: true,
            smallNumberThreshold: 0.01,
          });

    return (
      <RoutingLink
        style={{ display: "block", textAlign: "right" }}
        to={{
          pathname: linkToAssetDetailPage(
            answer.subject_type,
            answer.subject_id
          ),
          query: {
            tab: "measurements",
          },
        }}
      >
        {displayValue}
        {displayMeasurementUnit?.name?.symbol ??
          schema.config.questions[questionId].measurement_unit}
      </RoutingLink>
    );
  }

  // IMPROVE: need better renderers for complex data types
  switch (question.data_type) {
    case "geo_point":
      return <GeocoordinateDataDisplay coordinates={answer.value} />;
    case "file": {
      if (!answer.value?.name || !answer.value?.ref) {
        return "-";
      }

      return (
        <FileDataDisplay
          fileName={answer.value.name}
          fileId={answer.value.ref}
          prefetch={false}
        />
      );
    }
    case "lookup":
      if (!answer?.entity_type || !answer?.value) {
        return answer?.name ?? "-";
      }

      return (
        <RoutingLink
          to={linkToAssetDetailPage(answer?.entity_type, answer?.value)}
        >
          {answer?.name}
        </RoutingLink>
      );
    case "multi-pick-list": {
      return <ArrayDataDisplay value={answer.value} />;
    }
    case "pick-list": {
      if (typeof answer.value !== "string" && !Array.isArray(answer.value))
        return "-";

      return answer.value;
    }
    case "date": {
      if (typeof answer.value !== "string" && !isValid(answer.value))
        return "-";

      return (
        <DataTable.DataRow.DateCell
          value={answer.value}
          withTime={false}
        />
      );
    }
    case "date-time": {
      if (typeof answer.value !== "string" && !isValid(answer.value))
        return "-";

      return (
        <DataTable.DataRow.DateCell
          value={answer.value}
          withTime
        />
      );
    }
    case "date-time-range":
      return `${datetimeFormatter(
        new Date(answer.value?.[0])
      )} - ${datetimeFormatter(new Date(answer.value?.[1]))}`;
    case "boolean": {
      if (!isBoolean(answer.value)) return "-";

      return booleanFormatter(Boolean(answer.value));
    }
    case "number": {
      return (
        <DataTable.DataRow.NumberCell
          value={answer.value}
          maxFractionDigits={6}
          smallNumberThreshold={0.01}
          showNumberAsExponential
          {...(Number(answer.value) > 10 ** 6 ? { precision: 6 } : {})}
        />
      );
    }
    case "integer": {
      return (
        <DataTable.DataRow.NumberCell
          value={answer.value}
          precision={Number(answer.value) > 10 ** 6 ? 6 : 0}
        />
      );
    }
    case "string": {
      if (typeof answer.value !== "string") return "-";

      return <DataTable.DataRow.TextCell value={answer.value} />;
    }
    default:
      return "n/a";
  }
};

export const renderFormSectionName = (
  sectionId: string,
  section_index: number,
  schema: FormSchemaType
) => {
  const sectionName =
    schema?.config?.sections?.find((section) => section.id === sectionId)
      ?.name || "";

  const sectionIndexAsNumber = Number(section_index);
  return `${sectionName} ${!isNaN(sectionIndexAsNumber) ? sectionIndexAsNumber + 1 : ""}`;
};

export const getHeadersFromSchema = (
  measurementUnits: MeasurementUnitType[],
  schema?: FormSchemaType
): Array<HeaderType<FormSubmissionType>> => {
  if (!schema) return [];

  return schema.config.sections.map<HeaderType<FormSubmissionType>>((s) => {
    return {
      key: s.id,
      label: `${s.name}${s.is_repeatable ? " (Repeatable)" : ""}`,
      headers: s.questions.map((qid) => ({
        label: schema.config.questions[qid].prompt,
        key: `${s.id}.${qid}`,
        tooltip: schema.config.questions[qid].description,
        minWidth: 180,
        renderComponent: ({ item }) => {
          if (!item.answers || !Array.isArray(item.answers[s.id]))
            return <>n/a</>;

          const firstFilledSectionIdx = item.answers[s.id].findIndex(
            (sec) => !!sec
          );
          const firstFilledSection = item.answers[s.id][firstFilledSectionIdx];
          const answer = firstFilledSection?.[qid];
          const thisAndOtherRepeatedSectionsWithAnswers = item.answers[
            s.id
          ].reduce((count, sec, idx) => {
            if (
              idx === firstFilledSectionIdx ||
              !sec ||
              !Object.keys(sec).length ||
              sec?.[qid] === undefined
            ) {
              return count;
            }

            return count + 1;
          }, 0);

          // IMPROVE: better UI to show multiple answers
          return thisAndOtherRepeatedSectionsWithAnswers > 1 ||
            (thisAndOtherRepeatedSectionsWithAnswers >= 1 &&
              item.answers[s.id].length > 1) ? (
            <>
              {answer !== undefined
                ? "(multiple answers)"
                : "(answers in other sections)"}
            </>
          ) : (
            renderFormSubmissionAnswer(answer, qid, {
              schema,
              measurementUnits,
              sectionId: s.id,
              sectionIdx: 0,
              allAnswers: item.answers,
              allQuestions: schema.config.questions,
            })
          );
        },
      })),
    };
  });
};
