import { useNavigate } from "#src/Routers/hooks";
import { linkToFormSubmissionDetail } from "#src/Routers/links";
import { useDeleteOneFormSubmission } from "#src/components/hooks/adapters/useFormSubmissions";
import { linkToCreateFormSubmission } from "#src/routes/forms/categories/[categoryId]/templates/[formTemplateId]/create-form-submission";
import { linkToUpdateFormSubmission } from "#src/routes/forms/categories/[categoryId]/templates/[formTemplateId]/update-form-submission";
import { WorkflowTaskAddFormSubmissionRoutePath } from "#src/routes/workflows/all/[workflowId]/detail/task/[taskId]/form-submission/add";
import { ExceptionUtils } from "#src/utils/exception";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  Accordion,
  Button,
  DropdownMenu,
  EmptyState,
  KeyValueList,
  Link,
  Pill,
  PillProps,
} from "@validereinc/common-components";
import {
  BaseError,
  FormSubmissionType,
  UsersAdapter,
  WorkflowFormSubmissionTaskType,
  WorkflowTaskType,
  WorkflowTaskWithFormSubmissionType,
  WorkflowType,
  isWorkflowTaskWithFormSubmission,
  type FormSchemaType,
} from "@validereinc/domain";
import { datetimeFormatter } from "@validereinc/utilities";
import classNames from "classnames/bind";
import { Base64 } from "js-base64";
import React, { useMemo } from "react";
import styles from "./WorkflowFormSubmission.module.scss";

type WorkflowFormSubmissionProps = {
  workflowTask: WorkflowTaskType;
  formSchema?: FormSchemaType;
  workflow?: WorkflowType;
};

const cx = classNames.bind(styles);

const WorkflowTaskFormSubmissionEntry = ({
  submission,
  formSchema,
}: {
  submission: WorkflowTaskWithFormSubmissionType["form_submission"][0];
  formSchema: FormSchemaType;
}) => {
  const navigate = useNavigate();
  const { data: createdByUser } = useQuery(
    ["users", submission.created_by],
    () => {
      if (!submission.created_by) return;
      return UsersAdapter.getOne({ id: submission.created_by });
    },
    {
      enabled: Boolean(submission.created_by),
      staleTime: 5 * 60 * 1000,
      select: (resp) => resp?.data,
    }
  );

  const title = `${
    submission.form_schema?.name ? `${submission.form_schema.name} - ` : ""
  }${submission.id.split("-")[0]}`;

  const getStatusPillVariant = (
    status: FormSubmissionType["status"]
  ): PillProps["variant"] => {
    const map: Partial<
      Record<FormSubmissionType["status"], PillProps["variant"]>
    > = {
      pending: "warning",
      submitted: "info",
      validated: "success",
      invalidated: "error",
    };

    return map[status] ?? "default";
  };

  return (
    <KeyValueList
      data={[
        {
          title: "Form Submission",
          value: (
            <Link
              label={title}
              onClick={() =>
                navigate({
                  pathname:
                    submission.status === "draft"
                      ? linkToUpdateFormSubmission(
                          formSchema.form_category.id,
                          formSchema.id,
                          submission.id
                        )
                      : linkToFormSubmissionDetail(submission.id),
                })
              }
            />
          ),
        },
        {
          title: "Status",
          value: (
            // REVIEW: something is up with the BE schemas
            <Pill variant={getStatusPillVariant(submission.status)}>
              {submission.status}
            </Pill>
          ),
        },
        {
          title: "Submitted By",
          value: createdByUser?.name ?? submission.created_at,
        },
        {
          title: "Date Submitted",
          value: datetimeFormatter(new Date(submission.created_at)),
        },
      ]}
    />
  );
};

export const WorkflowTaskFormSubmission = ({
  workflowTask,
  formSchema,
  workflow,
}: WorkflowFormSubmissionProps) => {
  const encodedAndSerializedDefaultValues = useMemo(() => {
    let sanitizedDefaultValuesObject = {} as Record<string, string>;
    const prepopulatedValuesForFormSubmission =
      (
        workflow?.config.steps?.[workflowTask.step_id]
          ?.task as WorkflowFormSubmissionTaskType
      )?.input ?? {};

    sanitizedDefaultValuesObject = Object.fromEntries(
      Object.entries(prepopulatedValuesForFormSubmission)
        .map(([questionPath, defaultValue]) => {
          if (defaultValue.startsWith("$")) {
            return [
              questionPath,
              workflow?.variables?.[defaultValue] ?? "",
            ] as [string, string];
          }
          return [questionPath, defaultValue] as [string, string];
        })
        .filter(([_, value]) => value !== undefined && value !== null)
    );

    try {
      return Base64.encode(JSON.stringify(sanitizedDefaultValuesObject));
    } catch (e) {
      // Return a default value of an encoded empty object + error reporting:
      ExceptionUtils.reportException(
        new BaseError(
          `Could not encode form submission default values from workflow: ${JSON.stringify(sanitizedDefaultValuesObject)}`
        ),
        "error"
      );
      return Base64.encode(JSON.stringify({}));
    }
  }, [workflow, workflowTask?.step_id]);

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { mutate: removeFormSubmissionFromTask } = useDeleteOneFormSubmission({
    successMessage: "Successfully removed form submission association to task",
    errorMessage: "Unable to remove form submission association to task",
    customInvalidateFunction: () => {
      queryClient.invalidateQueries({
        queryKey: ["workflows"],
      });
    },
  });

  const hasSubmissions = isWorkflowTaskWithFormSubmission(workflowTask)
    ? workflowTask.form_submission.length > 0
    : false;

  // loading state
  if (!formSchema) {
    return <div className={cx("skeleton", "skeleton--fill")}></div>;
  }
  return (
    <>
      <h6 className={cx("title")}>Form Submissions</h6>
      <div>
        <div className={cx("attachSubmissionTrigger")}>
          <DropdownMenu
            options={[
              {
                label: "New Submission",
                onClick: () =>
                  navigate({
                    pathname: linkToCreateFormSubmission(
                      formSchema?.form_category_id,
                      formSchema.id
                    ),
                    query: {
                      "task-id": workflowTask.id,
                      "associated-asset-id":
                        workflowTask.workflow?.facility?.id,
                      "associated-asset-type": "facility",
                      "default-values": encodedAndSerializedDefaultValues,
                    },
                  }),
              },
              {
                label: "Existing Submission",
                onClick: () =>
                  navigate(
                    WorkflowTaskAddFormSubmissionRoutePath.toLinkParts({
                      pathParams: {
                        workflowId: workflowTask.workflow_id,
                        taskId: workflowTask.id,
                      },
                      queryParams: {
                        formSchemaId: formSchema.id,
                      },
                    })
                  ),
              },
            ]}
          >
            <Button
              icon="caret-down"
              iconPosition="right"
            >
              Add Form Submission
            </Button>
          </DropdownMenu>
        </div>
        {!hasSubmissions ? (
          <EmptyState
            title="No form submissions have been added"
            suggestion="Add a new submission or an existing submission using the button above"
          />
        ) : (
          <Accordion
            defaultActiveKeys={
              workflowTask.form_submission?.map(
                (submission) => submission.id
              ) ?? []
            }
          >
            {(workflowTask.form_submission ?? []).map((submission) => (
              <Accordion.AccordionPanel
                key={submission.id}
                title={`${
                  submission.form_schema?.name
                    ? `${submission.form_schema.name} - `
                    : ""
                }${submission.id.split("-")[0]}`}
                dataKey={submission.id}
              >
                <>
                  <WorkflowTaskFormSubmissionEntry
                    key={submission.id}
                    submission={submission}
                    formSchema={formSchema}
                  />
                  <Button
                    variant="outline"
                    icon="trash"
                    onClick={() => {
                      removeFormSubmissionFromTask({
                        id: submission.id,
                      });
                    }}
                    disabled={workflowTask.status !== "open"}
                  >
                    Remove
                  </Button>
                </>
              </Accordion.AccordionPanel>
            ))}
          </Accordion>
        )}
      </div>
    </>
  );
};
