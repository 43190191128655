import { useNavigate } from "#src/Routers/hooks";
import { RoutingLink } from "#src/batteries-included-components/RoutingLink";
import { useTableSortingAndPagination } from "#src/components/Redux/reducers/tableStateReducer";
import { useHasPermission } from "#src/contexts/AuthenticatedContext.helpers";
import { UserGroupDetailsRoutePath } from "#src/routes/settings/users/groups/[groupId]/details";
import { UserGroupCategoryDetailsRoutePath } from "#src/routes/settings/users/groups/categories/[categoryId]/details";
import { GroupCreateRoutePath } from "#src/routes/settings/users/groups/create";
import { useQuery } from "@tanstack/react-query";
import {
  Button,
  DataTable,
  DataTablePanel,
  Icon,
  StorageKeys,
  useFilters,
} from "@validereinc/common-components";
import {
  UserGroupCategoriesAdapter,
  UserGroupSchema,
  UserGroupType,
  UserGroupsAdapter,
  UserType,
  UsersAdapter,
} from "@validereinc/domain";
import React, { useMemo } from "react";

const UserGroupsTablePanel = ({
  tableConfigStorageKey,
  filterConfigStorageKey,
}: Pick<StorageKeys, "tableConfigStorageKey" | "filterConfigStorageKey">) => {
  const [tableState, setTableState] = useTableSortingAndPagination();
  const [isEditAllowed] = useHasPermission("user_group_categories:write");
  const [isUserGroupsReadAllowed] = useHasPermission("user_groups:read");
  const navigate = useNavigate();

  const [filters] = useFilters<{
    search: string;
    group_category_id: string;
  }>(filterConfigStorageKey);

  const groupsQueryPayload: Parameters<typeof UserGroupsAdapter.getList>[0] = {
    page: tableState.page,
    pageSize: tableState.pageSize,
    sortBy: tableState.sortBy,
    sortDirection: tableState.sortDirection,
    filters: {
      isAlreadyFormatted: true,
      ...(filters.search
        ? {
            $or: [
              { name: { $like: filters.search } },
              { description: { $like: filters.search } },
            ],
          }
        : {}),
      ...(filters.group_category_id
        ? {
            group_category_id: filters.group_category_id,
          }
        : {}),
    },
  };

  const groupsQuery = useQuery({
    queryKey: ["users", "groups", groupsQueryPayload],
    queryFn: () => UserGroupsAdapter.getList(groupsQueryPayload),
  });

  const userIdsToFetchDetailsFor = useMemo<string[]>(
    () =>
      (
        groupsQuery.data?.data.flatMap((i) => [i.created_by, i.updated_by]) ??
        []
      ).filter((id) => !!id) as string[],
    [groupsQuery]
  );

  const metaUsersQuery = useQuery({
    queryKey: ["users", { filters: { id: userIdsToFetchDetailsFor } }],
    queryFn: () =>
      UsersAdapter.getList({ filters: { id: userIdsToFetchDetailsFor } }),
    enabled: !!userIdsToFetchDetailsFor.length,
  });

  const metaUsersQueryMap = useMemo(() => {
    return (
      metaUsersQuery.data?.data.reduce<Record<string, UserType>>(
        (map, data) => {
          if (!data || map[data.id]) return map;

          map[data.id] = data;
          return map;
        },
        {}
      ) ?? {}
    );
  }, [metaUsersQuery]);

  const items = groupsQuery.data?.data ?? [];

  const uniqueCategoryIdsToFetch = Array.from(
    new Set(items.map((item) => item.group_category_id))
  );

  const categoriesQuery = useQuery({
    queryKey: ["users", "groups", "categories", uniqueCategoryIdsToFetch],
    queryFn: () =>
      UserGroupCategoriesAdapter.getList({
        filters: {
          id: uniqueCategoryIdsToFetch,
        },
      }),
    enabled: uniqueCategoryIdsToFetch.length > 0,
  });

  const categoryObjectsById = Object.fromEntries(
    (categoriesQuery.data?.data ?? []).map((category) => [
      category.id,
      category,
    ])
  );

  return (
    <DataTablePanel<UserGroupType>
      storageKey={tableConfigStorageKey}
      panelProps={{
        title: "Groups",
      }}
      actionRowWhenNoRowsSelected={[
        <Button
          key="create"
          iconPosition="left"
          variant="primary"
          size="x-small"
          icon={!isEditAllowed ? "lock" : "plus-circle"}
          disabled={!isEditAllowed}
          onClick={() =>
            navigate({
              pathname: GroupCreateRoutePath.toLink(),
            })
          }
        >
          Create Group
        </Button>,
      ]}
      dataTableProps={{
        isLoading:
          groupsQuery.isLoading ||
          (metaUsersQuery.isLoading && userIdsToFetchDetailsFor.length > 0) ||
          (uniqueCategoryIdsToFetch.length > 0 && categoriesQuery.isLoading),
        headers: [
          {
            key: UserGroupSchema.keyof().Enum.name,
            label: "Name",
            isSortable: true,
            renderComponent: ({ item }) => {
              return (
                <RoutingLink
                  to={UserGroupDetailsRoutePath.toLink({
                    pathParams: { groupId: item.id },
                  })}
                >
                  {item.name}
                </RoutingLink>
              );
            },
          },
          {
            key: UserGroupSchema.keyof().Enum.description,
            label: "Description",
            isSortable: true,
            renderComponent: ({ item }) => (
              <>{item.description?.trim() ? item.description : "-"}</>
            ),
          },
          {
            key: UserGroupSchema.keyof().Enum.group_category_id,
            label: "Category",
            isSortable: true,
            renderComponent: ({ item }) =>
              item.group_category_id &&
              categoryObjectsById[item.group_category_id] ? (
                <RoutingLink
                  to={UserGroupCategoryDetailsRoutePath.toLink({
                    pathParams: { categoryId: item.group_category_id },
                  })}
                >
                  {categoryObjectsById[item.group_category_id].name}
                </RoutingLink>
              ) : (
                <>-</>
              ),
          },
          {
            key: UserGroupSchema.keyof().Enum.updated_at,
            label: "Updated At",
            isSortable: true,
            renderComponent: ({ item }) => (
              <DataTable.DataRow.DateCell
                value={item.updated_at}
                withTime
              />
            ),
          },
          {
            key: UserGroupSchema.keyof().Enum.updated_by,
            label: "Updated By",
            isSortable: true,
            renderComponent: ({ item }) =>
              metaUsersQueryMap[item.updated_by ?? ""]?.name ?? "-",
          },
          {
            key: UserGroupSchema.keyof().Enum.created_at,
            label: "Created At",
            isSortable: true,
            renderComponent: ({ item }) => (
              <DataTable.DataRow.DateCell
                value={item.created_at}
                withTime
              />
            ),
          },
          {
            key: UserGroupSchema.keyof().Enum.created_by,
            label: "Created By",
            isSortable: true,
            renderComponent: ({ item }) =>
              metaUsersQueryMap[item.created_by ?? ""]?.name ?? "-",
          },
        ],
        items,
        ...(!isUserGroupsReadAllowed
          ? {
              emptyStateProps: {
                title: "You don't have the right permissions",
                icon: <Icon variant="lock" />,
                suggestion: "Talk to your administrator",
              },
            }
          : {}),
        pagination: {
          page: tableState.page,
          pageSize: tableState.pageSize,
          total: groupsQuery.data?.total_entries,
        },
        sorting: {
          sortBy: tableState.sortBy,
          sortDirection: tableState.sortDirection,
        },
        onSortChange: setTableState,
        onPaginationChange: setTableState,
      }}
    />
  );
};

export default UserGroupsTablePanel;
