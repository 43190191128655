import { useGetOneEstimationMethodConfiguration } from "#hooks/adapters/useEstimationMethods";
import { useListFlows } from "#hooks/adapters/useFlows";
import { useGetOneNetwork } from "#hooks/adapters/useNetworks";
import { useTableSortingAndPagination } from "#redux/reducers/tableStateReducer";
import { useParams } from "#routers/hooks";
import { NetworkDetailPageParameters } from "#routes/organization/networks/[networkId]/detail";
import {
  useNetworkCalculationFlowHeaders,
  useNetworkCalculationSourceHeaders,
} from "#routes/organization/networks/[networkId]/detail/NetworkCalculationTab/NetworkCalculationTab.helpers";
import type { CalculationsViewFilterAreaType } from "#src/batteries-included-components/FilterAreas/CalculationsFilterAreas";
import { DEFAULT_DATE_RANGES } from "#src/hooks/useDateRange";
import { useSessionStickyState } from "#src/hooks/useStickyState";
import {
  DataTablePanel,
  HeaderType,
  StorageKeys,
} from "@validereinc/common-components";
import { AssetType, FlowV2Type, SortDirection } from "@validereinc/domain";
import { getYearMonthFromDateRange } from "@validereinc/utilities";
import React from "react";

export const NetworkCalculationInletPanel = ({
  viewConfigStorageKey,
  tableConfigStorageKey,
}: StorageKeys) => {
  const { networkId } = useParams<NetworkDetailPageParameters>();
  const {
    data: assetGroup,
    isLoading: isNetworkLoading,
    isFetching: isNetworkingFetching,
  } = useGetOneNetwork(networkId);
  const [viewFilters] = useSessionStickyState<CalculationsViewFilterAreaType>(
    {},
    viewConfigStorageKey
  );
  const filters = {
    id: assetGroup?.network_inlet_ids ?? [],
  };

  const [tableState, updateTableState] = useTableSortingAndPagination(
    {
      sortBy: "name",
      sortDirection: SortDirection.ASCENDING,
    },
    filters
  );
  const {
    data: flows,
    isLoading: isFlowsLoading,
    isFetching: isFlowsFetching,
  } = useListFlows(
    {
      page: tableState.page,
      pageSize: tableState.pageSize,
      sortBy: tableState.sortBy,
      sortDirection: tableState.sortDirection,
      filters,
    },
    { enabled: !!filters.id.length }
  );
  const {
    data: configuration,
    isLoading: isConfigurationLoading,
    isFetching: isConfigurationFetching,
  } = useGetOneEstimationMethodConfiguration({
    id: assetGroup?.default_estimation_method_id,
    assetType: AssetType.ASSET_GROUP,
    yearMonth: getYearMonthFromDateRange(
      viewFilters.date_range ?? DEFAULT_DATE_RANGES.currentWholeMonth
    ),
  });

  const headers: Array<HeaderType<FlowV2Type>> = [
    ...useNetworkCalculationFlowHeaders(),
    ...useNetworkCalculationSourceHeaders(configuration),
  ];

  const items = flows?.data ?? [];
  const isLoading =
    isNetworkLoading || isFlowsLoading || isConfigurationLoading;
  const isFetching =
    isNetworkingFetching || isFlowsFetching || isConfigurationFetching;

  return (
    <DataTablePanel
      storageKey={tableConfigStorageKey}
      panelProps={{ title: "Inlet" }}
      dataTableProps={{
        headers,
        items,
        isLoading,
        isBusy: isFetching,
        sorting: {
          sortBy: tableState.sortBy,
          sortDirection: tableState.sortDirection,
        },
        onSortChange: updateTableState,
        onPaginationChange: updateTableState,
        pagination: {
          page: tableState.page,
          pageSize: tableState.pageSize,
          total: flows?.total_entries,
        },
      }}
    />
  );
};
