import {
  EstimationMethodDetailFilterConfigFilterType,
  EstimationMethodDetailViewConfigFilterType,
  EstimationMethodDetailViewFilterArea,
  EstimationMethodsTableFilterArea,
  EstimationMethodTableTitleDecorationFilterArea,
} from "#src/batteries-included-components/FilterAreas/EstimationMethodsFilterAreas/EstimationMethodsFilterAreas";
import { RoutingLink } from "#src/batteries-included-components/RoutingLink/RoutingLink";
import { useTableSortingAndPagination } from "#src/components/Redux/reducers/tableStateReducer";
import { useSessionStickyState } from "#src/hooks/useStickyState";
import { useStorageKey } from "#src/hooks/useStorageKey";
import { linkToEstimationMethodDetailPage } from "#utils/links";
import { useQuery } from "@tanstack/react-query";
import {
  Button,
  DataTable,
  DataTablePanel,
  HeaderType,
} from "@validereinc/common-components";
import type { AssetTypeType, EstimationMethodType } from "@validereinc/domain";
import {
  EstimationMethodDomain,
  EstimationMethodStatus,
  SortDirection,
} from "@validereinc/domain";
import { yearMonthFromISO } from "@validereinc/utilities";
import React from "react";

const ESTIMATION_METHOD_MODEL_KEYS = {
  Name: "name",
  Library: "analytics_library_id",
  "Reporting Group": "reporting_groups",
  Status: "status",
} as const;

const EstimationMethodsTab = ({
  entityId,
  entityType,
  onAdd,
  isWithinEntityDetail,
}: {
  entityId: string;
  entityType: AssetTypeType;
  onAdd: () => void;
  onRowClick: (args: { item: EstimationMethodType }) => void;
  isWithinEntityDetail?: boolean;
}) => {
  const storageKeys = useStorageKey(
    `${entityType}${isWithinEntityDetail ? "-detail" : ""}-estimation-methods`
  );

  const [filters] =
    useSessionStickyState<EstimationMethodDetailFilterConfigFilterType>(
      {},
      storageKeys.filterConfigStorageKey
    );
  const [{ month }] =
    useSessionStickyState<EstimationMethodDetailViewConfigFilterType>(
      {},
      storageKeys.viewConfigStorageKey
    );

  const [tableState, setTableState] = useTableSortingAndPagination(
    {
      sortBy: ESTIMATION_METHOD_MODEL_KEYS.Name,
      sortDirection: SortDirection.ASCENDING,
    },
    filters
  );

  const reqParams = {
    page: tableState.page,
    pageSize: tableState.pageSize,
    sortBy: tableState.sortBy,
    sortDirection: tableState.sortDirection,
    filters: {
      entity_id: entityId,
      entity_type: entityType,
      ...filters,
    },
    ...(month?.from ? { period: yearMonthFromISO(month?.from) } : {}),
  };

  const { data: estimationMethods, isLoading } = useQuery(
    ["estimation_methods", reqParams],
    () => EstimationMethodDomain.getList(reqParams)
  );

  const addEstimationMethod = () => {
    onAdd();
  };

  const tableHeaders: Array<HeaderType<EstimationMethodType>> = [
    {
      label: "Name",
      key: ESTIMATION_METHOD_MODEL_KEYS.Name,
      isSortable: true,
      renderComponent: ({ item }) => (
        <RoutingLink
          to={linkToEstimationMethodDetailPage(
            item.entity_type,
            item.entity_id,
            item.id
          )}
        >
          {item.name}
        </RoutingLink>
      ),
    },
    {
      label: "Status",
      key: "status",
      isSortable: true,
      renderComponent: ({ item }: { item: EstimationMethodType }) => {
        return (
          <DataTable.DataRow.PillCell
            variant={
              item.status === EstimationMethodStatus.ACTIVE
                ? "success"
                : "default"
            }
            value={item.status}
          />
        );
      },
    },
    {
      label: "Reporting Scenario",
      key: ESTIMATION_METHOD_MODEL_KEYS["Reporting Group"],
      isSortable: false,
      renderComponent: ({
        item: { reporting_groups: reportingGroups },
      }: {
        item: EstimationMethodType;
      }) => reportingGroups?.map(({ name }) => name)?.join(", ") || "-",
    },
    {
      label: "Library",
      key: ESTIMATION_METHOD_MODEL_KEYS.Library,
      isSortable: true,
    },
  ];

  const tableActions = (
    <Button onClick={addEstimationMethod}>Add Estimation Method</Button>
  );

  return (
    <>
      <EstimationMethodDetailViewFilterArea
        viewConfigStorageKey={storageKeys.viewConfigStorageKey}
      />
      <DataTablePanel
        storageKey={storageKeys.tableConfigStorageKey}
        panelProps={{
          title: "Estimation Methods",
          actionRow: tableActions,
          titleDecorator: (
            <EstimationMethodTableTitleDecorationFilterArea
              filterConfigStorageKey={storageKeys.filterConfigStorageKey}
            />
          ),
        }}
        dataTableProps={{
          variant: "simplicity-first",
          items: estimationMethods?.data ?? [],
          headers: tableHeaders,
          isLoading,
          sorting: {
            sortBy: tableState.sortBy,
            sortDirection: tableState.sortDirection,
          },
          pagination: {
            page: tableState.page,
            pageSize: tableState.pageSize,
            total: tableState.total,
          },
          onSortChange: setTableState,
          onPaginationChange: setTableState,
        }}
        filterComponent={
          <EstimationMethodsTableFilterArea
            filterConfigStorageKey={storageKeys.filterConfigStorageKey}
            assetType={entityType}
          />
        }
      />
    </>
  );
};

export default EstimationMethodsTab;
