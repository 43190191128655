import { useStorageKey } from "#src/hooks/useStorageKey";
import React from "react";
import { DataSetSubmissionsFilterPanel } from "./DataSetSubmissionsFilterPanel";
import { DataSetSubmissionsTablePanel } from "./DataSetSubmissionsTablePanel";

export const DataSetSubmissionsListTab = () => {
  const storageKeys = useStorageKey("dataset-submissions");

  return (
    <>
      <DataSetSubmissionsFilterPanel
        filterConfigStorageKey={storageKeys.filterConfigStorageKey}
      />
      <DataSetSubmissionsTablePanel {...storageKeys} />
    </>
  );
};
