import {
  LegacyUserClaimSchema,
  LegacyUserClaimType,
  UserSchema,
  UserStatus,
  UserType,
} from "../schemas";
import { DomainFaker, DomainMock } from "./utils";

export const legacyUserClaimMock = new DomainMock<
  LegacyUserClaimType,
  typeof LegacyUserClaimSchema
>(
  LegacyUserClaimSchema,
  () => ({
    id: DomainFaker.en.string.uuid(),
    name: DomainFaker.en.person.fullName(),
    email: DomainFaker.en.internet.email(),
    site_ids: [],
  }),
  true
);

export const userMock = new DomainMock<UserType, typeof UserSchema>(
  UserSchema,
  () => {
    const createdAt = DomainFaker.en.date.past();

    return {
      id: DomainFaker.en.string.uuid(),
      name: DomainFaker.en.person.fullName(),
      email: DomainFaker.en.internet.email(),
      quicksight: DomainFaker.en.helpers.arrayElement([true, false]),
      phone: DomainFaker.en.phone.number(),
      status: DomainFaker.en.helpers.arrayElement(Object.values(UserStatus)),
      auth0_id: DomainFaker.en.string.uuid(),
      timezone: DomainFaker.en.location.timeZone(),
      created_at: createdAt.toISOString(),
      created_by: DomainFaker.en.string.uuid(),
      updated_at: DomainFaker.en.date
        .between({ from: createdAt, to: new Date() })
        .toISOString(),
      updated_by: DomainFaker.en.string.uuid(),
      roles: [
        {
          id: DomainFaker.en.string.uuid(),
          name: DomainFaker.en.lorem.words({ min: 1, max: 3 }),
        },
      ],
      roles_count: 1,
      user_groups: [
        {
          id: DomainFaker.en.string.uuid(),
          name: DomainFaker.en.lorem.words({ min: 1, max: 3 }),
        },
        {
          id: DomainFaker.en.string.uuid(),
          name: DomainFaker.en.lorem.words({ min: 1, max: 3 }),
        },
      ],
      user_groups_count: 2,
    };
  },
  true
).fix();
