import { Column, Row, StorageKeys } from "@validereinc/common-components";
import type { FlowType } from "@validereinc/domain";
import classNames from "classnames/bind";
import React, { type ReactNode } from "react";
import styles from "./FlowDetailPage.module.scss";
import { FlowDetailsPanel } from "./FlowDetailsPanel";
import { FlowDevicesPanel } from "./FlowDevicesPanel";
import { FlowDownstreamPanel } from "./FlowDownstreamPanel";
import { FlowUpstreamPanel } from "./FlowUpstreamPanel";

const cx = classNames.bind(styles);

export const FlowOverviewTab = ({
  flow,
  isLoading,
  viewFilterAreaSlot,
}: Pick<StorageKeys, "viewConfigStorageKey"> & {
  viewFilterAreaSlot: ReactNode;
  flow?: FlowType;
  isLoading: boolean;
}) => {
  return (
    <>
      {viewFilterAreaSlot}
      <div className={cx("container")}>
        <div className={cx("contentContainer")}>
          <FlowDetailsPanel
            flow={flow}
            isLoading={isLoading}
          />
          <Row>
            <Column variant={9}>
              <FlowUpstreamPanel
                flow={flow}
                isLoading={isLoading}
              />
            </Column>

            <Column variant={9}>
              <FlowDownstreamPanel
                flow={flow}
                isLoading={isLoading}
              />
            </Column>
          </Row>

          <FlowDevicesPanel
            flowId={flow?.id}
            isLoading={isLoading}
          />
        </div>
      </div>
    </>
  );
};
