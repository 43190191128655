import {
  CustomReportVariantType,
  CustomReportVariants,
} from "#batteries-included-components/Layouts/CustomReport/CustomReportDetailLayout.helpers";
import { DeleteCustomReportModal } from "#batteries-included-components/Modals/CustomReports/DeleteCustomReportModal";
import { EditCustomReportModal } from "#batteries-included-components/Modals/CustomReports/EditCustomReportModal";
import { RoutingLink } from "#batteries-included-components/RoutingLink";
import { useListCalculatorResultSavedFilters } from "#hooks/adapters/useCalculatorResults";
import { useListRecordSavedFilters } from "#hooks/adapters/useRecords";
import { useGetManyReportingGroups } from "#hooks/adapters/useReportingGroups";
import { useGetManyUsers } from "#hooks/adapters/useUsers";
import { useTableSortingAndPagination } from "#redux/reducers/tableStateReducer";
import { CustomReportsListRoutePath } from "#routes/reports/custom-reports";
import { CustomReportsDetailRoutePath } from "#routes/reports/custom-reports/[reportVariant]/[reportId]/detail";
import { CustomReportCreateRoutePath } from "#routes/reports/custom-reports/[reportVariant]/create";
import { linkToVolumeCustomReportDetail } from "#routes/volume/custom-reports/[reportId]/detail";
import { linkToCreateVolumeCustomReport } from "#routes/volume/custom-reports/create";
import { useNavigate } from "#src/Routers/hooks";
import { SavedFilterTag } from "#src/components/hooks/FilterPanel/useSavedFilters";
import { useAuthenticatedContext } from "#src/contexts/AuthenticatedContext.helpers";
import { EmissionsCustomReportDetailRoute } from "#src/routes/emissions/custom-reports/[reportId]/detail";
import { EmissionsCustomReportCreateRoute } from "#src/routes/emissions/custom-reports/create";
import {
  Button,
  DataTable,
  DataTablePanel,
  HeaderType,
  StorageKeys,
  useFilters,
} from "@validereinc/common-components";
import {
  ReportingGroupType,
  SavedFilterStatus,
  SavedFilterType,
  SortDirection,
  UserType,
} from "@validereinc/domain";
import React, { useState } from "react";

export const CustomReportListTablePanel = ({
  variant,
  filterConfigStorageKey,
  tableConfigStorageKey,
  isOldSidebarLocation,
}: StorageKeys & {
  variant: CustomReportVariantType;
  isOldSidebarLocation?: boolean;
}) => {
  const navigate = useNavigate();
  const {
    v2: {
      userInfo: { checkHasPermissions, user },
    },
  } = useAuthenticatedContext();

  const [reportToEdit, setReportToEdit] = useState<
    SavedFilterType<unknown> | undefined
  >();
  const [reportToDelete, setReportToDelete] = useState<string | undefined>();
  const [filters] = useFilters(filterConfigStorageKey);
  const [tableState, updateTableState] = useTableSortingAndPagination(
    {
      sortBy: "name",
      sortDirection: SortDirection.ASCENDING,
    },
    filters
  );

  const canWriteCompanyReports = checkHasPermissions(
    "company_custom_reports:write"
  );
  const canDeleteCompanyReports = checkHasPermissions(
    "company_custom_reports:delete"
  );

  const getLinkToDetailPage = (id: string) => {
    return !isOldSidebarLocation
      ? CustomReportsDetailRoutePath.toLink({
          pathParams: { reportId: id, reportVariant: variant },
        })
      : variant === CustomReportVariants.EMISSIONS
        ? EmissionsCustomReportDetailRoute.toLink({
            pathParams: { reportId: id },
          })
        : linkToVolumeCustomReportDetail(id);
  };

  const navigateToCreatePage = () => {
    const pathname = !isOldSidebarLocation
      ? CustomReportCreateRoutePath.toLink({
          pathParams: { reportVariant: variant },
        })
      : variant === CustomReportVariants.EMISSIONS
        ? EmissionsCustomReportCreateRoute.toLink()
        : linkToCreateVolumeCustomReport();

    navigate({ pathname });
  };

  const apiParams = {
    page: tableState.page,
    pageSize: tableState.pageSize,
    sortBy: tableState.sortBy,
    sortDirection: tableState.sortDirection,
    filters: {
      tag: SavedFilterTag.CUSTOM_REPORT,
      ...filters,
      ...(filters?.status === SavedFilterStatus.DRAFT && user?.id
        ? { createdBy: user.id }
        : {}),
    },
  };

  const recordSavedFiltersQuery = useListRecordSavedFilters(apiParams, {
    enabled: variant === CustomReportVariants.VOLUMETRIC,
  });

  const resultSavedFiltersQuery = useListCalculatorResultSavedFilters(
    apiParams,
    { enabled: variant === CustomReportVariants.EMISSIONS }
  );

  let items: Array<SavedFilterType<unknown>> = [];
  let isLoading = false;
  let total = 0;

  switch (variant) {
    case CustomReportVariants.EMISSIONS:
      items = resultSavedFiltersQuery.data?.data ?? [];
      isLoading = resultSavedFiltersQuery.isLoading;
      total = resultSavedFiltersQuery.data?.total_entries;
      break;
    case CustomReportVariants.VOLUMETRIC:
      items = recordSavedFiltersQuery.data?.data ?? [];
      isLoading = recordSavedFiltersQuery.isLoading;
      total = recordSavedFiltersQuery.data?.total_entries;
      break;
  }

  const usersQuery = useGetManyUsers(
    Array.from(new Set(items.map(({ updated_by }) => updated_by))).filter(
      (item) => item
    )
  );

  const userMap = usersQuery.reduce(
    (accumulator: Record<string, UserType>, current) => {
      if (current.data?.id) {
        accumulator[current.data.id] = current.data;
      }
      return accumulator;
    },
    {}
  );

  const reportingGroupQuery = useGetManyReportingGroups(
    Array.from(
      new Set(
        items.map(
          (item) =>
            item?.filter?.reporting_group_id ?? item?.filter?.reporting_group
        )
      )
    ).filter(
      (item) => item && (typeof item === "string" || item instanceof String)
    )
  );

  const reportingGroupMap = reportingGroupQuery.reduce(
    (accumulator: Record<string, ReportingGroupType>, current) => {
      if (current.data?.id) {
        accumulator[current.data.id] = current.data;
      }
      return accumulator;
    },
    {}
  );

  const headers: Array<HeaderType<SavedFilterType<unknown>>> = [
    {
      label: "Name",
      key: "name",
      renderComponent: ({ item, value }) => (
        <RoutingLink to={getLinkToDetailPage(item.id)}>{value}</RoutingLink>
      ),
      isSortable: true,
    },
    {
      label: "Reporting Scenario",
      key: "filter.reporting_group_id",
      renderComponent: ({ item }) =>
        reportingGroupMap[
          item.filter?.reporting_group_id ?? item.filter?.reporting_group
        ]?.name ?? "-",
    },
    {
      label: "Saved By",
      key: "created_by",
      isSortable: true,
      renderComponent: ({ value }) => userMap[value]?.name ?? "-",
    },
    {
      label: "Saved At",
      key: "created_at",
      isSortable: true,
      renderComponent: ({ value }: { value: any }) => (
        <DataTable.DataRow.DateCell
          value={value}
          withTime={true}
        />
      ),
    },
  ];

  const getItemActions = ({ item }: { item: SavedFilterType<unknown> }) => [
    ...(item.status === SavedFilterStatus.DRAFT || canWriteCompanyReports
      ? [
          {
            label: "Edit",
            buttonProps: {
              onClick: () => setReportToEdit(item),
              icon: "pencil-simple",
            },
          },
        ]
      : []),
    ...(item.status === SavedFilterStatus.DRAFT || canDeleteCompanyReports
      ? [
          {
            label: "Delete",
            buttonProps: {
              onClick: () => setReportToDelete(item.id),
              icon: "trash",
            },
          },
        ]
      : []),
  ];

  const actionRow =
    filters?.status === SavedFilterStatus.DRAFT || canWriteCompanyReports
      ? [
          <Button
            key="create-report"
            variant="primary"
            onClick={() => navigateToCreatePage()}
          >
            Create Report
          </Button>,
        ]
      : [];

  return (
    <>
      <DataTablePanel
        storageKey={tableConfigStorageKey}
        panelProps={{ title: CustomReportsListRoutePath.title, actionRow }}
        dataTableProps={{
          variant: "simplicity-first",
          isLoading,
          isBusy:
            recordSavedFiltersQuery.isFetching ||
            resultSavedFiltersQuery.isFetching,
          items,
          headers,
          onSortChange: updateTableState,
          onPaginationChange: updateTableState,
          sorting: {
            sortBy: tableState.sortBy,
            sortDirection: tableState.sortDirection,
          },
          pagination: {
            page: tableState.page,
            pageSize: tableState.pageSize,
            total,
          },
          getItemActions,
        }}
      />
      <EditCustomReportModal
        onClose={() => setReportToEdit(undefined)}
        customReportId={reportToEdit?.id}
        name={reportToEdit?.name}
        status={reportToEdit?.status}
        variant={variant}
      />
      <DeleteCustomReportModal
        onClose={() => setReportToDelete(undefined)}
        customReportId={reportToDelete}
        variant={variant}
      />
    </>
  );
};
