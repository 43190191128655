import { useMeasurementTypes } from "#src/contexts/MeasurementTypeContext";
import {
  CalculatedFieldInput,
  MathDataDisplay,
  NumberDataDisplay,
  TextInput,
} from "@validereinc/common-components";
import type {
  FormSchemaMeasurementQuestionType,
  FormSchemaType,
} from "@validereinc/domain";
import type { WatchedFields } from "@validereinc/domain-controllers/logic/forms";
import {
  CalculatedFieldEquationController,
  type CalculatedFieldEquationControllerProps,
} from "@validereinc/domain-controllers/view/forms";
import type { RecordValueType } from "@validereinc/utilities/lib/types/type";
import React, { memo } from "react";

const CalculatedFieldEquation = memo(
  ({
    displayEquation,
  }: Parameters<CalculatedFieldEquationControllerProps["children"]>[0]) => {
    return (
      <MathDataDisplay.Root>
        <MathDataDisplay
          colorScheme="dark"
          dynamic
          style={{ whiteSpace: "nowrap", fontSize: 14 }}
        >
          {displayEquation}
        </MathDataDisplay>
      </MathDataDisplay.Root>
    );
  },
  (prevProps, newProps) =>
    prevProps.displayEquation === newProps.displayEquation
);

CalculatedFieldEquation.displayName = "CalculatedFieldEquation";

export const MeasurementField = ({
  name,
  prompt,
  description,
  is_required,
  measurement_unit,
  equation,
  sourceFieldValues,
  questionsMap,
  isReady,
  isValid,
  isBusy,
}: Omit<FormSchemaMeasurementQuestionType, "type"> & {
  name: string;
  isReady?: boolean;
  isValid?: boolean;
  isBusy?: boolean;
} & RecordValueType<WatchedFields>["calculation"] & {
    questionsMap: FormSchemaType["config"]["questions"];
  }) => {
  const { measurementUnits } = useMeasurementTypes();

  const displayMeasurementUnit = measurementUnits.find(
    ({ id }) => measurement_unit === id
  );

  if (equation) {
    return (
      <div style={{ minWidth: 400, maxWidth: "100%" }}>
        <CalculatedFieldInput
          name={name}
          label={prompt}
          isReady={!!isReady}
          isValid={!!isValid}
          validate={{
            isValidCalculation: (val: number) => {
              if (
                (typeof val === "number" && !isNaN(val)) ||
                // @ts-expect-error intentional comparison to empty "" with is the HTML standard for no value
                (!is_required && (val === "" || val === undefined))
              )
                return true;

              return "Invalid calculation result";
            },
          }}
          isBusy={isBusy}
          isRequired={is_required}
          showHint
          hintSlot={
            <CalculatedFieldEquationController
              equation={equation}
              questionsMap={questionsMap}
              sourceFieldValuesMap={sourceFieldValues}
            >
              {(props) => <CalculatedFieldEquation {...props} />}
            </CalculatedFieldEquationController>
          }
          displaySlot={(props) => (
            <NumberDataDisplay
              {...props}
              showInvalidIndicator={false}
              unit={
                displayMeasurementUnit?.name?.symbol ??
                displayMeasurementUnit?.name?.plural ??
                measurement_unit
              }
            />
          )}
        />
      </div>
    );
  }

  return (
    <div style={{ width: 400 }}>
      <TextInput
        name={name}
        label={prompt}
        type="number"
        isRequired={is_required}
        description={description}
        unit={displayMeasurementUnit?.name?.symbol ?? measurement_unit}
        isFluid
      />
    </div>
  );
};
