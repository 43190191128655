import {
  SummaryInformation,
  SummaryInformationContainer,
} from "#components/Common/SummaryInformation";
import { TemplatedConfigurationRunDrawer } from "#src/batteries-included-components/Drawers/TemplatedConfigurationRunDrawer";
import {
  EstimationMethodDetailViewFilterArea,
  EstimationMethodDetailViewConfigFilterType,
} from "#src/batteries-included-components/FilterAreas/EstimationMethodsFilterAreas/EstimationMethodsFilterAreas";
import { IsFeatureAvailable } from "#src/batteries-included-components/Layouts/Authorization/IsFeatureAvailable";
import { useEstimationMethodDetailContext } from "#src/batteries-included-components/Layouts/EstimationMethod/Detail";
import { SinglePropertyPillPanel } from "#src/batteries-included-components/Panels/PillPanels/SinglePropertyPillPanel";
import { useGetEstimationMethod } from "#src/components/hooks/useEstimationMethod";
import { QUERY_STATUS } from "#src/constants";
import { useIsFeatureAvailable } from "#src/contexts/AuthenticatedContext.helpers";
import { useSessionStickyState } from "#src/hooks/useStickyState";
import { useStorageKey } from "#src/hooks/useStorageKey";
import { useQuery } from "@tanstack/react-query";
import {
  Link,
  MathDataDisplayEquationsWithBreakdown,
  Panel,
} from "@validereinc/common-components";
import {
  Resources,
  TemplatedConfigurationRunAdapter,
} from "@validereinc/domain";
import { datetimeFormatter } from "@validereinc/utilities";
import classNames from "classnames/bind";
import { Tag } from "@phosphor-icons/react";
import React, { useState } from "react";
import EstimationMethodDetailsPanel, {
  EstimationMethodDetailsPanelProps,
} from "./EstimationMethodDetailsPanel";
import styles from "./EstimationMethodOverviewTab.module.scss";

const cx = classNames.bind(styles);

const EstimationMethodOverviewTab = ({
  detailsPanelProps = {},
}: EstimationMethodOverviewTabProps) => {
  const { method, calculator, entityType } = useEstimationMethodDetailContext();
  const [isTemplatedConfigsAvailable] = useIsFeatureAvailable({
    featureFlagQuery: "core:templated_configurations",
    permissionQuery: "templated_configurations:read",
  });
  const { viewConfigStorageKey } = useStorageKey("estimation-method-overview");

  const [filters] =
    useSessionStickyState<EstimationMethodDetailViewConfigFilterType>(
      {},
      viewConfigStorageKey
    );

  const { month } = filters;
  const [methodData] = useGetEstimationMethod(
    method?.data?.id,
    entityType,
    month?.from
  );

  const [
    isTemplatedConfigurationRunDrawerOpen,
    setIsTemplatedConfigurationRunDrawerOpen,
  ] = useState(false);

  const resourceId = method?.data?.id ?? "";

  const templatedConfigurationRunDependencyGraphQuery = useQuery({
    queryKey: [
      Resources.TEMPLATED_CONFIGURATION_RUN,
      "dependencyGraph",
      resourceId,
    ],
    queryFn: () =>
      TemplatedConfigurationRunAdapter.dependencyGraph.getOne({
        id: resourceId,
      }),
    enabled: isTemplatedConfigsAvailable && !!resourceId,
  });

  const templatedConfigurationRunDependencyGraph =
    templatedConfigurationRunDependencyGraphQuery?.data?.data;

  const calculatorDetail = calculator?.data;
  const lastCalculatorVersion = calculatorDetail?.versions.find(
    ({ version }: { version: string }) =>
      version === calculatorDetail?.default_version
  );
  const sharedSummaryInfoProps = {
    type: "vertical",
    allowWrap: true,
    isStatusPanel: true,
  };

  return (
    <div className={cx("container")}>
      <div className={cx("filterPanel")}>
        <EstimationMethodDetailViewFilterArea
          viewConfigStorageKey={viewConfigStorageKey}
        />
      </div>
      <div className={cx("subContainer", "alignStart")}>
        <Panel
          isFluidY={false}
          style={{ marginBottom: 0, alignSelf: "flex-start" }}
        >
          <SummaryInformationContainer variant="vertical">
            <SummaryInformationContainer
              variant="vertical"
              status={methodData?.status}
              statusVariant={methodData?.status}
            />
            {methodData?.updated_at ? (
              <SummaryInformation
                {...sharedSummaryInfoProps}
                title="Updated At"
                value={datetimeFormatter(new Date(methodData?.updated_at))}
              />
            ) : null}
            <SummaryInformation
              {...sharedSummaryInfoProps}
              title="Updated By"
              value={methodData?.updatedBy?.name}
            />
            {methodData?.updated_at ? (
              <SummaryInformation
                {...sharedSummaryInfoProps}
                title="Created At"
                value={datetimeFormatter(new Date(methodData?.updated_at))}
              />
            ) : null}
            <SummaryInformation
              {...sharedSummaryInfoProps}
              title="Created By"
              value={methodData?.createdBy?.name}
            />
            {templatedConfigurationRunDependencyGraph ? (
              <SummaryInformation
                {...sharedSummaryInfoProps}
                title="Configuration Template"
                value={
                  <Link
                    underline="none"
                    onClick={() =>
                      setIsTemplatedConfigurationRunDrawerOpen(true)
                    }
                    label={templatedConfigurationRunDependencyGraph.name}
                  />
                }
              />
            ) : null}
          </SummaryInformationContainer>
        </Panel>
        <SinglePropertyPillPanel
          panelProps={{
            title: "Reporting Scenarios",
            loaded: method?.status !== QUERY_STATUS.LOADING,
          }}
          values={methodData?.reporting_groups?.map(({ name }) => name) ?? []}
          emptyStateProps={{
            title: "No Reporting Scenarios",
            suggestion:
              "Edit this estimation method to associate reporting scenarios",
            icon: <Tag />,
          }}
        />
      </div>
      <div className={cx("subContainer")}>
        <EstimationMethodDetailsPanel {...detailsPanelProps} />
        {typeof lastCalculatorVersion?.documentation === "object" &&
        Object.keys(lastCalculatorVersion?.documentation ?? {}).length ? (
          <Panel
            isFluidY={false}
            style={{ marginBottom: 0 }}
          >
            <MathDataDisplayEquationsWithBreakdown
              title={lastCalculatorVersion?.documentation.title}
              sourceLink={lastCalculatorVersion?.documentation.link}
              equations={lastCalculatorVersion?.documentation.calculations.map(
                (calc) => ({
                  equation: calc.equation,
                  reference: calc.reference,
                  terms: calc.conditions.map((cond) => ({
                    math: cond.variable_name,
                    description: cond.variable_description,
                  })),
                })
              )}
              isLoading={calculator?.status === "loading"}
            />
          </Panel>
        ) : null}
      </div>
      <IsFeatureAvailable
        featureFlagQuery="core:templated_configurations"
        permissionQuery="templated_configurations:read"
        fallbackChildren={null}
      >
        <TemplatedConfigurationRunDrawer
          isOpen={isTemplatedConfigurationRunDrawerOpen}
          onClose={() => setIsTemplatedConfigurationRunDrawerOpen(false)}
          templatedConfigurationRunDependencyGraph={
            templatedConfigurationRunDependencyGraph
          }
          resourceId={resourceId}
        />
      </IsFeatureAvailable>
    </div>
  );
};

export type EstimationMethodOverviewTabProps = {
  detailsPanelProps?: EstimationMethodDetailsPanelProps;
};

export default EstimationMethodOverviewTab;
