import { useSearchParams } from "#routers/hooks";
import {
  DataTable,
  HeaderType,
  MultiDropdownInputWithSearch,
} from "@validereinc/common-components";
import React, { useEffect, useMemo } from "react";
import { getTimeStringFromUTC } from "../../../utils/timeFormatter";
import Input from "../../Common/Input/Input";

const WORKFLOW_TYPE = [
  {
    name: "Field",
    id: "field",
  },
  {
    name: "Third Party Lab",
    id: "third_party_lab",
  },
  {
    name: "Manual",
    id: "manual",
  },
];
export default ({ sites, measurementTypes, streamsWithName }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    if (!searchParams?.from || !searchParams?.to) {
      setSearchParams({
        ...searchParams,
      });
    }
  }, [searchParams]);

  // name in filters should match with the subscription
  const filters = useMemo(
    () => [
      {
        name: "name",
        component: (
          <Input
            icon="search"
            placeholder="Name"
          />
        ),
      },
      {
        name: "test_type",
        component: (
          <MultiDropdownInputWithSearch
            label={"Types"}
            labelKey={"name"}
            dropdownKey={"types"}
            options={WORKFLOW_TYPE}
            selectLimit={10}
            isClearable
          />
        ),
      },
      {
        name: "sites",
        component: (
          <MultiDropdownInputWithSearch
            label={"Sites"}
            labelKey={"name"}
            dropdownKey={"name"}
            options={sites}
            selectLimit={10}
            isClearable
          />
        ),
      },
      {
        name: "tests_required",
        component: (
          <MultiDropdownInputWithSearch
            label={"Measurements"}
            options={measurementTypes}
            selectLimit={10}
            isClearable
          />
        ),
      },
      {
        name: "stream_ids",
        component: (
          <MultiDropdownInputWithSearch
            label={"Stream"}
            labelKey={"name"}
            dropdownKey={"name"}
            options={streamsWithName}
            selectLimit={10}
            isClearable
          />
        ),
      },
    ],
    [sites, measurementTypes, streamsWithName]
  );

  function getFrequencyCell(rowData) {
    const { frequency, start_time_utc_epoch } = rowData;

    if (frequency.type === "monthly") {
      const date = getTimeStringFromUTC(start_time_utc_epoch, "D");
      const repeat =
        frequency.repeat_every_x_months > 1
          ? `Every ${frequency.repeat_every_x_months} months`
          : "Monthly";
      return `${repeat} on day ${date}`;
    } else {
      const frequencyDay = frequency.repeat_on_days
        .map((day) => day.substring(0, 3))
        .join(", ");
      const repeat =
        frequency.repeat_every_x_weeks > 1
          ? `Every ${frequency.repeat_every_x_weeks} weeks`
          : "Weekly";
      return `${repeat} on ${frequencyDay}`;
    }
  }
  const headers: Array<HeaderType<any>> = useMemo(() => {
    return [
      {
        label: "Status",
        key: "id",
        variant: "pill",
        renderComponent: ({ item }) => {
          const status = item?.occurrences_left === 0 ? "Inactive" : "Active";
          return (
            <DataTable.DataRow.PillCell
              variant={status === "Active" ? "success" : "error"}
              value={status}
            />
          );
        },
        isFixed: true,
      },
      {
        label: "Name",
        key: "name",
        isSortable: true,
        isFixed: true,
      },
      {
        label: "Type",
        key: "test_type",
        renderComponent: ({ item }) =>
          item.test_type
            ? WORKFLOW_TYPE.filter((type) => type.id === item.test_type)[0].name
            : "",
      },
      {
        label: "Sites",
        key: "sites",
        renderComponent: ({ item }) =>
          item.sites?.map((site) => site.name).join(", ") ?? "-",
      },
      {
        label: "Frequency",
        key: "frequency",
        renderComponent: ({ item }) => (item ? getFrequencyCell(item) : "-"),
      },
      {
        label: "Tests Required",
        key: "tests_required",
        renderComponent: ({ item }) => item.tests_required?.join(", ") ?? "-",
      },
    ];
  }, [sites, measurementTypes, streamsWithName]);

  return { headers, filters };
};
