import React, { useEffect, useMemo } from "react";

import { useSearchParams } from "#routers/hooks";
import {
  DataTable,
  DateRangeSelector,
  HeaderType,
  MultiDropdownInputWithSearch,
  Tooltip,
} from "@validereinc/common-components";
import moment from "moment";
import { SAMPLE_TYPES } from "../../utils/enums";
import { getStatusType } from "../Common/Table/rendererHelper";
import MeasurementsCell from "./MeasurementsCell";

const DEFAULT_MONTHLY_DATE_RANGE = 1;

const DEFAULT_DATE_RANGE = {
  from: moment().subtract(DEFAULT_MONTHLY_DATE_RANGE, "month").toDate(),
  to: moment().toDate(),
};

export default ({
  streams,
  sites,
  instruments,
  loading,
  getFormattedMeasurementValueWithUnit,
  supportedMeasurementTypes,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    if (!searchParams?.from || !searchParams?.to) {
      setSearchParams({
        ...searchParams,
        from: searchParams.from || DEFAULT_DATE_RANGE.from,
        to: searchParams.to || DEFAULT_DATE_RANGE.to,
      });
    }
  }, [searchParams]);
  // name in filters should match with the subscription
  const filters = useMemo(
    () => [
      {
        name: "date",
        component: <DateRangeSelector allowOneDayRange />,
        value:
          searchParams?.from && searchParams?.to
            ? {
                from: new Date(searchParams.from),
                to: new Date(searchParams.to),
              }
            : DEFAULT_DATE_RANGE,
      },
      {
        name: "site",
        component: (
          <MultiDropdownInputWithSearch
            label={"Site"}
            labelKey={"name"}
            dropdownKey={"name"}
            options={sites}
            selectLimit={10}
            isClearable
          />
        ),
      },
      {
        name: "instrumentName",
        component: (
          <MultiDropdownInputWithSearch
            label={"Instrument Name"}
            labelKey={"name"}
            dropdownKey={"name"}
            options={instruments}
            selectLimit={10}
            isClearable
          />
        ),
      },
      {
        name: "measurements",
        component: (
          <MultiDropdownInputWithSearch
            label={"Measurements"}
            options={Object.values(supportedMeasurementTypes)}
            selectLimit={10}
            isClearable
          />
        ),
      },
      {
        name: "stream",
        component: (
          <MultiDropdownInputWithSearch
            label={"Stream"}
            labelKey={"name"}
            dropdownKey={"name"}
            options={streams}
            selectLimit={10}
            isClearable
          />
        ),
      },
      {
        name: "samplePoint",
        component: (
          <MultiDropdownInputWithSearch
            label={"Sample Point ID"}
            labelKey={"sample_point_ids"}
            dropdownKey={"sample_point_ids"}
            options={streams.map((stream) => {
              return {
                streamId: stream.id,
                sample_point_ids: stream.sample_point_ids,
              };
            })}
            selectLimit={10}
            isClearable
          />
        ),
      },
      {
        name: "sampleType",
        component: (
          <MultiDropdownInputWithSearch
            label={"Sample Type"}
            options={Object.values(SAMPLE_TYPES)}
            selectLimit={4}
            isClearable
          />
        ),
      },
    ],
    [streams, sites]
  );

  const quickFilters = useMemo(() => {
    return {
      loading,
      items: [
        { label: "All" },
        {
          label: "Orphan Tests",
          variant: "error",
          value: "orphan",
        },
      ],
    };
  }, [loading]);

  const headers: Array<HeaderType<any>> = useMemo(() => {
    return [
      {
        label: "Test Name",
        key: "name",
        isFixed: true,
      },
      {
        label: "Test Date",
        key: "date",
        variant: "date",
        isSortable: true,
        renderComponent: ({ item }) => (
          <DataTable.DataRow.DateCell
            value={item.date}
            withTime
          />
        ),
      },
      {
        label: "Tester",
        key: "tested_by",
        suppress: true,
      },
      {
        label: "Site",
        key: "instrument.site_name",
      },
      {
        label: "Status",
        key: "status",
        variant: "pill",
        renderComponent: ({ item }) => (
          <DataTable.DataRow.PillCell
            variant={getStatusType(item.status, "test").type}
            value={item.status}
          />
        ),
      },
      {
        label: "Instrument Name",
        key: "instrument.name",
      },
      {
        label: "Stream",
        key: "sample.streamName",
      },
      {
        label: "Sample Point ID",
        key: "sample.samplePointIds",
      },
      {
        label: "Sample Date",
        key: "sample.started_at",
        renderComponent: ({ item }) => (
          <DataTable.DataRow.DateCell value={item.sample?.started_at} />
        ),
      },
      {
        label: "Sample Type",
        key: "sample.type",
      },
      {
        label: "Short Code",
        key: "short_code",
        renderComponent: ({ item }) => (
          <DataTable.DataRow.PillCell
            variant={"default"}
            value={item.short_code}
          />
        ),
      },
      {
        label: "Measurements",
        key: "measurements",
        renderComponent: ({ item }) => {
          let measurements;
          if (item.measurements) {
            measurements = Object.entries(item.measurements).map(
              ([name, measurement]) => {
                const formattedValue = getFormattedMeasurementValueWithUnit(
                  name,
                  measurement
                );

                return { name, value: formattedValue };
              }
            );
          }

          return (
            <Tooltip
              title="Measurements"
              content={<MeasurementsCell measurements={measurements} />}
            >
              <DataTable.DataRow.PillCell
                variant="info"
                value={measurements?.length}
              />
            </Tooltip>
          );
        },
      },
      {
        label: "Test Type",
        key: "type",
        isSortable: true,
        isFixed: true,
      },
    ];
  }, [sites, streams]);

  return { headers, filters, quickFilters };
};
