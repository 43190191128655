import config from "#src/config";
import { displayValueWithUnknownDataType } from "#src/utils/display";
import { getTimeStringFromUTCDate } from "#utils/timeFormatter";
import uniq from "lodash/uniq";

export const displayConditionValue = ({ item, dataKey }) => {
  if (item?.monitor?.conditions?.length > 1) {
    return "Many";
  } else if (item?.monitor?.conditions?.length === 1) {
    return displayValueWithUnknownDataType(
      item?.monitor?.conditions[0]?.[dataKey]
    );
  }
  return null;
};

export const getMeasurementIds = (alerts) => {
  const ids = [];

  alerts.forEach((alert) => {
    alert?.monitor?.conditions.forEach((condition) => {
      if (condition.measurement_series_id) {
        ids.push(condition.measurement_series_id);
      }
    });
  });

  return uniq(ids);
};

// Fall-back to the inserted_at time on alerts where started_at is null
export const getAlertTimestamp = (alertDetail) => {
  return alertDetail?.started_at ?? alertDetail?.inserted_at ?? undefined;
};

export const getAlertName = (alert) => {
  const name = alert?.monitor?.name;
  const timestamp = getAlertTimestamp(alert);

  const prefix = name ? `${name} Alert` : undefined;
  const suffix = timestamp
    ? getTimeStringFromUTCDate(timestamp, config.DATETIME_FORMAT_READABLE)
    : undefined;
  const separator = prefix && suffix ? " - " : "";

  return [prefix, suffix].join(separator);
};
