import {
  CheckboxInput,
  DateSelectorInput,
  Form,
  RadioInput,
  useFormContext,
} from "@validereinc/common-components";
import styles from "./DateRange.module.scss";
import React, { useEffect } from "react";
import classNames from "classnames/bind";

const cx = classNames.bind(styles);

interface DateRangeSectionProps {
  isDisabled?: boolean;
}

export const DateRange = (inputProps: DateRangeSectionProps) => {
  const form = useFormContext();

  const { hasDateRange, hasDateRangeStart, hasDateRangeEnd } = form.watch();

  // Remove dateRange from form values when start or end checkboxes change:
  useEffect(() => {
    form.setValue("dateRange", undefined);
  }, [hasDateRangeStart, hasDateRangeEnd]);

  // Remove checkbox states when changing date range radio:
  useEffect(() => {
    if (!hasDateRange) {
      form.setValue("hasDateRangeStart", undefined);
      form.setValue("hasDateRangeEnd", undefined);
    }
  }, [hasDateRange]);

  const hasDateRangeStartAndEnd =
    hasDateRange && hasDateRangeStart && hasDateRangeEnd;

  const rangePickerLabel = hasDateRangeStartAndEnd
    ? "Date Range"
    : hasDateRangeStart
      ? "Start Period"
      : hasDateRangeEnd
        ? "End Period"
        : "";

  return (
    <fieldset className={cx("date-range-fieldset")}>
      <p className={cx("title")}>Date Range</p>
      <RadioInput
        {...inputProps}
        name="hasDateRange"
        inputId="radio"
        labelKey="label"
        valueKey="value"
        options={[
          { label: "Set values for all time", value: false },
          { label: "Define range for values", value: true },
        ]}
        onChange={(val: boolean) => form.setValue("dateRange", val)}
      />
      {hasDateRange && (
        <>
          <div className={cx("checkbox-container")}>
            <CheckboxInput
              {...inputProps}
              name="hasDateRangeStart"
              label="Define Start Period"
              isLabelShown={false}
              isFluid
              className={cx("date-range-checkbox")}
            />
            <CheckboxInput
              {...inputProps}
              name="hasDateRangeEnd"
              label="Define End Period"
              isLabelShown={false}
              isFluid
              className={cx("date-range-checkbox")}
            />
          </div>
          {(hasDateRangeStart || hasDateRangeEnd) && (
            <DateSelectorInput
              {...inputProps}
              variant="month"
              isRange={!!hasDateRangeStartAndEnd}
              name="dateRange"
              label={rangePickerLabel}
              isFluid
              isRequired
              hasNextPrevButtons={false}
            />
          )}
        </>
      )}
    </fieldset>
  );
};
