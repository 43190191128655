import {
  FeatureFlagType,
  LegacyFeatureFlagsType,
  LegacyPermissionGrant,
  LegacyPermissionGrantType,
  LegacyPermissionsType,
  PermissionNameType,
  PermissionType,
  PermissionsToResourceMapping,
  ResourceDefinitionType,
  checkIsPermissionNameType,
} from "@validereinc/domain";
import { AuthQueryType } from "@validereinc/domain-controllers";
import startCase from "lodash/startCase";
import { useContext } from "react";
import {
  AuthenticatedContext,
  AuthenticatedContextAuthVersionType,
} from "./AuthenticatedContext";

/**
 * Hook into the Authenticated Context
 * @returns context
 */
export const useAuthenticatedContext = () => {
  const ctx = useContext(AuthenticatedContext);

  if (ctx === null) {
    throw new Error("Must be called within AuthenticatedContextProvider.");
  }

  return ctx;
};

// --- V1 Auth Helpers: reflects old monolith authorization system (Elixir API, OpsHub) ---

/**
 * @deprecated reflects old monolith authorization system (Elixir API, OpsHub).
 *
 * Check if given feature flags result in an allow or deny matched against the
 * feature flags state set in the back-end
 * @returns a boolean. true if requested feature flags result in an allow, false
 * for a deny.
 */
export type legacyCheckIsFeatureEnabledType = (
  featureFlags?: LegacyFeatureFlagsType
) => (
  /** provide a single flag to check against or multiple flags that all need to
   * satisfy */
  flagsToCheck: string | string[],
  /** provide a single flag as the first argument and additional single flags as
   * infinite more arguments if you want to check if any of all provided flags
   * satisfy */
  ...orFlagsToCheck: string[]
) => boolean;

/**
 * The query format in which you provide feature flags to check if they result in an allow or deny authorization
 * @deprecated reflects old monolith authorization system (Elixir API, OpsHub).
 */
export type LegacyFeatureFlagQueryType = Parameters<
  ReturnType<legacyCheckIsFeatureEnabledType>
>;

/**
 * A single permission string that incorporates the grant type as well as the
 * actual permission, used in the permissions query function parameters
 * @see {@link LegacyPermissionQueryType}
 * @deprecated reflects old monolith authorization system (Elixir API, OpsHub).
 */
export type LegacyPermissionCheckInQueryType =
  | `${string}:${LegacyPermissionGrantType}`
  | `${string}:${string}:${LegacyPermissionGrantType}`
  | `${string}:${string}`;

const checkIsGrant = (grant: string): grant is LegacyPermissionGrantType =>
  Object.values(LegacyPermissionGrant).some((g) => g === grant);

const splitPermissionIntoFeatureAndGrant = (
  perm: LegacyPermissionCheckInQueryType
): { feature: string; grant: LegacyPermissionGrantType } => {
  const lastSeparator = perm.lastIndexOf(":");
  const possibleGrant =
    lastSeparator > 0 ? perm.slice(lastSeparator + 1) : null;
  const possibleFeature =
    possibleGrant !== null && checkIsGrant(possibleGrant)
      ? perm.slice(0, lastSeparator)
      : perm;

  return {
    feature: possibleFeature,
    grant:
      possibleGrant && checkIsGrant(possibleGrant)
        ? possibleGrant
        : LegacyPermissionGrant.READ,
  };
};

/**
 * @deprecated reflects old monolith authorization system (Elixir API, OpsHub).
 *
 * Check if given permissions result in an allow or deny matched against the
 * permissions of the current authenticated user
 * @returns true if permissions grant access, false otherwise
 */
export type legacyCheckHasPermissionType = (
  permissions?: LegacyPermissionsType
) => (
  /** the permissions to check. provide a single permission, or an array of
   * permissions to check if all satisfy */
  permissionsToCheck:
    | LegacyPermissionCheckInQueryType
    | LegacyPermissionCheckInQueryType[],
  /** provide a single permission as the first argument and additional single permissions as
   * infinite more arguments if you want to check if any of all provided flags
   * satisfy */
  ...orPermissionsToCheck: LegacyPermissionCheckInQueryType[]
) => boolean;

/**
 * The query format in which you provide permissions to check if they result in an allow or deny authorization
 * @deprecated reflects old monolith authorization system (Elixir API, OpsHub).
 */
export type LegacyPermissionQueryType = Parameters<
  ReturnType<typeof legacyCheckHasPermission>
>;

/**
 * @deprecated reflects old monolith authorization system (Elixir API, OpsHub).
 *
 * Closure version of the inside function - loads featureFlags to check against
 * @param featureFlags feature flags from the back-end
 * @returns the function inside with featureFlags set
 */
export const legacyCheckIsFeatureEnabled: legacyCheckIsFeatureEnabledType =
  (featureFlags?: LegacyFeatureFlagsType) =>
  (flagsToCheck: string | string[], ...orFlagsToCheck: string[]) => {
    if (!featureFlags) {
      return false;
    }

    // if a single parameter was provided and it's an array...
    if (Array.isArray(flagsToCheck)) {
      return flagsToCheck.every((flag) => Boolean(featureFlags[flag]));
    }

    // if several parameters were provided...
    if (orFlagsToCheck.length) {
      return [flagsToCheck, ...orFlagsToCheck].some((flag) =>
        Boolean(featureFlags[flag])
      );
    }

    // if only a single flag was provided...
    return Boolean(featureFlags[flagsToCheck]);
  };

/**
 * @deprecated reflects old monolith authorization system (Elixir API, OpsHub).
 *
 * Closure version of the inside function - loads permissions to check against
 * @param permissions permissions from the back-end
 * @returns the function inside with permissions set
 */
export const legacyCheckHasPermission: legacyCheckHasPermissionType =
  (permissions) =>
  (permissionsToCheck, ...orPermissionsToCheck) => {
    if (!permissions) {
      return false;
    }

    // if a single parameter was provided and it's an array...
    if (Array.isArray(permissionsToCheck)) {
      return permissionsToCheck.every((perm) => {
        const { feature, grant } = splitPermissionIntoFeatureAndGrant(perm);

        return Boolean(permissions[feature]?.includes(grant));
      });
    }

    // if several parameters were provided...
    if (Array.isArray(orPermissionsToCheck)) {
      return [permissionsToCheck, ...orPermissionsToCheck].some((perm) => {
        const { feature, grant } = splitPermissionIntoFeatureAndGrant(perm);

        return Boolean(permissions[feature]?.includes(grant));
      });
    }

    // if only a single permission was provided...
    const { feature, grant } =
      splitPermissionIntoFeatureAndGrant(permissionsToCheck);

    return Boolean(permissions[feature]?.includes(grant));
  };

/**
 * @deprecated reflects old monolith authorization system (Elixir API, OpsHub).
 * Use the non-legacy hook of the same name instead.
 *
 * Check if a specified feature flag or set of feature flags are enabled for the
 * authenticated users' company. By principle of least privelege, default is
 * false/deny with one exception - when the feature flag config doesn't exist at
 * all, it is true/allow as the feature is assumed to not be controlled.
 * @returns an object with two properties: one to indicate if it's enabled or not and another
 * to indicate if the check is still loading.
 */
export const useLegacyIsFeatureEnabled = (
  /** provide a single flag to check against or multiple flags
   * that all need to satisfy */
  flagsToCheck: string | string[],
  /** provide a single flag as the first argument and
   * additional single flags as infinite more arguments if you want to check if
   * any of all provided flags satisfy */
  ...orFlagsToCheck: string[]
): { isEnabled: boolean; isLoading: boolean } => {
  const {
    v1: { featureFlags, isLoading },
  } = useAuthenticatedContext();

  return {
    isEnabled: legacyCheckIsFeatureEnabled(featureFlags)(
      flagsToCheck,
      ...orFlagsToCheck
    ),
    isLoading: isLoading,
  };
};

/**
 * @deprecated reflects old monolith authorization system (Elixir API, OpsHub).
 * Use the non-legacy hook of the same name instead.
 *
 * Check if given permissions result in an allow or deny matched against the
 * permissions configured for the authenticated user. By principle of least
 * privelege, deny/false is the default.
 * @returns an object with two properties: one a boolean - true if requested permissions result in an allow, false
 * for a deny, and another to indicate if the check is still loading
 */
export const useLegacyHasPermission = (
  /** the permissions to check. provide a single permission, or an array of
   * permissions to check if all satisfy */
  permissionsToCheck:
    | LegacyPermissionCheckInQueryType
    | LegacyPermissionCheckInQueryType[],
  /** provide a single permission as the first argument and additional single permissions as
   * infinite more arguments if you want to check if any of all provided flags
   * satisfy */
  ...orPermissionsToCheck: LegacyPermissionCheckInQueryType[]
) => {
  const {
    v1: { permissions, isLoading },
  } = useAuthenticatedContext();

  return {
    isAllowed: legacyCheckHasPermission(permissions)(
      permissionsToCheck,
      ...orPermissionsToCheck
    ),
    isLoading,
  };
};

/**
 * Get feature flag and permissions evaluators that take all auth systems into
 * account and any specific business-rules into account.
 * @returns an object with two functions: one to evaluate feature flags and one to evaluate permissions
 */
export const useGetHolisticAuthorizationEvaluators = () => {
  const {
    v1: {
      user: legacyUser,
      featureFlags: legacyFeatureFlags,
      permissions: legacyPermissions,
      checkHasPermission: legacyCheckHasPermission,
      checkIsFeatureEnabled: legacyCheckIsFeatureEnabled,
    },
    v2: {
      featureFlags: featureFlagsGlobal,
      checkIsFeatureEnabled: checkIsFeatureEnabledGlobal,
      userInfo: { permissions, checkHasPermissions },
      companyInfo: { checkIsFeatureEnabled },
    },
  } = useAuthenticatedContext();

  const isFeatureEnabledWaterfall = ({
    legacyFeatureFlagQuery,
    legacyNotFeatureFlagQuery,
    featureFlagQuery,
    featureId,
  }: {
    legacyFeatureFlagQuery?: LegacyFeatureFlagQueryType;
    legacyNotFeatureFlagQuery?: string;
    featureFlagQuery?: AuthQueryType;
    featureId?: string;
  }): boolean => {
    // if the V2 auth flag queried, talk to the V2 auth system alone to figure out access
    if (featureFlagQuery && featureFlagsGlobal) {
      // the queried flags have to be turned on globally and for the company, to
      // allow access
      return (
        checkIsFeatureEnabledGlobal(featureFlagQuery) &&
        checkIsFeatureEnabled(featureFlagQuery)
      );
    }

    // TODO: the logic within the legacy block below was directly ported over
    // from legacy code. evaluate and throw away as needed in the routing engine
    // refactor to come.
    // if the V1 auth flag is queried, talk to the V1 auth system alone to figure out access
    if (
      (legacyFeatureFlagQuery || legacyNotFeatureFlagQuery) &&
      legacyFeatureFlags
    ) {
      // does the user's role have the flag turned on? assume on if it isn't config'd
      const doesRoleHaveFlagConfigured = featureId
        ? legacyUser?.role.frontend_feature_toggles[featureId] ?? true
        : true;
      // is the flag that controls whether this feature is deprecated, turned on?
      const isDeprecated = Boolean(
        legacyNotFeatureFlagQuery &&
          legacyCheckIsFeatureEnabled(legacyNotFeatureFlagQuery)
      );

      // if the flag config'd on the role is present and is off, deny access
      if (!doesRoleHaveFlagConfigured) {
        return false;
      }

      // if the flag that controls deprecation is on, deny access
      if (isDeprecated) {
        return false;
      }

      if (!legacyFeatureFlagQuery) {
        return true;
      }

      // the queried flags have to be turned on globally, on the company, or on
      // the user, to allow access priority order is in reverse. i.e. flag on
      // the user takes precedence, follow by company, and so on.
      return legacyCheckIsFeatureEnabled(...legacyFeatureFlagQuery);
    }

    // in all other cases, assume uncontrolled, and allow access
    return true;
  };

  const hasPermissionsWaterfall = ({
    legacyPermissionsQuery,
    permissionsQuery,
    shouldCheckPermissions = true,
  }: {
    legacyPermissionsQuery?: LegacyPermissionQueryType;
    permissionsQuery?: AuthQueryType;
    shouldCheckPermissions?: boolean;
  }): boolean => {
    // if no permissions should be checked, allow access
    if (!shouldCheckPermissions) {
      return true;
    }

    // if the V2 auth permissions are queried, talk to the V2 auth system alone to figure out access
    if (permissionsQuery && permissions) {
      return checkHasPermissions(permissionsQuery);
    }

    // if the V1 auth permissions are queried, talk to the V1 auth system alone to figure out access
    if (legacyPermissionsQuery && legacyPermissions) {
      // permissions are configured through roles. access is granted
      // depending on what is queried and if those queried permissions are
      // configured on any of the roles the user has
      return legacyCheckHasPermission(...legacyPermissionsQuery);
    }

    // in all other cases, deny access (principle of least privelege)
    return false;
  };

  return { isFeatureEnabledWaterfall, hasPermissionsWaterfall };
};

/**
 * Check if a specified feature flag or set of feature flags are enabled for the
 * authenticated users' company. By principle of least privelege, default is
 * false/deny with one exception - when the global feature flag config doesn't
 * exist at all, it is true/allow as the featue is assumed to be not be
 * controlled.
 *
 * @returns an array: one to indicate if it's enabled or not and another
 * to indicate if the check is still loading.
 */
export const useIsFeatureEnabled = (
  query: AuthQueryType
): [boolean, boolean] => {
  const {
    v2: { isLoading },
  } = useAuthenticatedContext();
  const { isFeatureEnabledWaterfall } = useGetHolisticAuthorizationEvaluators();

  return [isFeatureEnabledWaterfall({ featureFlagQuery: query }), isLoading];
};

/**
 *
 * Check if given permissions result in an allow or deny matched against the
 * permissions configured for the authenticated user. By principle of least
 * privelege, deny/false is the default.
 * @returns an array: one a boolean - true if requested permissions result in an allow, false
 * for a deny, and another to indicate if the check is still loading
 */
export const useHasPermission = (query: AuthQueryType): [boolean, boolean] => {
  const {
    v2: { isLoading },
  } = useAuthenticatedContext();
  const { hasPermissionsWaterfall } = useGetHolisticAuthorizationEvaluators();

  return [hasPermissionsWaterfall({ permissionsQuery: query }), isLoading];
};

/**
 * @deprecated reflects old monolith authorization system (Elixir API, OpsHub).
 * Use the non-legacy hook of the same name instead.
 *
 * Check if a feature could be available given a feature flag query and a
 * permission query. Both have to result in an allow for the feature to be
 * available.
 * @returns an array: one a boolean - true if the requested feature flag and
 * permission are enabled, false for a deny, and another to indicate if the
 * check is still loading
 */
export const useLegacyIsFeatureAvailable = ({
  featureId,
  featureFlagQuery,
  notFeatureFlagQuery,
  permissionQuery,
}: {
  featureId: string;
  featureFlagQuery: LegacyFeatureFlagQueryType;
  notFeatureFlagQuery?: string;
  permissionQuery?: LegacyPermissionQueryType;
}): [boolean, boolean] => {
  const {
    v2: { isLoading },
  } = useAuthenticatedContext();
  const { isFeatureEnabledWaterfall, hasPermissionsWaterfall } =
    useGetHolisticAuthorizationEvaluators();
  const isAvailable =
    isFeatureEnabledWaterfall({
      legacyFeatureFlagQuery: featureFlagQuery,
      legacyNotFeatureFlagQuery: notFeatureFlagQuery,
      featureId,
    }) &&
    hasPermissionsWaterfall({
      legacyPermissionsQuery: permissionQuery,
      shouldCheckPermissions: !!permissionQuery,
    });

  return [isAvailable, isLoading];
};

/**
 * Check if a feature could be available given a feature flag query and a
 * permission query. Both have to result in an allow for the feature to be
 * available.
 * @returns an array: one a boolean - true if the requested feature flag and
 * permission are enabled, false for a deny, and another to indicate if the
 * check is still loading
 */
export const useIsFeatureAvailable = ({
  featureFlagQuery,
  permissionQuery,
}: {
  featureFlagQuery: AuthQueryType;
  permissionQuery?: AuthQueryType;
}): [boolean, boolean] => {
  const {
    v2: { isLoading },
  } = useAuthenticatedContext();
  const { isFeatureEnabledWaterfall, hasPermissionsWaterfall } =
    useGetHolisticAuthorizationEvaluators();
  const isAvailable =
    isFeatureEnabledWaterfall({ featureFlagQuery }) &&
    hasPermissionsWaterfall({
      permissionsQuery: permissionQuery,
      shouldCheckPermissions: !!permissionQuery,
    });

  return [isAvailable, isLoading];
};

/**
 * Use this hook to determine if an account + company that exists in both the v1
 * and v2 auth systems should still get the legacy Ops Hub experience. This will
 * need to be removed when Ops Hub is deprecated and the v1 auth system is
 * deprecated.
 * @returns boolean tuple. first is true if the ops experience is turned on. second is a holistic loading state.
 */
export const useIsOpsHubExperience = (): [boolean, boolean] => {
  const {
    activeAuthVersion,
    isLoading,
    v2: {
      companyInfo: { checkIsFeatureEnabled },
    },
  } = useAuthenticatedContext();

  const isDualAuth =
    activeAuthVersion !== "unknown" &&
    Array.isArray(activeAuthVersion) &&
    activeAuthVersion.length === 2 &&
    (["v1", "v2"] satisfies AuthenticatedContextAuthVersionType[]).every((v) =>
      activeAuthVersion.includes(v)
    );
  const isOpsExperienceEnabled = checkIsFeatureEnabled("ops:auth");

  return [isDualAuth ? isOpsExperienceEnabled : false, isLoading];
};

/**
 * Call with a list of permissions to create an evaluator that will get the
 * associated permissions out of that list based on a provided feature flag. The
 * basic match is by checking if the feature flag name also exists in the
 * permission name. additional conditions to also satisfy the match can be
 * provided.
 * @param permissions the list of all permissions available
 * @returns an evaluator function. evaluator function returns a list of
 * associated permissions.
 */
export const getAssociatedPermissionsOfFeatureFlag =
  (permissions: PermissionType[]) =>
  (
    featureFlag: FeatureFlagType,
    additionalConditions?: (
      featureFlag: FeatureFlagType,
      permissions: PermissionType[]
    ) => boolean
  ): PermissionType[] =>
    permissions.filter(
      (p) =>
        p.name
          .toLowerCase()
          .includes(
            featureFlag.version === "v1"
              ? featureFlag.name.split(":")[1].toLowerCase()
              : featureFlag.name.toLowerCase()
          ) ||
        (additionalConditions
          ? additionalConditions(featureFlag, permissions)
          : false)
    );

/**
 * Given a permission name, get the associated resource
 * @param permissionName a valid permission name
 * @returns the associated resource if it is defined or undefined otherwise
 */
export const getResourceForPermission = (
  permissionName: PermissionNameType
): ResourceDefinitionType | undefined => {
  const permissionCoreName = permissionName.toLowerCase().split(":")[0];
  const associatedPermissionKey = Object.keys(
    PermissionsToResourceMapping
  ).find((key) => {
    const keyCoreName = key.split(":")[0];

    return keyCoreName === permissionCoreName;
  }) as keyof typeof PermissionsToResourceMapping;

  if (
    !associatedPermissionKey ||
    !(associatedPermissionKey in PermissionsToResourceMapping)
  ) {
    return;
  }

  return PermissionsToResourceMapping[associatedPermissionKey];
};

export const getPermissionDisplayLabel = (permName: string) => {
  if (permName === "*") return "Everything";
  if (!checkIsPermissionNameType(permName)) return permName;

  const grant = permName.split(":")[1];
  const associatedResource = getResourceForPermission(permName);

  return associatedResource
    ? `${associatedResource.label.plural} ${startCase(grant)}`
    : permName;
};
