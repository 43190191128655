import { RoutingLink } from "#batteries-included-components/RoutingLink/RoutingLink";
import { useMigrateNetwork } from "#hooks/adapters/useNetworks";
import { useParams } from "#routers/hooks";
import { FlowDetailRoute } from "#routes/organization/flows/[flowId]/detail";
import { FlowEstimationMethodDetailRoute } from "#routes/organization/flows/[flowId]/detail/estimation-details/[estimationMethodId]";
import { FlowRecordDetailRoute } from "#routes/organization/flows/[flowId]/detail/record/[recordId]";
import {
  FILTER_CONFIG,
  RecordsViewFilterArea,
} from "#src/batteries-included-components/FilterAreas/RecordsFilterAreas";
import { useSessionStickyState } from "#src/hooks/useStickyState";
import { useStorageKey } from "#src/hooks/useStorageKey";
import { useQuery } from "@tanstack/react-query";
import {
  Button,
  ButtonToggleGroup,
  DataTable,
  DataTablePanel,
  HeaderType,
  useAlert,
} from "@validereinc/common-components";
import type {
  NetworkConfigurationInputType,
  NetworkConfigurationType,
} from "@validereinc/domain";
import { AssetGroupDomain, FlowDomain } from "@validereinc/domain";
import {
  fromUTC,
  toStartCaseString,
  yearMonthFormatter,
} from "@validereinc/utilities";
import parseISO from "date-fns/parseISO";
import React, { useState } from "react";

export const NetworkCalculationTab = () => {
  const [mode, setMode] = useState<"records" | "automation">("records");
  const { addAlert } = useAlert();
  const storageKeys = useStorageKey("networks-detail-calculations");
  const { networkId } = useParams<{ networkId: string }>();
  const [filters] = useSessionStickyState<any>(
    {},
    storageKeys.viewConfigStorageKey
  );
  const {
    [FILTER_CONFIG.reportingScenario.name]: reportingGroupId,
    [FILTER_CONFIG.dateRange.name]: dateRange,
  } = filters;

  const yearMonth =
    dateRange?.from && yearMonthFormatter(fromUTC(parseISO(dateRange?.from)));

  const [selected, setSelected] = useState<
    Record<string, NetworkConfigurationType>
  >({});
  const [isCalculating, setIsCalculating] = useState(false);

  const networkKey = {
    networkId,
    reportingGroupId,
    yearMonth,
  };

  const { data: flowTypes } = useQuery({
    queryKey: ["flowTypes"],
    queryFn: FlowDomain.getFlowTypes,
  });

  const { data: flowProductCategories } = useQuery({
    queryKey: ["flowProductCategories"],
    queryFn: FlowDomain.getFlowProductCategories,
  });

  const {
    data: networkConfigurations,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["networkConfigurations", networkKey],
    queryFn: () => AssetGroupDomain.getNetworkConfiguration(networkKey),
    select: (result) => result?.data,
    enabled: !!networkId && !!reportingGroupId && !!yearMonth,
  });

  const migrateNetwork = useMigrateNetwork();

  // Records can be promoted once adjusted_volumes are present on one or more flows
  const canPromote = networkConfigurations?.some(
    ({ adjusted_volume }) => adjusted_volume
  );

  const performNetworkCalculation = async (promoteToRecord: boolean) => {
    setIsCalculating(true);
    const input: NetworkConfigurationInputType = {};
    networkConfigurations?.forEach(({ id }) => {
      input[id] = { adjustable: !!selected[id] };
    });

    try {
      if (!promoteToRecord) {
        // Set selection and reporting group
        await AssetGroupDomain.setNetworkConfiguration({
          ...networkKey,
          input,
        });
      }
      // Run Calculation
      await AssetGroupDomain.runNetworkCalculation({
        ...networkKey,
        promoteToRecord,
      });

      await refetch();

      if (promoteToRecord) {
        setSelected({});
        addAlert({
          variant: "success",
          message: "Successfully saved adjusted volume to records.",
        });
        setSelected({});
      }
    } catch (error: any) {
      addAlert({
        variant: "error",
        message: `Unable to perform network proration${
          error.error ? `: ${error.error}` : ""
        }`,
      });
    } finally {
      setIsCalculating(false);
    }
  };

  const headers: Array<HeaderType<NetworkConfigurationType>> = [
    {
      key: "name",
      label: "Flow Name",
      renderComponent: ({ item: { id, name } }) => (
        <RoutingLink
          to={FlowDetailRoute.toLink({ pathParams: { flowId: id } })}
        >
          {name}
        </RoutingLink>
      ),
    },
    {
      key: "type",
      label: "Type",
      renderComponent: ({ item }) =>
        flowTypes?.find(({ id }) => id === item.type)?.name ?? "-",
    },
    {
      key: "product_category",
      label: "Product Category",
      renderComponent: ({ item }) =>
        flowProductCategories?.find(({ id }) => id === item.product_category)
          ?.name ?? "-",
    },
    {
      key: "adjusted_volume",
      label: "Adjusted Volume",
      renderComponent: ({ item }) => (
        <DataTable.DataRow.NumberCell value={item.adjusted_volume} />
      ),
    },
    {
      key: "volume",
      label: "Volume Input",
      renderComponent: ({ item }) => (
        <DataTable.DataRow.NumberCell value={item.volume} />
      ),
    },
    {
      key: "volume_source_type",
      label: "Source Type",
      renderComponent: ({ item }) => toStartCaseString(item.volume_source_type),
    },
    {
      key: "volume_record_id",
      label: "Source",
      renderComponent: ({ item }: { item: NetworkConfigurationType }) =>
        item.volume_source_type ? (
          <RoutingLink
            to={
              item.volume_source_type === "record"
                ? FlowRecordDetailRoute.toLink({
                    pathParams: {
                      flowId: item.id,
                      recordId: item.volume_record_id!,
                    },
                  })
                : FlowEstimationMethodDetailRoute.toLink({
                    pathParams: {
                      flowId: item.id,
                      estimationMethodId: item.volume_estimation_method_id!,
                    },
                  })
            }
          >{`${item.name} - ${yearMonth}`}</RoutingLink>
        ) : (
          "-"
        ),
    },
  ];

  const actionRow = [
    <Button
      key="preview"
      variant="primary"
      onClick={() => performNetworkCalculation(false)}
      disabled={isLoading || isCalculating || !Object.keys(selected).length}
    >
      Preview
    </Button>,
    ...(canPromote
      ? [
          <Button
            key="promote"
            onClick={() => performNetworkCalculation(true)}
            disabled={isLoading || isCalculating}
          >
            Promote
          </Button>,
        ]
      : []),
    <Button
      key="migrate"
      variant="primary"
      onClick={() => migrateNetwork.mutate(networkId)}
      disabled={isLoading || migrateNetwork.isLoading}
    >
      Migrate Network
    </Button>,
  ];

  return (
    <>
      <div
        style={{
          marginRight: 8,
          display: "flex",
          gap: 8,
          marginBottom: 16,
          alignItems: "center",
        }}
      >
        <ButtonToggleGroup
          activeKey={mode}
          items={[
            { label: "Records", dataKey: "records" },
            { label: "Default Automations", dataKey: "automation" },
          ]}
          onChange={(val) => setMode(val)}
        />
        <RecordsViewFilterArea
          viewConfigStorageKey={storageKeys.viewConfigStorageKey}
          isPeriodDateRange={false}
          isPeriodAvailable={mode === "records"}
        />
      </div>
      <DataTablePanel
        actionRowWhenNoRowsSelected={actionRow}
        storageKey={storageKeys.tableConfigStorageKey}
        panelProps={{ title: "Network Records" }}
        dataTableProps={{
          headers,
          items: networkConfigurations ?? [],
          onSelectionChange: setSelected,
          selected,
          isLoading: isLoading || isCalculating,
        }}
      />
    </>
  );
};
