import AlertConfigurationList from "#components/AlertManagement/AlertConfiguration";
import AlertConfigurationDetail from "#components/AlertManagement/AlertConfiguration/AlertConfigurationDetail";
import CreateAlertConfiguration from "#components/AlertManagement/AlertConfiguration/CreateAlertConfiguration";
import AlertList from "#components/AlertManagement/Alerts";
import AlertDetail from "#components/AlertManagement/Alerts/Detail/AlertDetail";
import DataMapping from "#components/DataMapping";
import BasicInfoForm from "#components/DataMapping/Create/BasicInfoForm";
import ValidationForm from "#components/DataMapping/Create/CreateValidationForm";
import DataMappingForm from "#components/DataMapping/Create/DataMappingForm";
import FileUploadForm from "#components/DataMapping/Create/FileUploadForm";
import DataMappingDetail from "#components/DataMapping/Detail";
import DataMappingUploadDetail from "#components/DataMapping/Detail/DataMappingUploadDetail";
import UploadFileUploadForm from "#components/DataMapping/Upload/FileUploadForm";
import UploadValidationForm from "#components/DataMapping/Upload/UploadValidationForm";
import EmissionReportDetail from "#components/Emissions/Reports/Detail/EmissionReportDetail";
import FormSubmissionDetail from "#components/Forms/FormSubmissionDetail";
import FormSubmissionList from "#components/Forms/FormSubmissionList";
import DeviceDetail from "#components/Organization/Devices/Detail/DeviceDetail";
import CreateFacilities from "#components/Organization/Facilities/CreateFacilities";
import AddDevice from "#components/Organization/Facilities/Detail/Devices/AddDevice";
import EquipmentDetail from "#components/Organization/Facilities/Detail/Equipment/EquipmentDetail";
import TestDetail from "#components/Tests/Detail/TestDetail";
import {
  CalculatorsListRoutePath,
  CalculatorsPage,
} from "#routes/calculations/calculators";
import { DataSubmissionsListPage } from "#routes/data-tools/submissions/DataSubmissionsListPage";
import {
  DataSetSubmissionDetailPage,
  DataSetSubmissionDetailRoutePath,
} from "#routes/data-tools/submissions/datasets/[transactionId]/detail";
import { AllEventsPage, linkToAllEvents } from "#routes/events/all-events";
import {
  EventCategoriesPage,
  linkToEventCategories,
} from "#routes/events/event-categories";
import {
  EventCategoryDetailPage,
  linkToEventCategoryDetail,
} from "#routes/events/event-categories/[eventCategoryId]";
import {
  EventTemplateDetailPage,
  linkToEventTemplateDetail,
} from "#routes/events/event-categories/[eventCategoryId]/templates/[templateId]/details";
import {
  EventPage,
  linkToEventDetails,
} from "#routes/events/event-categories/[eventCategoryId]/templates/[templateId]/events/[eventId]";
import {
  AddDevicesToEventPage,
  linkToAddDevicesToEvent,
} from "#routes/events/event-categories/[eventCategoryId]/templates/[templateId]/events/[eventId]/add-devices";
import {
  AddEquipmentToEventPage,
  linkToAddEquipmentToEvent,
} from "#routes/events/event-categories/[eventCategoryId]/templates/[templateId]/events/[eventId]/add-equipment";
import {
  AddFormSubmissionToEventPage,
  linkToAddFormSubmissionToEvent,
} from "#routes/events/event-categories/[eventCategoryId]/templates/[templateId]/events/[eventId]/add-form-submission";
import {
  EquipmentListPage,
  EquipmentListRoute,
} from "#routes/organization/equipment";
import {
  CalculationResultDetail,
  linkToEstimationMethodCalculationResult,
} from "#routes/organization/equipment/[equipmentId]/estimation-method/[methodId]/result/[resultId]/detail";
import {
  CreateEstimationMethod,
  linkToEstimationMethodCreate,
} from "#routes/organization/equipment/[equipmentId]/estimation-method/create";
import {
  EquipmentRecordValueDetailPage,
  linkToEquipmentRecordValueDetail,
} from "#routes/organization/equipment/[equipmentId]/record-details/[recordId]/value/[measurementType]";
import {
  EquipmentRecordValueSourcePage,
  linkToAddEquipmentRecordValueSource,
} from "#routes/organization/equipment/[equipmentId]/record-details/[recordId]/value/[measurementType]/add-source";
import { FacilitiesListRoute } from "#routes/organization/facilities";
import { FacilityListPage } from "#routes/organization/facilities/FacilityListPage";
import {
  FacilityDetailPage,
  linkToFacilityDetail,
} from "#routes/organization/facilities/[facilityId]";
import {
  FacilityRecordDetailPage,
  linkToFacilityRecordDetail,
} from "#routes/organization/facilities/[facilityId]/record/[recordId]/details";
import {
  FacilityRecordValueDetailPage,
  linkToFacilityRecordValueDetail,
} from "#routes/organization/facilities/[facilityId]/record/[recordId]/value/[measurementType]";
import {
  FacilityRecordValueSourcePage,
  linkToAddFacilityRecordValueSource,
} from "#routes/organization/facilities/[facilityId]/record/[recordId]/value/[measurementType]/add-source";
import { FlowListRoute } from "#routes/organization/flows";
import { FlowsListPage } from "#routes/organization/flows/FlowsListPage";
import { FlowDetailRoute } from "#routes/organization/flows/[flowId]/detail";
import { FlowDetailPage } from "#routes/organization/flows/[flowId]/detail/FlowDetailPage";
import { FlowCreateEstimationMethodRoute } from "#routes/organization/flows/[flowId]/detail/create-estimation-method";
import { CreateFlowEstimationMethodPage } from "#routes/organization/flows/[flowId]/detail/create-estimation-method/CreateFlowEstimationMethodPage";
import { FlowEditRoute } from "#routes/organization/flows/[flowId]/detail/edit";
import { EditFlowPage } from "#routes/organization/flows/[flowId]/detail/edit/EditFlowPage";
import { FlowEstimationMethodDetailRoute } from "#routes/organization/flows/[flowId]/detail/estimation-details/[estimationMethodId]";
import { FlowEstimationMethodDetailPage } from "#routes/organization/flows/[flowId]/detail/estimation-details/[estimationMethodId]/FlowEstimationMethodDetailPage";
import { FlowEstimationMethodResultDetailRoute } from "#routes/organization/flows/[flowId]/detail/estimation-details/[estimationMethodId]/result/[yearMonth]";
import { FlowEstimationMethodResultDetailPage } from "#routes/organization/flows/[flowId]/detail/estimation-details/[estimationMethodId]/result/[yearMonth]/FlowEstimationMethodResultDetailPage";
import { FlowRecordDetailRoute } from "#routes/organization/flows/[flowId]/detail/record/[recordId]";
import { FlowRecordDetailPage } from "#routes/organization/flows/[flowId]/detail/record/[recordId]/FlowRecordDetailPage";
import { FlowRecordValueDetailRoute } from "#routes/organization/flows/[flowId]/detail/record/[recordId]/value/[measurementType]";
import { FlowRecordValueDetailPage } from "#routes/organization/flows/[flowId]/detail/record/[recordId]/value/[measurementType]/FlowRecordValueDetailPage";
import { FlowRecordValueSourceRoute } from "#routes/organization/flows/[flowId]/detail/record/[recordId]/value/[measurementType]/source";
import { FlowRecordValueSourcePage } from "#routes/organization/flows/[flowId]/detail/record/[recordId]/value/[measurementType]/source/FlowRecordValueSourcePage";
import { FlowCreateRoute } from "#routes/organization/flows/create";
import { CreateFlowPage } from "#routes/organization/flows/create/CreateFlowPage";
import { NetworkListRoute } from "#routes/organization/networks";
import { NetworkListPage } from "#routes/organization/networks/NetworkListPage";
import { NetworkDetailRoute } from "#routes/organization/networks/[networkId]/detail";
import { NetworkDetailPage } from "#routes/organization/networks/[networkId]/detail/NetworkDetailPage";
import { NetworkCalculationResultRoute } from "#routes/organization/networks/[networkId]/detail/calculation-result/[period]/details";
import { NetworkCalculationResultPage } from "#routes/organization/networks/[networkId]/detail/calculation-result/[period]/details/NetworkCalculationResultPage";
import { EditNetworkPage } from "#routes/organization/networks/[networkId]/detail/edit/EditNetworkPage";
import { NetworkCreateRoute } from "#routes/organization/networks/create";
import { CreateNetworkPage } from "#routes/organization/networks/create/CreateNetworkPage";
import {
  RecordAutomationListPage,
  linkToRecordAutomation,
} from "#routes/records/automation/index";
import { CustomReportsListRoutePath } from "#routes/reports/custom-reports";
import { CustomReportListPage } from "#routes/reports/custom-reports/CustomReportListPage";
import { CustomReportsDetailRoutePath } from "#routes/reports/custom-reports/[reportVariant]/[reportId]/detail";
import { CustomReportDetailPage } from "#routes/reports/custom-reports/[reportVariant]/[reportId]/detail/CustomReportDetailPage";
import { CustomReportCreateRoutePath } from "#routes/reports/custom-reports/[reportVariant]/create";
import { CreateCustomReportPage } from "#routes/reports/custom-reports/[reportVariant]/create/CreateCustomReportPage";
import {
  TemplatedReportListPage,
  linkToTemplatedReportList,
} from "#routes/reports/templated-reports";
import {
  TemplatedReportExportPage,
  linkToExportTemplatedReport,
} from "#routes/reports/templated-reports/[reportName]/export";
import { ScenarioListPage, linkToScenarioList } from "#routes/scenarios";
import {
  EditScenarioPage,
  linkToEditScenario,
} from "#routes/scenarios/[scenarioId]/edit";
import {
  CreateScenarioPage,
  linkToCreateScenario,
} from "#routes/scenarios/create-scenario";
import { CustomAttributeRoute } from "#routes/settings/custom-attributes";
import {
  CreateRolePage,
  linkToCreateRolePage,
} from "#routes/settings/roles-and-permissions/roles/create";
import {
  AccountNavBarSettings,
  LegacyAccountNavBarSettings,
} from "#src/batteries-included-components/Layouts/Authentication/AccountNavBar";
import CreateEmissionReports from "#src/components/Emissions/Reports/Create/CreateEmissionReports";
import { LegacyOpsHubFeatures } from "#src/components/Layout/SideBar/SidebarTabs";
import {
  AuthQueryType,
  LegacyFeatureFlagQueryType,
  LegacyPermissionQueryType,
} from "#src/contexts/AuthenticatedContext.helpers";
import { CalculationsRoutePath } from "#src/routes/calculations";
import {
  CalculationsEstimationMethodsRoutePath,
  EstimationMethodListPage,
} from "#src/routes/calculations/estimation-methods";
import { ActivityLogsPage, linkToActivityLogs } from "#src/routes/change-log";
import { DashboardPage, linkToDashboards } from "#src/routes/dashboard";
import {
  CustomDashboardDetailsPage,
  linkToCustomDashboardDetails,
} from "#src/routes/dashboard/custom-dashboard/[customDashboardId]/details";
import {
  InsightsDetailsPage,
  linkToInsightsDetails,
} from "#src/routes/dashboard/insights/[insightsId]/details";
import { DataSubmissionsRoutePath } from "#src/routes/data-tools/submissions";
import { FORMS_BREADCRUMB } from "#src/routes/forms";
import { linkToFormCategoryList } from "#src/routes/forms/categories";
import { FormCategoryListPage } from "#src/routes/forms/categories/FormCategoryListPage";
import {
  FormCategoryDetailsPage,
  linkToFormCategoryDetails,
} from "#src/routes/forms/categories/[categoryId]";
import {
  CreateFormTemplatePage,
  linkToCreateFormCategoryFormTemplate,
} from "#src/routes/forms/categories/[categoryId]/create-form-template";
import {
  EditFormTemplatePage,
  linkToEditFormCategoryFormTemplate,
} from "#src/routes/forms/categories/[categoryId]/edit-form-template/[formSchemaId]";
import {
  FormTemplateDetailsPage,
  linkToFormTemplateDetail,
} from "#src/routes/forms/categories/[categoryId]/templates/[formTemplateId]";
import {
  CreateFormSubmissionPage,
  linkToCreateFormSubmission,
} from "#src/routes/forms/categories/[categoryId]/templates/[formTemplateId]/create-form-submission";
import {
  UpdateFormSubmissionPage,
  linkToUpdateFormSubmission,
} from "#src/routes/forms/categories/[categoryId]/templates/[formTemplateId]/update-form-submission";
import {
  FORM_SCHEMA_LIST_BREADCRUMB,
  linkToFormSchemaList,
} from "#src/routes/forms/templates";
import FormSchemaListPage from "#src/routes/forms/templates/FormSchemaListPage";
import { HomeRoute } from "#src/routes/home";
import { HomePage } from "#src/routes/home/HomePage";
import { linkToNotFound } from "#src/routes/not-found";
import { NotFoundPage } from "#src/routes/not-found/NotFoundPage";
import { DevicesListRoutePath } from "#src/routes/organization/devices";
import { DeviceListPage } from "#src/routes/organization/devices/DeviceListPage";
import { DevicesCreateRoutePath } from "#src/routes/organization/devices/create";
import { CreateDevicePage } from "#src/routes/organization/devices/create/CreateDevicePage";
import {
  EmissionsRecordDetail,
  linkToEquipmentEmissionRecordDetail,
} from "#src/routes/organization/equipment/[equipmentId]/emissions/[emissionPeriod]/detail";
import {
  EstimationMethodDetail,
  linkToEstimationMethodDetail,
} from "#src/routes/organization/equipment/[equipmentId]/estimation-method/[methodId]/detail";
import {
  EquipmentRecordDetailPage,
  linkToEquipmentRecordDetail,
} from "#src/routes/organization/equipment/[equipmentId]/record-details/[recordId]";
import { EquipmentCreateRoute } from "#src/routes/organization/equipment/create";
import CreateEquipmentPage from "#src/routes/organization/equipment/create/CreateEquipmentPage";
import {
  FacilityEmissionsRecordDetail,
  linkToFacilityEmissionRecordDetail,
} from "#src/routes/organization/facilities/[facilityId]/emissions/[emissionPeriod]/detail";
import { NetworkEditRoute } from "#src/routes/organization/networks/[networkId]/detail/edit";
import {
  RecordsListPage,
  linkToRecords,
} from "#src/routes/records/records/index";
import { CustomAttributePage } from "#src/routes/settings/custom-attributes/index";
import { MeasurementTypePage } from "#src/routes/settings/measurement-types/MeasurementTypePage";
import { linkToMeasurementTypesSettingsPage } from "#src/routes/settings/measurement-types/index";
import {
  RolesAndPermissionsPage,
  linkToRolesListPage,
} from "#src/routes/settings/roles-and-permissions";
import {
  RoleDetailPage,
  linkToRoleDetailPage,
} from "#src/routes/settings/roles-and-permissions/roles/[roleId]/detail";
import {
  EditRolePage,
  linkToRoleEditPage,
} from "#src/routes/settings/roles-and-permissions/roles/[roleId]/edit";
import {
  EditRolePermissionsPage,
  linkToEditRolePermissionsPage,
} from "#src/routes/settings/roles-and-permissions/roles/[roleId]/permissions/edit";
import {
  EditRoleUsersPage,
  linkToEditRoleUsersPage,
} from "#src/routes/settings/roles-and-permissions/roles/[roleId]/users/edit";
import { linkToUsersListPage } from "#src/routes/settings/users";
import { UsersListPage } from "#src/routes/settings/users/UserListPage";
import {
  CreateUserPage,
  linkToCreateUserPage,
} from "#src/routes/settings/users/create";
import {
  UserDetailPage,
  linkToUserDetailPage,
} from "#src/routes/settings/users/detail";
import {
  EditUserRolePage,
  linkToEditUserRolePage,
} from "#src/routes/settings/users/detail/roles/edit";
import {
  EditUserPage,
  linkToUserEditPage,
} from "#src/routes/settings/users/edit";
import { UserGroupsRoutePath } from "#src/routes/settings/users/groups";
import { UserGroupDetailsRoutePath } from "#src/routes/settings/users/groups/[groupId]/details";
import { UserGroupDetailsPage } from "#src/routes/settings/users/groups/[groupId]/details/UserGroupDetailsPage";
import { UserGroupEditRoutePath } from "#src/routes/settings/users/groups/[groupId]/edit";
import EditUserGroupPage from "#src/routes/settings/users/groups/[groupId]/edit/EditUserGroupsPage";
import { UserGroupCategoriesRoutePath } from "#src/routes/settings/users/groups/categories";
import { UserGroupCategoryDetailsRoutePath } from "#src/routes/settings/users/groups/categories/[categoryId]/details";
import { UserGroupCategoryDetailsPage } from "#src/routes/settings/users/groups/categories/[categoryId]/details/UserGroupCategoryDetailsPage";
import { GroupCreateRoutePath } from "#src/routes/settings/users/groups/create";
import CreateUserGroupPage from "#src/routes/settings/users/groups/create/CreateUserGroupsPage";
import { WorkflowsAllRoutePath } from "#src/routes/workflows/all";
import { AllWorkflowsPage } from "#src/routes/workflows/all/AllWorkflowsPage";
import { WorkflowDetailsRoutePath } from "#src/routes/workflows/all/[workflowId]";
import { WorkflowDetailPage } from "#src/routes/workflows/all/[workflowId]/WorkflowDetailPage";
import { WorkflowTaskAddFormSubmissionRoutePath } from "#src/routes/workflows/all/[workflowId]/detail/task/[taskId]/form-submission/add";
import { WorkflowTaskAddFormSubmissionPage } from "#src/routes/workflows/all/[workflowId]/detail/task/[taskId]/form-submission/add/WorkflowTaskAddFormSubmissionPage";
import { WorkflowCategoriesRoutePath } from "#src/routes/workflows/categories";
import { WorkflowCategoriesListPage } from "#src/routes/workflows/categories/WorkflowsCategoryListPage";
import { WorkflowCategoryDetailsRoutePath } from "#src/routes/workflows/categories/[workflowCategoryId]";
import { WorkflowCategoryDetailsPage } from "#src/routes/workflows/categories/[workflowCategoryId]/WorkflowCategoryDetailsPage";
import { WorkflowTasksRoutePath } from "#src/routes/workflows/tasks";
import { WorkflowMyTasksPage } from "#src/routes/workflows/tasks/TasksPage";
import { WorkflowTemplatesRoutePath } from "#src/routes/workflows/templates";
import { WorkflowTemplatesPage } from "#src/routes/workflows/templates/WorkflowTemplatesPage";
import { WorkflowTemplateDetailsRoutePath } from "#src/routes/workflows/templates/[workflowTemplateId]";
import { WorkflowTemplatePage } from "#src/routes/workflows/templates/[workflowTemplateId]/WorkflowTemplatePage";
import { BreadcrumbType } from "@validereinc/common-components";
import Immutable from "immutable";
import React, { ReactNode } from "react";
import Analyze from "../components/Analyze/Analyze";
import AnnotatePage from "../components/Annotations/Annotating/AnnotatePage";
import Annotations from "../components/Annotations/Annotations";
import ChainOfCustody from "../components/ChainOfCustody/ChainOfCustody";
import CreateChainOfCustody from "../components/ChainOfCustody/CreateChainOfCustody/CreateChainOfCustody";
import EmissionRecords from "../components/Emissions/Records/EmissionRecords";
import EmissionReports from "../components/Emissions/Reports/EmissionReports";
import InlineAnalyzer from "../components/Instrument/InlineAnalyzer/Summary/Summary";
import Instrument from "../components/Instrument/Instrument";
import Instruments from "../components/Instrument/Instruments";
import Inventory from "../components/Inventories/Inventory";
import InventoryDetail from "../components/Inventories/InventoryDetail";
import LabResults from "../components/LabResults/LabResults";
import CreateManualEntry from "../components/ManualEntry/CreateManualEntry";
import ManualEntry from "../components/ManualEntry/ManualEntry";
import CreateProductionReports from "../components/Production/Reports/CreateProductionReports/CreateProductionReports";
import ProductionReportDetail from "../components/Production/Reports/Detail/ProductionReportDetail";
import ProductionReports from "../components/Production/Reports/ProductionReports";
import StreamDetail from "../components/Production/Streams/Detail/StreamDetail";
import EditRecords from "../components/Production/Streams/Edit/EditRecords";
import Streams from "../components/Production/Streams/Streams";
import BalancesAnalyze from "../components/Records/Balances/Analyze/BalancesAnalyze";
import CreateBalances from "../components/Records/Balances/BalanceUpserts/CreateBalances";
import EditBalances from "../components/Records/Balances/BalanceUpserts/EditBalances";
import Balances from "../components/Records/Balances/Balances";
import RecordOfQuality from "../components/Records/Quality/RecordOfQuality";
import AdjustmentFactors from "../components/Records/Volume/AdjustmentFactors/AdjustmentFactors";
import RecordOfVolume from "../components/Records/Volume/RecordOfVolume";
import SampleDetail from "../components/Samples/Detail/SampleDetail";
import Samples from "../components/Samples/Samples";
import CompanySettings from "../components/Settings/Company/CompanySettings";
import CreateUser from "../components/Settings/Company/Members/CreateMember";
import EditUser from "../components/Settings/Company/Members/EditMember";
import RoleNotificationSettings from "../components/Settings/Company/Notifications/RoleNotificationSettings";
import CreateRole from "../components/Settings/Company/Roles/CreateRole";
import EditRole from "../components/Settings/Company/Roles/EditRole";
import UserSettings from "../components/Settings/User/UserSettings";
import TestManagement from "../components/Tests/TestManagement";
import ThirdPartyLabImports from "../components/ThirdPartyLabImports/ThirdPartyLabImports";
import CreateWorkflow from "../components/Workflows/CreateAndEditWorkflow/CreateWorkflow";
import EditWorkflow from "../components/Workflows/CreateAndEditWorkflow/EditWorkflow";
import ManageWorkflows from "../components/Workflows/Manage/ManageWorkflows";
import Workflows from "../components/Workflows/Workflows";
import config from "../config";
import {
  ADD_DEVICE_BREADCRUMB,
  ADD_EQUIPMENT_BREADCRUMB,
  ALERTS_BREADCRUMB,
  ALERT_BREADCRUMB,
  ALERT_CONFIGURATION_BREADCRUMB,
  ALERT_MANAGEMENT_BREADCRUMB,
  ANALYZE_BREADCRUMB,
  ANALYZE_DETAIL_BREADCRUMB,
  BALANCES_ANALYZE_BREADCRUMB,
  BALANCES_BREADCRUMB,
  CHAIN_OF_CUSTODY_BREADCRUMB,
  CREATE_ALERT_CONFIGURATION_BREADCRUMB,
  CREATE_BALANCES_BREADCRUMB,
  CREATE_CHAIN_OF_CUSTODY_BREADCRUMB,
  CREATE_DATA_MAPPING_BREADCRUMB,
  CREATE_EMISSION_REPORTS_BREADCRUMB,
  CREATE_FACILITIES_BREADCRUMB,
  CREATE_MANUAL_ENTRY_BREADCRUMB,
  CREATE_PRODUCTION_REPORTS_BREADCRUMB,
  CREATE_WORKFLOWS_BREADCRUMB,
  DATA_MANAGEMENT_BREADCRUMB,
  DATA_MAPPING_BREADCRUMB,
  DATA_MAPPING_DETAIL_BREADCRUMB,
  DATA_MAPPING_UPLOAD_DETAIL_BREADCRUMB,
  EDIT_BALANCES_BREADCRUMB,
  EDIT_EMISSION_REPORTS_BREADCRUMB,
  EDIT_RECORDS_BREADCRUMB,
  EDIT_WORKFLOWS_BREADCRUMB,
  EMISSION_BREADCRUMB,
  EMISSION_RECORDS_BREADCRUMB,
  EMISSION_REPORTS_BREADCRUMB,
  EMISSION_REPORTS_DETAIL_BREADCRUMB,
  EQUIPMENT_DETAIL_BREADCRUMB,
  FACILITIES_BREADCRUMB,
  FACILITY_DETAIL_BREADCRUMB,
  FORM_SUBMISSION_DETAIL_BREADCRUMB,
  FORM_SUBMISSION_LIST_BREADCRUMB,
  IMPORTS_BREADCRUMB,
  INSTRUMENTS_BREADCRUMB,
  INSTRUMENTS_DETAIL_BREADCRUMB,
  INVENTORIES_BREADCRUMB,
  INVENTORY_DETAIL_BREADCRUMB,
  LAB_RESULT_BREADCRUMB,
  MANAGE_WORKFLOW_BREADCRUMB,
  MANUAL_ENTRY_BREADCRUMB,
  MEASUREMENTS_BREADCRUMB,
  ORGANIZATION_BREADCRUMB,
  PRODUCTION_BREADCRUMB,
  PRODUCTION_REPORTS_BREADCRUMB,
  RECORDS_BREADCRUMB,
  RECORD_OF_QUALITY_BREADCRUMB,
  ROV_ADJUSTMENT_FACTORS_BREADCRUMB,
  ROV_BREADCRUMB,
  ROV_BREADCRUMB_DETAIL,
  SAMPLES_BREADCRUMB,
  SAMPLE_DETAIL_BREADCRUMB,
  STREAMS_BREADCRUMB,
  TESTS_BREADCRUMB,
  TEST_DETAIL_BREADCRUMB,
  WORKFLOW_TASKS_BREADCRUMB,
} from "./breadcrumbs";
import {
  linkToAdjustmentFactors,
  linkToAlertConfiguration,
  linkToAlertConfigurationDetail,
  linkToAlerts,
  linkToAnalyze,
  linkToAnnotations,
  linkToBalances,
  linkToChainOfCustody,
  linkToCompanySettings,
  linkToCreateAlertConfiguration,
  linkToCreateDataMappingEntityMapping,
  linkToCreateDataMappingUploadData,
  linkToCreateDataMappingValidation,
  linkToDataMapping,
  linkToEmissionRecords,
  linkToEmissionReports,
  linkToFormSubmissionList,
  linkToInstruments,
  linkToInventories,
  linkToLabResults,
  linkToManageWorkflows,
  linkToManualEntry,
  linkToProductionReports,
  linkToProductionStreams,
  linkToRecordOfQuality,
  linkToRecordOfVolume,
  linkToSamples,
  linkToTests,
  linkToThirdPartyImports,
  linkToUserSetting,
  linkToViewWorkflows,
} from "./links";

// IMPORT_LINK_MARKER_FOR_CLI

// IMPROVE: temporary type made to make working with the Immutable Record from
// legacy code easier. All this is throwaway in the routing engine refactor to
// come.
export type RouteType = {
  /** permission(s) query that needs to return true, for route to render.
   * Overrides what's set on the parent page config!
   * @deprecated reflects old monolith authorization system (Elixir API, OpsHub)
   * that is currently in use in legacy parts of the app
   * */
  legacyPermissionsQuery?: LegacyPermissionQueryType;
  /** permission(s) query that needs to return true for this route to render.
   * Overrides what's set on the parent page config! */
  permissionsQuery?: AuthQueryType;
  /** should permissions be checked at all for this route? Overrides what's set
   * on the parent page config! */
  shouldCheckPermissions?: boolean;
  /** feature flag(s) query that needs to return true, for this route to render.
   * Overrides what's set on the parent page config */
  featureFlagsQuery?: AuthQueryType;
  path: string | string[];
  exact: boolean;
  main: (...props: any[]) => ReactNode;
  // IMPROVE: breadcrumbs are defined co-located to the pages in the routes/
  // directory. this should be dropped in the routing engine refactor
  breadcrumbs: BreadcrumbType[];
};

// IMPROVE: temporary type made to make working with the Immutable Record from
// legacy code easier. All this is throwaway in the routing engine refactor to
// come.
export type PageConfigType = {
  /** permission(s) query that needs to return true, for this page and all
   * sub-routes to be available. Applies to all child routes.
   * @deprecated reflects old monolith authorization system (Elixir API, OpsHub)
   * that is currently in use in legacy parts of the app
   * */
  legacyPermissionsQuery?: LegacyPermissionQueryType;
  /** permission(s) query that needs to return true, for this page and all
   * sub-routes to be available. Applies to all child routes. */
  permissionsQuery?: AuthQueryType;
  /** feature flag(s) query that needs to return true, for this page and all
   * sub-routes to be available. Applies to all child routes.
   * @deprecated reflects old monolith authorization system (Elixir API, OpsHub)
   * that is currently in use in legacy parts of the app
   * */
  legacyFeatureFlagsQuery?: LegacyFeatureFlagQueryType;
  /** feature flag that shouldn't be on, for this page and all sub-routes to be
   * available. Applies to all child routes.
   * @deprecated reflects old monolith authorization system (Elixir API, OpsHub)
   * that is currently in use in legacy parts of the app
   * */
  legacyInverseFeatureFlagQuery?: string;
  /** feature flag(s) query that needs to return true, for this page and all
   * sub-routes to be available. Applies to all child routes. */
  featureFlagsQuery?: AuthQueryType;
  /** should permissions be checked at all? Note, this is not the same as NOT
   * setting a permissions query property - doing that will deny because denial
   * is the default when it comes to permissions */
  shouldCheckPermissions: boolean;
  // IMPROVE: we should configure all kinds of links through one unified field
  link?: string;
  // IMPROVE: we should configure all kinds of links through one unified field
  externalLink?: string;
  routes: RouteType[];
  // TODO: this field is a code smell. Should be removed when possible.
  action: boolean;
};

const PageConfig = Immutable.Record<PageConfigType>({
  link: undefined,
  externalLink: undefined,
  legacyPermissionsQuery: undefined,
  permissionsQuery: undefined,
  legacyFeatureFlagsQuery: undefined,
  legacyInverseFeatureFlagQuery: undefined,
  featureFlagsQuery: undefined,
  shouldCheckPermissions: true,
  routes: [],
  action: false,
});

const RouteDefinition = Immutable.Record<RouteType>({
  path: "",
  exact: false,
  main: () => null,
  breadcrumbs: [],
  legacyPermissionsQuery: undefined,
  permissionsQuery: undefined,
  featureFlagsQuery: undefined,
  shouldCheckPermissions: undefined,
});

export const PageConfigs = {
  workflows_templates: PageConfig({
    link: WorkflowTemplatesRoutePath.path,
    featureFlagsQuery: "core:workflows",
    permissionsQuery: "workflow_templates:read",
    routes: [
      RouteDefinition({
        path: WorkflowTemplatesRoutePath.path,
        exact: true,
        main: () => <WorkflowTemplatesPage />,
      }),
      RouteDefinition({
        path: WorkflowTemplateDetailsRoutePath.path,
        exact: true,
        main: () => <WorkflowTemplatePage />,
      }),
    ],
  }),

  workflows_categories: PageConfig({
    link: WorkflowCategoriesRoutePath.path,
    featureFlagsQuery: "core:workflows",
    permissionsQuery: "workflow_templates:read",
    routes: [
      RouteDefinition({
        path: WorkflowCategoriesRoutePath.path,
        exact: true,
        main: () => <WorkflowCategoriesListPage />,
      }),
      RouteDefinition({
        path: WorkflowCategoryDetailsRoutePath.path,
        exact: true,
        main: () => <WorkflowCategoryDetailsPage />,
      }),
    ],
  }),
  workflows_all: PageConfig({
    link: WorkflowsAllRoutePath.path,
    featureFlagsQuery: "core:workflows",
    permissionsQuery: "workflows:read",
    routes: [
      RouteDefinition({
        path: WorkflowsAllRoutePath.path,
        exact: true,
        main: () => <AllWorkflowsPage />,
      }),
      RouteDefinition({
        permissionsQuery: {
          $and: [
            "workflows:read",
            "workflow_templates:read",
            "workflow_tasks:read",
          ],
        },
        path: WorkflowDetailsRoutePath.path,
        exact: true,
        main: () => <WorkflowDetailPage />,
      }),
      RouteDefinition({
        featureFlagsQuery: {
          $and: ["core:workflows", "core:forms"],
        },
        permissionsQuery: {
          $and: [
            "workflows:read",
            "workflow_templates:read",
            "workflow_tasks:read",
            "workflow_tasks:write",
            "form_schemas:read",
            "form_submissions:write",
          ],
        },
        path: WorkflowTaskAddFormSubmissionRoutePath.path,
        exact: true,
        main: () => <WorkflowTaskAddFormSubmissionPage />,
      }),
    ],
  }),
  workflows_my_tasks: PageConfig({
    link: WorkflowTasksRoutePath.path,
    featureFlagsQuery: "core:workflows",
    permissionsQuery: {
      $and: [
        "workflows:read",
        "workflow_templates:read",
        "workflow_tasks:read",
      ],
    },
    routes: [
      RouteDefinition({
        path: WorkflowTasksRoutePath.path,
        exact: true,
        main: () => <WorkflowMyTasksPage />,
      }),
    ],
  }),
  records_v2_list: PageConfig({
    link: linkToRecords(),
    shouldCheckPermissions: false,
    permissionsQuery: {
      $and: [
        "reporting_groups:read",
        "records:read",
        { $or: ["facilities:read", "equipment:read", "flows:read"] },
      ],
    },
    featureFlagsQuery: {
      $and: [
        { $or: ["core:facilities", "core:equipment", "core:flows"] },
        "core:records",
      ],
    },
    routes: [
      RouteDefinition({
        path: linkToRecords(),
        exact: true,
        main: () => <RecordsListPage />,
      }),
    ],
  }),
  records_v2_automation: PageConfig({
    link: linkToRecordAutomation(),
    permissionsQuery: {
      $or: [
        { $and: ["reporting_groups:read", "records:read"] },
        "facilities:read",
        "equipment:read",
        "flows:read",
      ],
    },
    featureFlagsQuery: "dev:alpha",
    routes: [
      RouteDefinition({
        path: linkToRecordAutomation(),
        exact: true,
        main: () => <RecordAutomationListPage />,
      }),
    ],
  }),
  calculators: PageConfig({
    link: CalculatorsListRoutePath.path,
    featureFlagsQuery: {
      $and: [
        "core:estimation_methods",
        "core:scenarios",
        "core:facilities",
        {
          $or: ["core:equipment", "core:flows", "core:networks"],
        },
      ],
    },
    permissionsQuery: {
      $and: [
        "estimation_methods:read",
        "reporting_groups:read",
        "facilities:read",
        {
          $or: [
            { $and: ["equipment:read", "calculator_results:read"] },
            { $and: ["flows:read", "records:read"] },
            "networks:read",
          ],
        },
      ],
    },
    routes: [
      RouteDefinition({
        path: CalculatorsListRoutePath.path,
        exact: true,
        main: () => <CalculatorsPage />,
      }),
    ],
  }),
  calculations_estimation_methods: PageConfig({
    link: CalculationsEstimationMethodsRoutePath.path,
    featureFlagsQuery: {
      $and: [
        "core:estimation_methods",
        "core:scenarios",
        "core:facilities",
        { $or: ["core:equipment", "core:flows"] },
      ],
    },
    permissionsQuery: {
      $and: [
        "estimation_methods:read",
        "reporting_groups:read",
        "facilities:read",
        {
          $or: [
            { $and: ["equipment:read", "calculator_results:read"] },
            { $and: ["flows:read", "records:read"] },
          ],
        },
      ],
    },
    routes: [
      RouteDefinition({
        path: CalculationsEstimationMethodsRoutePath.path,
        exact: true,
        main: () => <EstimationMethodListPage />,
      }),
    ],
  }),

  custom_reports: PageConfig({
    link: CustomReportsListRoutePath.path,
    featureFlagsQuery: {
      $and: [
        "core:estimation_methods",
        "core:facilities",
        {
          $or: [
            "core:equipment",
            { $and: ["core:flows", "core:volumetric_reporting"] },
          ],
        },
      ],
    },
    permissionsQuery: {
      $and: [
        "estimation_methods:read",
        "facilities:read",
        {
          $or: [
            { $and: ["equipment:read", "calculator_results:read"] },
            {
              $and: ["flows:read", "records:read", "volumetric_reporting:read"],
            },
          ],
        },
      ],
    },
    routes: [
      RouteDefinition({
        path: CustomReportsListRoutePath.path,
        exact: true,
        main: () => <CustomReportListPage />,
      }),
      RouteDefinition({
        path: CustomReportsDetailRoutePath.path,
        exact: true,
        main: () => <CustomReportDetailPage />,
      }),
      RouteDefinition({
        path: CustomReportCreateRoutePath.path,
        exact: true,
        main: () => <CreateCustomReportPage />,
      }),
    ],
  }),

  templated_reports: PageConfig({
    link: linkToTemplatedReportList(),
    featureFlagsQuery: {
      $and: [
        "core:estimation_methods",
        "core:facilities",
        { $or: ["core:equipment", "core:flows"] },
      ],
    },
    permissionsQuery: "reports:read",
    routes: [
      RouteDefinition({
        path: linkToTemplatedReportList(),
        exact: true,
        main: () => <TemplatedReportListPage />,
      }),
      RouteDefinition({
        path: linkToExportTemplatedReport(),
        exact: true,
        main: () => <TemplatedReportExportPage />,
      }),
    ],
  }),
  organization_flows: PageConfig({
    link: FlowListRoute.path,
    featureFlagsQuery: "core:flows",
    permissionsQuery: {
      $and: ["flows:read", "facilities:read", "equipment:read"],
    },
    routes: [
      RouteDefinition({
        featureFlagsQuery: {
          $and: ["core:flows", "core:estimation_methods"],
        },
        permissionsQuery: {
          $and: ["flows:read", "calculator_results:read"],
        },
        path: FlowEstimationMethodResultDetailRoute.path,
        exact: true,
        main: () => <FlowEstimationMethodResultDetailPage />,
      }),
      RouteDefinition({
        featureFlagsQuery: {
          $and: ["core:flows", "core:estimation_methods"],
        },
        permissionsQuery: {
          $and: ["flows:read", "estimation_methods:write"],
        },
        path: FlowCreateEstimationMethodRoute.path,
        exact: true,
        main: () => <CreateFlowEstimationMethodPage />,
      }),
      RouteDefinition({
        featureFlagsQuery: {
          $and: ["core:flows", "core:estimation_methods"],
        },
        permissionsQuery: {
          $and: ["flows:read", "estimation_methods:read"],
        },
        path: FlowEstimationMethodDetailRoute.path,
        exact: true,
        main: () => <FlowEstimationMethodDetailPage />,
      }),
      RouteDefinition({
        featureFlagsQuery: {
          $and: ["core:flows", "core:records"],
        },
        permissionsQuery: {
          $and: ["flows:read", "records:read"],
        },
        path: FlowRecordDetailRoute.path,
        exact: true,
        main: () => <FlowRecordDetailPage />,
      }),
      RouteDefinition({
        featureFlagsQuery: {
          $and: ["core:flows", "core:records"],
        },
        permissionsQuery: {
          $and: ["flows:read", "records:read"],
        },
        path: FlowRecordValueDetailRoute.path,
        exact: true,
        main: () => <FlowRecordValueDetailPage />,
      }),
      RouteDefinition({
        featureFlagsQuery: {
          $and: ["core:flows", "core:records"],
        },
        permissionsQuery: {
          $and: ["flows:read", "records:read", "records:write"],
        },
        path: FlowRecordValueSourceRoute.path,
        exact: true,
        main: () => <FlowRecordValueSourcePage />,
      }),
      RouteDefinition({
        permissionsQuery: {
          $and: ["flows:write", "facilities:read", "equipment:read"],
        },
        path: FlowCreateRoute.path,
        exact: true,
        main: () => <CreateFlowPage />,
      }),
      RouteDefinition({
        path: FlowDetailRoute.path,
        exact: true,
        main: (props, permissions) => (
          <FlowDetailPage
            {...props}
            permissions={permissions}
          />
        ),
      }),
      RouteDefinition({
        permissionsQuery: {
          $and: [
            "flows:read",
            "flows:write",
            "facilities:read",
            "equipment:read",
          ],
        },
        path: FlowEditRoute.path,
        exact: true,
        main: () => <EditFlowPage />,
      }),
      RouteDefinition({
        path: FlowListRoute.path,
        exact: true,
        main: () => <FlowsListPage />,
      }),
    ],
  }),

  scenarios: PageConfig({
    link: linkToScenarioList(),
    featureFlagsQuery: "core:scenarios",
    permissionsQuery: "reporting_groups:read",
    routes: [
      RouteDefinition({
        permissionsQuery: {
          $and: ["reporting_groups:read", "reporting_groups:write"],
        },
        path: linkToScenarioList(),
        exact: true,
        main: () => <ScenarioListPage />,
      }),
      RouteDefinition({
        permissionsQuery: "reporting_groups:write",
        path: linkToCreateScenario(),
        exact: true,
        main: () => <CreateScenarioPage />,
      }),
      RouteDefinition({
        permissionsQuery: {
          $and: ["reporting_groups:read", "reporting_groups:write"],
        },
        path: linkToEditScenario(),
        exact: true,
        main: () => <EditScenarioPage />,
      }),
    ],
  }),

  organization_networks: PageConfig({
    link: NetworkListRoute.path,
    featureFlagsQuery: "core:networks",
    permissionsQuery: {
      $and: [
        "networks:read",
        "flows:read",
        "facilities:read",
        "equipment:read",
      ],
    },
    routes: [
      RouteDefinition({
        permissionsQuery: {
          $and: [
            "networks:write",
            "flows:read",
            "facilities:read",
            "equipment:read",
          ],
        },
        path: NetworkCreateRoute.path,
        exact: true,
        main: () => <CreateNetworkPage />,
      }),
      RouteDefinition({
        path: NetworkDetailRoute.path,
        exact: true,
        main: () => <NetworkDetailPage />,
      }),
      RouteDefinition({
        path: NetworkCalculationResultRoute.path,
        exact: true,
        main: () => <NetworkCalculationResultPage />,
      }),
      RouteDefinition({
        path: NetworkListRoute.path,
        exact: true,
        main: () => <NetworkListPage />,
      }),
      RouteDefinition({
        permissionsQuery: {
          $and: [
            "networks:read",
            "networks:write",
            "flows:read",
            "facilities:read",
            "equipment:read",
          ],
        },
        path: NetworkEditRoute.path,
        exact: true,
        main: () => <EditNetworkPage />,
      }),
    ],
  }),

  home: PageConfig({
    link: HomeRoute.path,
    featureFlagsQuery: "core:home",
    shouldCheckPermissions: false,
    routes: [
      RouteDefinition({
        path: HomeRoute.path,
        exact: true,
        main: () => <HomePage />,
      }),
    ],
  }),

  dashboards: PageConfig({
    link: linkToDashboards(),
    shouldCheckPermissions: false,
    routes: [
      RouteDefinition({
        path: linkToDashboards(),
        exact: true,
        main: (props, permissions) => (
          <DashboardPage
            {...props}
            permissions={permissions}
          />
        ),
      }),
      RouteDefinition({
        path: linkToInsightsDetails(),
        exact: true,
        main: () => {
          return <InsightsDetailsPage />;
        },
      }),
      RouteDefinition({
        path: linkToCustomDashboardDetails(),
        exact: true,
        main: () => {
          return <CustomDashboardDetailsPage />;
        },
      }),
    ],
  }),

  [LegacyOpsHubFeatures.BALANCES]: PageConfig({
    link: linkToBalances(),
    legacyPermissionsQuery: ["gas:accounting.balances:read"],
    routes: [
      RouteDefinition({
        path: "/app/balances/analyze/:id",
        exact: true,
        breadcrumbs: [
          RECORDS_BREADCRUMB,
          BALANCES_BREADCRUMB,
          BALANCES_ANALYZE_BREADCRUMB,
        ],
        main: (props, permissions) => (
          <BalancesAnalyze
            {...props}
            permissions={permissions}
          />
        ),
      }),
      RouteDefinition({
        legacyPermissionsQuery: ["gas:accounting.balances:write"],
        path: "/app/balances/edit/:id",
        exact: true,
        breadcrumbs: [
          RECORDS_BREADCRUMB,
          BALANCES_BREADCRUMB,
          BALANCES_ANALYZE_BREADCRUMB,
          EDIT_BALANCES_BREADCRUMB,
        ],
        main: (props, permissions) => (
          <EditBalances
            {...props}
            permissions={permissions}
          />
        ),
      }),
      RouteDefinition({
        legacyPermissionsQuery: ["gas:accounting.balances:write"],
        path: "/app/balances/create",
        exact: true,
        breadcrumbs: [
          RECORDS_BREADCRUMB,
          BALANCES_BREADCRUMB,
          CREATE_BALANCES_BREADCRUMB,
        ],
        main: (props, permissions) => (
          <CreateBalances
            {...props}
            permissions={permissions}
          />
        ),
      }),
      RouteDefinition({
        path: "/app/balances",
        exact: true,
        breadcrumbs: [RECORDS_BREADCRUMB, BALANCES_BREADCRUMB],
        main: (props, permissions) => (
          <Balances
            {...props}
            permissions={permissions}
          />
        ),
      }),
    ],
  }),
  [LegacyOpsHubFeatures.PRODUCTION_REPORTS]: PageConfig({
    link: linkToProductionReports(),
    legacyPermissionsQuery: ["gas:accounting.balances:read"],
    legacyFeatureFlagsQuery: ["production/reports"],
    routes: [
      RouteDefinition({
        legacyPermissionsQuery: ["gas:accounting.balances:write"],
        path: "/app/production/reports/create",
        exact: true,
        breadcrumbs: [
          PRODUCTION_BREADCRUMB,
          PRODUCTION_REPORTS_BREADCRUMB,
          CREATE_PRODUCTION_REPORTS_BREADCRUMB,
        ],
        main: (props, permissions) => (
          <CreateProductionReports
            {...props}
            permissions={permissions}
          />
        ),
      }),
      RouteDefinition({
        path: "/app/production/reports/detail/:id",
        exact: true,
        breadcrumbs: [PRODUCTION_BREADCRUMB, PRODUCTION_REPORTS_BREADCRUMB],
        main: (props, permissions) => (
          <ProductionReportDetail
            {...props}
            permissions={permissions}
          />
        ),
      }),

      RouteDefinition({
        path: "/app/production/reports",
        exact: true,
        breadcrumbs: [PRODUCTION_BREADCRUMB, PRODUCTION_REPORTS_BREADCRUMB],
        main: (props, permissions) => (
          <ProductionReports
            {...props}
            permissions={permissions}
          />
        ),
      }),
    ],
  }),
  alert_configuration: PageConfig({
    link: linkToAlertConfiguration(),
    featureFlagsQuery: "core:alerting",
    shouldCheckPermissions: false,
    routes: [
      RouteDefinition({
        path: linkToCreateAlertConfiguration(),
        exact: true,
        breadcrumbs: [
          ALERT_MANAGEMENT_BREADCRUMB,
          ALERT_CONFIGURATION_BREADCRUMB,
          CREATE_ALERT_CONFIGURATION_BREADCRUMB,
        ],
        main: (props, permissions) => (
          <CreateAlertConfiguration
            {...props}
            permissions={permissions}
          />
        ),
      }),
      RouteDefinition({
        path: linkToAlertConfigurationDetail(":alertConfigurationId"),
        exact: true,
        breadcrumbs: [
          ALERT_MANAGEMENT_BREADCRUMB,
          ALERT_CONFIGURATION_BREADCRUMB,
        ],
        main: (props, permissions) => (
          <AlertConfigurationDetail
            {...props}
            permissions={permissions}
          />
        ),
      }),
      RouteDefinition({
        path: "/app/alerts/configuration",
        exact: true,
        breadcrumbs: [
          ALERT_MANAGEMENT_BREADCRUMB,
          ALERT_CONFIGURATION_BREADCRUMB,
        ],
        main: (props, permissions) => (
          <AlertConfigurationList
            {...props}
            permissions={permissions}
          />
        ),
      }),
    ],
  }),
  alerts: PageConfig({
    link: linkToAlerts(),
    featureFlagsQuery: "core:alerting",
    shouldCheckPermissions: false,
    routes: [
      RouteDefinition({
        path: "/app/alerts/alerts",
        exact: true,
        breadcrumbs: [ALERT_MANAGEMENT_BREADCRUMB, ALERTS_BREADCRUMB],
        main: (props, permissions) => (
          <AlertList
            {...props}
            permissions={permissions}
          />
        ),
      }),
      RouteDefinition({
        path: "/app/alerts/alerts/:alertId/detail",
        exact: true,
        breadcrumbs: [
          ALERT_MANAGEMENT_BREADCRUMB,
          ALERTS_BREADCRUMB,
          ALERT_BREADCRUMB,
        ],
        main: (props, permissions) => (
          <AlertDetail
            {...props}
            permissions={permissions}
          />
        ),
      }),
    ],
  }),

  forms: PageConfig({
    link: linkToFormSubmissionList(),
    featureFlagsQuery: "core:forms",
    permissionsQuery: { $and: ["form_submissions:read", "form_schemas:read"] },
    routes: [
      RouteDefinition({
        path: "/app/forms/submissions",
        exact: true,
        breadcrumbs: [FORMS_BREADCRUMB, FORM_SUBMISSION_LIST_BREADCRUMB],
        main: (props, permissions) => (
          <FormSubmissionList
            {...props}
            permissions={permissions}
          />
        ),
      }),
      RouteDefinition({
        path: "/app/forms/submissions/:formSubmissionId/detail",
        exact: true,
        breadcrumbs: [
          FORMS_BREADCRUMB,
          FORM_SUBMISSION_LIST_BREADCRUMB,
          FORM_SUBMISSION_DETAIL_BREADCRUMB,
        ],
        main: (props, permissions) => (
          <FormSubmissionDetail
            {...props}
            permissions={permissions}
          />
        ),
      }),
    ],
  }),
  form_categories: PageConfig({
    link: linkToFormCategoryList(),
    featureFlagsQuery: "core:forms",
    permissionsQuery: "form_schemas:read",
    routes: [
      RouteDefinition({
        path: linkToFormCategoryList(),
        exact: true,
        main: () => <FormCategoryListPage />,
      }),
      RouteDefinition({
        path: linkToFormCategoryDetails(),
        exact: true,
        main: () => <FormCategoryDetailsPage />,
      }),
      RouteDefinition({
        featureFlagsQuery: {
          $and: ["forms:schemas_create", "core:forms"],
        },
        permissionsQuery: "form_schemas:write",
        path: linkToCreateFormCategoryFormTemplate(),
        exact: true,
        main: () => <CreateFormTemplatePage />,
      }),
      RouteDefinition({
        featureFlagsQuery: {
          $and: ["forms:schemas_create", "core:forms"],
        },
        permissionsQuery: "form_schemas:write",
        path: linkToEditFormCategoryFormTemplate(),
        exact: true,
        main: () => <EditFormTemplatePage />,
      }),
      RouteDefinition({
        path: linkToFormTemplateDetail(),
        exact: true,
        main: () => <FormTemplateDetailsPage />,
      }),
      RouteDefinition({
        permissionsQuery: {
          $and: ["form_submissions:write", "form_schemas:read"],
        },
        path: linkToCreateFormSubmission(),
        exact: true,
        main: () => <CreateFormSubmissionPage />,
      }),
      RouteDefinition({
        permissionsQuery: {
          $and: [
            "form_submissions:read",
            "form_submissions:write",
            "form_schemas:read",
          ],
        },
        path: linkToUpdateFormSubmission(),
        exact: true,
        main: () => <UpdateFormSubmissionPage />,
      }),
    ],
  }),

  form_configs: PageConfig({
    link: linkToFormSchemaList(),
    featureFlagsQuery: "core:forms",
    permissionsQuery: "form_schemas:read",
    routes: [
      RouteDefinition({
        path: linkToFormSchemaList(),
        exact: true,
        breadcrumbs: [FORMS_BREADCRUMB, FORM_SCHEMA_LIST_BREADCRUMB],
        main: (props, permissions) => (
          <FormSchemaListPage
            {...props}
            permissions={permissions}
          />
        ),
      }),
    ],
  }),

  organization_facilities: PageConfig({
    link: FacilitiesListRoute.path,
    featureFlagsQuery: "core:facilities",
    permissionsQuery: "facilities:read",
    routes: [
      RouteDefinition({
        featureFlagsQuery: {
          $and: ["core:facilities", "core:equipment"],
        },
        permissionsQuery: {
          $and: ["facilities:read", "equipment:read", "equipment:write"],
        },
        path: "/app/organization/facilities/:facilityId/equipment/add",
        exact: true,
        breadcrumbs: [
          ORGANIZATION_BREADCRUMB,
          FACILITIES_BREADCRUMB,
          FACILITY_DETAIL_BREADCRUMB,
          ADD_EQUIPMENT_BREADCRUMB,
        ],
        main: (props) => <CreateEquipmentPage {...props} />,
      }),
      RouteDefinition({
        featureFlagsQuery: {
          $and: ["core:facilities", "core:devices"],
        },
        permissionsQuery: {
          $and: ["facilities:read", "devices:read", "devices:write"],
        },
        path: "/app/organization/facilities/:facilityId/devices/add",
        exact: true,
        breadcrumbs: [
          ORGANIZATION_BREADCRUMB,
          FACILITIES_BREADCRUMB,
          FACILITY_DETAIL_BREADCRUMB,
          ADD_DEVICE_BREADCRUMB,
        ],
        main: (props, permissions) => (
          <AddDevice
            {...props}
            permissions={permissions}
          />
        ),
      }),
      RouteDefinition({
        permissionsQuery: "facilities:write",
        path: "/app/organization/facilities/create",
        exact: true,
        breadcrumbs: [
          ORGANIZATION_BREADCRUMB,
          FACILITIES_BREADCRUMB,
          CREATE_FACILITIES_BREADCRUMB,
        ],
        main: (props, permissions) => (
          <CreateFacilities
            {...props}
            permissions={permissions}
          />
        ),
      }),
      RouteDefinition({
        featureFlagsQuery: {
          $and: ["core:facilities", "core:records"],
        },
        permissionsQuery: {
          $and: ["facilities:read", "records:read"],
        },
        path: linkToFacilityRecordDetail(),
        exact: true,
        main: () => <FacilityRecordDetailPage />,
      }),
      RouteDefinition({
        featureFlagsQuery: {
          $and: ["core:facilities", "core:records"],
        },
        permissionsQuery: {
          $and: ["facilities:read", "records:read"],
        },
        path: linkToFacilityRecordValueDetail(),
        exact: true,
        main: () => <FacilityRecordValueDetailPage />,
      }),
      RouteDefinition({
        featureFlagsQuery: {
          $and: ["core:facilities", "core:records"],
        },
        permissionsQuery: {
          $and: ["facilities:read", "records:read", "records:write"],
        },
        path: linkToAddFacilityRecordValueSource(),
        exact: true,
        main: () => <FacilityRecordValueSourcePage />,
      }),
      RouteDefinition({
        path: linkToFacilityDetail(),
        exact: true,
        main: () => <FacilityDetailPage />,
      }),
      RouteDefinition({
        path: FacilitiesListRoute.path,
        exact: true,
        main: () => <FacilityListPage />,
      }),
      RouteDefinition({
        featureFlagsQuery: {
          $and: ["core:facilities", "core:estimation_methods"],
        },
        permissionsQuery: {
          $and: ["facilities:read", "calculator_results:read"],
        },
        path: linkToFacilityEmissionRecordDetail(),
        exact: true,
        main: (props) => <FacilityEmissionsRecordDetail {...props} />,
      }),
    ],
  }),

  organization_equipment: PageConfig({
    link: EquipmentListRoute.path,
    featureFlagsQuery: "core:equipment",
    permissionsQuery: "equipment:read",
    routes: [
      RouteDefinition({
        path: "/app/organization/equipment/:equipmentId/detail",
        exact: true,
        breadcrumbs: [
          ORGANIZATION_BREADCRUMB,
          FACILITIES_BREADCRUMB,
          FACILITY_DETAIL_BREADCRUMB,
          EQUIPMENT_DETAIL_BREADCRUMB,
        ],
        main: (props, permissions) => (
          <EquipmentDetail
            {...props}
            permissions={permissions}
          />
        ),
      }),
      RouteDefinition({
        featureFlagsQuery: {
          $and: ["core:equipment", "core:records"],
        },
        permissionsQuery: {
          $and: ["equipment:read", "records:read"],
        },
        path: linkToEquipmentRecordDetail(),
        exact: true,
        main: () => <EquipmentRecordDetailPage />,
      }),

      RouteDefinition({
        featureFlagsQuery: {
          $and: ["core:equipment", "core:records"],
        },
        permissionsQuery: {
          $and: ["equipment:read", "records:read"],
        },
        path: linkToEquipmentRecordValueDetail(),
        exact: true,
        main: () => <EquipmentRecordValueDetailPage />,
      }),

      RouteDefinition({
        featureFlagsQuery: {
          $and: ["core:equipment", "core:records"],
        },
        permissionsQuery: {
          $and: ["equipment:read", "records:read", "records:write"],
        },
        path: linkToAddEquipmentRecordValueSource(),
        exact: true,
        main: () => <EquipmentRecordValueSourcePage />,
      }),
      RouteDefinition({
        featureFlagsQuery: {
          $and: ["core:equipment", "core:estimation_methods"],
        },
        permissionsQuery: {
          $and: [
            "equipment:read",
            "estimation_methods:read",
            "calculator_results:read",
          ],
        },
        path: linkToEstimationMethodCalculationResult(),
        exact: true,
        main: () => <CalculationResultDetail />,
      }),
      RouteDefinition({
        featureFlagsQuery: {
          $and: ["core:equipment", "core:estimation_methods"],
        },
        permissionsQuery: {
          $and: ["equipment:read", "estimation_methods:write"],
        },
        path: linkToEstimationMethodCreate(),
        exact: true,
        main: (props) => <CreateEstimationMethod {...props} />,
      }),
      RouteDefinition({
        featureFlagsQuery: {
          $and: ["core:equipment", "core:estimation_methods"],
        },
        permissionsQuery: {
          $and: ["equipment:read", "estimation_methods:read"],
        },
        path: linkToEstimationMethodDetail(),
        exact: true,
        main: (props) => <EstimationMethodDetail {...props} />,
      }),
      RouteDefinition({
        permissionsQuery: { $and: ["equipment:write", "facilities:read"] },
        featureFlagsQuery: "core:equipment",
        path: EquipmentCreateRoute.path,
        exact: true,
        main: () => <CreateEquipmentPage />,
      }),
      RouteDefinition({
        path: "/app/organization/equipment",
        exact: true,
        main: () => <EquipmentListPage />,
      }),
      RouteDefinition({
        featureFlagsQuery: {
          $and: ["core:equipment", "core:estimation_methods"],
        },
        permissionsQuery: {
          $and: ["equipment:read", "calculator_results:read"],
        },
        path: linkToEquipmentEmissionRecordDetail(),
        exact: true,
        main: (props) => <EmissionsRecordDetail {...props} />,
      }),
    ],
  }),
  organization_devices: PageConfig({
    link: DevicesListRoutePath.path,
    featureFlagsQuery: "core:devices",
    permissionsQuery: "devices:read",
    routes: [
      RouteDefinition({
        path: DevicesListRoutePath.path,
        exact: true,
        main: () => <DeviceListPage />,
      }),
      RouteDefinition({
        permissionsQuery: "devices:write",
        path: DevicesCreateRoutePath.path,
        exact: true,
        main: () => <CreateDevicePage />,
      }),
      RouteDefinition({
        path: "/app/organization/devices/:deviceId/details",
        exact: true,
        main: (props, permissions) => (
          <DeviceDetail
            {...props}
            permissions={permissions}
          />
        ),
      }),
    ],
  }),
  [LegacyOpsHubFeatures.EMISSIONS_RECORDS]: PageConfig({
    link: linkToEmissionRecords(),
    legacyPermissionsQuery: ["esg:emissions_reports"],
    legacyFeatureFlagsQuery: ["esg/emission_records"],
    routes: [
      RouteDefinition({
        path: "/app/esg_emissions/records/",
        exact: true,
        breadcrumbs: [EMISSION_BREADCRUMB, EMISSION_RECORDS_BREADCRUMB],
        main: (props, permissions) => (
          <EmissionRecords
            {...props}
            permissions={permissions}
          />
        ),
      }),
    ],
  }),
  [LegacyOpsHubFeatures.EMISSIONS_REPORTS]: PageConfig({
    link: linkToEmissionReports(),
    legacyPermissionsQuery: ["esg:emissions_reports"],
    legacyFeatureFlagsQuery: ["esg/emission_records"],
    routes: [
      RouteDefinition({
        legacyPermissionsQuery: ["esg:emissions_reports:validere"],
        path: "/app/esg_emissions/reports/detail/:id/edit",
        exact: true,
        breadcrumbs: [
          EMISSION_BREADCRUMB,
          EMISSION_REPORTS_BREADCRUMB,
          EMISSION_REPORTS_DETAIL_BREADCRUMB,
          EDIT_EMISSION_REPORTS_BREADCRUMB,
        ],
        main: (props, permissions) => (
          <CreateEmissionReports
            {...props}
            permissions={permissions}
          />
        ),
      }),
      RouteDefinition({
        path: "/app/esg_emissions/reports/detail/:id",
        exact: true,
        breadcrumbs: [
          EMISSION_BREADCRUMB,
          EMISSION_REPORTS_BREADCRUMB,
          EMISSION_REPORTS_DETAIL_BREADCRUMB,
        ],
        main: (props, permissions) => (
          <EmissionReportDetail
            {...props}
            permissions={permissions}
          />
        ),
      }),
      RouteDefinition({
        legacyPermissionsQuery: ["esg:emissions_reports:validere"],
        path: "/app/esg_emissions/reports/create",
        exact: true,
        breadcrumbs: [
          EMISSION_BREADCRUMB,
          EMISSION_REPORTS_BREADCRUMB,
          CREATE_EMISSION_REPORTS_BREADCRUMB,
        ],
        main: (props, permissions) => (
          <CreateEmissionReports
            {...props}
            permissions={permissions}
          />
        ),
      }),
      RouteDefinition({
        path: "/app/esg_emissions/reports",
        exact: true,
        breadcrumbs: [EMISSION_BREADCRUMB, EMISSION_REPORTS_BREADCRUMB],
        main: (props, permissions) => (
          <EmissionReports
            {...props}
            permissions={permissions}
          />
        ),
      }),
    ],
  }),
  // REVIEW: this is an Ops feature. It also doesn't need it's own config and can be merged with LegacyOpsFeatures.DATA_MAPPING.
  data_mapping_upload_detail: PageConfig({
    link: linkToDataMapping(),
    legacyPermissionsQuery: ["core:data_ingest"],
    legacyFeatureFlagsQuery: ["core/data_ingest"],
    routes: [
      RouteDefinition({
        path: "/app/data-management/data-mapping/:id/detail/:taskId",
        exact: true,
        breadcrumbs: [
          DATA_MANAGEMENT_BREADCRUMB,
          DATA_MAPPING_BREADCRUMB,
          DATA_MAPPING_DETAIL_BREADCRUMB,
          DATA_MAPPING_UPLOAD_DETAIL_BREADCRUMB,
        ],
        main: (props, permissions) => (
          <DataMappingUploadDetail
            {...props}
            permissions={permissions}
          />
        ),
      }),
    ],
  }),
  // REVIEW: this is an Ops feature. It also doesn't need it's own config and can be merged with LegacyOpsFeatures.DATA_MAPPING.
  data_mapping_detail: PageConfig({
    link: linkToDataMapping(),
    legacyPermissionsQuery: ["core:data_ingest"],
    legacyFeatureFlagsQuery: ["core/data_ingest"],
    routes: [
      RouteDefinition({
        path: "/app/data-management/data-mapping/:id/detail",
        exact: true,
        breadcrumbs: [
          DATA_MANAGEMENT_BREADCRUMB,
          DATA_MAPPING_BREADCRUMB,
          DATA_MAPPING_DETAIL_BREADCRUMB,
        ],
        main: (props, permissions) => (
          <DataMappingDetail
            {...props}
            permissions={permissions}
          />
        ),
      }),
    ],
  }),
  [LegacyOpsHubFeatures.DATA_MAPPING]: PageConfig({
    link: linkToDataMapping(),
    legacyPermissionsQuery: ["core:data_ingest"],
    legacyFeatureFlagsQuery: ["core/data_ingest"],
    routes: [
      RouteDefinition({
        path: "/app/data-management/data-mapping",
        exact: true,
        breadcrumbs: [DATA_MANAGEMENT_BREADCRUMB, DATA_MAPPING_BREADCRUMB],
        main: (props, permissions) => (
          <DataMapping
            {...props}
            permissions={permissions}
          />
        ),
      }),
    ],
  }),
  // REVIEW: this is an Ops feature. It also doesn't need it's own config and can be merged with LegacyOpsFeatures.DATA_MAPPING.
  create_data_mapping_validation: PageConfig({
    link: linkToCreateDataMappingValidation(),
    legacyPermissionsQuery: ["core:data_ingest"],
    legacyFeatureFlagsQuery: ["core/data_ingest"],
    routes: [
      RouteDefinition({
        legacyPermissionsQuery: ["core:data_ingest:write"],
        path: "/app/data-management/data-mapping/create/validation",
        exact: true,
        breadcrumbs: [
          DATA_MANAGEMENT_BREADCRUMB,
          DATA_MAPPING_BREADCRUMB,
          CREATE_DATA_MAPPING_BREADCRUMB,
        ],
        main: (props, permissions) => (
          <ValidationForm
            {...props}
            permissions={permissions}
          />
        ),
      }),
    ],
  }),
  // REVIEW: this is an Ops feature. It also doesn't need it's own config and can be merged with LegacyOpsFeatures.DATA_MAPPING.
  create_data_mapping_mapping: PageConfig({
    link: linkToCreateDataMappingEntityMapping(),
    legacyPermissionsQuery: ["core:data_ingest"],
    legacyFeatureFlagsQuery: ["core/data_ingest"],
    routes: [
      RouteDefinition({
        legacyPermissionsQuery: ["core:data_ingest:write"],
        path: "/app/data-management/data-mapping/create/entity-mapping",
        exact: true,
        breadcrumbs: [
          DATA_MANAGEMENT_BREADCRUMB,
          DATA_MAPPING_BREADCRUMB,
          CREATE_DATA_MAPPING_BREADCRUMB,
        ],
        main: (props, permissions) => (
          <DataMappingForm
            {...props}
            permissions={permissions}
          />
        ),
      }),
    ],
  }),
  // REVIEW: this is an Ops feature. It also doesn't need it's own config and can be merged with LegacyOpsFeatures.DATA_MAPPING.
  create_data_mapping_upload: PageConfig({
    link: linkToCreateDataMappingUploadData(),
    legacyPermissionsQuery: ["core:data_ingest"],
    legacyFeatureFlagsQuery: ["core/data_ingest"],
    routes: [
      RouteDefinition({
        legacyPermissionsQuery: ["core:data_ingest:write"],
        path: "/app/data-management/data-mapping/create/upload",
        exact: true,
        breadcrumbs: [
          DATA_MANAGEMENT_BREADCRUMB,
          DATA_MAPPING_BREADCRUMB,
          CREATE_DATA_MAPPING_BREADCRUMB,
        ],
        main: (props, permissions) => (
          <FileUploadForm
            {...props}
            permissions={permissions}
          />
        ),
      }),
    ],
  }),
  // REVIEW: this is an Ops feature. It also doesn't need it's own config and can be merged with LegacyOpsFeatures.DATA_MAPPING.
  create_data_mapping_basic_info: PageConfig({
    link: linkToCreateDataMappingUploadData(),
    legacyPermissionsQuery: ["core:data_ingest"],
    legacyFeatureFlagsQuery: ["core/data_ingest"],
    routes: [
      RouteDefinition({
        legacyPermissionsQuery: ["core:data_ingest:write"],
        path: "/app/data-management/data-mapping/create/basic-info",
        exact: true,
        breadcrumbs: [
          DATA_MANAGEMENT_BREADCRUMB,
          DATA_MAPPING_BREADCRUMB,
          CREATE_DATA_MAPPING_BREADCRUMB,
        ],
        main: (props, permissions) => (
          <BasicInfoForm
            {...props}
            permissions={permissions}
          />
        ),
      }),
    ],
  }),
  // REVIEW: this is an Ops feature. It also doesn't need it's own config and can be merged with LegacyOpsFeatures.DATA_MAPPING.
  upload_data_mapping_file_upload: PageConfig({
    link: linkToCreateDataMappingUploadData(),
    legacyPermissionsQuery: ["core:data_ingest"],
    legacyFeatureFlagsQuery: ["core/data_ingest"],
    routes: [
      RouteDefinition({
        legacyPermissionsQuery: ["core:data_ingest:write"],
        path: "/app/data-management/data-mapping/:id/upload/file-upload",
        exact: true,
        breadcrumbs: [DATA_MANAGEMENT_BREADCRUMB, DATA_MAPPING_BREADCRUMB],
        main: (props, permissions) => (
          <UploadFileUploadForm
            {...props}
            permissions={permissions}
          />
        ),
      }),
    ],
  }),
  // REVIEW: this is an Ops feature. It also doesn't need it's own config and can be merged with LegacyOpsFeatures.DATA_MAPPING.
  upload_data_mapping_file_validation: PageConfig({
    link: linkToCreateDataMappingUploadData(),
    legacyPermissionsQuery: ["core:data_ingest"],
    legacyFeatureFlagsQuery: ["core/data_ingest"],
    routes: [
      RouteDefinition({
        legacyPermissionsQuery: ["core:data_ingest:write"],
        path: "/app/data-management/data-mapping/:id/upload/validation",
        exact: true,
        breadcrumbs: [DATA_MANAGEMENT_BREADCRUMB, DATA_MAPPING_BREADCRUMB],
        main: (props, permissions) => (
          <UploadValidationForm
            {...props}
            permissions={permissions}
          />
        ),
      }),
    ],
  }),
  data_tools_submissions: PageConfig({
    link: DataSubmissionsRoutePath.path,
    featureFlagsQuery: "core:data_pipeline",
    permissionsQuery: "data_submissions:read",
    routes: [
      RouteDefinition({
        path: DataSubmissionsRoutePath.path,
        exact: true,
        main: () => <DataSubmissionsListPage />,
      }),
      RouteDefinition({
        path: DataSetSubmissionDetailRoutePath.path,
        exact: true,
        main: () => <DataSetSubmissionDetailPage />,
      }),
    ],
  }),

  [LegacyOpsHubFeatures.MANUAL_ENTRY]: PageConfig({
    link: linkToManualEntry(),
    legacyPermissionsQuery: ["lite:lab_results:write"],
    routes: [
      RouteDefinition({
        path: "/app/manual-entry/create/:manualEntryType",
        exact: true,
        breadcrumbs: [
          DATA_MANAGEMENT_BREADCRUMB,
          MANUAL_ENTRY_BREADCRUMB,
          CREATE_MANUAL_ENTRY_BREADCRUMB,
        ],
        main: (props, permissions) => (
          <CreateManualEntry
            {...props}
            permissions={permissions}
          />
        ),
      }),
      RouteDefinition({
        path: "/app/manual-entry",
        exact: true,
        breadcrumbs: [DATA_MANAGEMENT_BREADCRUMB, MANUAL_ENTRY_BREADCRUMB],
        main: (props, permissions) => (
          <ManualEntry
            {...props}
            permissions={permissions}
          />
        ),
      }),
    ],
  }),
  [LegacyOpsHubFeatures.RECORDS_OF_VOLUME]: PageConfig({
    link: linkToRecordOfVolume(),
    legacyPermissionsQuery: ["gas:accounting.records_of_volume"],
    routes: [
      RouteDefinition({
        path: "/app/record-of-volume/daily/:stream_id",
        exact: true,
        breadcrumbs: [
          RECORDS_BREADCRUMB,
          ROV_BREADCRUMB,
          ROV_BREADCRUMB_DETAIL,
        ],
        main: (props, permissions) => (
          <RecordOfVolume
            {...props}
            permissions={permissions}
          />
        ),
      }),
      RouteDefinition({
        path: "/app/record-of-volume",
        breadcrumbs: [RECORDS_BREADCRUMB, ROV_BREADCRUMB],
        exact: true,
        main: (props, permissions) => (
          <RecordOfVolume
            {...props}
            permissions={permissions}
          />
        ),
      }),
    ],
  }),
  // REVIEW: this is an Ops feature but has no associated sidebar entry
  adjustment_factors: PageConfig({
    link: linkToAdjustmentFactors(),
    legacyPermissionsQuery: ["gas:accounting.adjustment_factors"],
    routes: [
      RouteDefinition({
        path: "/app/adjustment-factors",
        exact: true,
        breadcrumbs: [
          RECORDS_BREADCRUMB,
          ROV_BREADCRUMB,
          ROV_ADJUSTMENT_FACTORS_BREADCRUMB,
        ],
        main: (props, permissions) => (
          <AdjustmentFactors
            {...props}
            permissions={permissions}
          />
        ),
      }),
    ],
  }),
  [LegacyOpsHubFeatures.RECORDS_OF_QUALITY]: PageConfig({
    link: linkToRecordOfQuality(),
    legacyPermissionsQuery: ["gas:records_of_quality"],
    routes: [
      RouteDefinition({
        path: "/app/record-of-quality",
        exact: true,
        breadcrumbs: [RECORDS_BREADCRUMB, RECORD_OF_QUALITY_BREADCRUMB],
        main: (props, permissions) => (
          <RecordOfQuality
            {...props}
            permissions={permissions}
          />
        ),
      }),
    ],
  }),
  [LegacyOpsHubFeatures.PRODUCTION_STREAMS]: PageConfig({
    link: linkToProductionStreams(),
    legacyPermissionsQuery: ["gas:records_of_quality"],
    legacyFeatureFlagsQuery: ["production/records"],
    routes: [
      RouteDefinition({
        path: "/app/production/streams/:streamId/records/:recordId/edit",
        exact: true,
        breadcrumbs: [
          PRODUCTION_BREADCRUMB,
          STREAMS_BREADCRUMB,
          EDIT_RECORDS_BREADCRUMB,
        ],
        main: (props, permissions) => (
          <EditRecords
            {...props}
            permissions={permissions}
          />
        ),
      }),
      RouteDefinition({
        path: "/app/production/streams/:streamId",
        exact: true,
        breadcrumbs: [PRODUCTION_BREADCRUMB, STREAMS_BREADCRUMB],
        main: (props, permissions) => (
          <StreamDetail
            {...props}
            permissions={permissions}
          />
        ),
      }),
      RouteDefinition({
        path: "/app/production/streams",
        exact: true,
        breadcrumbs: [PRODUCTION_BREADCRUMB, STREAMS_BREADCRUMB],
        main: (props, permissions) => (
          <Streams
            {...props}
            permissions={permissions}
          />
        ),
      }),
    ],
  }),
  [LegacyOpsHubFeatures.WORKFLOWS_MANAGE]: PageConfig({
    link: linkToManageWorkflows(),
    legacyPermissionsQuery: ["lite:workflows:write"],
    routes: [
      RouteDefinition({
        path: "/app/workflows/manage/edit/:workflowId",
        exact: true,
        breadcrumbs: [
          DATA_MANAGEMENT_BREADCRUMB,
          MANAGE_WORKFLOW_BREADCRUMB,
          EDIT_WORKFLOWS_BREADCRUMB,
        ],
        main: (props, permissions) => (
          <EditWorkflow
            {...props}
            permissions={permissions}
          />
        ),
      }),
      RouteDefinition({
        path: "/app/workflows/manage",
        exact: true,
        breadcrumbs: [DATA_MANAGEMENT_BREADCRUMB, MANAGE_WORKFLOW_BREADCRUMB],
        main: (props, permissions) => (
          <ManageWorkflows
            {...props}
            permissions={permissions}
          />
        ),
      }),
    ],
  }),
  [LegacyOpsHubFeatures.WORKFLOWS]: PageConfig({
    link: linkToViewWorkflows(),
    legacyPermissionsQuery: ["lite:workflows"],
    routes: [
      RouteDefinition({
        legacyPermissionsQuery: ["lite:workflows:write"],
        path: "/app/workflows/create",
        exact: true,
        breadcrumbs: [
          DATA_MANAGEMENT_BREADCRUMB,
          MANAGE_WORKFLOW_BREADCRUMB,
          CREATE_WORKFLOWS_BREADCRUMB,
        ],
        main: (props, permissions) => (
          <CreateWorkflow
            {...props}
            permissions={permissions}
          />
        ),
      }),
      RouteDefinition({
        path: "/app/workflows/tasks/:view/view",
        breadcrumbs: [DATA_MANAGEMENT_BREADCRUMB, WORKFLOW_TASKS_BREADCRUMB],
        main: (props, permissions) => (
          <Workflows
            {...props}
            permissions={permissions}
          />
        ),
      }),
      RouteDefinition({
        path: "/app/workflows/tasks",
        exact: true,
        main: (props, permissions) => (
          <Workflows
            {...props}
            permissions={permissions}
          />
        ),
      }),
      {
        path: "/app/workflows",
        exact: true,
        main: (props, permissions) => (
          <Workflows
            {...props}
            permissions={permissions}
          />
        ),
      },
    ],
  }),
  [LegacyOpsHubFeatures.INSTRUMENTS]: PageConfig({
    link: linkToInstruments(),
    legacyPermissionsQuery: ["360:field_labs"],
    routes: [
      RouteDefinition({
        path: "/app/inline-instruments/:instrumentType/:instrumentId/:tab",
        exact: true,
        breadcrumbs: [
          DATA_MANAGEMENT_BREADCRUMB,
          INSTRUMENTS_BREADCRUMB,
          INSTRUMENTS_DETAIL_BREADCRUMB,
        ],
        main: (props, permissions) => (
          <InlineAnalyzer
            {...props}
            permissions={permissions}
          />
        ),
      }),
      RouteDefinition({
        path: ["/app/instrument/list", "/app/instrument/manage"],
        exact: true,
        breadcrumbs: [DATA_MANAGEMENT_BREADCRUMB, INSTRUMENTS_BREADCRUMB],
        main: (props, permissions) => (
          <Instruments
            {...props}
            permissions={permissions}
          />
        ),
      }),
      RouteDefinition({
        path: [
          "/app/instrument/view/:instrumentId/summary",
          "/app/instrument/view/:instrumentId/tests",
          "/app/instrument/view/:instrumentId/csvExport",
        ],
        exact: false,
        breadcrumbs: [
          DATA_MANAGEMENT_BREADCRUMB,
          INSTRUMENTS_BREADCRUMB,
          INSTRUMENTS_DETAIL_BREADCRUMB,
        ],
        main: (props, permissions) => (
          <Instrument
            {...props}
            permissions={permissions}
          />
        ),
      }),
    ],
  }),
  [LegacyOpsHubFeatures.TESTS]: PageConfig({
    link: linkToTests(),
    legacyPermissionsQuery: ["360:test_management"],
    routes: [
      RouteDefinition({
        path: "/app/tests/:testId/detail",
        exact: true,
        breadcrumbs: [
          MEASUREMENTS_BREADCRUMB,
          TESTS_BREADCRUMB,
          TEST_DETAIL_BREADCRUMB,
        ],
        main: (props, permissions) => (
          <TestDetail
            {...props}
            permissions={permissions}
          />
        ),
      }),
      RouteDefinition({
        path: "/app/tests",
        exact: true,
        breadcrumbs: [MEASUREMENTS_BREADCRUMB, TESTS_BREADCRUMB],
        main: (props, permissions) => (
          <TestManagement
            {...props}
            permissions={permissions}
          />
        ),
      }),
    ],
  }),
  [LegacyOpsHubFeatures.SAMPLES]: PageConfig({
    link: linkToSamples(),
    legacyPermissionsQuery: ["lite:lab_results"],
    routes: [
      RouteDefinition({
        path: "/app/samples/:sampleId/detail",
        exact: true,
        breadcrumbs: [
          MEASUREMENTS_BREADCRUMB,
          SAMPLES_BREADCRUMB,
          SAMPLE_DETAIL_BREADCRUMB,
        ],
        main: (props, permissions) => (
          <SampleDetail
            {...props}
            permissions={permissions}
          />
        ),
      }),
      RouteDefinition({
        path: "/app/samples",
        exact: true,
        breadcrumbs: [MEASUREMENTS_BREADCRUMB, SAMPLES_BREADCRUMB],
        main: (props, permissions) => (
          <Samples
            {...props}
            permissions={permissions}
          />
        ),
      }),
    ],
  }),

  [LegacyOpsHubFeatures.INVENTORIES]: PageConfig({
    link: linkToInventories(),
    legacyPermissionsQuery: ["360:field_labs"],
    routes: [
      RouteDefinition({
        path: [
          "/app/inventories/view/:inventoryId/:tab",
          "/app/inventories/view/:inventoryId",
        ],
        exact: true,
        breadcrumbs: [
          MEASUREMENTS_BREADCRUMB,
          INVENTORIES_BREADCRUMB,
          INVENTORY_DETAIL_BREADCRUMB,
        ],
        main: (props, permissions) => (
          <InventoryDetail
            {...props}
            permissions={permissions}
          />
        ),
      }),
      RouteDefinition({
        path: "/app/inventories",
        exact: true,
        breadcrumbs: [MEASUREMENTS_BREADCRUMB, INVENTORIES_BREADCRUMB],
        main: (props, permissions) => (
          <Inventory
            {...props}
            permissions={permissions}
          />
        ),
      }),
    ],
  }),
  [LegacyOpsHubFeatures.LAB_RESULTS]: PageConfig({
    link: linkToLabResults(),
    legacyPermissionsQuery: ["lite:lab_results"],
    routes: [
      RouteDefinition({
        path: "/app/lab-results",
        exact: true,
        breadcrumbs: [MEASUREMENTS_BREADCRUMB, LAB_RESULT_BREADCRUMB],
        main: (props, permissions) => (
          <LabResults
            {...props}
            permissions={permissions}
          />
        ),
      }),
    ],
  }),
  event_categories: PageConfig({
    link: linkToEventCategories(),
    featureFlagsQuery: "core:events",
    permissionsQuery: "event_schemas:read",
    routes: [
      RouteDefinition({
        path: linkToEventCategories(),
        exact: true,
        main: () => <EventCategoriesPage />,
      }),
      RouteDefinition({
        permissionsQuery: { $or: ["events:read", "event_schemas:read"] },
        path: linkToEventCategoryDetail(),
        exact: true,
        main: () => <EventCategoryDetailPage />,
      }),
      RouteDefinition({
        permissionsQuery: { $and: ["event_schemas:read", "events:read"] },
        path: linkToEventTemplateDetail(),
        exact: true,
        main: () => <EventTemplateDetailPage />,
      }),
      RouteDefinition({
        featureFlagsQuery: {
          $and: [
            "core:events",
            {
              $or: ["core:forms", "core:devices", "core:equipment"],
            },
          ],
        },
        permissionsQuery: {
          $and: [
            "events:read",
            {
              $or: [
                { $and: ["form_schemas:read", "form_submissions:read"] },
                "devices:read",
                "equipment:read",
              ],
            },
          ],
        },
        path: linkToEventDetails(),
        exact: true,
        main: () => <EventPage />,
      }),
      RouteDefinition({
        featureFlagsQuery: { $and: ["core:events", "core:forms"] },
        permissionsQuery: {
          $and: [
            "events:read",
            "events:write",
            "form_submissions:read",
            "form_schemas:read",
          ],
        },
        path: linkToAddFormSubmissionToEvent(),
        exact: true,
        main: () => <AddFormSubmissionToEventPage />,
      }),
      RouteDefinition({
        featureFlagsQuery: { $and: ["core:events", "core:devices"] },
        permissionsQuery: {
          $and: ["events:read", "events:write", "devices:read"],
        },
        path: linkToAddDevicesToEvent(),
        exact: true,
        main: () => <AddDevicesToEventPage />,
      }),
      RouteDefinition({
        featureFlagsQuery: { $and: ["core:events", "core:equipment"] },
        permissionsQuery: {
          $and: ["events:read", "events:write", "equipment:read"],
        },
        path: linkToAddEquipmentToEvent(),
        exact: true,
        main: () => <AddEquipmentToEventPage />,
      }),
    ],
  }),
  all_events: PageConfig({
    link: linkToAllEvents(),
    featureFlagsQuery: "core:events",
    permissionsQuery: "events:read",
    routes: [
      RouteDefinition({
        path: linkToAllEvents(),
        exact: true,
        main: () => <AllEventsPage />,
      }),
    ],
  }),
  [LegacyOpsHubFeatures.ANALYZE]: PageConfig({
    link: linkToAnalyze(),
    legacyPermissionsQuery: ["lite:analyze"],
    routes: [
      {
        path: "/app/analyze",
        exact: true,
        breadcrumbs: [ANALYZE_BREADCRUMB, ANALYZE_DETAIL_BREADCRUMB],
        main: (props, permissions) => (
          <Analyze
            {...props}
            permissions={permissions}
          />
        ),
      },
    ],
  }),
  [LegacyOpsHubFeatures.IMPORTS]: PageConfig({
    link: linkToThirdPartyImports(),
    legacyPermissionsQuery: ["lite:lab_results"],
    routes: [
      RouteDefinition({
        path: "/app/imports",
        exact: true,
        breadcrumbs: [DATA_MANAGEMENT_BREADCRUMB, IMPORTS_BREADCRUMB],
        main: (props, permissions) => (
          <ThirdPartyLabImports
            {...props}
            permissions={permissions}
          />
        ),
      }),
    ],
  }),
  [LegacyOpsHubFeatures.CHAINS_OF_CUSTODY]: PageConfig({
    link: linkToChainOfCustody(),
    legacyPermissionsQuery: ["lite:chains_of_custody"],
    routes: [
      RouteDefinition({
        legacyPermissionsQuery: ["lite:chains_of_custody:write"],
        path: "/app/chain-of-custody/create/:cocId",
        exact: true,
        breadcrumbs: [
          DATA_MANAGEMENT_BREADCRUMB,
          CHAIN_OF_CUSTODY_BREADCRUMB,
          CREATE_CHAIN_OF_CUSTODY_BREADCRUMB,
        ],
        main: (props, permissions) => (
          <CreateChainOfCustody
            {...props}
            permissions={permissions}
          />
        ),
      }),
      RouteDefinition({
        legacyPermissionsQuery: ["lite:chains_of_custody:write"],
        path: "/app/chain-of-custody/create",
        exact: true,
        breadcrumbs: [
          DATA_MANAGEMENT_BREADCRUMB,
          CHAIN_OF_CUSTODY_BREADCRUMB,
          CREATE_CHAIN_OF_CUSTODY_BREADCRUMB,
        ],
        main: (props, permissions) => (
          <CreateChainOfCustody
            {...props}
            permissions={permissions}
          />
        ),
      }),
      RouteDefinition({
        path: "/app/chain-of-custody/form/:cocId/view",
        exact: true,
        breadcrumbs: [DATA_MANAGEMENT_BREADCRUMB, CHAIN_OF_CUSTODY_BREADCRUMB],
        main: (props, permissions) => (
          <ChainOfCustody
            {...props}
            permissions={permissions}
          />
        ),
      }),
      RouteDefinition({
        path: "/app/chain-of-custody",
        exact: true,
        breadcrumbs: [DATA_MANAGEMENT_BREADCRUMB, CHAIN_OF_CUSTODY_BREADCRUMB],
        main: (props, permissions) => (
          <ChainOfCustody
            {...props}
            permissions={permissions}
          />
        ),
      }),
    ],
  }),
  [LegacyAccountNavBarSettings.COMPANY]: PageConfig({
    link: linkToCompanySettings(),
    legacyPermissionsQuery: ["core:user.management"],
    routes: [
      RouteDefinition({
        path: "/app/settings/company/members/:user_id/edit",
        exact: true,
        main: (props, permissions) => (
          <EditUser
            {...props}
            permissions={permissions}
          />
        ),
      }),
      RouteDefinition({
        legacyPermissionsQuery: ["core:user.management:write"],
        path: "/app/settings/company/members/create",
        exact: true,
        main: (props, permissions) => (
          <CreateUser
            {...props}
            permissions={permissions}
          />
        ),
      }),
      RouteDefinition({
        legacyPermissionsQuery: ["core:user.management:write"],
        path: "/app/settings/company/roles/create",
        exact: true,
        main: (props, permissions) => (
          <CreateRole
            {...props}
            permissions={permissions}
          />
        ),
      }),
      RouteDefinition({
        path: "/app/settings/company/roles/:roleId/edit",
        exact: true,
        main: (props, permissions) => (
          <EditRole
            {...props}
            permissions={permissions}
          />
        ),
      }),
      RouteDefinition({
        legacyPermissionsQuery: ["core:user.management:write"],
        path: "/app/settings/company/notifications/roles/:roleId/edit",
        exact: true,
        main: (props, permissions) => (
          <RoleNotificationSettings
            {...props}
            permissions={permissions}
          />
        ),
      }),
      RouteDefinition({
        path: "/app/settings/company/:tab/view",
        exact: true,
        main: (props, permissions) => (
          <CompanySettings
            {...props}
            permissions={permissions}
          />
        ),
      }),
      RouteDefinition({
        path: "/app/settings/company",
        exact: true,
        main: (props, permissions) => (
          <CompanySettings
            {...props}
            permissions={permissions}
          />
        ),
      }),
    ],
  }),
  [LegacyOpsHubFeatures.ANNOTATIONS]: PageConfig({
    link: linkToAnnotations(),
    legacyPermissionsQuery: ["360:annotations"],
    routes: [
      RouteDefinition({
        path: "/app/annotations/:testId/annotate",
        exact: true,
        main: (props, permissions) => (
          <AnnotatePage
            {...props}
            permissions={permissions}
          />
        ),
      }),
      RouteDefinition({
        path: "/app/annotations",
        exact: true,
        main: (props, permissions) => (
          <Annotations
            {...props}
            permissions={permissions}
          />
        ),
      }),
    ],
  }),
  [LegacyAccountNavBarSettings.ACCOUNT]: PageConfig({
    link: linkToUserSetting(),
    legacyPermissionsQuery: ["core:user.management"],
    shouldCheckPermissions: false,
    routes: [
      RouteDefinition({
        path: "/app/settings/account/:tab/view",
        exact: true,
        main: (props, permissions) => (
          <UserSettings
            {...props}
            permissions={permissions}
          />
        ),
      }),
      RouteDefinition({
        path: "/app/settings/account",
        exact: true,
        main: (props, permissions) => (
          <UserSettings
            {...props}
            permissions={permissions}
          />
        ),
      }),
    ],
  }),
  [AccountNavBarSettings.USERS]: PageConfig({
    link: linkToUsersListPage(),
    shouldCheckPermissions: false,
    routes: [
      RouteDefinition({
        featureFlagsQuery: "core:roles_permissions",
        path: linkToUsersListPage(),
        exact: true,
        main: () => <UsersListPage />,
      }),
      RouteDefinition({
        featureFlagsQuery: "core:roles_permissions",
        path: UserGroupsRoutePath.path,
        exact: true,
        main: () => <UsersListPage />,
      }),
      RouteDefinition({
        featureFlagsQuery: "core:roles_permissions",
        path: UserGroupCategoriesRoutePath.path,
        exact: true,
        main: () => <UsersListPage />,
      }),
      RouteDefinition({
        path: linkToUserDetailPage(),
        exact: true,
        main: () => <UserDetailPage />,
      }),
      RouteDefinition({
        path: GroupCreateRoutePath.path,
        exact: true,
        main: () => <CreateUserGroupPage />,
        featureFlagsQuery: "core:workflows",
        permissionsQuery: "user_groups:write",
      }),
      RouteDefinition({
        featureFlagsQuery: "core:roles_permissions",
        permissionsQuery: "users:write",
        path: linkToCreateUserPage(),
        exact: true,
        main: () => <CreateUserPage />,
      }),
      RouteDefinition({
        path: linkToUserEditPage(),
        exact: true,
        main: () => <EditUserPage />,
      }),
      RouteDefinition({
        path: linkToEditUserRolePage(),
        exact: true,
        main: () => <EditUserRolePage />,
      }),
      RouteDefinition({
        featureFlagsQuery: "core:workflows",
        permissionsQuery: "user_group_categories:read",
        path: UserGroupCategoryDetailsRoutePath.path,
        exact: true,
        main: () => <UserGroupCategoryDetailsPage />,
      }),
      RouteDefinition({
        featureFlagsQuery: "core:workflows",
        permissionsQuery: "user_groups:write",
        path: UserGroupEditRoutePath.path,
        exact: true,
        main: () => <EditUserGroupPage />,
      }),
      RouteDefinition({
        featureFlagsQuery: "core:workflows",
        permissionsQuery: "user_groups:read",
        path: UserGroupDetailsRoutePath.path,
        exact: true,
        main: () => <UserGroupDetailsPage />,
      }),
    ],
  }),

  [AccountNavBarSettings.ROLES_AND_PERMISSIONS]: PageConfig({
    link: linkToRolesListPage(),
    featureFlagsQuery: "core:roles_permissions",
    shouldCheckPermissions: false,
    routes: [
      RouteDefinition({
        path: linkToRolesListPage(),
        exact: true,
        main: () => <RolesAndPermissionsPage />,
      }),
      RouteDefinition({
        permissionsQuery: "roles:write",
        path: linkToCreateRolePage(),
        exact: true,
        main: () => <CreateRolePage />,
      }),
      RouteDefinition({
        path: linkToRoleDetailPage(),
        exact: true,
        main: () => <RoleDetailPage />,
      }),
      RouteDefinition({
        permissionsQuery: "roles:write",
        path: linkToRoleEditPage(),
        exact: true,
        main: () => <EditRolePage />,
      }),
      RouteDefinition({
        permissionsQuery: "roles:write",
        path: linkToEditRoleUsersPage(),
        exact: true,
        main: () => <EditRoleUsersPage />,
      }),
      RouteDefinition({
        permissionsQuery: "roles:write",
        path: linkToEditRolePermissionsPage(),
        exact: true,
        main: () => <EditRolePermissionsPage />,
      }),
    ],
  }),

  [AccountNavBarSettings.CHANGE_LOG]: PageConfig({
    link: linkToActivityLogs(),
    featureFlagsQuery: "core:activity_logs",
    permissionsQuery: "activities:read_all",
    routes: [
      RouteDefinition({
        path: linkToActivityLogs(),
        exact: true,
        main: () => <ActivityLogsPage />,
      }),
    ],
  }),
  [AccountNavBarSettings.MEASUREMENT_TYPES]: PageConfig({
    link: linkToMeasurementTypesSettingsPage(),
    permissionsQuery: "company_unit_config:read",
    routes: [
      RouteDefinition({
        path: linkToMeasurementTypesSettingsPage(),
        exact: true,
        main: () => <MeasurementTypePage />,
      }),
    ],
  }),
  [AccountNavBarSettings.CUSTOM_ATTRIBUTES]: PageConfig({
    link: CustomAttributeRoute.path,
    permissionsQuery: "custom_attribute_definition:read",
    routes: [
      RouteDefinition({
        path: CustomAttributeRoute.path,
        exact: true,
        main: () => <CustomAttributePage />,
      }),
    ],
  }),
  [AccountNavBarSettings.KNOWLEDGE_BASE]: PageConfig({
    externalLink: config.KNOWLEDGE_BASE_URL,
    shouldCheckPermissions: false,
  }),
  [AccountNavBarSettings.CONTACT_SUPPORT]: PageConfig({
    action: true,
    shouldCheckPermissions: false,
  }),
  not_found: PageConfig({
    shouldCheckPermissions: false,
    link: linkToNotFound(),
    routes: [
      RouteDefinition({
        path: linkToNotFound(),
        main: () => <NotFoundPage />,
      }),
    ],
  }),
};

export const Routes = Object.values(PageConfigs).flatMap(
  (pageConfig) => pageConfig?.routes ?? []
);

export default PageConfigs;
