import TemplatedConfigurationResourcePills from "#src/batteries-included-components/PillsCollection/TemplatedConfigurationResourcePills/TemplatedConfigurationResourcePills";
import { useListEquipmentTypes } from "#src/components/hooks/adapters/useEquipment";
import {
  useGetOneTemplatedConfiguration,
  useListTemplatedConfigurations,
} from "#src/components/hooks/adapters/useTemplatedConfigurations";
import { useAuthenticatedContext } from "#src/contexts/AuthenticatedContext.helpers";
import useLocalization from "#src/hooks/useLocalization";
import { ExceptionUtils } from "#src/utils/exception";
import { useQuery } from "@tanstack/react-query";
import {
  Banner,
  Button,
  DropdownInput,
  FileUploadInput,
  Form,
  Icon,
  LoadingState,
  useAlert,
  useFormContext,
} from "@validereinc/common-components";
import {
  DatasetAdapter,
  EquipmentSchema,
  ResourceDefinitions,
  Resources,
  TemplateAdapter,
} from "@validereinc/domain";
import { TemplatedConfigurations } from "@validereinc/domain-controllers";
import { downloadBlob } from "@validereinc/utilities";
import classNames from "classnames/bind";
import { snakeCase } from "lodash";
import React, { useEffect, useMemo } from "react";
import styles from "../ImportDataAction.module.scss";
import { useImportDataActionContext } from "../ImportDataActionContext/ImportDataActionContext";

const cx = classNames.bind(styles);

const ImportEquipmentTemplatedConfigurationRuns = () => {
  const { claims } = useAuthenticatedContext();
  const equipmentTypesQuery = useListEquipmentTypes({});
  const equipmentTypes = equipmentTypesQuery.data?.data ?? [];

  const { localize } = useLocalization();
  const { addAlert } = useAlert();
  const {
    status,
    acceptedMimeTypes,
    datasetId,
    setDatasetId,
    preselectedTemplatedConfigurationName,
  } = useImportDataActionContext();

  const form = useFormContext();
  const [selectedEquipmentType, selectedTemplateName] = form.watch([
    EquipmentSchema.keyof().Enum.type_id,
    "template_name",
  ]);

  const availableTemplatesQuery = useListTemplatedConfigurations(
    {
      filters: {
        primary_resource_type: Resources.EQUIPMENT,
        equipment_type_id: selectedEquipmentType,
      },
    },
    {
      enabled:
        !!selectedEquipmentType || !!preselectedTemplatedConfigurationName,
    }
  );
  const preselectedTemplateQuery = useGetOneTemplatedConfiguration(
    { id: preselectedTemplatedConfigurationName! },
    { enabled: !!preselectedTemplatedConfigurationName }
  );
  const selectedTemplatedConfiguration = preselectedTemplateQuery.data
    ? preselectedTemplateQuery.data.data
    : (availableTemplatesQuery.data?.data ?? []).find(
        (template) => template.name === selectedTemplateName
      );

  const primaryResourceId = useMemo(
    () =>
      TemplatedConfigurations.logic.getResourceIdForResource(
        Resources.EQUIPMENT,
        selectedTemplatedConfiguration
      ),
    [selectedTemplatedConfiguration?.display_name]
  );

  const datasetQueryParams = {
    company_id: claims.company?.id,
    templated_configuration_name: selectedTemplateName,
    custom_attribute_configuration: {
      [primaryResourceId ?? Resources.EQUIPMENT]: {
        entity_subtype: selectedEquipmentType,
      },
    },
  };
  const {
    data: dataset,
    isLoading: isLoadingDataset,
    isError: isErrorDataset,
  } = useQuery({
    queryFn: () =>
      DatasetAdapter.templatedConfigurations.getList(datasetQueryParams),
    queryKey: ["datasets", "templatedConfigurations", datasetQueryParams],
    select: (resp) => ((resp.meta.total ?? 0) >= 1 ? resp.data[0] : null),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled:
      !!claims &&
      !!primaryResourceId &&
      !!selectedTemplateName &&
      !!selectedEquipmentType,
  });

  const {
    data: templateBlob,
    isLoading: isLoadingTemplateBlob,
    isError: isErrorTemplateBlob,
  } = useQuery(
    ["datasets", "template", dataset?.id, claims.company.id!],
    () =>
      TemplateAdapter.dataset.getOne({
        datasetId: dataset!.id,
        companyId: claims.company.id!,
      }),
    {
      enabled: !!dataset?.id && !!claims?.company?.id,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (!dataset) {
      setDatasetId("");
      return;
    }
    if (dataset?.id === datasetId) return;

    setDatasetId(dataset.id);
  }, [dataset?.id, datasetId]);

  const selectedEquipmentTypeDetails = equipmentTypes.find(
    (equipmentType) => equipmentType.id === selectedEquipmentType
  );

  const handleDownloadTemplateClick = () => {
    if (!selectedEquipmentTypeDetails) return;
    if (!templateBlob) {
      addAlert({
        variant: "error",
        message: "Template not available!",
      });
      return;
    }

    const fileName = [
      snakeCase(selectedEquipmentTypeDetails.name),
      Resources.EQUIPMENT,
      "import_template",
    ].join("_");

    try {
      downloadBlob(fileName, templateBlob);
      addAlert({
        variant: "success",
        message: `Template ${fileName}.xlsx downloaded.`,
      });
    } catch (err) {
      addAlert({
        variant: "error",
        message: `Template failed to download.`,
      });
      ExceptionUtils.reportException(err, "error", {
        hint: "Failed to download import template for templated configuration",
      });
    }
  };

  const isTemplatePickerDisabled =
    !selectedEquipmentType ||
    availableTemplatesQuery.isLoading ||
    availableTemplatesQuery.data?.total_entries === 0 ||
    preselectedTemplateQuery.data !== undefined;

  return (
    <>
      <Form {...form}>
        {!preselectedTemplateQuery.data ? (
          <DropdownInput
            label={`${localize("Equipment")} Type`}
            name={EquipmentSchema.keyof().Enum.type_id}
            options={equipmentTypes}
            valueKey="id"
            labelKey="name"
            isRequired
            isFluid
            isLoading={equipmentTypesQuery.isLoading}
          />
        ) : null}
        <DropdownInput
          label="Configuration Template"
          name="template_name"
          options={availableTemplatesQuery.data?.data ?? []}
          defaultValue={preselectedTemplatedConfigurationName}
          valueKey="name"
          labelKey="display_name"
          isFluid
          isLoading={selectedEquipmentType && availableTemplatesQuery.isLoading}
          isDisabled={isTemplatePickerDisabled}
          isRequired
        />
        {preselectedTemplateQuery.data ? (
          <DropdownInput
            label={`${localize("Equipment")} Type`}
            name={EquipmentSchema.keyof().Enum.type_id}
            options={preselectedTemplateQuery.data.data.equipment_types}
            valueKey="id"
            labelKey="name"
            isRequired
            isFluid
            isLoading={equipmentTypesQuery.isLoading}
          />
        ) : null}
        {availableTemplatesQuery.data?.total_entries === 0 && (
          <Banner
            titleText={`There are no ${ResourceDefinitions.templated_configuration.label.plural} available for this type of ${localize(ResourceDefinitions.equipment.label.singular)}`}
            color="info"
            icon={<Icon variant="info" />}
          />
        )}
      </Form>
      {isErrorDataset ? (
        <Banner
          icon={<Icon variant="warning-circle" />}
          titleText="There was a problem loading the import configuration."
          variant="error"
        />
      ) : null}
      {selectedTemplateName && selectedEquipmentType && isLoadingDataset ? (
        <LoadingState label="Loading..." />
      ) : null}
      {selectedTemplateName && dataset ? (
        <>
          {selectedTemplatedConfiguration?.description && (
            <>
              <p className={cx("title")}>Template Description</p>
              <p className={cx("description")}>
                {selectedTemplatedConfiguration.description}
              </p>
            </>
          )}
          <p className={cx("title")}>Resource Types</p>
          <TemplatedConfigurationResourcePills
            templatedConfiguration={selectedTemplatedConfiguration}
          />
          <Button
            icon="arrow-square-out"
            variant="outline"
            onClick={handleDownloadTemplateClick}
            isLoading={
              !isErrorDataset && (isLoadingDataset || isLoadingTemplateBlob)
            }
            disabled={isErrorDataset || isErrorTemplateBlob}
            style={{ marginTop: 16 }}
          >
            Download Import Template
          </Button>
          <FileUploadInput
            name="file"
            label="Select file to upload"
            placeholder="Upload a .csv, .xls, .xlsx, or .json file"
            isRequired={true}
            rules={{
              required: "A file with data to import is required",
            }}
            acceptedFileTypes={acceptedMimeTypes}
            showIcon={true}
            style={{ marginTop: 12 }}
            isDisabled={
              form.formState.isSubmitting || !!status?.percentComplete
            }
          />
        </>
      ) : null}
    </>
  );
};

export default ImportEquipmentTemplatedConfigurationRuns;
