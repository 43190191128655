import { useTableSortingAndPagination } from "#redux/reducers/tableStateReducer";
import { CreateMeasurementSeriesDialog } from "#src/batteries-included-components/Dialogs/CreateMeasurementSeriesDialog";
import { DeleteMeasurementSeriesDialog } from "#src/batteries-included-components/Dialogs/DeleteMeasurementSeriesDialog";
import { EditMeasurementSeriesDialog } from "#src/batteries-included-components/Dialogs/EditMeasurementSeriesDialog";
import { useListMeasurementSeries } from "#src/components/hooks/adapters/useMeasurementSeries";
import { useIsFeatureAvailable } from "#src/contexts/AuthenticatedContext.helpers";
import {
  Button,
  DataTablePanel,
  HeaderType,
} from "@validereinc/common-components";
import {
  DeviceMeasurementSeriesSchema,
  MeasurementSeriesType,
  SortDirection,
} from "@validereinc/domain";
import React, { useState } from "react";

export const MeasurementSeriesTablePanel = ({
  deviceId,
}: {
  deviceId?: string;
}) => {
  const [tableState, updateTableState] = useTableSortingAndPagination({
    sortBy: "description",
    sortDirection: SortDirection.ASCENDING,
  });
  const [isCreateMeasurementSeriesOpen, setIsCreateMeasurementSeriesOpen] =
    useState(false);
  const [itemToDelete, setItemToDelete] =
    useState<MeasurementSeriesType | null>();
  const [itemToUpdate, setItemToUpdate] =
    useState<MeasurementSeriesType | null>();
  const [isDeviceWriteEnabled] = useIsFeatureAvailable({
    featureFlagQuery: "core:devices",
    permissionQuery: "devices:write",
  });
  const [isDeviceDeleteEnabled] = useIsFeatureAvailable({
    featureFlagQuery: "core:devices",
    permissionQuery: "devices:delete",
  });

  const params = {
    page: tableState.page,
    pageSize: tableState.pageSize,
    sortBy: tableState.sortBy,
    sortDirection: tableState.sortDirection,
    filters: { deviceId },
  };

  const { data, isLoading } = useListMeasurementSeries(params, {
    enabled: !!deviceId,
  });
  const items = data?.data ?? [];

  const headers: Array<HeaderType<MeasurementSeriesType>> = [
    {
      key: DeviceMeasurementSeriesSchema.keyof().Enum.description,
      label: "Description",
    },
    {
      key: DeviceMeasurementSeriesSchema.keyof().Enum.measurement_type,
      label: "Measurement Type",
    },
    {
      key: DeviceMeasurementSeriesSchema.keyof().Enum.unit,
      label: "Measurement Unit",
    },
  ];

  const getItemActions = ({ item }: { item: MeasurementSeriesType }) => [
    ...(isDeviceWriteEnabled
      ? [
          {
            label: "Edit",
            buttonProps: {
              onClick: () => setItemToUpdate(item),
              icon: "pencil",
            },
          },
        ]
      : []),
    ...(isDeviceDeleteEnabled
      ? [
          {
            label: "Delete",
            buttonProps: {
              onClick: () => setItemToDelete(item),
              icon: "trash",
            },
          },
        ]
      : []),
  ];

  const actionRow = isDeviceWriteEnabled
    ? [
        <Button
          key="add-measurement-series"
          onClick={() => setIsCreateMeasurementSeriesOpen(true)}
        >
          Create
        </Button>,
      ]
    : [];

  return (
    <>
      <DataTablePanel
        panelProps={{ title: "Measurement Series", actionRow }}
        dataTableProps={{
          variant: "simplicity-first",
          items,
          isLoading,
          headers,
          onSortChange: updateTableState,
          onPaginationChange: updateTableState,
          getItemActions,
          sorting: {
            sortBy: tableState.sortBy,
            sortDirection: tableState.sortDirection,
          },
          pagination: {
            page: tableState.page,
            pageSize: tableState.pageSize,
            total: data?.total_entries,
          },
        }}
      />
      <CreateMeasurementSeriesDialog
        id={deviceId}
        isOpen={isCreateMeasurementSeriesOpen}
        onClose={() => setIsCreateMeasurementSeriesOpen(false)}
      />
      <EditMeasurementSeriesDialog
        itemToUpdate={itemToUpdate}
        isOpen={!!itemToUpdate}
        onClose={() => setItemToUpdate(undefined)}
      />

      <DeleteMeasurementSeriesDialog
        itemToDelete={itemToDelete}
        isOpen={!!itemToDelete}
        onClose={() => setItemToDelete(undefined)}
      />
    </>
  );
};
