import { RoutingLink } from "#src/batteries-included-components/RoutingLink";
import { linkToFormCategoryDetails } from "#src/routes/forms/categories/[categoryId]";
import { linkToFormTemplateDetail } from "#src/routes/forms/categories/[categoryId]/templates/[formTemplateId]";
import { WorkflowCategoryDetailsRoutePath } from "#src/routes/workflows/categories/[workflowCategoryId]";
import { useQuery } from "@tanstack/react-query";
import { KeyValuePanel } from "@validereinc/common-components";
import {
  FormSchemaAdapter,
  Resources,
  WorkflowTemplatesAdapter,
  WorkflowTemplateType,
} from "@validereinc/domain";
import { cronToSimpleDisplayText } from "@validereinc/utilities";
import React from "react";
import {
  getDurationForDisplay,
  getFormTriggerDetails,
  getTrigger,
  getWorkflowCompletionTriggerDetails,
  isFormTrigger,
  isScheduledTrigger,
  isWorkflowCompletionTrigger,
} from "./WorkflowTemplatePage.helpers";
import { WorkflowTemplateDetailsRoutePath } from ".";

export const WorkflowTemplateKeyValuePanel = ({
  templateDetails,
  isLoading,
  workflowOwnerName,
}: {
  templateDetails?: WorkflowTemplateType;
  isLoading: boolean;
  workflowOwnerName?: string;
}) => {
  const formSubmissionTriggerDetails =
    templateDetails?.triggers?.[0] && isFormTrigger(templateDetails.triggers[0])
      ? getFormTriggerDetails(templateDetails.triggers[0])
      : undefined;

  const workflowCompletionTriggerDetails =
    templateDetails?.triggers?.[0] &&
    isWorkflowCompletionTrigger(templateDetails.triggers[0])
      ? getWorkflowCompletionTriggerDetails(templateDetails.triggers[0])
      : undefined;

  const { data: formSchema } = useQuery(
    [Resources.FORM_SCHEMA, formSubmissionTriggerDetails?.formSchemaId],
    () => {
      if (!formSubmissionTriggerDetails?.formSchemaId) {
        return;
      }

      return FormSchemaAdapter.getOne({
        id: formSubmissionTriggerDetails.formSchemaId,
      });
    },
    {
      enabled: Boolean(formSubmissionTriggerDetails),
      staleTime: 2 * 60 * 1000,
      select: (resp) => resp?.data,
    }
  );

  const triggeringWorkflowTemplateId =
    workflowCompletionTriggerDetails?.workflowTemplateId;
  const { data: triggeringWorkflowTemplate } = useQuery(
    ["workflowTemplate", triggeringWorkflowTemplateId],
    () => {
      if (!triggeringWorkflowTemplateId) {
        return;
      }

      return WorkflowTemplatesAdapter.getOne({
        id: triggeringWorkflowTemplateId,
      });
    },
    {
      enabled: Boolean(triggeringWorkflowTemplateId),
      staleTime: 2 * 60 * 1000,
    }
  );

  const detailsData = [
    {
      title: "Description",
      value: templateDetails?.description
        ? templateDetails.description.trim()
        : "-",
    },
    {
      title: "Category",
      value: templateDetails?.workflow_category ? (
        <RoutingLink
          to={WorkflowCategoryDetailsRoutePath.toLinkParts({
            pathParams: {
              workflowCategoryId: templateDetails.category_id,
            },
          })}
        >
          {templateDetails.workflow_category.name.trim()}
        </RoutingLink>
      ) : (
        "-"
      ),
    },
    {
      title: "Trigger",
      value: templateDetails?.triggers?.[0]
        ? getTrigger(templateDetails.triggers[0])
        : "-",
    },
    ...(templateDetails?.triggers?.[0] &&
    isScheduledTrigger(templateDetails.triggers[0])
      ? [
          {
            title: "Repeats",
            value: templateDetails
              ? cronToSimpleDisplayText(templateDetails.triggers[0].cron)
              : "-",
          },
        ]
      : []),
    ...(formSchema
      ? [
          {
            title: "Form Submission Trigger Template",
            value: formSchema ? (
              <RoutingLink
                to={linkToFormTemplateDetail(
                  formSchema.form_category_id,
                  formSchema.id
                )}
              >
                {formSchema.name}
              </RoutingLink>
            ) : null,
          },
          {
            title: "Form Submission Trigger Category",
            value: formSchema ? (
              <RoutingLink
                to={linkToFormCategoryDetails(formSchema.form_category_id)}
              >
                {formSchema.form_category.name}
              </RoutingLink>
            ) : null,
          },
        ]
      : []),
    {
      title: "Due Date",
      value: templateDetails?.triggers?.[0]?.due_date
        ? getDurationForDisplay(
            templateDetails.triggers?.[0]?.due_date.duration,
            templateDetails.triggers?.[0]?.due_date.time_period
          )
        : "-",
    },
    ...(triggeringWorkflowTemplate?.data
      ? [
          {
            title: "Workflow Trigger Template",
            value: triggeringWorkflowTemplate.data.id ? (
              <RoutingLink
                to={WorkflowTemplateDetailsRoutePath.toLinkParts({
                  pathParams: {
                    workflowTemplateId: triggeringWorkflowTemplate.data.id,
                  },
                })}
              >
                {triggeringWorkflowTemplate.data.name}
              </RoutingLink>
            ) : null,
          },
        ]
      : []),
    {
      title: "# of User Tasks",
      value: templateDetails?.config.steps
        ? Object.values(templateDetails.config.steps).reduce((sum, step) => {
            if (step.type !== "user_task") {
              return sum;
            }

            return sum + 1;
          }, 0)
        : "-",
    },
    {
      title: "Workflow Owner",
      value: workflowOwnerName ?? "-",
    },
  ];

  return (
    <KeyValuePanel
      panelMaxColumnCount={2}
      panelProps={{
        title: "Template Details",
        isFluidY: false,
        loaded: !isLoading,
        style: { alignSelf: "flex-start", marginBottom: 0 },
      }}
      data={detailsData}
    />
  );
};
