import FacilityService from "#components/Services/FacilityService";
import { displayValueByDataType } from "#src/utils/display";
import { KeyValuePanel } from "@validereinc/common-components";
import { formatCoordinateDegrees } from "@validereinc/utilities";
import * as PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

const OverviewPanel = ({ facilityDetail, isDisplayMap }) => {
  const [customAttributes, setCustomAttributes] = useState([]);

  useEffect(() => {
    (async () => {
      const {
        data: { data },
      } = await FacilityService.getCustomAttributes();

      setCustomAttributes(data);
    })();
  }, []);

  const data = [
    {
      title: "Location",
      value: formatCoordinateDegrees([
        facilityDetail?.latitude,
        facilityDetail?.longitude,
      ]),
    },
    ...customAttributes
      .filter(
        ({ entity_subtype }) =>
          !entity_subtype || facilityDetail?.type == entity_subtype
      )
      .map(({ display_name, field_name, data_type }) => {
        const value = facilityDetail?.custom_attributes?.[field_name];

        return {
          title: display_name,
          value: displayValueByDataType(value, data_type),
        };
      }),
  ];

  return (
    <KeyValuePanel
      panelMaxColumnCount={isDisplayMap ? 2 : 3}
      panelKeyValueListProps={{ maxRowCount: 6, variant: "shaded" }}
      panelProps={{ isFluidY: false }}
      data={data}
    />
  );
};

OverviewPanel.propTypes = {
  facilityDetail: PropTypes.object,
  isDisplayMap: PropTypes.bool,
};

export default OverviewPanel;
