import { z } from "zod";

/**
 * A transformed file object type that works with the file upload service within the Data Platform API
 * @see {@link https://admin-staging.dataplatform.validere.xyz/docs#/Files}
 */
export const UploadedFileSchema = z.object({
  ref: z.string(),
  name: z.string(),
});

export type UploadedFileType = z.infer<typeof UploadedFileSchema>;
