import {
  Button,
  useFieldArray,
  useFormContext,
} from "@validereinc/common-components";
import React, { useEffect } from "react";
import { QuestionCondition } from "./QuestionCondition";
import classNames from "classnames/bind";
import styles from "../../../../CreateFormTemplate.module.scss";
import { AttributeDataType } from "@validereinc/domain";
import { ConditionOperators } from "@validereinc/domain-controllers/logic/forms";

const cx = classNames.bind(styles);

export const QuestionConditions = ({
  defaultConditions,
  questions,
  handleRemoveLastCondition,
}) => {
  const form = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: "conditions",
  });

  const onAddCondition = () => {
    append({ question: "", operation: "", value: "", type: "" });
  };

  const getConditionOperationFromObject = (condition: {
    operation: string;
  }) => {
    const keys = Object.keys(condition);
    if (keys.length !== 1) {
      return "";
    }
    return keys[0];
  };

  const getConditionTypeFromObject = (
    questionId: string,
    condition: Record<string, any>
  ) => {
    const questionWithCondition = questions.find(
      (question) => question.question_id === questionId
    );

    if (Object.hasOwn(condition, ConditionOperators.$EQ)) {
      return ConditionOperators.$EQ;
    }

    if (
      [ConditionOperators.$LIKE, ConditionOperators.$IN].some((operator) =>
        Object.hasOwn(condition, operator)
      )
    ) {
      const isQuestionPickList = [
        AttributeDataType.PICK_LIST,
        AttributeDataType.MULTI_PICK_LIST,
      ].includes(questionWithCondition.data_type);

      if (isQuestionPickList) {
        const conditionedOptionsLength = condition.$in.length;

        if (
          conditionedOptionsLength ===
          questionWithCondition.pick_list_values.length
        ) {
          return ConditionOperators.$EXISTS;
        } else if (conditionedOptionsLength === 1) {
          return ConditionOperators.$EQ;
        }
      }
      return "partial";
    }

    if (
      [
        ConditionOperators.$GT,
        ConditionOperators.$GTE,
        ConditionOperators.$LT,
        ConditionOperators.$LTE,
      ].some((operator) => Object.hasOwn(condition, operator))
    ) {
      return "comparative";
    }

    return "";
  };

  const getConditionComparatorFromObject = (condition: {
    operation: string;
  }) => {
    const keys = Object.keys(condition);
    if (keys.length !== 1) {
      return "";
    }
    return condition[keys[0]];
  };

  useEffect(() => {
    if (defaultConditions) {
      for (const [questionId, condition] of Object.entries(defaultConditions)) {
        append({
          question: questionId,
          operation:
            condition === true
              ? ConditionOperators.$EXISTS
              : getConditionOperationFromObject(condition),

          value: "",
          type:
            condition === true
              ? ConditionOperators.$EXISTS
              : getConditionTypeFromObject(questionId, condition),

          comparator:
            condition === true
              ? undefined
              : getConditionComparatorFromObject(condition),
        });
      }
    }
  }, [defaultConditions]);

  const onRemoveCondition = (conditionIndex: number) => {
    remove(conditionIndex);

    if (conditionIndex === 0) {
      handleRemoveLastCondition();
    }
  };

  return (
    <>
      <Button
        className={cx("addConditionButton")}
        icon="plus-circle"
        onClick={onAddCondition}
      >
        Add Condition
      </Button>

      {fields.map((_field, index) => (
        <QuestionCondition
          key={index}
          index={index}
          questions={questions}
          onRemoveCondition={onRemoveCondition}
          isRemovable
        />
      ))}
    </>
  );
};
