import { CalculationsRoutePath } from "#src/routes/calculations";
import { RoutePath } from "#src/utils/route";

export const CalculatorsListRoutePath = CalculationsRoutePath.concat(
  new RoutePath({
    path: "/calculators",
    title: "Calculators",
  })
);

export const CalculatorsTab = {
  volume: "volume",
  networks: "networks",
  emissions: "emissions",
} as const;

export * from "./CalculatorsPage";
