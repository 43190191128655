import { useBreadcrumbs } from "#routers/breadcrumbsHelper";
import { useNavigate } from "#routers/hooks";
import { EditRoleFormPanel } from "#src/batteries-included-components/Panels/DetailsPanel/EditRoleFormPanel";
import {
  RoleContext,
  RoleProvider,
} from "#src/routes/settings/roles-and-permissions/roles/[roleId]/detail/RoleContext";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  Button,
  Form,
  FormButton,
  Page,
  useAlert,
  useForm,
} from "@validereinc/common-components";
import { RoleUpdateType, RolesAdapter } from "@validereinc/domain";
import React, { useContext } from "react";
import { EDIT_ROLE_BREADCRUMB, EDIT_ROLE_PROFILE_TITLE } from ".";
import { ROLES_BREADCRUMB } from "../..";
import { ROLES_AND_PERMISSIONS_BREADCRUMB } from "../../..";
import { SETTINGS_BREADCRUMB } from "../../../..";
import { ROLE_DETAILS_BREADCRUMB, linkToRoleDetailPage } from "../detail";

const EditRolePageContent = () => {
  const navigate = useNavigate();
  const { addAlert } = useAlert();
  const { roleQuery } = useContext(RoleContext) ?? {};
  const form = useForm({ defaultValues: roleQuery?.data });
  const breadcrumbs = useBreadcrumbs(
    [
      SETTINGS_BREADCRUMB,
      ROLES_AND_PERMISSIONS_BREADCRUMB,
      ROLES_BREADCRUMB,
      ROLE_DETAILS_BREADCRUMB,
      EDIT_ROLE_BREADCRUMB,
    ],
    {
      3: roleQuery?.data?.name,
    },
    { roleId: roleQuery?.data?.id }
  );
  const queryClient = useQueryClient();

  const navigateToRoleDetail = () => {
    if (!roleQuery?.data?.id) return;

    navigate({ pathname: linkToRoleDetailPage(roleQuery.data.id) });
  };

  const updateRoleMutation = useMutation({
    mutationFn: async ({ name, description }: RoleUpdateType) => {
      if (!roleQuery?.data?.id) {
        throw new Error("Role ID must be specified to update it");
      }

      await RolesAdapter.updateOne({
        id: roleQuery.data.id,
        data: {
          name,
          description,
        },
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["roles"],
      });
      addAlert?.({
        variant: "success",
        message: "Successfully updated role",
      });
      navigateToRoleDetail();
    },
    onError: (err) => {
      console.error(err);
      addAlert?.({
        variant: "error",
        message: "Failed to update role",
      });
    },
  });

  const footer = (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        gap: 16,
        width: "100%",
        justifyContent: "space-between",
      }}
    >
      <Button
        onClick={navigateToRoleDetail}
        disabled={updateRoleMutation.isLoading}
        isLoading={roleQuery?.isLoading}
      >
        Cancel
      </Button>

      <FormButton
        variant="primary"
        type="submit"
        onClick={form.handleSubmit(updateRoleMutation.mutate)}
        disabled={roleQuery?.isLoading ?? updateRoleMutation.isLoading}
      >
        Save
      </FormButton>
    </div>
  );

  return (
    <Form
      {...form}
      onSubmit={form.handleSubmit(updateRoleMutation.mutate)}
      style={{ height: "100%" }}
    >
      <Page
        title={EDIT_ROLE_PROFILE_TITLE}
        category={`Role | ${roleQuery?.data?.name}`}
        isLoading={roleQuery?.isLoading}
        breadcrumbs={breadcrumbs}
        footer={footer}
      >
        <EditRoleFormPanel />
      </Page>
    </Form>
  );
};

export const EditRolePage = () => (
  <RoleProvider>
    <EditRolePageContent />
  </RoleProvider>
);
