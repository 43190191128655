import {
  EmptyState,
  Icon,
  KeyValueTileGrid,
  Panel,
} from "@validereinc/common-components";
import { DeviceType } from "@validereinc/domain";
import React from "react";

export const SelectedDevicesPanel = ({
  selectedDevices,
  onDeselectDevice,
}: {
  selectedDevices: DeviceType[];
  onDeselectDevice: (id: string) => void;
}) => {
  return (
    <Panel
      title="Selected Devices"
      isFluidY={false}
    >
      {selectedDevices?.length ? (
        <KeyValueTileGrid
          layout="even"
          data={selectedDevices.map(({ id, name }) => ({
            title: "Name",
            value: name,
            action: {
              onClick: () => onDeselectDevice(id),
              variant: "outline",
              icon: "minus-circle",
            },
          }))}
        />
      ) : (
        <EmptyState
          title="No Devices Selected"
          icon={<Icon variant="stack" />}
          suggestion="Select devices to add to event."
        />
      )}
    </Panel>
  );
};
