import { getStatusType } from "#common/Table/rendererHelper";
import { QUERY_STATUS } from "#constants";
import { useFormSchemas } from "#hooks/useForms";
import { linkToFormSubmissionDetail } from "#routers/links";
import FormService from "#services/FormService";
import { RoutingLink } from "#src/batteries-included-components/RoutingLink";
import { useStorageKey } from "#src/hooks/useStorageKey";
import useTableState from "#src/hooks/useTableState";
import { getPropertyAsMap } from "#utils/objectFormatter";
import {
  DataTable,
  DataTablePanel,
  SortingType,
} from "@validereinc/common-components";
import {
  FormSubmissionType,
  SortDirection,
  UserType,
  UsersAdapter,
} from "@validereinc/domain";
import { toStartCaseString } from "@validereinc/utilities";
import * as PropTypes from "prop-types";
import React, { useCallback, useMemo } from "react";

const sorting: SortingType = {
  sortBy: "created_at",
  sortDirection: SortDirection.DESCENDING,
};

const FormTable = ({ formCategoryId, relatedEntityId, relatedEntityType }) => {
  const { tableConfigStorageKey } = useStorageKey(
    `${relatedEntityType}-${relatedEntityId}-form-category-${formCategoryId}`
  );
  const [formSchemas, schemaLoadingState] = useFormSchemas();

  // IMPROVE: use new table rendering patterns. Out of scope at time of writing.
  const onFetchData = useCallback(async (newSearch) => {
    const { sort, sortDirection, page, rowPerPage } = newSearch;

    // IMPROVE: use new react-query data fetching patterns. Out of scope at time of writing.
    const {
      data: { data, ...pageData },
    } = await FormService.listFormSubmissions({
      sort: sort ?? sorting.sortBy,
      sortDirection: sortDirection ?? sorting.sortDirection,
      "form_category.id": formCategoryId,
      related_entity_id: relatedEntityId,
      related_entity_type: relatedEntityType,
      page,
      rowPerPage,
    });

    const userIds = Array.from<string>(
      new Set(data.map(({ created_by }: { created_by: string }) => created_by))
    );
    const usersQueries = await Promise.allSettled(
      userIds.map((id) => UsersAdapter.getOne({ id }))
    );
    const usersMap = usersQueries.reduce<Record<string, UserType>>(
      (map, query) => {
        if (query.status !== "fulfilled") {
          return map;
        }

        map[query.value.data.id] = query.value.data;
        return map;
      },
      {}
    );

    return {
      data: {
        ...pageData,
        data: data.map((submission: FormSubmissionType) => ({
          ...submission,
          created_by_user: usersMap?.[submission.created_by],
        })),
      },
    };
  }, []);

  const { tableProps } = useTableState({
    onFetchData,
    initialSort: sorting,
  });

  const items = useMemo(() => {
    const formSchemasMap = getPropertyAsMap(formSchemas);
    return tableProps?.items?.map((item) => {
      const form_schema = formSchemasMap[item?.form_schema_id];
      return {
        ...item,
        form_schema,
      };
    });
  }, [formSchemas, tableProps.items]);

  return (
    <DataTablePanel
      storageKey={tableConfigStorageKey}
      dataTableProps={{
        ...tableProps,
        isLoading:
          tableProps?.loading || schemaLoadingState !== QUERY_STATUS.SUCCESS,
        variant: "simplicity-first",
        items,
        headers: [
          {
            label: "Submission Name",
            key: "id",
            renderComponent: ({ item }) =>
              item?.form_schema && item.id ? (
                <RoutingLink to={linkToFormSubmissionDetail(item?.id)}>
                  {`${item?.form_schema?.name} - ${item?.id?.slice(0, 7)}`}
                </RoutingLink>
              ) : (
                "-"
              ),
          },
          {
            label: "Form Template",
            key: "form_schema.name",
          },
          {
            label: "Submission Date",
            key: "created_at",
            isSortable: true,
            renderComponent: ({ item }) => (
              <DataTable.DataRow.DateCell
                value={item.created_at}
                withTime
              />
            ),
          },
          {
            label: "Submitted By",
            key: "created_by",
            isSortable: true,
            renderComponent: ({ item }) => item.created_by_user?.name ?? "-",
          },
          {
            label: "Status",
            key: "status",
            isSortable: true,
            renderComponent: ({ item }) => (
              <DataTable.DataRow.PillCell
                variant={getStatusType(item.status, "form").type}
                value={toStartCaseString(item.status)}
              />
            ),
          },
        ],
      }}
    />
  );
};

FormTable.propTypes = {
  formCategoryId: PropTypes.string,
  relatedEntityId: PropTypes.string,
  relatedEntityType: PropTypes.string,
};

export default FormTable;
