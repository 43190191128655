import CustomAttributeField from "#components/Common/CustomAttributeField";
import { useSearchParams } from "#routers/hooks";
import { displayValueByDataType } from "#src/utils/display";
import {
  HeaderType,
  filterResponseToFilterConfig,
} from "@validereinc/common-components";
import { localStorageToJSON, toFlattenedObject } from "@validereinc/utilities";
import pick from "lodash/pick";
import React, { useCallback, useEffect, useMemo, useState } from "react";

// REVIEW: Datatable cannot currently render arrays or bools, requires
// additional common component work. This should be closer to the custom
// attribute domain model.
const DATATABLE_UNSUPPORTED_ATTR_TYPES = [
  "multi-pick-list",
  "number-array",
  "geo_point",
  "boolean",
];

/**
 * @deprecated use useReducer and tableReducer instead along with react query. see the conventions around tables in the developer docs for more info.
 */
export function useFilterPanel(
  headers,
  {
    localStorageKey,
    listCustomAttributes,
    listSavedFilters,
    saveFilter,
    editFilter,
    deleteFilter,
    persistentFilterKeys,
  } = {}
) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [customAttributes, setCustomAttributes] = useState([]);
  const [savedFilters, setSavedFilters] = useState(
    listSavedFilters ? [] : undefined
  );
  const [selectedColumns, setSelectedColumns] = useState(
    () => localStorageToJSON(localStorageKey) ?? []
  );
  const [customAttributesLoading, setCustomAttributesLoading] = useState(true);
  const [savedFiltersLoading, setSavedFiltersLoading] = useState(true);

  const fetchSavedFilters = useCallback(
    async (shouldReload = true) => {
      if (listSavedFilters) {
        if (shouldReload) {
          setSavedFiltersLoading(true);
        }

        // TODO: refactor this file into Typescript and consider applying a TS type guard
        // the old approach included status in the response while new domain API doesn't include status
        // so to support pages like Facilities we need the old approach
        // but for new pages like Events we use the new approach
        const response = await listSavedFilters({ rowPerPage: 1000 });

        const newSavedFilters = response.status
          ? response.data.data
          : response.data;

        setSavedFilters(newSavedFilters);
      } else {
        setSavedFilters(undefined);
      }
      setSavedFiltersLoading(false);
    },
    [listSavedFilters, setSavedFilters]
  );

  const fetchCustomAttributes = useCallback(async () => {
    if (listCustomAttributes) {
      setCustomAttributesLoading(true);
      const {
        data: { data: newCustomAttributes },
      } = await listCustomAttributes();
      setCustomAttributes(newCustomAttributes);
    } else {
      setCustomAttributes([]);
    }
    setCustomAttributesLoading(false);
  }, [listCustomAttributes, setCustomAttributes]);

  useEffect(async () => {
    await Promise.all([fetchCustomAttributes(), fetchSavedFilters()]);
  }, [fetchSavedFilters, fetchCustomAttributes]);

  const combinedHeaders: Array<HeaderType<unknown>> = useMemo(
    () => [
      ...headers,
      ...customAttributes.map(({ display_name, field_name, data_type }) => ({
        label: display_name,
        key: `custom_attributes.${field_name}`,
        renderComponent: ({ value }) =>
          displayValueByDataType(value, data_type),
      })),
    ],
    [headers, customAttributes]
  );

  const displayedHeaders = useMemo(
    () =>
      selectedColumns?.length
        ? combinedHeaders.filter(({ key }) => selectedColumns.includes(key))
        : combinedHeaders,
    [combinedHeaders, selectedColumns]
  );

  const customFilters = useMemo(
    () =>
      customAttributes.map((props, index) => ({
        section: "Filters",
        component: (
          <CustomAttributeField
            attribute={{ ...props, is_required: false }}
            isDisabled={false}
            isOptionalTextShown={false}
            isFilterForm
            key={`filter-${index}`}
          />
        ),
      })),
    [customAttributes]
  );

  const onChange = useCallback(
    ({ columnsToShow, ...restFilters }) => {
      if (columnsToShow?.length) {
        localStorage.setItem(localStorageKey, JSON.stringify(columnsToShow));
        setSelectedColumns(columnsToShow);
      }
      setSearchParams(toFlattenedObject(restFilters));
    },
    [setSelectedColumns, setSearchParams]
  );

  const onReset = ({ columnsToShow: _columnsToShow, ...restFilters }) => {
    const persistentFilters = pick(searchParams, persistentFilterKeys);
    const newFilters = {
      ...restFilters,
      ...persistentFilters,
    };

    setSearchParams(
      toFlattenedObject(newFilters, { transformObjectValue: (val) => val?.id })
    );
  };

  const onSaveFilter = saveFilter
    ? async (filterName, filters) => {
        const { savedFilter: _savedFilter, ...restFilters } = filters;
        const result = await saveFilter(filterName, restFilters);

        setSearchParams({
          savedFilter: result?.data?.id,
          ...filterResponseToFilterConfig(result?.data?.filter),
        });

        await fetchSavedFilters();
      }
    : undefined;

  const onEditFilter = editFilter
    ? async (id, filter) => {
        await editFilter(id, filter);

        await fetchSavedFilters(false);
      }
    : undefined;

  const onDeleteFilter = deleteFilter
    ? async (id) => {
        await deleteFilter(id);

        await fetchSavedFilters(false);
      }
    : undefined;

  return {
    isLoading: customAttributesLoading || savedFiltersLoading,
    headers: combinedHeaders,
    displayedHeaders,
    customAttributes,
    customFilters,
    filterProps: {
      value: searchParams,
      onChange,
      onReset,
      onSaveFilter,
      savedFilters,
      onEditFilter,
      onDeleteFilter,
    },
  };
}
