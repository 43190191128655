import {
  FacilityDropdownInput,
  FlowDropdownInput,
} from "#src/batteries-included-components/Dropdowns";
import { FilterArea, useFilterAreaContext } from "#src/components/FilterArea";
import { FilterDrawer } from "#src/components/FilterDrawer";
import { useListCalculatorResults } from "#src/components/hooks/adapters/useCalculatorResults";
import { useListCalculators } from "#src/components/hooks/adapters/useCalculators";
import { useListEquipmentTypes } from "#src/components/hooks/adapters/useEquipment";
import { useListFlowTypes } from "#src/components/hooks/adapters/useFlows";
import { useCustomAttributeFiltersV2 } from "#src/components/hooks/FilterPanel/useCustomAttributeFilters";
import { useListLibrary } from "#src/components/hooks/useLibrary";
import { DEVICE_STATUS_OPTIONS } from "#src/constants";
import useLocalization from "#src/hooks/useLocalization";
import {
  Accordion,
  DateSelectorInput,
  DropdownInput,
  TextInput,
  type StorageKeys,
  PillToggleProps,
  PillToggleGroup,
  PillToggleVariants,
} from "@validereinc/common-components";
import {
  AssetType,
  EstimationMethodStatus,
  EstimationMethodStatusType,
  FilterObjectType,
  FlowStatus,
  type AssetTypeType,
} from "@validereinc/domain";
import { toStartCaseString } from "@validereinc/utilities";
import endOfMonth from "date-fns/endOfMonth";
import startOfMonth from "date-fns/startOfMonth";
import React, { useMemo } from "react";
import { z } from "zod";
import startCase from "lodash/startCase";

export const EstimationMethodsTableFilterAreaContent = ({
  hasCustomAttributeFilters,
  assetType,
}: {
  hasCustomAttributeFilters?: boolean;
  assetType: AssetTypeType;
}) => {
  const { localize } = useLocalization();
  const { customAttributeFilters: facilityCustomAttrFilters } =
    useCustomAttributeFiltersV2({
      assetType: AssetType.FACILITY,
      enabled: hasCustomAttributeFilters,
      prefix:
        assetType === AssetType.FLOW
          ? "flow.facility.custom_attributes"
          : "equipment.facility.custom_attributes",
    });
  const { customAttributeFilters: equipmentCustomAttrFilters } =
    useCustomAttributeFiltersV2({
      assetType: AssetType.EQUIPMENT,
      enabled: hasCustomAttributeFilters,
      prefix:
        assetType === AssetType.FLOW
          ? "flow.equipment.custom_attributes"
          : "equipment.custom_attributes",
    });
  const { customAttributeFilters: flowCustomAttrFilters } =
    useCustomAttributeFiltersV2({
      assetType: AssetType.FLOW,
      enabled: hasCustomAttributeFilters,
      prefix: "flow.custom_attributes",
    });
  const equipmentTypes = useListEquipmentTypes({}).data?.data ?? [];
  const flowTypes = useListFlowTypes()?.data ?? [];
  const calculatorQuery = useListCalculators();
  const validCalculatorsQuery = useListCalculatorResults({
    filters: {
      entity_type: { $exact: assetType },
    },
    groupBy: ["estimation_method.analytics_calculator_id"],
  });
  const [libraries] = useListLibrary();

  const validCalculatorIds =
    validCalculatorsQuery.data?.data.map(
      (item) => item["estimation_method.analytics_calculator_id"]
    ) ?? [];

  const calculatorOptions = useMemo(
    () =>
      calculatorQuery.data?.calculators && validCalculatorIds.length
        ? calculatorQuery.data.calculators
            .map((calculator) => ({
              value: calculator.id,
              label:
                calculator.versions.find(
                  (version) => version.version === calculator.default_version
                )?.title ?? calculator.id,
            }))
            .filter(({ value }) => validCalculatorIds.includes(value))
        : [],
    [calculatorQuery.data, validCalculatorIds]
  );
  const libraryNames =
    libraries?.children?.map((library) => library.name) ?? [];

  return (
    <>
      <Accordion defaultActiveKeys={["est-methods", assetType]}>
        <Accordion.AccordionPanel
          dataKey="est-methods"
          title="Estimation Methods"
        >
          <DropdownInput
            name="analytics_calculator_id"
            label="Calculator Name"
            placeholder="Select a Calculator..."
            options={calculatorOptions}
            labelKey="label"
            valueKey="value"
            isLoading={calculatorQuery.isLoading}
            isOptionalTextShown={false}
          />
          <DropdownInput
            label="Library"
            name="analytics_library_id"
            placeholder="Select a Library..."
            options={libraryNames}
            isFluid
            isSearchable
            isOptionalTextShown={false}
          />
        </Accordion.AccordionPanel>
        <Accordion.AccordionPanel
          dataKey={AssetType.FACILITY}
          title={localize(`${AssetType.FACILITY}_plural`)}
        >
          <FacilityDropdownInput
            isFluid
            name={
              assetType === AssetType.FLOW
                ? "flow.associated_facility_id"
                : "equipment.facility.id"
            }
          />
          {facilityCustomAttrFilters}
        </Accordion.AccordionPanel>
        {assetType === AssetType.EQUIPMENT ? (
          <Accordion.AccordionPanel
            dataKey={AssetType.EQUIPMENT}
            title={localize(`${AssetType.EQUIPMENT}_plural`)}
          >
            <DropdownInput
              name="equipment.status"
              label={`${localize(AssetType.EQUIPMENT)} Status`}
              isOptionalTextShown={false}
              options={DEVICE_STATUS_OPTIONS}
              labelKey="label"
              valueKey="value"
              isMulti
            />
            <DropdownInput
              name="equipment.type.id"
              label={`${localize(AssetType.EQUIPMENT)} Type`}
              isOptionalTextShown={false}
              options={equipmentTypes}
              labelKey="name"
              valueKey="id"
              isMulti
            />
            {equipmentCustomAttrFilters}
          </Accordion.AccordionPanel>
        ) : null}
        {assetType === AssetType.FLOW ? (
          <Accordion.AccordionPanel
            dataKey={AssetType.FLOW}
            title={localize(`${AssetType.FLOW}_plural`)}
          >
            <FlowDropdownInput
              placeholder={`Search ${localize(`${AssetType.FLOW}_plural`)}...`}
              isFluid
              name="flow.id"
            />
            <DropdownInput
              name="flow.type"
              label={`${localize(AssetType.FLOW)} Type`}
              placeholder="Search Type..."
              isOptionalTextShown={false}
              options={flowTypes}
              labelKey="name"
              valueKey="id"
              isMulti
            />
            <DropdownInput
              name="flow.status"
              label="Flow Status"
              options={Object.values(FlowStatus).map((id) => ({
                id,
                name: toStartCaseString(id),
              }))}
              placeholder="Select Status..."
              labelKey="name"
              valueKey="id"
              isMulti
              isFluid
              isSearchable
              isOptionalTextShown={false}
            />
            {flowCustomAttrFilters}
          </Accordion.AccordionPanel>
        ) : null}
      </Accordion>
    </>
  );
};

export const EstimationMethodsTableFilterArea = ({
  filterConfigStorageKey,
  hasCustomAttributeFilters = true,
  assetType,
}: Pick<StorageKeys, "filterConfigStorageKey"> & {
  hasCustomAttributeFilters?: boolean;
  assetType: AssetTypeType;
}) => {
  return (
    <FilterArea.Root storageKey={filterConfigStorageKey}>
      <FilterArea.Container aria-label="Filters for Estimation Methods">
        <FilterDrawer.Root>
          <FilterArea.Content>
            {({ handleOnChange }) => (
              <div style={{ marginRight: 8, marginBottom: 0 }}>
                <TextInput
                  name="name"
                  label="Search"
                  isLabelShown={false}
                  placeholder="Search Methods..."
                  type="search"
                  isInline
                  onChange={(val) => handleOnChange(val, "name")}
                />
              </div>
            )}
          </FilterArea.Content>
          <FilterDrawer.Trigger />
          <FilterDrawer.Content>
            <EstimationMethodsTableFilterAreaContent
              hasCustomAttributeFilters={hasCustomAttributeFilters}
              assetType={assetType}
            />
          </FilterDrawer.Content>
        </FilterDrawer.Root>
      </FilterArea.Container>
    </FilterArea.Root>
  );
};

export const EstimationMethodDetailViewFilterSchema = z.object({
  month: z.object({
    from: z.string().datetime().optional(),
    to: z.string().datetime().optional(),
  }),
});

export type EstimationMethodDetailViewFilterType = z.infer<
  typeof EstimationMethodDetailViewFilterSchema
>;

export type EstimationMethodDetailFilterConfigFilterType = FilterObjectType<{
  analytics_calculator_id?: string;
  analytics_library_id?: string;
  name?: string;
  status?: EstimationMethodStatusType[];
  [AssetType.EQUIPMENT]?: object;
  [AssetType.FLOW]?: object;
}>;

export type EstimationMethodDetailViewConfigFilterType = {
  month?: {
    from: string;
    to: string;
  };
};

export const EstimationMethodDetailViewFilterArea = ({
  viewConfigStorageKey,
}: Pick<StorageKeys, "viewConfigStorageKey">) => {
  const name = EstimationMethodDetailViewFilterSchema.keyof().Enum.month;

  return (
    <FilterArea.Root
      storageKey={viewConfigStorageKey}
      defaultValues={{
        [name]: {
          from: startOfMonth(new Date()),
          to: endOfMonth(new Date()),
        },
      }}
      applyDefaultValues
    >
      <FilterArea.Container aria-label="Filters for Estimation Methods">
        <FilterArea.Content>
          {({ handleOnChange }) => (
            <DateSelectorInput
              name={name}
              variant="month"
              isRange={false}
              onChange={(val) => handleOnChange(val, name)}
            />
          )}
        </FilterArea.Content>
      </FilterArea.Container>
    </FilterArea.Root>
  );
};

const EstimationMethodStatusPillToggles = ({
  onChange,
}: {
  onChange: (val: string[], name: string) => void;
}) => {
  const { storedFilters: { status } = {} } = useFilterAreaContext<{
    status: string[];
  }>();
  const pillToggles: PillToggleProps[] = [
    {
      name: "All",
      label: "All",
      value: "",
      isSelected: !status?.length || status?.includes(""),
      shouldSelectAll: true,
    },
    {
      name: EstimationMethodStatus.ACTIVE,
      label: startCase(EstimationMethodStatus.ACTIVE),
      value: EstimationMethodStatus.ACTIVE,
      variant: PillToggleVariants.GOOD,
      isSelected: status?.includes(EstimationMethodStatus.ACTIVE) ?? false,
    },
    {
      name: EstimationMethodStatus.INACTIVE,
      label: startCase(EstimationMethodStatus.INACTIVE),
      value: EstimationMethodStatus.INACTIVE,
      variant: PillToggleVariants.NEUTRAL,
      isSelected: status?.includes(EstimationMethodStatus.INACTIVE) ?? false,
    },
  ];

  return (
    <PillToggleGroup
      name={"status"}
      pills={pillToggles}
      onChange={(val) => onChange(val, "status")}
    />
  );
};

export const EstimationMethodTableTitleDecorationFilterArea = ({
  filterConfigStorageKey,
}: Pick<StorageKeys, "filterConfigStorageKey">) => {
  const name = "status";
  return (
    <FilterArea.Root storageKey={filterConfigStorageKey}>
      <FilterArea.Container aria-label="Filters for estimation methods">
        <FilterArea.Content>
          {({ handleOnChange }) => (
            <div style={{ marginRight: 8, display: "flex", gap: 8 }}>
              <EstimationMethodStatusPillToggles
                onChange={(val) => handleOnChange(val, name)}
              />
            </div>
          )}
        </FilterArea.Content>
      </FilterArea.Container>
    </FilterArea.Root>
  );
};
