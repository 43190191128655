import { Button, Dialog, useAlert } from "@validereinc/common-components";
import React, { useState } from "react";
import InstrumentService from "../../../Services/InstrumentService";

export const ArchiveInstrumentModal = (props: {
  show: boolean;
  instrumentDetail?: Record<string, any> | null;
  onHide: () => void;
  refetchInstruments: () => void;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { addAlert } = useAlert();

  const action =
    props.instrumentDetail?.status !== "active" ? "unarchive" : "archive";

  const onSubmit = () => {
    const { instrumentDetail } = props;
    const actionFunc =
      action === "archive"
        ? InstrumentService.archiveInstrument
        : InstrumentService.unarchiveInstrument;

    if (!instrumentDetail) return;

    setIsLoading(true);

    actionFunc(instrumentDetail.id)
      .then(() => {
        setIsLoading(false);
        addAlert({
          variant: "success",
          message: "Instrument successfully archived.",
        });

        props.refetchInstruments();
        props.onHide();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const instrumentName = props.instrumentDetail
    ? props.instrumentDetail.name
    : "";

  return (
    <Dialog
      isOpen={props.show}
      onClose={props.onHide}
      title={`${action === "archive" ? "Archive" : "Unarchive"} ${instrumentName}`}
      actionRow={[
        action === "archive" ? (
          <Button
            variant="error"
            type="submit"
            disabled={isLoading}
            onClick={onSubmit}
          >
            Archive
          </Button>
        ) : (
          <Button
            variant="primary"
            type="submit"
            disabled={isLoading}
            onClick={onSubmit}
          >
            Unarchive
          </Button>
        ),
      ]}
    >
      <p>Are you sure you would like to {action} this instrument?</p>
    </Dialog>
  );
};

export default ArchiveInstrumentModal;
