import {
  Accordion,
  Form,
  Icon,
  Pill,
  TextInput,
  useForm,
  colours,
} from "@validereinc/common-components";
import classNames from "classnames/bind";
import React from "react";
import styles from "./CompanySelector.module.scss";
import { useAuthenticatedContext } from "#src/contexts/AuthenticatedContext.helpers";
import { useNavigate } from "#src/Routers/hooks";
import { HomeRoute } from "#src/routes/home";

const cx = classNames.bind(styles);

const companyIconBackgroundColours = [
  colours.colours.charts["100"],
  colours.colours.charts["400"],
  colours.colours.charts["300"],
  colours.colours.charts["600"],
];

const { AccordionPanel } = Accordion;

export const CompanySelector = ({
  currentCompanyIndex,
}: {
  currentCompanyIndex: number;
}) => {
  const {
    switchCompany,
    v2: {
      userInfo: { user },
      companyInfo: { company },
    },
  } = useAuthenticatedContext();
  const navigate = useNavigate();
  const form = useForm();
  const searchTerm = form.watch("searchTerm", "");
  const companies = user?.companies ?? [];
  const companiesToDisplay = companies?.filter((company) => {
    return company.name.toLowerCase().includes(searchTerm.toLowerCase());
  });

  return companies && companies.length > 1 ? (
    <div className={cx("account-menu-companies-accordion-container")}>
      <Accordion
        defaultActiveKeys={[]}
        className={cx("account-menu-companies-accordion")}
      >
        <AccordionPanel
          key="companies"
          dataKey="companies"
          title={
            <div
              className={cx("account-menu-companies-accordion-title-container")}
            >
              <p className={cx("account-menu-companies-accordion-title")}>
                Other Accounts
              </p>
              <Pill variant="default">{companies.length}</Pill>
            </div>
          }
          className={cx("account-menu-companies-accordion-panel")}
        >
          <Form {...form}>
            <div
              className={cx(
                "account-menu-companies-accordion-panel-content-container"
              )}
            >
              <div className={cx("account-menu-companies-search-input")}>
                <TextInput
                  name="searchTerm"
                  placeholder="Search Accounts..."
                  type="search"
                />
              </div>
              {companiesToDisplay && companiesToDisplay.length > 0 ? (
                <div className={cx("account-menu-company-items-container")}>
                  {companiesToDisplay.map((companyToDisplay, index) => {
                    const isCurrentCompany =
                      companyToDisplay.id === company?.id;
                    return (
                      <div
                        key={companyToDisplay.id}
                        className={cx(
                          !isCurrentCompany
                            ? "account-menu-company-item-container"
                            : "",
                          index === currentCompanyIndex - 1
                            ? "no-border-bottom"
                            : ""
                        )}
                      >
                        <div
                          className={cx(
                            "account-menu-company-item",
                            isCurrentCompany
                              ? "account-menu-current-company"
                              : ""
                          )}
                          onClick={() => {
                            switchCompany(companyToDisplay.id);
                            navigate({ pathname: HomeRoute.path });
                          }}
                        >
                          <div
                            className={cx(
                              "account-menu-company-item-icon-container"
                            )}
                            style={{
                              backgroundColor:
                                companyIconBackgroundColours[
                                  companies.findIndex(
                                    (company) =>
                                      company.id ===
                                      companiesToDisplay[index].id
                                  ) % companyIconBackgroundColours.length
                                ],
                            }}
                          >
                            <Icon
                              size={14}
                              variant="building"
                            />
                          </div>
                          <div
                            className={cx(
                              "account-menu-company-item-text-container"
                            )}
                          >
                            <p
                              className={cx(
                                "account-menu-company-item-name-text"
                              )}
                            >
                              {companyToDisplay.name}
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <p className={cx("account-menu-no-accounts-found-text")}>
                  No accounts found
                </p>
              )}
            </div>
          </Form>
        </AccordionPanel>
      </Accordion>
    </div>
  ) : null;
};
