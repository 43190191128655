import { linkToFacilities } from "#routers/links";
import FacilityService from "#services/FacilityService";
import { RoutingLink } from "#src/batteries-included-components/RoutingLink";
import config from "#src/config";
import useLocalization from "#src/hooks/useLocalization";
import { displayValueByDataType } from "#src/utils/display";
import { getTimeStringFromDate } from "#utils/timeFormatter";
import {
  KeyValuePanel,
  type KeyValuePanelProps,
} from "@validereinc/common-components";
import { formatCoordinateDegrees } from "@validereinc/utilities";
import React, { useEffect, useState } from "react";

const EquipmentDetailsPanel = ({
  equipmentDetail,
  isMapDisplayed,
}: EquipmentDetailsPanelProps) => {
  const [customAttributes, setCustomAttributes] = useState([]);
  const { localize, isLoading: isMappingLoading } = useLocalization();

  useEffect(() => {
    (async () => {
      const {
        data: { data },
      } = await FacilityService.getEquipmentCustomAttributes();

      setCustomAttributes(data);
    })();
  }, []);

  const data: KeyValuePanelProps["data"] = [
    {
      title: localize("Facility"),
      value: (
        <RoutingLink to={linkToFacilities(equipmentDetail?.facility?.id)}>
          {equipmentDetail?.facility?.name ?? "-"}
        </RoutingLink>
      ),
    },
    {
      title: "Effective Date",
      value: getTimeStringFromDate(
        equipmentDetail?.effective_date,
        config.DATEMONTH_FORMAT
      ),
    },
    { title: "Type", value: equipmentDetail?.type?.name },
    {
      title: "Location",
      value: formatCoordinateDegrees([
        equipmentDetail?.latitude,
        equipmentDetail?.longitude,
      ]),
    },

    ...(customAttributes
      ?.filter(
        ({ entity_subtype }) =>
          !entity_subtype || equipmentDetail?.type_id == entity_subtype
      )
      .map(({ display_name, field_name, data_type }) => {
        const value = equipmentDetail?.custom_attributes?.[field_name];

        return {
          title: display_name,
          value: displayValueByDataType(value, data_type),
        };
      }) ?? []),
  ];

  return (
    <KeyValuePanel
      panelMaxColumnCount={isMapDisplayed ? 2 : 3}
      panelKeyValueListProps={{ maxRowCount: 6, variant: "shaded" }}
      panelProps={{ isFluidY: false, loaded: !isMappingLoading }}
      data={data}
    />
  );
};

type EquipmentDetailsPanelProps = {
  equipmentDetail: any;
  isMapDisplayed?: boolean;
};

export default EquipmentDetailsPanel;
